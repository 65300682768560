import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../../config";
import {
  BsArrowDown,
  BsArrowLeft,
  BsArrowUp,
  BsThreeDots,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCompletedCoaching,
  GetPurchasedCoaching,
  GetTopCoaching,
  GetUpcomingCoaching,
  MarkCoachingAsCompleted,
} from "../../../redux/actions/coachingAction";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { toast, ToastContainer } from "react-toastify";
import {
  ErrorMessage,
  FieldError,
  getDateNTime,
  getTimeFormat,
  Loader,
} from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { CreateCoachingFeedback, CreateFeedback } from "../../../redux/actions";
import { useForm } from "react-hook-form";
import { FaEye } from "react-icons/fa";

const moment = require("moment-timezone");

export default function MyCoaching() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.coaching);
  useEffect(() => {
    dispatch(GetPurchasedCoaching(auth));
    dispatch(GetUpcomingCoaching(auth));
    dispatch(GetCompletedCoaching(auth));
    dispatch(GetTopCoaching());
    document.title = `My Coaching || EduTech`;
  }, [dispatch, auth]);
  let data = getListing && getListing.getPurchasedCoaching;
  let upCommingData = getListing && getListing.getUpcomingCoaching;
  let topCoaching = getListing && getListing.getTopCoaching;
  let completedData = getListing && getListing.getCompletedCoaching;
  console.log("test data for my coaching", topCoaching);
  let history = useHistory();
  let columns = [
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "zoomId",
      text: "Zoom ID",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "zoomPassCode",
      text: "Zoom PassCode",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "fixedDate",
      text: "Date-Time",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.fixedDate != null ? (
              <>
                {/* {getDateNTime(row.fixedDate) + getTimeFormat(row.fixedDate)
                  ? getDateNTime(row.fixedDate) +
                    " " +
                    getTimeFormat(row.fixedDate)
                  : ""} */}

                {row.fixedDate.split("T")[0] +
                  " , " +
                  moment(row.fixedDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
              </>
            ) : (
              <>
                {/* {getDateNTime(row.startRecurrentDate) +
                getTimeFormat(row.startRecurrentDate)
                  ? getDateNTime(row.startRecurrentDate) +
                    " " +
                    getTimeFormat(row.startRecurrentDate)
                  : ""}{" "} */}
                {row.startRecurrentDate.split("T")[0] +
                  " , " +
                  moment(
                    row.startRecurrentDate.split("T")[1],
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                -{" "}
                {row.endRecurrentDate.split("T")[0] +
                  " , " +
                  moment(row.endRecurrentDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                {/* {getDateNTime(row.endRecurrentDate) +
                getTimeFormat(row.endRecurrentDate)
                  ? getDateNTime(row.endRecurrentDate) +
                    " " +
                    getTimeFormat(row.endRecurrentDate)
                  : ""} */}
              </>
            )}
          </span>
        );
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        function editCoachingList() {
          history.push({
            pathname: PATH.MY_COACHING_PREVIEW,
            state: {
              id: row,
            },
          });
        }
        function markAsCompleted(id) {
          let finalData = {
            CoachingId: id,
          };
          dispatch(
            MarkCoachingAsCompleted(finalData, auth, Notification, moveToNext)
          );
        }
        function Notification(data) {
          toast.success(data, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
        function moveToNext() {
          dispatch(GetPurchasedCoaching(auth));
        }
        return (
          <>
            <div
              className="manage-user float-right font-600 text-secondary d-flex"
              id="clinician-table-three-button-dropdown"
            >
              <div
                className="cursor"
                id="clinician-table-three-button-dropdown"
              >
                <Dropdown className="vertical_dropdown_icon">
                  <Dropdown.Toggle className="status-options">
                    <BsThreeDots />
                  </Dropdown.Toggle>
                  <Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
                    <Dropdown.Item href="#" onClick={() => editCoachingList()}>
                      View
                    </Dropdown.Item>

                    <Dropdown.Item
                      href="https://zoom.us/join"
                      target="_blank"
                      className="zoom-link-class"
                    >
                      Zoom Link
                    </Dropdown.Item>
                    {row.status === "Complete" ? (
                      <Dropdown.Item href="#" disabled>
                        Completed
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        href="#"
                        onClick={() => markAsCompleted(row.coachingId)}
                      >
                        Mark As Completed
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item>
                      <FeedbackIconFunction id={row.coachingId} />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <DropdownButton
                  id="clinician-table-three-button-dropdown"
                  title={
                    <BsThreeDots
                      id="clinician-table-three-button-dropdown"
                      className="mr-2 cursor"
                    />
                  }
                  size="sm"
                  alignRight
                  bsPrefix="three-dot-icon"
                  className="edutech-admin-three-dot-icon  clinician-drop-down"
                >
                  <Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
                    <></>
                  </Dropdown.Menu>
                </DropdownButton> */}
              </div>
            </div>
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="teacher-curriculum-dashboard ">
        <Link to={PATH.ADMIN_DASHBOARD}>
          <BsArrowLeft size={35} className="mr-2" />
        </Link>
        <div
          className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Total Coaching</h3>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey={3}
        // onSelect={(k) => Call(k)}
        className="mb-3 explore_tabs"
      >
        <Tab eventKey={3} title="Total Coaching">
          <TotalCoaching
            columns={columns}
            data={data}
            getListing={getListing}
            defaultSorted={defaultSorted}
          />
        </Tab>
        <Tab eventKey={2} title=" Upcomming Coaching ">
          <UpcommingCoaching
            upCommingData={upCommingData}
            getListing={getListing}
          />
        </Tab>
        <Tab eventKey={1} title="Completed Coaching ">
          <CompletedCoaching
            completedData={completedData}
            getListing={getListing}
          />
        </Tab>
        <Tab eventKey={4} title="Recomended Coaching ">
          <TopCoaching topCoaching={topCoaching} getListing={getListing} />
        </Tab>
      </Tabs>
    </>
  );
}

function FeedbackIconFunction(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span
        onClick={(e) => {
          setModalShow(true);
        }}
      >
        FeedBack
      </span>
      <FeedBackModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        coachingId={props.id}
      />
    </>
  );
}

function FeedBackModal(props) {
  let dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const { register, handleSubmit, errors } = useForm();
  let auth = useAuth();
  function onSubmit(data) {
    let finalData = {
      subject: subject,
      description: description,
      coachingId: props?.coachingId,
    };
    dispatch(CreateCoachingFeedback(finalData, auth, Notification));
  }
  function Notification() {
    toast.success("Feedback has been sent to the course provider", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    setSubject("");
    setDescription("");
    props.onHide();
  }

  function spaceEnter(e) {
    if (e.keyCode === 32) {
      setSubject(subject + " ");
    }
  }

  function spaceEnterdescription(e) {
    if (e.keyCode === 32) {
      setDescription(description + " ");
    }
  }

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">FeedBack</Modal.Title>
        </Modal.Header>
        {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
        <Modal.Body>
          <Form.Group>
            <Form.Label>Subject:</Form.Label>
            <Form.Control
              className="mt-1 px-4 py-0 "
              name="subject"
              type="text"
              placeholder="Enter Your subject"
              value={subject}
              onKeyUp={(e) => {
                spaceEnter(e);
              }}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              style={{
                borderColor: errors && errors.subject ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {errors.subject && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description:</Form.Label>
            <Form.Control
              className="mt-1"
              name="description"
              as="textarea"
              maxlength="300"
              pattern="(/[^\s]+/,)"
              rows={3}
              required
              requiredMessage="This field is required."
              errorTarget="under"
              placeholder="Enter Your Description"
              value={description}
              onKeyUp={(e) => {
                spaceEnterdescription(e);
              }}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              style={{
                borderColor: errors && errors.description ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group text-right mb-0">
            <Button
              type="button"
              className="download-btn mb-2 bg-default-light mt-3 ml-4"
              variant="primary"
              onClick={() => onSubmit()}
            >
              Send
            </Button>
          </div>
        </Modal.Footer>
        {/* </Form> */}
      </Modal>
    </>
  );
}

function TotalCoaching(props) {
  return (
    <>
      {props.getListing &&
        props.getListing.getPurchasedCoachingLoading === true &&
        props.getListing.getPurchasedCoachingSuccess === false &&
        props.getListing.getPurchasedCoachingFailure === false && <Loader />}
      {props.getListing &&
        props.getListing.getPurchasedCoachingLoading === false &&
        props.getListing.getPurchasedCoachingSuccess === false &&
        props.getListing.getPurchasedCoachingFailure === true && (
          <ErrorMessage message={props.getListing.getPurchasedCoachingError} />
        )}
      {props.getListing &&
        props.getListing.getPurchasedCoachingLoading === false &&
        props.getListing.getPurchasedCoachingSuccess === true &&
        props.getListing.getPurchasedCoachingFailure === false && (
          <div className="admin-side-table-styling   card p-4 teacher-curriculum coaching_list_table">
            <MyDataTable
              data={props.data}
              columns={props.columns}
              defaultSorted={props.defaultSorted}
              // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}

function UpcommingCoaching(props) {
  let columns = [
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "companyTitle",
      text: "Company Title",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "interest",
      text: "Interest",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "fixedDate",
      text: "Date-Time",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.fixedDate != null ? (
              <>
                {/* {getDateNTime(row.fixedDate) + getTimeFormat(row.fixedDate)
                  ? getDateNTime(row.fixedDate) +
                    " " +
                    getTimeFormat(row.fixedDate)
                  : ""} */}

                {row.fixedDate.split("T")[0] +
                  " , " +
                  moment(row.fixedDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
              </>
            ) : (
              <>
                {/* {getDateNTime(row.startRecurrentDate) +
                getTimeFormat(row.startRecurrentDate)
                  ? getDateNTime(row.startRecurrentDate) +
                    " " +
                    getTimeFormat(row.startRecurrentDate)
                  : ""}{" "} */}
                {row.startRecurrentDate.split("T")[0] +
                  " , " +
                  moment(
                    row.startRecurrentDate.split("T")[1],
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                -{" "}
                {row.endRecurrentDate.split("T")[0] +
                  " , " +
                  moment(row.endRecurrentDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                {/* {getDateNTime(row.endRecurrentDate) +
                getTimeFormat(row.endRecurrentDate)
                  ? getDateNTime(row.endRecurrentDate) +
                    " " +
                    getTimeFormat(row.endRecurrentDate)
                  : ""} */}
              </>
            )}
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {props.getListing &&
        props.getListing.getUpcomingCoachingLoading === true &&
        props.getListing.getUpcomingCoachingSuccess === false &&
        props.getListing.getUpcomingCoachingFailure === false && <Loader />}
      {props.getListing &&
        props.getListing.getUpcomingCoachingLoading === false &&
        props.getListing.getUpcomingCoachingSuccess === false &&
        props.getListing.getUpcomingCoachingFailure === true && (
          <ErrorMessage message={props.getListing.getUpcomingCoachingError} />
        )}
      {props.getListing &&
        props.getListing.getUpcomingCoachingLoading === false &&
        props.getListing.getUpcomingCoachingSuccess === true &&
        props.getListing.getUpcomingCoachingFailure === false && (
          <div className="admin-side-table-styling   card p-4 teacher-curriculum coaching_list_table">
            <MyDataTable
              data={props.upCommingData}
              columns={columns}
              defaultSorted={defaultSorted}
              // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}

function CompletedCoaching(props) {
  let columns = [
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "companyTitle",
      text: "Company Title",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "zoomId",
      text: "Zoom ID",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "zoomPassCode",
      text: "Zoom PassCode",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "fixedDate",
      text: "Date-Time",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.fixedDate != null ? (
              <>
                {/* {getDateNTime(row.fixedDate) + getTimeFormat(row.fixedDate)
                  ? getDateNTime(row.fixedDate) +
                    " " +
                    getTimeFormat(row.fixedDate)
                  : ""} */}

                {row.fixedDate.split("T")[0] +
                  " , " +
                  moment(row.fixedDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
              </>
            ) : (
              <>
                {/* {getDateNTime(row.startRecurrentDate) +
                getTimeFormat(row.startRecurrentDate)
                  ? getDateNTime(row.startRecurrentDate) +
                    " " +
                    getTimeFormat(row.startRecurrentDate)
                  : ""}{" "} */}
                {row.startRecurrentDate.split("T")[0] +
                  " , " +
                  moment(
                    row.startRecurrentDate.split("T")[1],
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                -{" "}
                {row.endRecurrentDate.split("T")[0] +
                  " , " +
                  moment(row.endRecurrentDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                {/* {getDateNTime(row.endRecurrentDate) +
                getTimeFormat(row.endRecurrentDate)
                  ? getDateNTime(row.endRecurrentDate) +
                    " " +
                    getTimeFormat(row.endRecurrentDate)
                  : ""} */}
              </>
            )}
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {props.getListing &&
        props.getListing.getCompletedCoachingLoading === true &&
        props.getListing.getCompletedCoachingSuccess === false &&
        props.getListing.getCompletedCoachingFailure === false && <Loader />}
      {props.getListing &&
        props.getListing.getCompletedCoachingLoading === false &&
        props.getListing.getCompletedCoachingSuccess === false &&
        props.getListing.getCompletedCoachingFailure === true && (
          <ErrorMessage message={props.getListing.getCompletedCoachingError} />
        )}
      {props.getListing &&
        props.getListing.getCompletedCoachingLoading === false &&
        props.getListing.getCompletedCoachingSuccess === true &&
        props.getListing.getCompletedCoachingFailure === false && (
          <div className="admin-side-table-styling   card p-4 teacher-curriculum coaching_list_table">
            <MyDataTable
              data={props.completedData}
              columns={columns}
              defaultSorted={defaultSorted}
              // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}

function TopCoaching(props) {
  let history = useHistory();
  let columns = [
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "companyTitle",
      text: "Company Title",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "recurrence",
      text: "Recurrence",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return <span>{row.recurrence === "None" ? "No" : "Yes"}</span>;
      },
    },
    {
      dataField: "fixedDate",
      text: "Date-Time",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.fixedDate != null ? (
              <>
                {/* {getDateNTime(row.fixedDate) + getTimeFormat(row.fixedDate)
                  ? getDateNTime(row.fixedDate) +
                    " " +
                    getTimeFormat(row.fixedDate)
                  : ""} */}
                {row.fixedDate.split("T")[0] +
                  " , " +
                  moment(row.fixedDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
              </>
            ) : (
              <>
                {row.startRecurrentDate.split("T")[0] +
                  " , " +
                  moment(
                    row.startRecurrentDate.split("T")[1],
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                {/* {getDateNTime(row.startRecurrentDate) +
                getTimeFormat(row.startRecurrentDate)
                  ? getDateNTime(row.startRecurrentDate) +
                    " " +
                    getTimeFormat(row.startRecurrentDate)
                  : ""}{" "} */}
                -{" "}
                {row.endRecurrentDate.split("T")[0] +
                  " , " +
                  moment(row.endRecurrentDate.split("T")[1], "HH:mm:ss").format(
                    "hh:mm A"
                  )}
                {/* {getDateNTime(row.endRecurrentDate) +
                getTimeFormat(row.endRecurrentDate)
                  ? getDateNTime(row.endRecurrentDate) +
                    " " +
                    getTimeFormat(row.endRecurrentDate)
                  : ""} */}
              </>
            )}
          </span>
        );
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        function editCoachingList() {
          history.push({
            pathname: PATH.COACHING_PREVIEW,
            state: {
              CoachingData: row,
              Coaching: "RECOMMENDED",
            },
          });
        }
        return (
          <>
            <FaEye className="cursor" onClick={() => editCoachingList()} />
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {props.getListing &&
        props.getListing.getTopCoachingLoading === true &&
        props.getListing.getTopCoachingSuccess === false &&
        props.getListing.getTopCoachingFailure === false && <Loader />}
      {props.getListing &&
        props.getListing.getTopCoachingLoading === false &&
        props.getListing.getTopCoachingSuccess === false &&
        props.getListing.getTopCoachingFailure === true && (
          <ErrorMessage message={props.getListing.getTopCoachingError} />
        )}
      {props.getListing &&
        props.getListing.getTopCoachingLoading === false &&
        props.getListing.getTopCoachingSuccess === true &&
        props.getListing.getTopCoachingFailure === false && (
          <div className="admin-side-table-styling   card p-4 teacher-curriculum coaching_list_table">
            <MyDataTable
              data={props.topCoaching}
              columns={columns}
              defaultSorted={defaultSorted}
              // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}
