import { request, success, failure, COACHING_REQUEST } from "./utilities";
import {
  addUpdateCoaching,
  deleteCoaching,
  getCoachingList,
  updateCoaching,
  publishCoaching,
  updateAdminCoaching,
  getUpcomingCoaching,
  getTodaysCoaching,
  getTopCoaching,
  getPurchasedCoaching,
  getCompletedCoaching,
  purchaseCoaching,
  getUpcomingCoachingLP,
  getTodaysCoachingLP,
  markCoachingAsCompleted,
} from "../api";

export function AddUpdateCoaching(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.ADD_UPDATE_COACHING_REQUEST));
    addUpdateCoaching(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.ADD_UPDATE_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.ADD_UPDATE_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.ADD_UPDATE_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UpdateAdminCoaching(data, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.UPDATE_ADMIN_COACHING_REQUEST));
    updateAdminCoaching(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.UPDATE_ADMIN_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.UPDATE_ADMIN_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.UPDATE_ADMIN_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetCoachingList(auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_COACHING_LIST_REQUEST));
    getCoachingList(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_COACHING_LIST_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_COACHING_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_COACHING_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetPurchasedCoaching(auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_PURCHASED_COACHING_REQUEST));
    getPurchasedCoaching(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_PURCHASED_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_PURCHASED_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_PURCHASED_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function DeleteCoaching(coachingId, auth, loadingState, notification) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.DELETE_COACHING_REQUEST));
    if (loadingState) {
      loadingState(true);
    }
    deleteCoaching(coachingId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(COACHING_REQUEST.DELETE_COACHING_SUCCESS, coachingId)
          );
          if (loadingState) {
            loadingState(false);
          }
          notification(response.data.message);
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.DELETE_COACHING_FAILURE,
              response.data.message
            )
          );
          if (loadingState) {
            loadingState(false);
          }
        }
      },
      (error) => {
        if (loadingState) {
          loadingState(false);
        }
        dispatch(
          failure(
            COACHING_REQUEST.DELETE_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PublishCoaching(
  data,
  auth,
  loadingState,
  AfterStatusChange,
  Notification
) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.PUBLISH_COACHING_REQUEST));
    if (loadingState) {
      loadingState(true);
    }
    publishCoaching(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(COACHING_REQUEST.PUBLISH_COACHING_SUCCESS, data));
          if (loadingState) {
            loadingState(false);
          }
          AfterStatusChange();
          Notification(response.data.message);
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.PUBLISH_COACHING_FAILURE,
              response.data.message
            )
          );
          if (loadingState) {
            loadingState(false);
          }
        }
      },
      (error) => {
        if (loadingState) {
          loadingState(false);
        }
        dispatch(
          failure(
            COACHING_REQUEST.PUBLISH_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PurchaseCoaching(data, auth, AfterBuyCoaching, notification) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.PUBLISH_COACHING_REQUEST));
    purchaseCoaching(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(COACHING_REQUEST.PUBLISH_COACHING_SUCCESS, data));
          notification(response.data.message, "success");
          AfterBuyCoaching();
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.PUBLISH_COACHING_FAILURE,
              response.data.message
            )
          );
          notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.PUBLISH_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UpdateCoaching(coachingId, auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.UPDATE_COACHING_REQUEST));
    updateCoaching(coachingId, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.UPDATE_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.UPDATE_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.UPDATE_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function MarkCoachingAsCompleted(data, auth, Notification, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.MARK_COACHING_COMPELTE_REQUEST));
    markCoachingAsCompleted(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.MARK_COACHING_COMPELTE_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.MARK_COACHING_COMPELTE_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.MARK_COACHING_COMPELTE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetUpcomingCoaching(auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_UPCOMING_COACHING_REQUEST));
    getUpcomingCoaching(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_UPCOMING_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_UPCOMING_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_UPCOMING_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetCompletedCoaching(auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_COMPLETED_COACHING_REQUEST));
    getCompletedCoaching(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_COMPLETED_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_COMPLETED_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_COMPLETED_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetTodaysCoaching(auth, moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_TODAYS_COACHING_REQUEST));
    getTodaysCoaching(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_TODAYS_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_TODAYS_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_TODAYS_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetTopCoaching(moveToNext) {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_TOP_COACHING_REQUEST));
    getTopCoaching().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_TOP_COACHING_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_TOP_COACHING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_TOP_COACHING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetTodaysCoachingLP() {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_TODAYS_COACHING_LP_REQUEST));
    getTodaysCoachingLP().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_TODAYS_COACHING_LP_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_TODAYS_COACHING_LP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_TODAYS_COACHING_LP_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetUpcomingCoachingLP() {
  return (dispatch) => {
    dispatch(request(COACHING_REQUEST.GET_UPCOMING_COACHING_LP_REQUEST));
    getUpcomingCoachingLP().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              COACHING_REQUEST.GET_UPCOMING_COACHING_LP_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              COACHING_REQUEST.GET_UPCOMING_COACHING_LP_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            COACHING_REQUEST.GET_UPCOMING_COACHING_LP_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
