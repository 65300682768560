import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function createFaqs(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.FAQS.createFaqs, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function updateFaqs(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.FAQS.updateFaqs, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getFAQByStudent() {
  return axios.get(APP_SETTINGS.API_PATH.FAQS.getFAQByStudent, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}
export function getFAQByProvider() {
  return axios.get(APP_SETTINGS.API_PATH.FAQS.getFAQByProvider, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}