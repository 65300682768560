import React from "react";
import { useEffect } from "react";
import {Revanue} from "./Revanue";
import { Link } from "react-router-dom";
import  { Subscriber } from "./Piecharts";
import {Topcategpory} from "./Topcategory";
import TopCourses from "./TopCourses";
import {
  FaBookOpen,
  FaUserFriends,
  FaChartLine,
  FaUserTie,
} from "react-icons/fa";
import { PATH } from "../../../config";
import { AdminDashboardTopCategoriesGraph, AdminDashboardTopCoursesGraph, GetAdminDashboardTiles } from "../../../redux/actions/dashboardTilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
export default function AdminDashboard() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let GetTilesCount = useSelector((state) => state.dashboardTiles);
  let data = GetTilesCount && GetTilesCount.getAdminDashboardTiles;
  let CategoryData = GetTilesCount && GetTilesCount.adminDashboardTopCategoriesGraph;
  let CoursesData = GetTilesCount && GetTilesCount.adminDashboardTopCoursesGraph;
  useEffect(() => {
    // Update the document title using the browser API
    dispatch(GetAdminDashboardTiles(auth));
    dispatch(AdminDashboardTopCategoriesGraph(auth));
    dispatch(AdminDashboardTopCoursesGraph(auth));
    document.title = `Dashboard || EduTech`;
  }, []);
  return (
    <>
      <div className="admin-dashboard-styling">
        <div className="row d-flex justify-conten-between ">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <Link to={PATH.ADMIN_TOTAL_COURSES}>
              <div className=" p-4 card-body-styling">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FaBookOpen className="div-style" />
                    <span className="ml-2 set-font-size">Total Courses</span>
                  </div>
                  <div className="mb-0 circle-styling">
                    <span>{data.totalCourses}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <Link to={PATH.ADMIN_TOTAL_USERS}>
              <div className=" p-4 card-body-styling">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FaUserFriends className="div-style" />
                    <span className="ml-2 set-font-size">Total Users</span>
                  </div>
                  <div className="mb-0 circle-styling">
                    <span>{data.totalUsers}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <Link to={PATH.ADMIN_TOTAL_COACHING}>
              <div className=" p-4 card-body-styling">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FaUserTie className="div-style" />
                    <span className="ml-2 set-font-size">Total Coaching</span>
                  </div>
                  <div className="mb-0 circle-styling">
                    <span>{data.totalCoachings}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <Link to={PATH.ADMIN_TOTAL_SALES}>
              <div className=" p-4 card-body-styling">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <FaChartLine className="div-style" />
                    <span className="ml-2 set-font-size">Total Sales</span>
                  </div>
                  <div className="mb-0 circle-styling">
                    <span>{data.totalSales}</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className=" row">
          {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  mt-1 ">
            <div className="card set-card-border-radius">
              <div className=" card-header ">
                <h4 className="mb-0">Reports and Statistics</h4>
              </div>
              <div className="px-2 pt-4">
                <ReportAndStatics />
              </div>
            </div>
          </div> */}
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  mt-1 ">
            <div className="card set-card-border-radius">
              <div className=" card-header ">
                <h4 className="mb-0">Revenue</h4>
              </div>
              <div className="px-2 pt-4">
                <Revanue />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  mt-1 ">
            <div className="card set-card-border-radius">
              <div className=" card-header ">
                <h4 className="mb-0">Subscribers </h4>
              </div>
              <div className="px-2 pt-4">
                <Subscriber />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  mt-1 ">
            <div className="card set-card-border-radius">
              <div className=" card-header ">
                <h4 className="mb-0">Top Categories</h4>
              </div>
              <div className="px-2 pt-4">
                <Topcategpory CategoryData={CategoryData} />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6  mt-1 ">
            <div className="card set-card-border-radius">
              <div className=" card-header ">
                <h4 className="mb-0">Top Courses </h4>
              </div>
              <div className="px-2 pt-4">
                <TopCourses CoursesData={CoursesData} />
              </div>
            </div>
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  mt-1 ">
            <div className="card set-card-border-radius">
              <div className=" card-header ">
                <h4 className="mb-0">Top Category </h4>
              </div>
              <div className="px-2 pt-4">
                <Topcategpory />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
