import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaMinus } from "react-icons/fa";
import RequestCategory from "./RequestCategory";
import { CreateInterest,  GetAllApprovedInterest } from "../../../redux/actions/interestAction";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, FieldError, Loader } from "../../../assets";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
export default function RequestTopic() {
  const [showInputButton, setInputButton] = useState(false);
  const [description, setDescription] = useState(null);
  const [formValues, setFormValues] = useState([]);
  useEffect(() => { }, [formValues]);
  let dispatch = useDispatch();
  let auth = useAuth();
  useEffect(() => {
    document.title = "RequestTopic || EduTech";
    dispatch(GetAllApprovedInterest());
  }, [dispatch]);
  let interestValue = useSelector((state) => state.interest);
  let approvecategory = interestValue && interestValue.getAllApprovedInterest;
  const { handleSubmit, register, reset, errors } = useForm();
  const [showField, setAddField] = useState(false);
  const [showbuttonValue, setbuttonValue] = useState([]);
  
  function onSubmit(data) {
    let finalData = {
      "description": data.description,
    }
    dispatch(CreateInterest(finalData, auth, moveToNext, Notificiation))

  }
  function moveToNext() {
    window.location.reload(false);
  }
  function Notificiation(data, condition) {
    reset();
    condition === "error" ?
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
  return (
    <>
      <div className="settings-view">
        <div className="row">
          <div className="col-12 col-xl-12 col-lg-12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group>
                <div className="d-flex align-items-center justify-content-between admin">
                  <Form.Label className="bold-800 font-20">
                    {`Current Categories`}{" "}
                  </Form.Label>{" "}
                  <Button
                    type="button"
                    className={`cursor add-button`}
                    onClick={() => setAddField(true)}
                  >
                    Add Category
                  </Button>
                </div>
                <div className="interest-btn">
                  <div className="interest-btn">
                    <>
                      <>
                        {interestValue &&
                          interestValue.getAllApprovedInterestLoading === true &&
                          interestValue.getAllApprovedInterestSuccess === false &&
                          interestValue.getAllApprovedInterestFailure === false &&
                          <Loader />}
                        {interestValue &&
                          interestValue.getAllApprovedInterestLoading === false &&
                          interestValue.getAllApprovedInterestSuccess === false &&
                          interestValue.getAllApprovedInterestFailure === true && (
                            <ErrorMessage message={interestValue.getAllApprovedInterestError} />
                          )}
                        {interestValue &&
                          interestValue.getAllApprovedInterestLoading === false &&
                          interestValue.getAllApprovedInterestSuccess === true &&
                          interestValue.getAllApprovedInterestFailure === false && (
                            <div>
                              {approvecategory && approvecategory.map((interest, index) => {
                                return (
                                  <>
                                    <ButtonSection interest={interest} index={index} />
                                  </>
                                );
                              })}
                            </div>
                          )}
                      </>

                    </>
                  </div>
                  {showField === true ? (
                    <div className="col-md-8">
                      <Row className="">
                        <Col xs={1} className="d-flex align-self-start p-0">
                          <Button
                            type="button"
                            className={`cursor add-button`}
                            onClick={() => setAddField(false)}
                          >
                            <FaMinus size={30} />
                          </Button>
                        </Col>
                        <Col xs={9}>
                          <div className="form-group ">
                            <input
                              type="text"
                              className="form-control floating mt-3 edutech-plus-field"
                              name="description"
                              placeholder="Enter new topic"
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                              style={{
                                borderColor: errors && errors.description ? "#a80000" : "",
                              }}
                              ref={register({ required: true })}
                            />
                            {errors.description && (
                              <FieldError message={"This Field is Required"} />
                            )}
                          </div>
                        </Col>
                        <Col xs={2} className="d-flex align-self-start p-0">
                          <button
                            type="submit"
                            className="btn DeleleteIcon-btn"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <RequestCategory />
    </>
  );
}
function ButtonSection(props) {
  const [buttonState, setButtonState] = useState(false);
  let { index } = props;
  return (
    <>
      {
        <Button
          type="button"
          key={index}
          onClick={() => setButtonState(!buttonState)}
          className={`cursor  ${buttonState === true ? "active" : ""}`}
        >
          {props.interest.description}
        </Button>
      }
    </>
  );
}
