import {
  createManualPriceRange,
  deletePricingPlan,
  getManualPricingRange,
  getSelectedPricing,
  priceCheckWithinRange,
  pricingPlan,
  pricingPlanByFilter,
  selectPricingPlans,
} from "../api";
import { request, success, failure, PRICING_PLAN_REQUEST } from "./utilities";

export function PricingPlan(
  data,
  auth,
  Notificiation,
  moveToNext,
  AfterCreatePriceSuccess
) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.PRICING_PLAN_REQUEST));
    pricingPlan(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.PRICING_PLAN_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notificiation(response.data.message, "success");
          AfterCreatePriceSuccess();
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.PRICING_PLAN_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.PRICING_PLAN_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function SelectPricingPlans(data, auth, Notificiation) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_REQUEST));
    selectPricingPlans(data, auth, Notificiation).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_SUCCESS,
              response.data.data
            )
          );
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.SELECT_PRICING_PLAN_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CreateManualPriceRange(data, auth, Notification, moveToNext) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_REQUEST));
    createManualPriceRange(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notification(response.data.message, "success");
          // AfterCreatePriceSuccess()
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.CREATE_MANUAL_PRICING_RANGE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PricingPlanByFilter(data) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_REQUEST));
    pricingPlanByFilter(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.PRICING_PLAN_BY_FILTER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetSelectedPricing(data) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_REQUEST));
    getSelectedPricing(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.GET_SELECTED_PRICING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function DeletePricingPlan(data, auth, moveToNext, Notificiation) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_REQUEST));
    deletePricingPlan(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_SUCCESS, data)
          );
          if (moveToNext) {
            moveToNext();
          }
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.DELETE_PRICING_PLAN_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetManualPricingRange() {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_REQUEST));
    getManualPricingRange().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.GET_MANUAL_PRICING_RANGE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function PriceCheckWithinRange(price, auth, Notificiation) {
  return (dispatch) => {
    dispatch(request(PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_REQUEST));
    priceCheckWithinRange(price, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_SUCCESS,
              response.data.data
            )
          );
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(
              PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_FAILURE,
              response.data.message
            )
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            PRICING_PLAN_REQUEST.PRICE_CHECK_WITH_RANGE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
