import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function createFeedback(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.FEED_BACK.createFeedback, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function createCoachingFeedback(data, auth) {
  return axios.post(
    APP_SETTINGS.API_PATH.FEED_BACK.createCoachingFeedback,
    data,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
