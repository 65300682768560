import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router";
import { PATH } from "../../../../../config";
import { Link } from "react-router-dom";
export default function CreateNewSection(props) {
  const [inputFeeld, setInputFeeld] = useState();
  let history = useHistory();
  function goBack() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
      // state: { fromPreviewPage: fromPreviewPage },
    });
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h5 onClick={() => goBack()} className="cursor">
          {" "}
          <BsArrowLeft size={35} className="cursor mr-2" />
           Section Name{" "}
        </h5>
        <div className="teacher-curriculum-dashboard ">
          <Link
            to={{
              pathname: PATH.TEACHERCURRICULUMLIST,
              // state: { fromPreviewPage: fromPreviewPage },
            }}
          >
            <button className="create_course w-100" type="button">
              Save
            </button>
          </Link>
        </div>
      </div>
      <div className="d-flex mt-5 create-new-section">
        <div className="col-4">
          <h5> New Section</h5>
        </div>
        <div className="col-8">
          <Card className="bg-color:white   px-3 py-2 create-pricing text-center">
            <Card.Body className="text-left">
              <label className="text-left">Section Name</label>
              <input
                className="form-control"
                value={inputFeeld}
                onChange={(e) => setInputFeeld(e.target.value)}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
