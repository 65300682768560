import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function lookUpList(data) {
  return axios.get(APP_SETTINGS.API_PATH.LOOKUP.lookUp, data);
}
export function uploadAzure(data) {
  return axios.post(APP_SETTINGS.API_PATH.LOOKUP.AZURE.uploadAzure, data);
}
export function uploadImage(data) {
  return axios.post(APP_SETTINGS.API_PATH.LOOKUP.AZURE.uploadImage, data);
}
