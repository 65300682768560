import React, { useState } from "react";
import {
  Card,
  Dropdown,
  Accordion,
  Tab,
  Tabs,
  Modal,
  Form,
} from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { BiCaretDown } from "react-icons/bi";
import { BsBookmark, BsArrowLeft } from "react-icons/bs";
import { IMAGES } from "../../../../../assets";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { PATH } from "../../../../../config";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router";
import { IoCloseSharp } from "react-icons/io5";
import ReactPlayer from "react-player";
const steps = [
  {
    label: "Introduction to Business Breakthrough",
    description: `(4 minutes)`,
  },
  {
    label: "What is a Breakthrough?",
    description: "(3 minutes)",
  },
  {
    label: "Who can Breakthrough?",
    description: `(3 minutes)`,
  },
  {
    label: "Breakthrough with Confidence",
    description: `(5 minutes)`,
  },
  {
    label: "PIF’s mission and vision",
    description: `(4 minutes)`,
  },
  {
    label: "Quiz",
    description: ``,
  },
];

export default function TeacherCurriculumPreview() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [showfunc, setfunc] = React.useState(0);
  const [condition, setCondition] = useState(0);
  const [showCertificate, setCertificateSection] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setfunc((prevActiveStep) => prevActiveStep + 1);
    if (showfunc === 0) {
      setCondition(0);
    }
    if (showfunc === 1) {
      setCondition(1);
    }
    if (showfunc === 2) {
      setCondition(2);
    }
    if (showfunc === 3) {
      setCondition(3);
    }
    if (showfunc === 4) {
      setCondition(4);
    }
    if (showfunc === 5) {
      setCertificateSection(true);
    }
    // if (showfunc == 4) {
    //   setCondition(0);
    // }
  };
  const handleReset = () => {
    setActiveStep(0);
    if (condition === false) {
      setCondition(true);
    } else {
      setCondition(false);
    }
    setActiveStep(0);
    setfunc(0);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setfunc((prevActiveStep) => prevActiveStep - 1);
  };
  const ANSWAER_CHOOSE = [
    {
      lookupId: 1,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 1,
    },
    {
      lookupId: 2,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 2,
    },
    {
      lookupId: 3,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 3,
    },
    {
      lookupId: 4,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 4,
    },
  ];
  const ANSWAER_CHOOSE1 = [
    {
      lookupId: 5,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 5,
    },
    {
      lookupId: 6,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 6,
    },
    {
      lookupId: 7,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 7,
    },
    {
      lookupId: 8,
      type: "group",
      text: "Capital, Profits, Talent, Keymanship",
      value: "Capital, Profits, Talent, Keymanship",
      order: 8,
    },
  ];
  const [button, setButton] = useState(0);
  const [button1, setButton1] = useState(0);
  let history = useHistory();
  function goBack() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
      // state: { fromPreviewPage: fromPreviewPage },
    });
    // history.push(PATH.TEACHERCURRICULUMLIST)
  }
  let urls = [
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
    ["https://www.youtube.com/embed/8C46CbYocXI"],
    ["https://www.youtube.com/embed/nlMuHtV82q8"],
    ["https://www.youtube.com/embed/E9lAeMz1DaM"],
  ];
  return (
    <>
      <h5 onClick={() => goBack()} className="cursor">
        {" "}
        <BsArrowLeft size={35} className="cursor mr-2" />{" "}
      </h5>
      <div className="row dashboard-page business-breakthrough-dashboard">
        <div className="col-sm-12 col-lg-4 col-md-4 p-4 bg-white mb-4 pb-2 div-rounded-30">
          <Accordion defaultActiveKey="0" className="bg-white">
            <div className="mb-0 bg-white ">
              <Card.Header className="pl-1 pt-1 bg-white">
                <div className="row justify-content-between bg-white">
                  <div className="col-10 bg-white">
                    <Accordion.Toggle
                      className="p-0 bg-white"
                      as={Button}
                      Introduction
                      to
                      Business
                      Breakthrough
                      variant="link"
                      eventKey="0"
                    >
                      <h3 className="text-dark m-0 p-0 font-size-22 accordin-title font-weight-700 text-left">
                        Business Breakthrough
                      </h3>
                    </Accordion.Toggle>
                  </div>

                  <div className="col-2  text-right">
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <BiCaretDown className="text-dark set-icon-font-size" />
                    </Accordion.Toggle>
                  </div>
                </div>
                <p className=" mb-0 pt-0 font-size-12 text-dark text-left font-weight-600">
                  6 videos | Total: 19 minutes
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Box sx={{ maxWidth: 400 }}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index = 0) => (
                      <Step type="button" key={step.label}>
                        <StepLabel
                          optional={
                            (index === 0 && (
                              <Typography
                                variant="caption"
                                className="cursor"
                                onClick={() => setfunc(0) || setActiveStep(0)}
                              >
                                (3 minutes)
                              </Typography>
                            )) ||
                            (index === 1 && (
                              <Typography
                                variant="caption"
                                className="cursor"
                                onClick={() => setfunc(1) || setActiveStep(1)}
                              >
                                (4 minutes)
                              </Typography>
                            )) ||
                            (index === 2 && (
                              <Typography
                                variant="caption"
                                className="cursor"
                                onClick={() => setfunc(2) || setActiveStep(2)}
                              >
                                (4 minutes)
                              </Typography>
                            )) ||
                            (index === 3 && (
                              <Typography
                                variant="caption"
                                className="cursor"
                                onClick={() => setfunc(3) || setActiveStep(3)}
                              >
                                (4 minutes)
                              </Typography>
                            )) ||
                            (index === 4 && (
                              <Typography
                                variant="caption"
                                className="cursor"
                                onClick={() => setfunc(4) || setActiveStep(4)}
                              >
                                (4 minutes)
                              </Typography>
                            ))
                          }
                        >
                          {step.label}
                        </StepLabel>
                        <StepContent>
                          {index === steps.length - 1 ? (
                            <Box sx={{ mb: 2 }}>
                              <div>
                                <Button
                                  className="text-white"
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Finish
                                </Button>
                              </div>
                            </Box>
                          ) : (
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Back
                            </Button>
                          )}
                          <div className="row mt-2" sx={{ mb: 2, mt: 2 }}>
                            <div className="col-12">
                              <div className="float-right text-right">
                                <div>
                                  {showfunc === 0 && <small></small>}

                                  {showfunc === 1 && <small></small>}
                                  {showfunc === 2 && (
                                    <small className="invisible">" "</small>
                                  )}
                                </div>
                                <button
                                  className="btn btn-primary"
                                  onClick={handleNext}
                                  sx={{ mt: 1, mr: 3 }}
                                >
                                  {index === steps.length - 1
                                    ? "Finish"
                                    : "Next"}
                                </button>
                              </div>
                              <div className="float-left">
                                <div>
                                  {showfunc === 0 && (
                                    <small className="invisible">" "</small>
                                  )}
                                  {showfunc === 1 && <small></small>}

                                  {showfunc === 2 && <small></small>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                      <Typography>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                      </Button>
                    </Paper>
                  )}
                </Box>
              </Accordion.Collapse>
            </div>
            <div className="mb-0 ">
              <Card.Header className="pl-0 bg-white">
                <div className="row justify-content-between bg-white">
                  <div className="col-10">
                    <Accordion.Toggle
                      className="p-0 bg-white"
                      as={Button}
                      variant="link"
                      eventKey="1"
                    >
                      <h3 className="text-dark m-0 font-size-22 accordin-title text-left font-weight-700">
                        Capital
                      </h3>
                    </Accordion.Toggle>
                  </div>
                  <div className="col-2 text-right">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <BiCaretDown className="text-dark set-icon-font-size" />
                    </Accordion.Toggle>
                  </div>
                </div>
                <p className=" mb-0 font-size-12 text-dark text-left">
                  3 videos | Total: 21 minutes
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat
                </Card.Body>
              </Accordion.Collapse>
            </div>
            <div className="mb-0 bg-white">
              <Card.Header className="pl-0 bg-white">
                <div className="row justify-content-between bg-white">
                  <div className="col-10 bg-white">
                    <Accordion.Toggle
                      className="p-0 bg-white"
                      as={Button}
                      variant="link"
                      eventKey="2"
                    >
                      <h3 className="text-dark font-size-12  text-left m-0 accordin-title font-weight-700">
                        Profits
                      </h3>
                    </Accordion.Toggle>
                  </div>
                  <div className="col-2 text-right">
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <BiCaretDown className="text-dark set-icon-font-size" />
                    </Accordion.Toggle>
                  </div>
                </div>
                <p className=" mb-1 font-size-12 text-dark text-left">
                  3 videos | Total: 15 minutes
                </p>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body className=" ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
        {/* {condition === 0 ? (
          <VideoSection
            handleNext={handleNext}
            urls={urls[showfunc]}
            condition={condition}
          />
        ) : condition === 1 ? (
          <VideoSection
            handleNext={handleNext}
            urls={urls[showfunc]}
            condition={condition}
          />
        ) : condition === 2 ? ( */}
        {showfunc !== 5 ? (
          <VideoSection
            handleNext={handleNext}
            urls={urls[showfunc] ? urls[showfunc] : ""}
            condition={condition}
          />
        ) : showCertificate === false ? (
          <div className="col-sm-12 col-lg-8 col-md-8 section-quiz">
            {/* <div className="d-flex align-items-center justify-content-end">
              <GenericSearchField placeholder="Search" />
            </div> */}
            <Card className="py-4">
              <div className="d-flex align-items-center justify-content-between px-4">
                <div>
                  <h4 className="mb-0 font-weight-800">Question 1 of 3</h4>
                </div>
                <div>
                  <h4
                    className="mb-0 font-weight-800 cursor"
                    onClick={() => setCertificateSection(true)}
                  >
                    Skip Quiz
                  </h4>
                </div>
              </div>
              <hr />
              <div className="d-flex mt-3 pl-1 px-4">
                <p className="font-weight-700">Q.1</p>
                <p className="ml-4 font-weight-700">
                  Which are the four essential ingredients for a business
                  breakthrough?
                </p>
              </div>
              <div className="px-4">
                {ANSWAER_CHOOSE &&
                  ANSWAER_CHOOSE.map((answer, index) => {
                    return (
                      <>
                        <AnswerChoose
                          setButton={setButton}
                          button={button}
                          answer={answer}
                          index={index}
                        />
                      </>
                    );
                  })}
                <p className="set-p-tag-backround-answerfeeld pl-4 ml-5">
                  CONFIRM ANSWER
                </p>
                <div className="d-flex mt-3 pb-3 pl-1">
                  <p className="font-weight-700">Q.1</p>
                  <p className="ml-4 font-weight-700">
                    Which are the four essential ingredients for a business
                    breakthrough?
                  </p>
                </div>
                {ANSWAER_CHOOSE1 &&
                  ANSWAER_CHOOSE1.map((answer, index) => {
                    return (
                      <>
                        <AnswerChoose1
                          setButton1={setButton1}
                          button1={button1}
                          answer={answer}
                          index={index}
                        />
                      </>
                    );
                  })}
                <p className="set-p-tag-backround-answerfeeld pl-4 ml-5">
                  CONFIRM ANSWER
                </p>
                <button
                  className="btn btn-primary submit-quiz text-end float-right"
                  onClick={() => setCertificateSection(true)}
                >
                  Submit
                </button>
              </div>
            </Card>
          </div>
        ) : (
          <ShowCerificate />
        )}
      </div>
    </>
  );
}

function AnswerChoose(props) {
  let { index } = props;
  return (
    <p
      key={index}
      onClick={() => props.setButton(props.answer.lookupId)}
      className={`cursor set-p-tag-backround pl-4 ml-5 ${
        props.button === props.answer.lookupId ? "active" : ""
      }`}
    >
      {props.answer.text}
    </p>
  );
}
function AnswerChoose1(props) {
  let { index } = props;
  return (
    <p
      key={index}
      onClick={() => props.setButton1(props.answer.lookupId)}
      className={`cursor set-p-tag-backround pl-4 ml-5 ${
        props.button1 === props.answer.lookupId ? "active" : ""
      }`}
    >
      {props.answer.text}
    </p>
  );
}

function ShowAddModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="Notesheader">
        <div className="d-flex justify-content-center align-items-center modalheader ">
          <p>Add Notes</p>
          <IoCloseSharp className="modalclose" onClick={props.onHide} />
        </div>
        <hr />

        <Form.Group controlId="" className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control type="" placeholder="Title" />
        </Form.Group>
        <Form.Group controlId="" className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>
        <div className="d-flex justify-content-center ">
          <button
            type="button"
            className="btn btn-primary btn-lg mt-2 btn-size"
          >
            {" "}
            Submit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ShowCerificate() {
  return (
    <div className="col-sm-12 col-lg-8 col-md-8 Certication text-center">
      <Card className="">
        <Card.Body>
          <p className="fw-bold text-center firstheading">
            Congratulations, Zaid!
          </p>
          <p className="text-center text-muted para">
            You have Completed Course Successfully!
          </p>

          <button className="btn btn-primary submit-quiz mt-2">
            Download Certificate
          </button>

          <Card className="certicate-card mt-5 text-left">
            <Card.Body>
              <p className="mb-0 recomend-para">We recommend watching next:</p>
              <div className=" explore-updated-screen mt-0">
                <div className="d-flex align-items-center justify-content-between mb-2 pb-4 pt-2  flex-direction-set">
                  <div className="d-flex align-items-center flex-direction-set">
                    <div className="set-view-small-screen mr-4">
                      <img src={IMAGES.IPO2} className="img-fluid" alt="" />
                    </div>
                    <div className="set-view-small-screen">
                      <div className="    ">
                        <h2 className="pt-1 mb-0 pb-1">
                          Introduction to Trade Finance
                        </h2>
                        <h5 className="mb-0 mt-1">
                          Lorem Ipsum has been the industry's standard
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <BsBookmark />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}
function VideoSection(props) {
  const [showNextButton, setShowNextButton] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [languageSelect, setLanguageSelect] = useState(null);
  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  console.log("raw setStates", setCurrentUrlIndex, setShowNextButton)
  return (
    <>
      <div className="col-sm-12 col-lg-8 col-md-8 pt-3">
        {/* <div className="d-flex align-items-center justify-content-end">
          <GenericSearchField placeholder="Search" />
        </div> */}
        <h2 className="mb-3 font-weight-700">
          Introduction to Business Breakthrough
        </h2>
        <Card className=" p-4">
          <div className="embed-responsive embed-responsive-16by9 video-height">
            {showNextButton === false ? (
              <>
                <ReactPlayer
                  url={props.urls[currentUrlIndex]}
                  playing
                  controls
                  onEnded={() => props.handleNext(true)}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                <div className="edutech-video-span">
                  <p className="edutech-video-span-determine-text">
                    What determines
                  </p>
                  <p className="edutech-video-span-profit-text">
                    Good Profits?
                  </p>
                </div>
              </>
            ) : (
              <></>
              // <button
              //   onClick={() => {
              //     setCurrentUrlIndex(
              //       (prevUrlIndex) => (prevUrlIndex + 1) % urls.length
              //     );
              //     setShowNextButton(false);
              //   }}
              //   style={{
              //     position: "absolute",
              //     top: 0,
              //     zIndex: 10,
              //     background: "red",
              //     fontSize: "2em",
              //   }}
              // >
              //   holaaaaaaaaaaa
              // </button>
            )}
          </div>
          <div className="row pt-4 pl-2 align-items-center">
            {/* <div className="col-8 d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="mr-3 dropdown-note set-backrouund-light d-flex align-items-center"
                >
                  <BsFilesAlt className=" icon-font-size-30 text-color-green" />
                  <span className="text-color-green ml-2 "> Add Note</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Take Notes Here....</Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    className="NoteSize"
                    onClick={() => setModalShow(true)}
                  >
                    Add <AiOutlinePlusCircle className="icon" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="set-backrouund-light d-flex align-items-center cursor">
                <AiOutlineDownload className=" icon-font-size-30 text-color-green" />
                <span className="text-color-green ml-2 ">
                  {" "}
                  Download your note
                </span>
              </div>
              <div className="set-backrouund-light d-flex align-items-center cursor ml-3">
                <AiOutlineDownload className=" icon-font-size-30 text-color-green" />
                <span className="text-color-green ml-2 "> Download video</span>
              </div>
            </div> */}
            {/* <div className="col-4 text-right">
              <IoIosThumbsUp className="text-muted icon-font-size-30 mr-4 cursor" />
              <IoIosThumbsDown className="text-muted icon-font-size-30 cursor" />
            </div> */}
          </div>
        </Card>
        <div className="tabs-modify">
          <Tabs
            className="nav-justified"
            style={{
              background: "#f7f7f7",
              borderBottom: "3px solid #f0ecec",
              borderColor: "none",
            }}
          >
            <Tab className="nav-item" eventKey={3} title=" SUMMARY">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      <h3 className="font-weight-00"> SUMMARY</h3>
                      <p className="font-weight-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore evt dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehender.
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={1} title="RESOURCES">
              <div className="row">
                <div className="col-md-12 col-12 col-lg-12 col-sm-12 ">
                  <Card className=" p-4">
                    <div className="text-capitilize b-500 ">
                      <h3 className="font-weight-700">LESSON RESOURCES</h3>
                      <div className="d-flex align-items-center py-2">
                        <span className="text-color-green  ">
                          {" "}
                          Download slides{" "}
                        </span>
                        <AiOutlineDownload className=" icon-font-size-30 text-color-green ml-5 " />
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-color-green  ">
                          Download transcript
                        </span>{" "}
                        <AiOutlineDownload className=" icon-font-size-30 text-color-green  margin-left-20" />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab className="nav-item " eventKey={6} title="TRANSCRIPT">
              <div className="col-md-12 col-12 col-lg-12 col-sm-12 transcript-dropdown">
                <Card className="d-flex p-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="dropdown_bg_grey">
                      <Dropdown alignRight>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="text-left pr-5"
                        >
                          {languageSelect ? languageSelect : "Engish"}
                          <IoMdArrowDropdown />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="shadow">
                          <Dropdown.Item
                            onClick={() => setLanguageSelect("Portuguese")}
                          >
                            Portuguese
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setLanguageSelect("Spanish")}
                          >
                            Spanish
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setLanguageSelect("French")}
                          >
                            French
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div>
                      <p className="mb-0 font-weight-700">Help Us Translate</p>
                    </div>
                  </div>
                  <div className="d-flex mt-3 pl-1">
                    <p className="text-muted">0:00</p>
                    <p className="ml-4 text-muted">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit.
                    </p>
                  </div>
                  <div className="d-flex  pl-1">
                    <p className="text-muted">0:00</p>
                    <p className="ml-4 text-muted">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit.
                    </p>
                  </div>
                </Card>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <ShowAddModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
