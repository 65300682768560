import React, { useEffect, useState } from "react";
import { IMAGES } from "../../assets";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { GetTopCategoriesCourses } from "../../redux/actions/coursesAction";
import { Create, Remove } from "../../redux/actions";
import { toast } from "react-toastify";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
export default function ExploreDashboard(props) {
  let history = useHistory();
  const location = useLocation();
  let dispatch = useDispatch();
  let auth = useAuth();
  const CoursessssData = location?.data;
  let getTopCategoryListing = useSelector((state) => state.courses);
  let topCategoryCourses =
    getTopCategoryListing && getTopCategoryListing.getTopCategoriesCourses;
  function moveToNext() {
    // history.push(PATH.EXPLORE_ADDTOCARTCOURSE);
  }
  const [addWishList, setAddWishList] = useState(null);
  function moveToNextPage(props) {
    history.push({
      pathname: PATH.COURSE_PREVIEW,
      state: {
        // coursesData: props.list,
      },
    });
  }
  useEffect(() => {
    dispatch(GetTopCategoriesCourses(CoursessssData?.interestId, moveToNext));
  }, []);
  return (
    <>
      <div className="Explore ">
        <div className="container-fluid  ">
          <div className=" mt-3 pt-3 explore-updated-screen">
            <div className="row d-flex ">
              <div className="col-md-8 col-lg-8 col-sm-12 set-view-small-screen d-flex align-items-centerx">
                <h2 className="cursor">{CoursessssData?.description}</h2>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 text-right set-view-small-screen">
                <h4 className="ml-2 text-muted text-right">
                  {topCategoryCourses.length} results
                </h4>
              </div>
            </div>
            <div className="row d-flex">
              {topCategoryCourses &&
                topCategoryCourses.map((list, index) => {
                  return (
                    <>
                      <div className="col-md-4">
                        <div
                          className=" mb-2 pb-4 pt-4 mt-2 flex-direction-set cursor"
                          key={index}
                          // onClick={() => moveToNextPage()}
                        >
                          <Link
                            to={{
                              pathname: PATH.EXPLORE_COURSE_PREVIEW,
                              state: {
                                coursesData: list,
                                Courses: "TOPCATEGORY",
                              },
                            }}
                          >
                            <div className=" coursecard mt-2 profileCards ">
                              <div className="card ">
                                {/* <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                                <img className="card-img-top  img-fluid embed-responsive-item" src={list.coursePreviewPictureURL ? list.coursePreviewPictureURL : IMAGES.NOIMAGEFOUND} alt="" />
                              </div> */}
                                <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                                  <img
                                    className="img-fluid embed-responsive-item"
                                    src={
                                      list.coursePreviewPictureURL
                                        ? list.coursePreviewPictureURL
                                        : IMAGES.NOIMAGEFOUND
                                    }
                                  />
                                  <div className="wishlist_border">
                                    <WishListIcon
                                      addWishList={addWishList}
                                      setAddWishList={setAddWishList}
                                      CourseId={topCategoryCourses.courseId}
                                    />
                                  </div>
                                </div>
                                <div className="card-body pl-0">
                                  <div className="set-view-small-screen">
                                    <div className="    ">
                                      <h4 className="pt-1 mb-0 pb-1">
                                        {list.courseTitle}
                                      </h4>
                                      <h6 className="mb-0 mt-1 text-justify py-1 course-description-text-scroll">
                                        {list.courseDescription
                                          ? list.courseDescription
                                          : "No Description Yet"}
                                      </h6>
                                      {/* <p className="text-muted mt-1">
                                      Update{" "}
                                      <span className="pt-1 px-2 span-text-coor"> November 2021</span>{" "}
                                      <span className="px-2">5.5 Hrs total</span>
                                    </p> */}
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-item-center">
                                    {list.price === 0 ? (
                                      <p className="firstpara text-left mt-0 pt-0">
                                        {" "}
                                        {list.price - list.discountedPrice
                                          ? list.price - list.discountedPrice
                                          : "Free"}{" "}
                                      </p>
                                    ) : (
                                      <p className="firstpara text-left mt-0 pt-0">
                                        {" "}
                                        $
                                        {list.price - list.discountedPrice
                                          ? list.price - list.discountedPrice
                                          : "Free"}{" "}
                                      </p>
                                    )}

                                    {list.price === 0 ? (
                                      ""
                                    ) : (
                                      <span className=" ml-3 mb-0 pricing-discount-line text-muted float-right pr-2">
                                        ${list.discountedPrice}
                                      </span>
                                    )}
                                  </div>
                                  {/* <p className="firstpara text-left mt-0 pt-0">
                                  {" "}
                                  ${(list.price) - (list.discountedPrice) ? (list.price) - (list.discountedPrice) : "Free"}{" "}
                                  {
                                    list.price === 0 ?
                                      ""
                                      :
                                      <span className=" ml-3 mb-0 pricing-discount-line text-muted float-right pr-2">
                                        ${list.discountedPrice}
                                      </span>
                                  }

                                </p> */}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function WishListIcon(props) {
  let auth = useAuth();
  let dispatch = useDispatch();
  function wishlist(e, val) {
    e.stopPropagation();
    props.setAddWishList(val);
    if (props.addWishList !== true) {
      dispatch(Create(props.CourseId, auth, Notification));
    } else {
      dispatch(Remove(props.CourseId, auth, NotificationRemove));
    }
  }
  function Notification(data) {
    toast.success("Added to wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function NotificationRemove(data) {
    toast.error("Removed from wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      {props.addWishList === true ? (
        <RiHeartFill onClick={(e) => wishlist(e, false)} />
      ) : (
        <RiHeartLine onClick={(e) => wishlist(e, true)} />
      )}
    </>
  );
}
