import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { IMAGES } from "../../assets/images";
import { LoginNavBar } from "../../assets/genericComponents/LoginNavBar";
import { PATH } from "../../config";
import { Link, useHistory, useLocation } from "react-router-dom";
import StudentStep from "./studentStep";
import TeacherSteps from "./TeacherSteps";
import {
  CREDENTIALS,
  STUDENT_ROLE,
  FREE,
  TEACHER_ROLE,
} from "../../assets/LookUpdata";

export default function SignUpSteps() {
  let location = useLocation();
  let history = useHistory()
  const [currentView, setCurrentView] = useState(0);

  useEffect(() => {
    document.title = "SignUp || EduTech";
  }, []);

  function changeView(value) {
    setCurrentView(value);
  }

  const [providerForm, setProviderForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: 0,
    loginTypeId: 0,
    socialKeyId: "",
    provider: {
      independentProvider: false,
      locationId: 0,
      nicheMarket: "",
      experience: 0,
    },
  });
  const [studentForm, setStudentform] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: 0,
    image: "",
    loginTypeId: 0,
    socialKeyId: "",
    planTypeId: 0,
    student: {
      industryId: 0,
      jobTitle: "",
      experience: 0,
    },
    studentInterest: [],
    number: "",
    expMonth: 0,
    expYear: 0,
    cvc: "",
    price: 0,
    percentage: 0
  });
  const [socialData, setSocialData] = useState(
    location && location.data ? location.data : null
  );
  const [loginTypeId, setLoginTypeId] = useState(location && location.data && location.data.loginTypeId ? location.data.loginTypeId : 0);
  const [StudentProfilePage, setStudentProfilePage] = useState();
  const [TeacherProfilePage, setTeacherProfilePage] = useState();
  function moveToLogin() {
    history.push(PATH.LOGIN)
    window.location.reload(true)
    window.location.reload(false)
  }
  return (
    <React.Fragment>
      <LoginNavBar />
      <section className="img-container">
        <img src={IMAGES.LOGIN_BG} className="img-fluid" alt="login-BG" />
      </section>
      <div className="eduteck-section">
        <div className="edutech-logo" onClick={moveToLogin}>
          {/* <Link to={PATH.LOGIN}> */}
          <img
            src={IMAGES.EDUTECH_LOGO}
            className="img-fluid"
            alt="edutech-logo"
          />
          {/* </Link> */}
        </div>

        {currentView === 0 && (
          <Step1
            setProviderForm={setProviderForm}
            providerForm={providerForm}
            studentForm={studentForm}
            setStudentform={setStudentform}
            changeView={changeView}
            socialData={socialData}
            loginTypeId={loginTypeId}
            setStudentProfilePage={setStudentProfilePage}
            setTeacherProfilePage={setTeacherProfilePage}
          />
        )}
        {currentView === 1 && (
          <StudentStep
            studentForm={studentForm}
            setStudentform={setStudentform}
            changeView={changeView}
            StudentProfilePage={StudentProfilePage}
          />
        )}
        {currentView === 2 && (
          <TeacherSteps
            setProviderForm={setProviderForm}
            providerForm={providerForm}
            changeView={changeView}
            TeacherProfilePage={TeacherProfilePage}
          />
        )}
      </div>
    </React.Fragment>
  );
}
function Step1({ ...props }) {
  const { handleSubmit } = useForm();
  const [NextButtonValue, setNextButtonValue] = useState(0);
  function onSubmit(data) {
    if (NextButtonValue === 2) {
      let stdData = props.studentForm;
      stdData.roleId = STUDENT_ROLE;
      stdData.planTypeId = FREE;
      // With Social MEdia
      if (props.loginTypeId !== 0) {
        stdData.firstName = props.socialData.firstName;
        stdData.lastName = props.socialData.lastName;
        stdData.email = props.socialData.email;
        stdData.photo = props.socialData.photo;
        stdData.loginTypeId = props.socialData.loginTypeId;
        stdData.socialKeyId = props.socialData.socialKey;
        props.setStudentProfilePage(1);
      }
      else {
        stdData.loginTypeId = CREDENTIALS;
      }
      props.setStudentform({ ...stdData });
      props.changeView(1);
    } else if (NextButtonValue === 1) {
      let proData = props.providerForm;
      proData.roleId = TEACHER_ROLE;
      // With Social MEdia
      if (props.loginTypeId !== 0) {
        proData.firstName = props.socialData.firstName;
        proData.lastName = props.socialData.lastName;
        proData.email = props.socialData.email;
        proData.photo = props.socialData.photo;
        proData.loginTypeId = props.socialData.loginTypeId;
        proData.socialKeyId = props.socialData.socialKey;
        props.setTeacherProfilePage(1);
      }
      else {
        proData.loginTypeId = CREDENTIALS;
      }
      props.setProviderForm({ ...proData });
      props.changeView(2);
    }
  }
  return (
    <div className="step-section signup-page signup_page_colour_change">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title pt-3">Please Indicate Your User Group</h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* <Form.Group className="dropdown_bg_grey">
              <Dropdown alignRight>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  onBlur={() => onFocusOutFromDropDown()}
                  className={`${NextButtonValue === 2 ? "dropdown-normal-edutech" : ""
                    } ${isActive === true ? "active" : ""}  mb-4 `}
                >
                  {SubscriptionPlan ? SubscriptionPlan : "Student"}
                  <IoMdArrowDropdown />
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow ">
                  {SUBSCRIPTION_PLAN &&
                    SUBSCRIPTION_PLAN.map((plan, index) => {
                      return (
                        <>
                          {plan.text === "Free" ? (
                            <Dropdown.Item
                              key={index}
                              onClick={() => selectMenuHandler(plan.text)}
                            >
                              {plan.text}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              key={index}
                              disabled
                              onClick={() => selectMenuHandler(plan.text)}
                            >
                              {plan.text}
                            </Dropdown.Item>
                          )}
                        </>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group> */}
            <Form.Group>
              <Button
                type="button"
                onClick={() => setNextButtonValue(2)}
                className={`cursor btn-select ${NextButtonValue === 2 ? "active" : ""
                  }`}
              >
                {"Student"}
              </Button>
            </Form.Group>
            <Form.Group>
              <Button
                type="button"
                onClick={() => setNextButtonValue(1)}
                className={`cursor btn-select ${NextButtonValue === 1 ? "active" : ""
                  }`}
              >
                {"Course Provider"}
              </Button>
            </Form.Group>

            <Form.Group>
              <Button type="submit" className="next_button">
                {"Next"}
              </Button>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}
