import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../../../config";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FaEdit, FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage, Loader } from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCoachingList,
  PublishCoaching,
} from "../../../../redux/actions/coachingAction";
import { useAuth } from "../../../../Navigation/Auth/ProvideAuth";
import MyDataTable from "../../../../assets/genericComponents/MyDataTable";
import {
  GetCourseList,
  PublishCourse,
} from "../../../../redux/actions/coursesAction";
import { RiArrowUpDownFill } from "react-icons/ri";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

export default function TeacherCurriculumPublish(props) {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Courses || EduTech`;
  }, []);
  let history = useHistory();
  console.log("here is the console", props);
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.courses);
  useEffect(() => {
    dispatch(GetCourseList(auth));
    document.title = `Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getCourseList;
  function EditCourseList(row) {
    //
    history.push({
      pathname: PATH.TEACHER_COURSE_PREVIEW,
      state: {
        id: row,
      },
    });
  }
  let columns = [
    // {
    //   dataField: "courseId",
    //   text: "Course ID",
    // },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "courseTitle",
      text: "Course Title",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">
                {row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""}{" "}
              </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return <span>{row.price === 0 ? "FREE" : row.price}</span>;
      },
    },
    // {
    //   dataField: "pricePlan",
    //   text: "Price Plan",
    // },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        return (
          <>
            <DropDownData row={row} />
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let columns2 = [
    // {
    //   dataField: "courseId",
    //   text: "Course ID",
    // },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "courseTitle",
      text: "Course Title",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">
                {row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""}{" "}
              </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        console.log("row", cell, row);
        return (
          <span>{row.price ? row.price - row.discountedPrice : "FREE"}</span>
        );
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        return (
          <>
            <FaEye
              onClick={() => EditCourseList(row.courseId)}
              className="cursor"
            />
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  console.log("asdasds", auth.edu_tech_user.permissionDTO);
  return (
    <>
      <ToastContainer />
      {auth?.edu_tech_user?.permissionDTO?.[0]?.isAddPermission === false ? (
        ""
      ) : (
        <>
          <div className="teacher-curriculum-dashboard ">
            <div className="d-flex align-items-center justify-content-end ">
              <div className="">
                <Link to={PATH.TEACHERNEWCOURSE}>
                  <button className="create_course w-100" type="button">
                    Create New Course
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}

      <>
        {getListing &&
          getListing.getCourseListLoading === true &&
          getListing.getCourseListSuccess === false &&
          getListing.getCourseListFailure === false && <Loader />}
        {getListing &&
          getListing.getCourseListLoading === false &&
          getListing.getCourseListSuccess === false &&
          getListing.getCourseListFailure === true && (
            <ErrorMessage message={getListing.getCourseListError} />
          )}
        {getListing &&
          getListing.getCourseListLoading === false &&
          getListing.getCourseListSuccess === true &&
          getListing.getCourseListFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              {auth.edu_tech_user.roleId === 1 ||
              auth.edu_tech_user.roleId === 4 ? (
                <>
                  <MyDataTable
                    data={data}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    // handleDataChange={handleDataChange}
                  />
                </>
              ) : (
                <>
                  <MyDataTable
                    data={data}
                    columns={columns2}
                    defaultSorted={defaultSorted}
                    // handleDataChange={handleDataChange}
                  />
                </>
              )}
            </div>
          )}
      </>
    </>
  );
}
function DropDownData(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  console.log("asdkhgkhsagfkhgsakh", props);
  let auth = useAuth();
  const [loading, setLoading] = useState(false);
  function publishCoaching() {
    let finalData = {
      CourseId: props.row.courseId,
    };
    dispatch(
      PublishCourse(
        finalData,
        auth,
        loadingState,
        AfterStatusChange,
        Notification
      )
    );
  }
  function unPublishCoaching() {
    let finalData = {
      CourseId: props.row.courseId,
    };
    dispatch(
      PublishCourse(
        finalData,
        auth,
        loadingState,
        AfterStatusChange,
        Notification
      )
    );
  }
  function AfterStatusChange() {
    dispatch(GetCourseList(auth));
  }
  function loadingState(value) {
    setLoading(value);
  }
  function Notification(data) {
    toast.success(data, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  function editCoachingList() {
    history.push({
      pathname: PATH.ADMIN_COURSE_PREVIEW,
      state: {
        id: props.row.courseId,
      },
    });
  }
  console.log("test data", loading);
  return (
    <>
      <Dropdown className="vertical_dropdown_icon">
        <Dropdown.Toggle className="status-options">
          <BiDotsVerticalRounded />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <>
            <Dropdown.Item onClick={() => editCoachingList()}>
              View
            </Dropdown.Item>
            {props.row.status !== "UNPUBLISHED" ? (
              <Dropdown.Item
                href="#"
                onClick={() => {
                  unPublishCoaching();
                }}
              >
                UnPublish
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                href="#"
                onClick={() => {
                  publishCoaching();
                }}
              >
                Publish
              </Dropdown.Item>
            )}
          </>
          {/* <Dropdown.Item
            href="#"
          // onClick={() => deleteCoachingList(props.row.coachingId)}
          >
            Update
          </Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      {/* <FaEdit
            onClick={() => editCoachingList()}
            className="cursor" /> */}
      {/* } */}
    </>
  );
}
