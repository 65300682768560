import React, { useEffect, useState } from "react";
import { BsArrowDown, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetCoachingStudentsList, GetCourseStudentsList, GetTotalCoachingSales, GetTotalCoursesSales } from "../../../redux/actions";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { dateTimeConvert, ErrorMessage, Loader } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
const { SearchBar } = Search;
export default function TotalSales() {
  const [key, setKey] = useState(3);
  let dispatch = useDispatch();
  let auth = useAuth()
  useEffect(() => {
    dispatch(GetCourseStudentsList(auth));
    dispatch(GetCoachingStudentsList(auth));
  }, []);
  return (
    <>
      <Link to={PATH.TEACHERDASHBOARD}>
        <BsArrowLeft size={35} className="mr-2" />
      </Link>
      <div
        className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Total Students</h3>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        // onSelect={(k) => Call(k)}
        className="explore_tabs"
      >
        <Tab eventKey={3} title="Courses">
          <TotalCourses />
        </Tab>
        <Tab eventKey={2} title="Coaching">
          <TotalCoaching />
        </Tab>
      </Tabs>
    </>
  );
}
function TotalCourses() {
  let totalCoursesSale = useSelector((state) => state.dashboardTiles);
  let data = totalCoursesSale && totalCoursesSale.getCourseStudentsList;
  console.log("sadksagdhsadsa", data)
  let columns = [
    {
      text: 'ID',
      dataField: 'id',
      formatter: (cell, row, index) => {
        return (
          <>
            {index + 1}
          </>
        )
      }
    },
    {
      dataField: "name",
      text: "Student Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.name ? row.name : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.email ? row.email : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "industry",
      text: "Industry",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.industry ? row.industry : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "jobTitle",
      text: "Job Title",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.jobTitle ? row.jobTitle : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "experience",
      text: "Experience",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.experience ? row.experience : "N/A"}
            </>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {totalCoursesSale &&
        totalCoursesSale.getCourseStudentsListLoading === true &&
        totalCoursesSale.getCourseStudentsListSuccess === false &&
        totalCoursesSale.getCourseStudentsListFailure === false && <Loader />}
      {totalCoursesSale &&
        totalCoursesSale.getCourseStudentsListLoading === false &&
        totalCoursesSale.getCourseStudentsListSuccess === false &&
        totalCoursesSale.getCourseStudentsListFailure === true && (
          <ErrorMessage message={totalCoursesSale.getCourseStudentsListError} />
        )}
      {totalCoursesSale &&
        totalCoursesSale.getCourseStudentsListLoading === false &&
        totalCoursesSale.getCourseStudentsListSuccess === true &&
        totalCoursesSale.getCourseStudentsListFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>

  )
}
function TotalCoaching() {
  let totalCoachingSale = useSelector((state) => state.dashboardTiles);
  let data = totalCoachingSale && totalCoachingSale.getCoachingStudentsList;
  console.log("sakdgsahdwq", data)
  let columns = [
    {
      text: 'ID',
      dataField: 'id',
      formatter: (cell, row, index) => {
        return (
          <>
            {index + 1}
          </>
        )
      }
    },
    {
      dataField: "name",
      text: "Student Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.name ? row.name : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.email ? row.email : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "industry",
      text: "Industry",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.industry ? row.industry : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "jobTitle",
      text: "Job Title",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.jobTitle ? row.jobTitle : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "experience",
      text: "Experience",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.experience ? row.experience : "N/A"}
            </>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {totalCoachingSale &&
        totalCoachingSale.getCoachingStudentsListLoading === true &&
        totalCoachingSale.getCoachingStudentsListSuccess === false &&
        totalCoachingSale.getCoachingStudentsListFailure === false && <Loader />}
      {totalCoachingSale &&
        totalCoachingSale.getCoachingStudentsListLoading === false &&
        totalCoachingSale.getCoachingStudentsListSuccess === false &&
        totalCoachingSale.getCoachingStudentsListFailure === true && (
          <ErrorMessage message={totalCoachingSale.getCoachingStudentsListError} />
        )}
      {totalCoachingSale &&
        totalCoachingSale.getCoachingStudentsListLoading === false &&
        totalCoachingSale.getCoachingStudentsListSuccess === true &&
        totalCoachingSale.getCoachingStudentsListFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>

  )
}

