import React from "react";
import { Card } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router";
import { PATH } from "../../../../../config";

export default function FinishUp() {
  let history = useHistory();
  function goBack() {
    history.push(PATH.FINISHUPCOACHING);
  }

  return (
    <React.Fragment>
      <div className="container ">
        <div className="row ReviewandSaveCard">
          <div className="col-md-10 offset-md-1 d-c">
            <h2
              className="d-flex align-items-center cursor  font-weight-bold"
              onClick={() => goBack()}
            >
              <BsArrowLeft size={35} className="mr-2" /> Preview
            </h2>
            <Card className="mt-4">
              <Card.Body>
                <div className="form-group">
                  <label for="email" className="font-weight-bold ">
                    NAME
                  </label>
                  <p>xyz</p>
                </div>
                <div className="form-group mt-3">
                  <label for="email" className="font-weight-bold">
                    PRICING
                  </label>
                  <p>ONE TIME PURCHASE</p>
                </div>
                <div className="row ">
                  <div className="col-md-6 col-xl-3 ">
                    <div className="card mt-2 mb-3">
                      <div className="card-body">
                        <div className="d-flex">
                          <p className="mb-1">Price:</p>
                          <p className="mb-1 ml-1">3333</p>
                        </div>
                        <div className="d-flex">
                          <p className="mb-1">Currency:</p>
                          <p className="mb-1 ml-1">SGD</p>
                        </div>
                        <div className="d-flex">
                          <p className="mb-1">Recurs:</p>
                          <p className="mb-1 ml-1">No</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label for="email" className="font-weight-bold">
                    INTAKE CLIENT
                  </label>
                  <p>With an intake form</p>
                </div>
                <label for="email" className="font-weight-bold mt-3">
                  FIRST MILESTONE
                </label>
                <div className="row">
                  <div className="col-md-10 col-xl-5 ">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex">
                          <p>Date:</p>
                          <p className=" ml-1"> On the enrollment date</p>
                        </div>

                        <h5 className="my-1">WELCOME!</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
