import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { FieldError } from "../../assets/genericComponents/ErrorMessage";
import { IMAGES } from "../../assets/images";
import { useHistory } from "react-router-dom";
import { PATH } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { LoginLogo } from "../../assets";
import { CheckCode, CheckEmail, Put } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function ForgotPassword() {
  const [currentView, setCurrentView] = useState(0);
  const [form1, setForm1] = useState("");
  function changeView(value) {
    setCurrentView(value);
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="eduteck-section edutech-login">
        <div className="edutech-logo">
          <LoginLogo />
        </div>
        <div className="edutech-logo1">
          <LoginLogo />
        </div>
        <div className="step-section">
          <div className="login-section px-0">
            <h1 className="text-center text-uppercase">Forgot Password</h1>
            {currentView === 0 && <GetEmail changeView={changeView} setForm1={setForm1} />}
            {currentView === 1 && <EnterCode changeView={changeView} form1={form1} />}
            {currentView === 2 && <ChangePassword changeView={changeView} form1={form1} />}
          </div>
        </div>
      </div>
      <section className="cloudbase">
        <img
          src={IMAGES.CLOUDBASE_BG}
          className="img-fluid"
          alt="cloudbase-logo"
        />
      </section>
    </React.Fragment>
  );
}
function GetEmail(props) {
  let dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState();
  function onSubmit(data) {
    dispatch(CheckEmail(data.email, moveToNext, Notification))
  }
  function moveToNext(data) {
    props.changeView(1);
    props.setForm1(email)
  }
  function Notification(data, condition) {
    // reset();
    condition === "error" ?
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          className="mt-1"
          name="email"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Enter Email"
          style={{
            borderColor: errors && errors.email ? "#a80000" : "",
          }}
          ref={register({ required: true })}
        />
        {errors.email && <FieldError message={"This Field is Required"} />}
      </Form.Group>
      <Form.Group className="mb-0">
        <Button type="submit" className="login_button">
          {"Next"}
        </Button>
      </Form.Group>
    </Form>
  );
}
function EnterCode(props) {
  let dispatch = useDispatch()
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = useState(props.form1);
  const [securityKey, setSecurityKey] = useState();
  function onSubmit(data) {
    dispatch(CheckCode(props.form1, data.securityKey, moveToNext, Notification))
    // props.changeView(2);
  }
  function moveToNext(data) {
    props.changeView(2);
    // props.setForm1(email)
  }
  function Notification(data, condition) {
    // reset();
    condition === "error" ?
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            className="mt-1"
            name="email"
            type="email"
            disabled
            value={props.form1}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Enter Email"
            style={{
              borderColor: errors && errors.email ? "#a80000" : "",
            }}
            ref={register({ required: true })}
          />
          {errors.email && <FieldError message={"This Field is Required"} />}
        </Form.Group>
        <Form.Group>
          <Form.Label>Security Key</Form.Label>
          <Form.Control
            className="mt-1"
            name="securityKey"
            type="text"
            value={securityKey}
            onChange={(e) => {
              setSecurityKey(e.target.value);
            }}
            placeholder="Enter valid key"
            style={{
              borderColor: errors && errors.securityKey ? "#a80000" : "",
            }}
            ref={register({ required: true })}
          />
          {errors.securityKey && (
            <FieldError message={"This Field is Required"} />
          )}
        </Form.Group>
        <Form.Group className="mb-0">
          <Button type="submit" className="login_button">
            {"Next"}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
}
function ChangePassword(props) {
  let dispatch = useDispatch()
  const { register, handleSubmit, errors, watch } = useForm();
  const [email, setEmail] = useState(props.form1);
  let history = useHistory();
  const password = useRef({});
  password.current = watch("password", "");
  function onSubmit(data) {
    let finalData = {
      "email": props.form1,
      "password": data.password,
      "confirmPassword": data.confirmPassword
    }
    dispatch(Put(finalData, moveToNext, Notificiation));
  }
  function moveToNext(data) {
    history.push(PATH.LOGIN);
  }
  function Notificiation(data, condition) {
    // reset();
    condition === "error" ?
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    // history.push(PATH.LOGIN);
  }
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            className="mt-1"
            name="email"
            type="email"
            value={email}
            disabled
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Enter Email"
            style={{
              borderColor: errors && errors.email ? "#a80000" : "",
            }}
            ref={register({ required: true })}
          />
          {errors.email && <FieldError message={"This Field is Required"} />}
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            className="mt-1"
            name="password"
            type="password"
            placeholder="Enter Password"
            style={{
              borderColor: errors && errors.password ? "#a80000" : "",
            }}
            ref={register({
              required: true,
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            })}
          />
          {errors && errors.password && (
            <FieldError
              message={
                "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
              }
            />
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            className="mt-1"
            name="confirmPassword"
            type="password"
            placeholder="Enter Confirm Password"
            style={{
              borderColor: errors && errors.confirmPassword ? "#a80000" : "",
            }}
            ref={register({
              validate: (value) =>
                value === password.current || "The passwords does not match",
            })}
          />
          {errors && errors.confirmPassword && (
            <FieldError message={errors.confirmPassword.message} />
          )}
        </Form.Group>
        <Form.Group className="mb-0">
          <Button type="submit" className="login_button">
            {"Save Changes"}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
}
