import ProfileAvatar from "./profile-avatar.jpg";
import LoginBg from "./Login_Bg.jpg";
import EdutechLogo from "./Eduteck-logo.png";
import EdutechLogoSmall from "./EDutech-logo-small.png";
import CloudBaseBG from "./cloudbase-Bg.png";
import facebook from "./facebook.png";
import google from "./google.png";
import linkedin from "./linkedin.png";
import Frame from "./Frame.png";
import Group from "./Group.png";
import Coursecard from "./course_card.png";
import ProfileLogo from "./Ellipse 9.jpg";
import Certificate from "./Certificate.png";
import IPO2 from "./IPO (2).png";
import market from "./market.png";
import vectary from "./Vectarytexture.png";
import partypic from "./Partypic.png";
import ipo from "./IPO.png";
import man from "./Man.png";
import manhading from "./Manhanding.png";
import random1 from "./r1.png";
import random2 from "./r2.png";
import random3 from "./r3.png";
import random4 from "./r4.png";
import Capital from "./cc.png";
import checkIcon from "./check-icon.png";
import userprofile from "./staff.png";
import free from "./Free.png";
import cardIconImage from "./cardIconImage.png";
import cardIconImage2 from "./cardIconImage2.png";
import Coachclass from "./Coachclass.png";
import Coachgroup from "./coachgroup.png";
import ITCourse from "./IT course.jpg";
import Graphics from "./gdi-promising-graphic-designing-courses.jpg";
import Marketing from "./Digital-Marketing-Course-To-Learn.jpg";
import Development from "./development.jfif";
import MachineLearning from "./machinelearning.jfif";
import DataBase from "./database.jfif";
import DataStructure from "./datastructure.jfif";
import Venture from "./venture-capital.jfif";
import Creation from "./creation.png";
import category1 from "./explore/category1.png";
import category2 from "./explore/category2.png";
import category3 from "./explore/category3.png";
import category4 from "./explore/category4.png";
import course1 from "./explore/course1.png";
import course2 from "./explore/course2.png";
import course3 from "./explore/course3.png";
import course4 from "./explore/course4.png";
import finishup1 from "./finish1.png";
import finishup2 from "./finsih2.png";
import setGoalImg from "./set_goal_img.png";
import faqLogo from "./faq_logo.jpg";
import zoomllink from "./zoomllink.png";
import noImageFound from "./no-image-found.jpg";
import contactus from "./contactus.png";
// import bg from "./bg.jpg";
import bg from "./bg.jpg";
import logo from "./logo.png";
import Sign from "./Sign.png";
export const IMAGES = {
  NOIMAGEFOUND: noImageFound,
  CONTACTUSLOGO: contactus,
  BGCERT: bg,
  LOGOCERT: logo,
  SIGNCERT: Sign,
  ZOOMLLINK: zoomllink,
  VENTURE: Venture,
  FAQLOGO: faqLogo,
  DATASTRUCTURE: DataStructure,
  DATABASE: DataBase,
  MACHINELEARNING: MachineLearning,
  ITCOURSE: ITCourse,
  GRAPHICS: Graphics,
  MARKETING: Marketing,
  DEVELOPMENT: Development,
  COACHCLASS: Coachclass,
  COACHGROUP: Coachgroup,
  MARKET: market,
  CAPITAL: Capital,
  IPO2: IPO2,
  CERTIFICATE: Certificate,
  COURSECARD: Coursecard,
  FRAME: Frame,
  GROUP: Group,
  AVATAR: ProfileAvatar,
  LOGIN_BG: LoginBg,
  EDUTECH_LOGO: EdutechLogo,
  EDUTECH_LOGO_SMALL: EdutechLogoSmall,
  CLOUDBASE_BG: CloudBaseBG,
  FACEBOOK: facebook,
  GOOGLE: google,
  LINKEDIN: linkedin,
  VECTARUTEXTURE: vectary,
  PARTYPIC: partypic,
  IPO: ipo,
  MAN: man,
  MANHANDING: manhading,
  RANDOM1: random1,
  RANDOM2: random2,
  RANDOM3: random3,
  RANDOM4: random4,
  PROFILELOGO: ProfileLogo,
  CHECKICON: checkIcon,
  USERPROFILE: userprofile,
  FREE: free,
  CARDICONIMAGE: cardIconImage,
  CARDICONIMAGE2: cardIconImage2,
  CREATION: Creation,
  CATEGORY1: category1,
  CATEGORY2: category2,
  CATEGORY3: category3,
  CATEGORY4: category4,
  COURSE1: course1,
  COURSE2: course2,
  COURSE3: course3,
  COURSE4: course4,
  FINISHUP1: finishup1,
  FINISHUP2: finishup2,
  SETGOALIMG: setGoalImg,
};
