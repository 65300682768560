
import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import { AdminDashboardRevenue } from "../../../redux/actions/dashboardTilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { ErrorMessage, landlordTenantByMonthData, Loader } from "../../../assets";
import HighchartsReact from "highcharts-react-official";

export function Revanue() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let report = useSelector((state) => state.dashboardTiles)
  let CoursesData = report && report.adminDashboardRevenue;
  useEffect(() => {
    dispatch(AdminDashboardRevenue(auth))
  }, [dispatch]);
  const options = {
    chart: {
      type: 'areaspline'
    },
    title: {
      text: 'Revenue'
    },
    subtitle: {
      text: ''
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      accessibility: {
        description: 'Months of the year'
      }
    },
    yAxis: {
      title: {
        text: '$'
      },
      labels: {
        formatter: function () {
          return this.value + '$';
        }
      }
    },
    tooltip: {
      crosshairs: true,
      shared: true
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1
        }
      }
    },
    series: [
      {
        name: 'Price',
        marker: {
          symbol: 'circle'
        },
        data: landlordTenantByMonthData("landlord", report.adminDashboardRevenue),
      }
    ]


  };
  return (
    <>
      {
        report
        &&
        report.adminDashboardRevenueLoading === true
        &&
        <Loader />
      }
      {
        // report
        // &&
        // report.adminDashboardRevenueSuccess === true
        // &&
        <HighchartsReact highcharts={Highcharts} options={options} />
      }
      {
        report
        &&
        report.adminDashboardRevenueLoading === false
        &&
        report.adminDashboardRevenueFailure === true
        &&
        report.adminDashboardRevenueSuccess === false
        &&
        report.adminDashboardRevenueError
        &&
        <ErrorMessage message={report.adminDashboardRevenueError} />
      }
    </>
  )
}
