import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function getUserProfile(auth) {
  return axios.get(APP_SETTINGS.API_PATH.USER_PROFILE.getUserProfile, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getUserStudentProfile(auth) {
  return axios.get(APP_SETTINGS.API_PATH.USER_PROFILE.getUserStudentProfile, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function updateProviderProfile(data, auth) {
  return axios.post(
    APP_SETTINGS.API_PATH.USER_PROFILE.updateProviderProfile,
    data,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function updateStudentProfile(data, auth) {
  return axios.post(
    APP_SETTINGS.API_PATH.USER_PROFILE.updateStudentProfile,
    data,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
