import React, { useEffect } from "react";
import { IMAGES } from "../../../assets";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { GetInProgressCourseList } from "../../../redux/actions/coursesAction";
export default function CurrentDashboardProgress(props) {
  let dispatch = useDispatch();
  let auth = useAuth();
  useEffect(() => {
    dispatch(GetInProgressCourseList(auth, moveToNext));
  }, []);
  let getInProgressList = useSelector((state) => state.courses);
  let inProgressListData =
    getInProgressList && getInProgressList.getInProgressCourseList;
  function moveToNext() {
    // history.push(PATH.EXPLORE_ADDTOCARTCOURSE);
  }
  return (
    <>
      <div className="Explore ">
        <div className="container-fluid  ">
          <div className=" mt-3 pt-3 explore-updated-screen">
            <div className="row d-flex ">
              <div className="col-md-8 col-lg-8 col-sm-12 set-view-small-screen d-flex align-items-centerx">
                <h2 className="cursor">Current Progres Courses</h2>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 text-right set-view-small-screen">
                <h4 className="ml-2 text-muted text-right">
                  {inProgressListData.length} results
                </h4>
              </div>
            </div>
            {inProgressListData.length === 0 ? (
              <h4 className="text-center  pt-4">No Record Found</h4>
            ) : (
              <>
                <div className="row d-flex">
                  {inProgressListData &&
                    inProgressListData.map((list, index) => {
                      return (
                        <>
                          <div className="col-md-4">
                            <div
                              className=" mb-2 pb-4 pt-4 mt-2 flex-direction-set cursor"
                              key={index}
                              // onClick={() => moveToNextPage()}
                            >
                              <Link
                                to={{
                                  pathname: PATH.DASHBOARD_BREAKTHROUGH,
                                  state: {
                                    currentprogressCourseDataList: list,
                                    Courses: "CURRENTPROGRESSBREAKTHROUGH",
                                  },
                                }}
                              >
                                <div className=" coursecard mt-2 profileCards ">
                                  <div className="card ">
                                    <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                                      <img
                                        className="card-img-top  img-fluid embed-responsive-item"
                                        src={
                                          list.coursePreviewPictureURL
                                            ? list.coursePreviewPictureURL
                                            : IMAGES.NOIMAGEFOUND
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-body pl-0">
                                      <div className="set-view-small-screen">
                                        <div className="    ">
                                          <h4 className="pt-1 mb-0 pb-1">
                                            {list.courseTitle}
                                          </h4>
                                          <h6 className="mb-0 mt-1 text-justify py-1 course-description-text-scroll">
                                            {list.courseDescription
                                              ? list.courseDescription
                                              : "No Description Yet"}
                                          </h6>
                                          <p className="text-muted mt-1">
                                            Update{" "}
                                            <span className="pt-1 px-2 span-text-coor">
                                              {" "}
                                              November 2021
                                            </span>{" "}
                                            <span className="px-2">
                                              5.5 Hrs total
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between align-item-center">
                                        {list.price === 0 ? (
                                          <p className="firstpara text-left mt-0 pt-0">
                                            {" "}
                                            {list.price - list.discountedPrice
                                              ? list.price -
                                                list.discountedPrice
                                              : "Free"}{" "}
                                          </p>
                                        ) : (
                                          <p className="firstpara text-left mt-0 pt-0">
                                            {" "}
                                            $
                                            {list.price - list.discountedPrice
                                              ? list.price -
                                                list.discountedPrice
                                              : "Free"}{" "}
                                          </p>
                                        )}

                                        {list.price === 0 ? (
                                          ""
                                        ) : (
                                          <span className=" ml-3 mb-0 pricing-discount-line text-muted float-right pr-2">
                                            ${list.discountedPrice}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
