import React, { useState } from "react";
import AddQuiz from "../AddLectures/AddQuiz";
import AddResources from "../AddLectures/AddResources";
import AddSummary from "../AddLectures/AddSummary";
import AddTranscript from "../AddLectures/AddTranscript";
import AddVideo from "../AddLectures/AddVideo";
import TeacherCourseInformation from "../CourseInformation";
import TeacherCurriculumList from "../CurriculumList/CurriculumList";
import TeacherCurriculumPublish from "../CurriculumPublish";
import TeacherNewCourse from "../NewCourse";
import TeacherPricingList from "../PricingPlan/PricingList";

export default function TeacherCoursesGroup() {
    const [currentView, setCurrentView] = useState(0);
    function changeView(value) {
        setCurrentView(value);
    }


    const [Courses, setCourses] = useState({
        CoursesId: 0,
        CoursesName: null,
        companyName: null,
        pricePlan: 0,
        price: 0,
        dateType: 0,
        fixedDate: null,
        startRecurrentDate: null,
        endRecurrentDate: null,
        description: null,
        zoomId: null,
        zoomLink: null,
        zoomPassCode: null,
        video: null,
        userId: 0,
        roleId: 0,
        status: 0,
    });

    return (
        <React.Fragment>
            <div className="row Courses-Grouping">
                <div className="col-lg-12 col-md-12">
                    {currentView === 0 && (
                        <TeacherCurriculumPublish
                            setCurrentView={setCurrentView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 1 && (
                        <TeacherNewCourse
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 2 && (
                        <TeacherCurriculumList
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 3 && (
                        <AddVideo
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 4 && (
                        <AddSummary
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 5 && (
                        <AddQuiz
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 6 && (
                        <AddTranscript
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 7 && (
                        <AddResources
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 8 && (
                        <TeacherPricingList
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                    {currentView === 9 && (
                        <TeacherCourseInformation
                            changeView={changeView}
                            Courses={Courses}
                            setCourses={setCourses}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}