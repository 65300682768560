import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import AdminStudents from "./AdminStudents";
import CourseProvider from "./AdminCourseProvider";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { ManageUsersAdmin } from "../../../redux/actions";
export default function TotalUsers() {
  let dispatch = useDispatch();
  function Call(key) {
    dispatch(ManageUsersAdmin(key));
  }
  useEffect(() => {
    dispatch(ManageUsersAdmin(3));
  }, []);
  return (
    <>
      <Link to={PATH.ADMIN_DASHBOARD}>
        <BsArrowLeft size={35} className="mr-2" />
      </Link>
      <div
        className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
      >

        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Total Users</h3>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey={3}
        onSelect={(k) => Call(k)}
        className="mb-3 explore_tabs"
      >
        <Tab eventKey={3} title="Students">
          <AdminStudents />
        </Tab>
        <Tab eventKey={2} title="Course Providers">
          <CourseProvider />
        </Tab>
        {/* <Tab eventKey={1} title="Staff">
          <Staff />
        </Tab> */}
      </Tabs>
    </>
  );
}


