const ROLE = [
  {
    lookupId: 1,
    type: "Role",
    text: "Amin",
    value: "Admin",
    order: 1,
    status: 1,
  },
  {
    lookupId: 2,
    type: "Role",
    text: "Course Provider",
    value: "Provider",
    order: 2,
    status: 1,
  },
  {
    lookupId: 3,
    type: "Role",
    text: "Student",
    value: "Student",
    order: 3,
    status: 1,
  },
];
const LOGIN_TYPE = [
  {
    lookupId: 10,
    type: "LoginType",
    text: "Credentials",
    value: "Credentials",
    order: 1,
    status: 1,
  },
  {
    lookupId: 11,
    type: "LoginType",
    text: "Google",
    value: "Google",
    order: 2,
    status: 1,
  },
  {
    lookupId: 12,
    type: "LoginType",
    text: "Facebook",
    value: "Facebook",
    order: 3,
    status: 1,
  },
  {
    lookupId: 13,
    type: "LoginType",
    text: "Linkedin",
    value: "Linkedin",
    order: 4,
    status: 1,
  },
];
const SUBSCRIPTION_PLAN = [
  {
    lookupId: 101,
    type: "SubscriptionPlan",
    text: "Free",
    value: "Free",
    order: 1,
    status: 1,
  },
  {
    lookupId: 102,
    type: "SubscriptionPlan",
    text: "Silver",
    value: "Silver",
    order: 2,
    status: 1,
  },
  {
    lookupId: 103,
    type: "SubscriptionPlan",
    text: "Gold",
    value: "Gold",
    order: 3,
    status: 1,
  },
  {
    lookupId: 104,
    type: "SubscriptionPlan",
    text: "Platinum",
    value: "Platinum",
    order: 4,
    status: 1,
  },
];
const INDUSTRY_TYPE = [
  {
    lookupId: 50,
    type: "IndustryType",
    text: "Engineering",
    value: "Engineering",
    order: 1,
    status: 1,
  },
  {
    lookupId: 51,
    type: "IndustryType",
    text: "Aerospace",
    value: "Aerospace",
    order: 2,
    status: 1,
  },
  {
    lookupId: 52,
    type: "IndustryType",
    text: "Technology",
    value: "Technology",
    order: 3,
    status: 1,
  },
  {
    lookupId: 53,
    type: "IndustryType",
    text: "Finance",
    value: "Finance",
    order: 4,
    status: 1,
  },
  {
    lookupId: 54,
    type: "IndustryType",
    text: "Law",
    value: "Law",
    order: 5,
    status: 1,
  },
  {
    lookupId: 55,
    type: "IndustryType",
    text: "IT",
    value: "IT",
    order: 5,
    status: 1,
  },
];
const CATEGORY_TYPE = [
  {
    lookupId: 501,
    type: "IndustryType",
    text: "Engineering",
    value: "Engineering",
    order: 1,
    status: 1,
  },
  {
    lookupId: 502,
    type: "IndustryType",
    text: "Aerospace",
    value: "Aerospace",
    order: 2,
    status: 1,
  },
  {
    lookupId: 503,
    type: "IndustryType",
    text: "Technology",
    value: "Technology",
    order: 3,
    status: 1,
  },
  {
    lookupId: 504,
    type: "IndustryType",
    text: "Finance",
    value: "Finance",
    order: 4,
    status: 1,
  },
  {
    lookupId: 505,
    type: "IndustryType",
    text: "Law",
    value: "Law",
    order: 5,
    status: 1,
  },
  {
    lookupId: 506,
    type: "IndustryType",
    text: "IT",
    value: "IT",
    order: 5,
    status: 1,
  },
];
const AREA_OF_INTEREST = [
  {
    lookupId: 301,
    type: "AreaOfInterest",
    text: "Road To IPO",
    value: "RoadToIPO",
    order: 1,
    status: 1,
  },
  {
    lookupId: 302,
    type: "AreaOfInterest",
    text: "Entrepreneurship",
    value: "Entrepreneurship",
    order: 2,
    status: 1,
  },
  {
    lookupId: 303,
    type: "AreaOfInterest",
    text: "Trade Finance",
    value: "TradeFinance",
    order: 3,
    status: 1,
  },
  {
    lookupId: 304,
    type: "AreaOfInterest",
    text: "Investing",
    value: "Investing",
    order: 4,
    status: 1,
  },
  {
    lookupId: 305,
    type: "AreaOfInterest",
    text: "CrowdFunding",
    value: "CrowdFunding",
    order: 5,
    status: 1,
  },
  {
    lookupId: 306,
    type: "AreaOfInterest",
    text: "Business Law",
    value: "BusinessLaw",
    order: 6,
    status: 1,
  },
  {
    lookupId: 307,
    type: "AreaOfInterest",
    text: "Merger & Acquisitions",
    value: "MergerndAcquisitions",
    order: 7,
    status: 1,
  },
  {
    lookupId: 308,
    type: "AreaOfInterest",
    text: "Capital Raising",
    value: "CapitalRaising",
    order: 8,
    status: 1,
  },
];

const ADMIN_ROLE = 1;
const TEACHER_ROLE = 2;
const STUDENT_ROLE = 3;

const CREDENTIALS = 10;

const FREE = 101;
const SILVER = 102;
const GOLD = 103;
const PLATINUM = 104;

const FREEPLAN = 401;
const PRICEPLAN = 402;

const FIXEDDATE = 451;
const RECURRENTDATE = 452;

const PUBLISH = 491;
const UNPUBLISHED = 492;


export {
  ROLE,
  LOGIN_TYPE,
  SUBSCRIPTION_PLAN,
  INDUSTRY_TYPE,
  AREA_OF_INTEREST,
  ADMIN_ROLE,
  TEACHER_ROLE,
  STUDENT_ROLE,
  CREDENTIALS,
  FREE,
  SILVER,
  GOLD,
  PLATINUM,
  FREEPLAN,
  PRICEPLAN,
  FIXEDDATE,
  RECURRENTDATE,
  PUBLISH,
  UNPUBLISHED,
  CATEGORY_TYPE
};
