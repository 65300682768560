// A wrapper for <Route> that redirects to the login

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { PATH } from "../../config";
import { useAuth } from "./ProvideAuth";
function PublicRoute({ children, ...rest }) {
  let auth = useAuth();
  console.log(auth.edu_tech_user);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth && auth.edu_tech_user && auth.edu_tech_user.token ? (
          <>
            {auth?.edu_tech_user?.roleId === 4 ? (
              <Redirect
                to={{
                  pathname: PATH.ADMIN_FAQ,
                  state: { from: location },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: PATH.DASHBOARD,
                  state: { from: location },
                }}
              />
            )}
          </>
        ) : (
          <>{children}</>
        )
      }
    />
  );
}
export default PublicRoute;
