import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Dropdown,
  DropdownButton,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;
const Swal = require("sweetalert2");
function Accepted() {
  Swal.fire({
    title: "Your course has been accepted",
    //   text: "You won't be able to revert this!",
    icon: "success",
    showCancelButton: false,
    confirmButtonColor: "#00386e",
    //   cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
}
function Rejected() {
  Swal.fire({
    title: "Your course has been rejected",
    //   text: "You won't be able to revert this!",
    icon: "info",
    showCancelButton: false,
    confirmButtonColor: "#00386e",
    //   cancelButtonColor: "#d33",
    confirmButtonText: "OK",
  });
}
class CourseRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
    };
  }

  columns = [
    // {
    //     dataField: "id",
    //     text: "Course Id",
    // },
    {
      dataField: "tittle",
      text: "Tittle",
    },

    {
      dataField: "creationdate",
      text: "DATE",
    },
    {
      dataField: "addCatagory",
      text: "CATEGORY",
    },
    {
      dataField: "companyname",
      text: "Company Name",
    },
    {
      dataField: "plan",
      text: "PLAN",
    },
    {
      dataField: "enrollment",
      text: "ENROLLMENTS",
    },
    {
      dataField: "more",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div
            className="float-right font-600 text-secondary d-flex"
            id="clinician-table-three-button-dropdown"
          >
            <div
              className="cursor manage-user"
              id="clinician-table-three-button-dropdown"
            >
              <DropdownButton
                id="clinician-table-three-button-dropdown"
                title={
                  <BsThreeDots
                    id="clinician-table-three-button-dropdown"
                    className="mr-2 cursor"
                  />
                }
                size="sm"
                alignRight
                bsPrefix="three-dot-icon"
                className="edutech-admin-three-dot-icon  clinician-drop-down"
              >
                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Accepted();
                  }}
                >
                  <div className="edutech-admin-dots-inner">{"Accept"}</div>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  className="delete-field-dropdown"
                  onClick={() => {
                    Rejected();
                  }}
                >
                  <div className="edutech-admin-dots-inner">
                    {"Reject"}
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];

  products = [
    {
      tittle: "Dummy Course Name",
      creationdate: "26/7/21",
      addCatagory: "Fund Management",
      companyname: "Name",
      plan: "Free",
      enrollment: "0",
      info: "addres",
    },
    {
      tittle: "orange",
      creationdate: "26/7/21",
      addCatagory: "Venture Capital",
      companyname: "Name",
      plan: "200",
      enrollment: "12",
      info: "addres",
    },
  ];

  defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];

  handleDataChange = ({ dataSize }) => {
    this.setState({ rowCount: dataSize });
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  render() {
    return (
      <div>
        <div
          className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Course Requests</h3>
            </div>
          </div>
        </div>
        <div className="admin-side-table-styling card p-4">
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={this.products}
            columns={this.columns}
            columnToggle
            search
          >
            {(props) => (
              <div>
                <div className="text-left pt-3 pb-4 mt-0 d-flex justify-content-between">
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: "250px", height: "40px" }}
                  />
                  {/* <Button variant=" edutech-admin-primary" onClick={this.openModal} >
                                        {" Add Request"}
                                    </Button> */}
                </div>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  {...props.baseProps}
                  filter={filterFactory()}
                  pagination={paginationFactory()}
                  defaultSorted={this.defaultSorted}
                  onDataSizeChange={this.handleDataChange}
                  noDataIndication="There is no record"
                  hover
                  condensed
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          className="admin-manage-user"
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Add Course Request
          </Modal.Title>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-12 col-md-6">
                  <Form.Group>
                    <Form.Label>Tittle</Form.Label>
                    <Form.Control
                      name="cousename"
                      type="text"
                      placeholder="Enter Course Name"
                    />
                  </Form.Group>
                </div>

                <div className="col-12 col-md-6">
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      name="category"
                      type="text"
                      placeholder="Enter Category"
                    />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer ClassName="edutech-footter-modal ">
            <Button variant=" edutech-admin-primary " onClick={this.closeModal}>
              Add Request
            </Button>
            {/* <Button variant=" edutech-admin-primary" onClick={this.closeModal} >Cancel</Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CourseRequests;
