import React, { useState } from "react";
import { Button, Form, FormText } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { FieldError } from "../../../../../assets";
export default function AddQuiz(props) {
  const { register, handleSubmit, errors } = useForm();
  const [idx, setidx] = React.useState([0]);
  const [cx, setcx] = React.useState(1);
  const CoursesData = props?.CoursesData;
  function onSubmit(data) {
    console.log("here is :::", data)
    let myQuizList = []
    for (let i = 0; i < data.quizQuestionList.length; i++) {
      let obj =
      {
        questionDescription: data.quizQuestionList[i].questionDescription,
        quizOptionList: data.quiz[i + "OptionList"]
      }
      myQuizList.push(obj)
    }
    let xyz = {
      "quiz": [
        {
          quizQuestionList: [
            myQuizList
          ]
        }
      ]
    }
    CoursesData.quiz = [{ quizQuestionList: myQuizList }]
    console.log("here is my final submit looks like::::", CoursesData.quiz)
    props.setSelectedView(3)
  }
  const addEnemy = () => {
    if (idx.length < 5) {
      setidx(previdx => [...previdx, cx]);
      setcx(prevcx => prevcx + 1);
    };
  }
  const clearFriends = index => () => {
    setidx(previdx => [...previdx.filter(item => item !== index)]);
    setcx(prevcx => prevcx - 1);
  };
  console.log("There is summary console", cx)

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} name="myform" id="myform" className="text-left">
        <div className="d-flex justify-content-between">
          <h3 className="font-weight-bold mb-3">Quiz</h3>
          <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" onClick={addEnemy}  >
            Add New Quiz
          </Button>
        </div>
        {
          idx.map(indexesss => {
            const fieldName = `quizQuestionList[${indexesss}]`;
            return (
              <fieldset name={fieldName} key={fieldName}>
                <div className="form-check mt-3">
                  <Form.Group>
                    <Form.Control
                      value=""
                      type="checkbox"
                      id="flexCheckDefault"
                      className=" edutech-checkbox form-check-input edutech-curriculum-checkbox"
                    />
                    <Form.Label className="form-check-label edutech-curriculum-text" for="flexCheckDefault">This quiz will be graded</Form.Label>
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-between mt-5 mb-3">
                  <h4 className="font-weight-bold mb-3 mt-4">Type question here</h4>
                  <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" onClick={clearFriends(indexesss)}  >
                    Remove Quiz
                  </Button>
                </div>
                <input
                  type="text"
                  placeholder="Type your question"
                  id="flexCheckDefault"
                  // pattern="(\s*\S\s*)+)"
                  pattern="(\s*\S\s*)+"
                  // pattern="(/^([A-z])*[^\s]\1*$/)"
                  className="edutech-curriculum-text-field"
                  name={`${fieldName}.questionDescription`}
                  style={{
                    borderColor: errors && errors.quizQuestionList ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.quizQuestionList && (
                  <FieldError message={"This Field is Required"} />
                )}
                <AnswermapingSection
                  errors={errors}
                  questionNo={indexesss}
                  register={register}

                />
              </fieldset>
            )
          })
        }
        <div className="mt-5">
          <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" type="submit">
            Next
          </Button>
        </div>
      </Form >
    </>
  );
}
function AnswermapingSection(props) {
  const [indexes, setIndexes] = React.useState([0]);
  const [counter, setCounter] = React.useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const addFriend = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };
  const removeFriend = index => () => {
    setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
    setCounter(prevCounter => prevCounter - 1);
  };
  return (
    <>
      <div className="edutech-curriculum-checkbox-field">
        <div className="d-flex justify-content-between">
          <h3 className="font-weight-bold mb-3">Type Answer here</h3>
          <Button className="quiz-edutech-curriculum-action-button float-right" onClick={addFriend}  >
            <FaPlus />
          </Button>
        </div>
        {/* <h4 className="font-weight-bold mb-3 mt-4">Type Answer here</h4>
        <div className="input-group quiz">
          <Button className="quiz-edutech-curriculum-action-button float-right" onClick={addFriend}  >
            <FaPlus />
          </Button>
        </div> */}
        {
          indexes.map((index, answerId) => {
            const fieldNameOption = `quiz[${props.questionNo}]OptionList[${index}]`;
            console.log("ashjgdqywgdq", fieldNameOption)
            return (
              <AnswerBodySection
                fieldName={fieldNameOption}
                removeFriend={removeFriend}
                id={answerId}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                index={index}
                register={props.register}
              />
            );
          })
        }
      </div >
    </>
  )
}
function AnswerBodySection(props) {
  console.log("askdgksahdqqwwq", props)
  return (
    <fieldset name={props.fieldName} key={props.fieldName} className="my-2">
      <div className="input-group quiz">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <input
              type="checkbox"
              className="form-check-input edutech-curriculum-checkbox quiz-new-checkbox "
              value={props.isChecked.toString}
              // id={`${props.fieldName}.props.id`}
              name={`${props.fieldName}.isCorrect`}
              // required
              onChange={() => {
                props.setIsChecked(!props.isChecked);
              }}
              ref={props.register()}
            />
          </div>
        </div>
        <input
          type="text"
          className="form-control"
          id="inlineFormInputGroupUsername"
          name={`${props.fieldName}.optionDescription`}
          pattern="(\s*\S\s*)+"
          placeholder=""
          required
          // style={{
          //   borderColor: props.errors && props.errors.quizOptionList ? "#a80000" : "",
          // }}
          ref={props.register({ required: true })}
        />
        {/* {props.errors.quizOptionList && (
          <FieldError message={"This Field is Required"} />
        )} */}
        <Button className="quiz-edutech-curriculum-action-button" onClick={props.removeFriend(props.index)}  >
          <FaMinus />
        </Button>
      </div>
    </fieldset>
  )
}