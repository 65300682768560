import { request, success, failure, FAQS_REQUEST } from "./utilities";
import {
  createFaqs,
  getFAQByProvider,
  getFAQByStudent,
  updateFaqs,
} from "../api";

export function CreateFaqs(data, auth, AfterStatusChange, Notificiation) {
  return (dispatch) => {
    dispatch(request(FAQS_REQUEST.CREATE_FAQS_REQUEST));
    createFaqs(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(FAQS_REQUEST.CREATE_FAQS_SUCCESS, response.data.data)
          );
          AfterStatusChange();
          Notificiation(response.data.message, "success");
        } else {
          dispatch(
            failure(FAQS_REQUEST.CREATE_FAQS_FAILURE, response.data.message)
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            FAQS_REQUEST.CREATE_FAQS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UpdateFaqs(data, auth, Notificiation, MoveToNext) {
  return (dispatch) => {
    dispatch(request(FAQS_REQUEST.UPDATE_FAQS_REQUEST));
    updateFaqs(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(FAQS_REQUEST.UPDATE_FAQS_SUCCESS, response.data.data)
          );
          Notificiation(response.data.message, "success");
          if (MoveToNext) {
            MoveToNext();
          }
        } else {
          dispatch(
            failure(FAQS_REQUEST.UPDATE_FAQS_FAILURE, response.data.message)
          );
          Notificiation(response.data.message, "error");
          if (MoveToNext) {
            MoveToNext();
          }
        }
      },
      (error) => {
        dispatch(
          failure(
            FAQS_REQUEST.UPDATE_FAQS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetFAQByStudent() {
  return (dispatch) => {
    dispatch(request(FAQS_REQUEST.GET_FAQ_BY_STUDENT_REQUEST));
    getFAQByStudent().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(FAQS_REQUEST.GET_FAQ_BY_STUDENT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(
              FAQS_REQUEST.GET_FAQ_BY_STUDENT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            FAQS_REQUEST.GET_FAQ_BY_STUDENT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetFAQByProvider() {
  return (dispatch) => {
    dispatch(request(FAQS_REQUEST.GET_FAQ_BY_PROVIDER_REQUEST));
    getFAQByProvider().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              FAQS_REQUEST.GET_FAQ_BY_PROVIDER_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              FAQS_REQUEST.GET_FAQ_BY_PROVIDER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            FAQS_REQUEST.GET_FAQ_BY_PROVIDER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
