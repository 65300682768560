import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownButton,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import {
  BsArrowDown,
  BsArrowDownUp,
  BsArrowUp,
  BsThreeDots,
} from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { Delete } from "../Admin-Manage_User/Delete";
export default function ManageCourses() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Admin || EduTech`;
  }, []);
  const [modalShow, setModalShow] = React.useState(false);
  const [gridView, setGridView] = useState(false);
  const staffColumns = [
    {
      dataField: "tittle",
      text: "Tittle",
      sort: true,
      classes: "align-middle table-left-hover-class ",
      sortCaret: (order, column) => {
        if (!order) {
          return <BsArrowDownUp className="ml-2 inline-block " size={18} />;
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowDown size={20} className="text-brand-primary" />
              // <BiChevronUp size={20} className="text-brand-primary" />
            )}
            {order === "desc" && (
              <BsArrowUp size={20} className="text-brand-primary" />
              // <BiChevronDown size={20} className="text-brand-primary"  />
            )}
          </React.Fragment>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {};
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: true,
      classes: "align-middle table-left-hover-class ",
      sortCaret: (order, column) => {
        if (!order) {
          return <BsArrowDownUp className="ml-2 inline-block " size={18} />;
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowDown size={20} className="text-brand-primary" />
              // <BiChevronUp size={20} className="text-brand-primary" />
            )}
            {order === "desc" && (
              <BsArrowUp size={20} className="text-brand-primary" />
              // <BiChevronDown size={20} className="text-brand-primary"  />
            )}
          </React.Fragment>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {};
      },
    },
    {
      dataField: "compliance",
      text: "Course Compliance",
      sort: true,
      classes: "align-middle",
      sortCaret: (order, column) => {
        if (!order) {
          return <BsArrowDownUp className="ml-2 inline-block " size={18} />;
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowDown size={20} className="text-brand-primary" />
              // <BiChevronUp size={20} className="text-brand-primary" />
            )}
            {order === "desc" && (
              <BsArrowUp size={20} className="text-brand-primary" />
              // <BiChevronDown size={20} className="text-brand-primary"  />
            )}
          </React.Fragment>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {};
      },
    },
    {
      dataField: "more",
      text: "Actions",
      classes: "align-middle table-right-hover-class edutech",
      formatter: (cell, row) => {
        return (
          <div
            className="float-right font-600 text-secondary d-flex"
            id="clinician-table-three-button-dropdown"
          >
            <div className="cursor" id="clinician-table-three-button-dropdown">
              <DropdownButton
                id="clinician-table-three-button-dropdown"
                title={
                  <BsThreeDots
                    id="clinician-table-three-button-dropdown"
                    className="mr-2 cursor"
                  />
                }
                size="sm"
                alignRight
                bsPrefix="three-dot-icon"
                className="edutech-admin-three-dot-icon  clinician-drop-down"
              >
                <Link to={PATH.TEACHERCOURSEINFORMATION}>
                  <Dropdown.Item as="button" className="add-org-field-dropdown">
                    <div className="edutech-admin-dots-inner">
                      <BiEditAlt className="mr-3" size={25} /> {"Edit"}
                    </div>
                  </Dropdown.Item>
                </Link>
                {/* <Dropdown.Item as="button" className="edit-field-dropdown" >
                                    <div className="edutech-admin-dots-inner">
                                        <FaRegEye className="mr-3 " size={25} /> {"View"}
                                    </div>
                                </Dropdown.Item> */}
                <Dropdown.Item as="button" className="delete-field-dropdown">
                  <div className="edutech-admin-dots-inner">
                    <Delete />
                  </div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        );
      },
      style: (col, data, index) => {
        return {};
      },
      headerStyle: (column, colIndex) => {
        return {
          textAlign: "center",
          width: "75px",
        };
      },
    },
  ];

  let courselist = [
    {
      id: 0,
      tittle: "IPO Road",
      author: "IPO Road",
      compliance: "Enforce Lecture Order",
    },
    {
      id: 1,
      tittle: "IPO Road",
      author: "IPO Road",
      compliance: "Enforce Lecture Order",
    },
    {
      id: 2,
      tittle: "IPO Road",
      author: "IPO Road",
      compliance: "Enforce Lecture Order",
    },
    {
      id: 3,
      tittle: "IPO Road",
      author: "IPO Road",
      compliance: "Enforce Lecture Order",
    },
    {
      id: 4,
      tittle: "IPO Road",
      author: "IPO Road",
      compliance: "Enforce Lecture Order",
    },
    {
      id: 5,
      tittle: "IPO Road",
      author: "IPO Road",
      compliance: "Enforce Lecture Order",
    },
  ];
  return (
    <div>
      <div className={`my-4 card card-custom w-100 bg-dark`}>
        <div className="card-header border-0">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="mb-0" variant="h4">
              Manage Courses
            </h2>
          </div>
        </div>
      </div>
      <div className={`card card-custom w-100`}>
        <div className="card-header border-0">
          <div className="container-fluid p-0">
            <div className="row h-100 align-items-center">
              <div className="col-12 col-lg-3 col-md-4 col-sm-6 mb-3  sercha-bar-shadow mb-md-0 text-right">
                <input
                  type="text"
                  className="form-control  form-controls sercha-bar-shadow searchbar-padding set-small-screen-width w-75"
                  id=""
                  aria-describedby=""
                  placeholder="Search"
                />
                {/* {<BiSearch className="searchicon " />} */}
              </div>
              <div className="col-12 col-md-2 mb-3 mb-md-0"></div>
              <div className="col-12 col-md-2 mb-3 mb-md-0"></div>
              <div className="col-12 col-md-4 col-lg">
                <div className="d-flex justify-content-end align-items-center edutech">
                  <Button
                    variant=" edutech-admin-primary"
                    onClick={() => setModalShow(true)}
                  >
                    {"Add Courses"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-0">
          <div className="my-4">
            {gridView ? (
              <div className="row">
                {courselist.map((idx) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-6 md-mb-3"></div>
                  );
                })}
              </div>
            ) : (
              <BootstrapTable
                wrapperClasses="table-responsive"
                keyField="id"
                data={courselist}
                columns={staffColumns}
                bordered={false}
                defaultSortDirection="asc"
              />
            )}
          </div>
          <AddCourseModal show={modalShow} onHide={() => setModalShow(false)} />
          {/* <EditUserModal
                        show={addmodalShow}
                        onHide={() => setAddModalShow(false)}
                    /> */}
        </div>
      </div>
    </div>
  );
}
function AddCourseModal(props) {
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [contactno, setcontactno] = useState("");
  const [experience, setexperience] = useState("");
  const [industrySelect, setIndustrySelect] = useState(null);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Courses
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="fname"
                  type="text"
                  value={fname}
                  onChange={(e) => {
                    setFname(e.target.value);
                  }}
                  placeholder="Enter First Name"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="lname"
                  type="text"
                  value={lname}
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                  placeholder="Enter Last Name"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter Email"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Contact no.</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="contact no"
                  type="text"
                  value={contactno}
                  onChange={(e) => {
                    setcontactno(e.target.value);
                  }}
                  placeholder="Enter contact no"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6">
              <Form.Group>
                <Form.Label>Experience</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="experience"
                  type="number"
                  value={experience}
                  onChange={(e) => {
                    setexperience(e.target.value);
                  }}
                  placeholder="Enter experience"
                />
              </Form.Group>
            </div>
            <div className="col-12 col-md-6 mt-1">
              <Form.Group className="dropdown-set">
                <Form.Label>What Industry are you in?</Form.Label>
                <Dropdown alignRight className="edutech-admin-courses">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="text-left w-100"
                  >
                    {industrySelect ? industrySelect : "IT"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow">
                    <Dropdown.Item
                      onClick={() => setIndustrySelect("Engineering")}
                    >
                      Engineering
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setIndustrySelect("Aerospace")}
                    >
                      Aerospace
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => setIndustrySelect("Technology")}
                    >
                      Technology
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setIndustrySelect("Finance")}>
                      Finance
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setIndustrySelect("Law")}>
                      Law
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer ClassName="edutech-footter-modal">
        <Button variant=" edutech-admin-primary" onClick={props.onHide}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
// function EditUserModal(props) {
//     const [email, setEmail] = useState("alma.lawson@example.com");
//     const [fname, setFname] = useState("Alma");
//     const [lname, setLname] = useState("Lawson");
//     const [industry, setindustry] = useState("Software engineering");
//     const [interest, setinterest] = useState("Invest");
//     return (
//         <Modal
//             {...props}
//             size="lg"
//             aria-labelledby="contained-modal-title-vcenter"
//             centered
//         >
//             <Modal.Header closeButton>
//                 <Modal.Title id="contained-modal-title-vcenter">
//                     Add User
//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <Form >
//                     <div className="row">
//                         <div className="col-12 col-md-6">
//                             <Form.Group>
//                                 <Form.Label>First Name</Form.Label>
//                                 <Form.Control
//                                     className="mt-1"
//                                     name="fname"
//                                     type="text"
//                                     value={fname}
//                                     onChange={(e) => {
//                                         setFname(e.target.value);
//                                     }}
//                                     placeholder="Enter First Name"

//                                 />

//                             </Form.Group>
//                         </div>
//                         <div className="col-12 col-md-6">
//                             <Form.Group>
//                                 <Form.Label>Last Name</Form.Label>
//                                 <Form.Control
//                                     className="mt-1"
//                                     name="lname"
//                                     type="text"
//                                     value={lname}
//                                     onChange={(e) => {
//                                         setLname(e.target.value);
//                                     }}
//                                     placeholder="Enter Last Name"

//                                 />

//                             </Form.Group>
//                         </div>
//                         <div className="col-12 col-md-6">
//                             <Form.Group>
//                                 <Form.Label>Email or phone number</Form.Label>
//                                 <Form.Control
//                                     className="mt-1"
//                                     name="email"
//                                     type="email"
//                                     value={email}
//                                     onChange={(e) => {
//                                         setEmail(e.target.value);
//                                     }}
//                                     placeholder="Enter Email"

//                                 />

//                             </Form.Group>
//                         </div>
//                         <div className="col-12 col-md-6">
//                             <Form.Group>
//                                 <Form.Label>Industry</Form.Label>
//                                 <Form.Control
//                                     className="mt-1"
//                                     name="industry"
//                                     type="text"
//                                     value={industry}
//                                     onChange={(e) => {
//                                         setindustry(e.target.value);
//                                     }}
//                                     placeholder="Enter Industry"

//                                 />

//                             </Form.Group>
//                         </div>
//                         <div className="col-12 col-md-6">
//                             <Form.Group>
//                                 <Form.Label>Area of Interest</Form.Label>
//                                 <Form.Control
//                                     className="mt-1"
//                                     name="interest"
//                                     type="text"
//                                     value={interest}
//                                     onChange={(e) => {
//                                         setinterest(e.target.value);
//                                     }}
//                                     placeholder="Enter area of interest"

//                                 />

//                             </Form.Group>
//                         </div>

//                     </div>
//                 </Form>
//             </Modal.Body>
//             <Modal.Footer ClassName="edutech-footter-modal ">
//                 <Button variant=" edutech-admin-primary" onClick={props.onHide} >Submit</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// }
