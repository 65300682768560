import { FORGET_PASSWORD_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  checkEmailLoading: false,
  checkEmailSuccess: false,
  checkEmailFailure: false,
  checkEmailError: null,
  checkEmail: [],

  checkCodeLoading: false,
  checkCodeSuccess: false,
  checkCodeFailure: false,
  checkCodeError: null,
  checkCode: [],

  putLoading: false,
  putSuccess: false,
  putFailure: false,
  putError: null,
  put: [],

};

export const forgetPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_REQUEST.CHECK_EMAIL_REQUEST:
      return {
        ...state,
        checkEmailLoading: true,
        checkEmailSuccess: false,
        checkEmailFailure: false,
        checkEmailError: null,
      };
    case FORGET_PASSWORD_REQUEST.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checkEmailLoading: false,
        checkEmailSuccess: true,
        checkEmailFailure: false,
        checkEmailError: null,
        checkEmail: action.payload,
      };
    case FORGET_PASSWORD_REQUEST.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        checkEmailLoading: false,
        checkEmailSuccess: false,
        checkEmailFailure: true,
        checkEmailError: action.payload,
      };

    case FORGET_PASSWORD_REQUEST.CHECK_CODE_REQUEST:
      return {
        ...state,
        checkCodeLoading: true,
        checkCodeSuccess: false,
        checkCodeFailure: false,
        checkCodeError: null,
      };
    case FORGET_PASSWORD_REQUEST.CHECK_CODE_SUCCESS:
      return {
        ...state,
        checkCodeLoading: false,
        checkCodeSuccess: true,
        checkCodeFailure: false,
        checkCodeError: null,
        checkCode: action.payload,
      };
    case FORGET_PASSWORD_REQUEST.CHECK_CODE_FAILURE:
      return {
        ...state,
        checkCodeLoading: false,
        checkCodeSuccess: false,
        checkCodeFailure: true,
        checkCodeError: action.payload,
      };

    case FORGET_PASSWORD_REQUEST.PUT_REQUEST:
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putFailure: false,
        putError: null,
      };
    case FORGET_PASSWORD_REQUEST.PUT_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putFailure: false,
        putError: null,
        put: action.payload,
      };
    case FORGET_PASSWORD_REQUEST.PUT_FAILURE:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putFailure: true,
        putError: action.payload,
      };

    default:
      return state;
  }
};
