import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../../assets/genericComponents/Loader";
import { ErrorMessage } from "./../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
export default function Students() {
  let user = useSelector((state) => state.admin);
  const [modalShow, setModalShow] = React.useState(false);
  let data = user && user.manageUser;
  let columns = [
    {
      dataField: "fullName",
      text: "Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "student.industryWorkName",
      text: "Industry",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "student.jobTitle",
      text: "Job Title",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "student.experience",
      text: "Experience",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "more",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <>
            <StudentDetailDataModal row={row} />
          </>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];

  return (
    <>
      {user &&
        user.manageUserLoading === true &&
        user.manageUserSuccess === false &&
        user.manageUserFailure === false && <Loader />}
      {user &&
        user.manageUserLoading === false &&
        user.manageUserSuccess === false &&
        user.manageUserFailure === true && (
          <ErrorMessage message={user.manageUserError} />
        )}
      {user &&
        user.manageUserLoading === false &&
        user.manageUserSuccess === true &&
        user.manageUserFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}
function StudentDetailDataModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FaEye className="cursor" onClick={handleShow} />
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Student Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">Full Name :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.fullName}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">Email :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.email}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">Industry :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.student?.industryWorkName}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">Job Title :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.student?.jobTitle}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">Experience :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.student?.experience}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">
                Available Credits / Total Credits :
              </label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={`${props?.row?.student?.availableCredit} / ${props?.row?.student?.totalCredit}`}
              />
            </div>
            <div className="col-md-6 mb-4 ">
              <label className="form-label fw-900">Total Courses :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.student?.courseCount}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label fw-900">Total Coachings :</label>
              <input
                type="text"
                className="form-control"
                readOnly
                value={props?.row?.student?.coachingCount}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
