import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router";
import { FieldError } from "../../../../../assets";
import { PATH } from "../../../../../config";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { GetAllApprovedInterest, Interest } from "../../../../../redux/actions/interestAction";
export default function NameService(props) {
  const { Coaching, setCoaching } = props;
  console.log("naemService", Coaching);
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [coachingName, setCoachingName] = useState(
    Coaching && Coaching.coachingName ? Coaching.coachingName : null
  );
  const [companyTitle, setCompanyTitle] = useState(
    Coaching && Coaching.companyTitle ? Coaching.companyTitle : null
  );
  let dispatch = useDispatch();
  function goBack() {
    Coaching.name = null;
    Coaching.companyTitle = null;
    setCoaching(Coaching);
    history.push(PATH.COACHINGLIST);
  }
  function onSubmit() {
    Coaching.coachingName = coachingName;
    Coaching.companyTitle = companyTitle;
    interestValue?.getAllApprovedInterest.map((item, i) => {
      if (item.description === Locationselect) {
        Coaching.interestId = item.interestId;
      }
    });
    setCoaching(Coaching);
    // props.changeView(2);
    // props.handleNext(2);
    if (Locationselect) {
      props.changeView(2);
      props.handleNext(2);
    }
  }
  let interestValue = useSelector((state) => state.interest);
  console.log("theis is reducer interest ", interestValue)
  const [Locationselect, setLocationselect] = useState(
    Coaching && Coaching.interestId ? Coaching.interestId : ""
  );
  useEffect(() => {
    dispatch(GetAllApprovedInterest());
  }, [dispatch]);
  return (
    <React.Fragment>
      <h2
        className="d-flex align-items-center cursor font-weight-bold"
        onClick={() => goBack()}
      >
        <BsArrowLeft size={35} className="mr-2" /> Give your coaching service a
        name
      </h2>
      <hr className="edutech-hr-colors" />
      <p className="mt-4 font-weight-bold">
        What would you call this coaching service?
      </p>
      <Card className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Coaching Name</Form.Label>
            <Form.Control
              className="font-weight-bold edutech-coaching-name-text"
              name="coachiing"
              type="text"
              // pattern="\s[a-zA-Z0-9]+"
              pattern="(\s*\S\s*)+"
              value={coachingName}
              onChange={(e) => {
                setCoachingName(e.target.value);
              }}
              style={{ borderColor: errors && errors.coachiing ? "#a80000" : "" }}
              ref={register({ required: true })}
            />
            {errors.coachiing && <FieldError message={"This Field is Required"} />}
          </Form.Group>
          <Form.Group>
            <Form.Label className="font-weight-bold">Company Name</Form.Label>
            <Form.Control
              className="font-weight-bold edutech-coaching-name-text"
              name="companyTitle"
              type="text"
              value={companyTitle}
              pattern="(\s*\S\s*)+"
              onChange={(e) => {
                setCompanyTitle(e.target.value);
              }}
              style={{
                borderColor: errors && errors.companyTitle ? "#a80000" : "",
              }}
              ref={register({ required: true })}
            />
            {errors.companyTitle && (
              <FieldError message={"This Field is Required"} />
            )}
          </Form.Group>
          <Form.Group className="dropdown_bg_grey height_adjust_dropdown">
            <Form.Label>Select your Category</Form.Label>
            <Dropdown alignRight>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="text-left"
                name="locationId"
              >
                {Locationselect ? Locationselect : "Select"}
                <IoMdArrowDropdown />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow search_field">
                {interestValue.getAllApprovedInterest &&
                  interestValue.getAllApprovedInterest.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => setLocationselect(item.description)}
                      >
                        {item.description}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>

          </Form.Group>
          <div className="mt-5">
            <Button
              type="submit"
              className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold"
            >
              Next
            </Button>
          </div>
        </Form>
      </Card>
    </React.Fragment>
  );
}
