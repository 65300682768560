import React, { useState, useRef, useEffect } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FiCheckCircle } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoArrowBackSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FieldError, Loader } from "../../assets";
import { CREDENTIALS } from "../../assets/LookUpdata";
import { INDEPENDENT_PROVIDER, PATH, PROVIDING_SERVICES } from "../../config";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import {
  getCountries,
  RegisterProvider,
  IsEmailExist,
} from "./../../redux/actions";
import { ToastContainer, toast } from "react-toastify";

export default function TeacherSteps(props) {
  const [stdstep, setstdstep] = useState(
    props.TeacherProfilePage ? props.TeacherProfilePage : 0
  );
  return (
    <div>
      <ToastContainer />
      {stdstep === 0 && <Step setstdstep={setstdstep} {...props} />}
      {stdstep === 1 && (
        <Step1
          setstdstep={setstdstep}
          changeView={props.changeView}
          {...props}
        />
      )}
    </div>
  );
}

function Step(props) {
  let dispatch = useDispatch();
  let emailExist = useSelector((state) => state.account);
  const { register, handleSubmit, errors, watch } = useForm();
  let teacherData = props.providerForm;
  const [fname, setFname] = useState(
    teacherData && teacherData.firstName ? teacherData.firstName : ""
  );
  const [lname, setLname] = useState(
    teacherData && teacherData.lastName ? teacherData.lastName : ""
  );
  const [email, setEmail] = useState(
    teacherData && teacherData.email ? teacherData.email : ""
  );
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [passwordShown1, setPasswordShown1] = useState(false);
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };
  const password = useRef({});
  password.current = watch("password", "");
  function onSubmit(data) {
    teacherData.firstName = data.firstName;
    teacherData.lastName = data.lastName;
    teacherData.email = data.email;
    teacherData.password = data.password;
    teacherData.confirmPassword = data.confirmPassword;
    dispatch(IsEmailExist(teacherData.email, moveToNext, notification));
    props.setProviderForm({ ...props.providerForm }, teacherData);
  }
  function moveToNext() {
    props.setstdstep(1);
  }
  function notification(data, condition) {
    if (condition === "success") {
      moveToNext();
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  function goBack() {
    teacherData.firstName = "";
    teacherData.lastName = "";
    teacherData.email = "";
    teacherData.password = "";
    teacherData.confirmPassword = "";
    props.changeView(0);
  }
  return (
    <div className="step-section">
      <div className="card">
        <div className="card-body">
          <IoArrowBackSharp
            className="edutech-back-arrow mb-3"
            onClick={() => goBack()}
          />
          <h5 className="card-title">
            Just a few details and we are good to go!
          </h5>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                className="mt-1"
                name="firstName"
                autocomplete="off"
                type="text"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                placeholder="Enter First Name"
                style={{
                  borderColor: errors && errors.firstName ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {fname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.firstName && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                className="mt-1"
                name="lastName"
                type="text"
                autocomplete="off"
                value={lname}
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                placeholder="Enter Last Name"
                style={{
                  borderColor: errors && errors.lastName ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {lname !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.lastName && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="mt-1"
                name="email"
                type="email"
                autocomplete="off"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter Email"
                style={{ borderColor: errors && errors.email ? "#a80000" : "" }}
                ref={register({
                  required: true,
                  pattern:
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
                })}
              />
              {email !== "" ? <FiCheckCircle className="check-icon" /> : ""}
              {errors.email && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                className="mt-1"
                name="password"
                type={passwordShown ? "text" : "password"}
                autocomplete="off"
                placeholder="Enter Password"
                style={{
                  borderColor: errors && errors.password ? "#a80000" : "",
                }}
                ref={register({
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^%*?&.()-_=+])[A-Za-z\d@$!%#^*?&.()-_=+]{8,}$/,
                })}
              />
              {passwordShown === false ? (
                <AiFillEyeInvisible
                  className="show_pswd"
                  onClick={togglePasswordVisiblity}
                />
              ) : (
                <AiFillEye
                  className="show_pswd"
                  onClick={togglePasswordVisiblity}
                />
              )}
              {errors && errors.password && (
                <FieldError
                  message={
                    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                  }
                />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                className="mt-1"
                name="confirmPassword"
                type={passwordShown1 ? "text" : "password"}
                autocomplete="off"
                placeholder="Enter Confirm Password"
                style={{
                  borderColor:
                    errors && errors.confirmPassword ? "#a80000" : "",
                }}
                ref={register({
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
              />
              {passwordShown1 === false ? (
                <AiFillEyeInvisible
                  className="show_pswd"
                  onClick={togglePasswordVisiblity1}
                />
              ) : (
                <AiFillEye
                  className="show_pswd"
                  onClick={togglePasswordVisiblity1}
                />
              )}
              {errors && errors.confirmPassword && (
                <FieldError message={errors.confirmPassword.message} />
              )}
            </Form.Group>
            <Form.Group>
              {emailExist && emailExist.emailExistLoading === false && (
                <Button type="submit" className="next_button">
                  {"Next"}
                </Button>
              )}
              {emailExist && emailExist.emailExistLoading === true && (
                <Loader />
              )}
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}
function Step1(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  let signUp = useSelector((state) => state.account);
  let country = useSelector((state) => state.country);
  const { register, handleSubmit, errors } = useForm();
  let teacherData = props.providerForm;
  const [provider, setProvider] = useState("");
  const [providerId, setProviderId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [Locationselect, setLocationselect] = useState(null);
  const [LocationselectId, setLocationselectId] = useState(null);
  const [nicheMarket, setNicheMarket] = useState("");
  const [nicheMarketId, setNicheMarketId] = useState("");
  const [experience, setExperience] = useState("");
  console.log("asdasfdas", providerId, nicheMarketId, LocationselectId);
  function goBack() {
    if (teacherData.loginTypeId !== CREDENTIALS) {
      props.changeView(0);
    } else {
      props.setstdstep(0);
    }
  }
  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);
  const updateSearch = (search) => {
    setSearchValue(search);
    let filterSearch = country.countries;
    filterSearch = country.countries.filter((list) => {
      return list.value.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    setSearchList(filterSearch);
  };
  useEffect(() => {
    setSearchList(country.countries);
  }, [country]);
  function onSubmit(data) {
    // if (provider === "Yes") {
    //   teacherData.provider.independentProvider = true;
    // } else {
    //   teacherData.provider.independentProvider = false;
    // }
    if (providerId && LocationselectId && nicheMarketId) {
      country.countries.map((item, i) => {
        if (item.value === Locationselect) {
          teacherData.provider.locationId = item.countryId;
        }
      });
      teacherData.provider.providerStatusId = providerId;
      teacherData.provider.locationId = LocationselectId;
      teacherData.provider.regionId = nicheMarketId;
      teacherData.provider.experience = parseInt(data.experience);
      props.setProviderForm({ ...props.providerForm }, teacherData);
      dispatch(RegisterProvider(teacherData, moveToNext, notification));
    } else {
      notification2();
    }
  }
  function moveToNext() {
    setTimeout(function () {
      history.push(PATH.LOGIN);
    }, 4600);
  }
  function notification(data, condition) {
    if (condition === "success") {
      toast.success("Registration completed successfully.", {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }
  function notification2(data, condition) {
    toast.error("Please fill the form.", {
      position: "top-right",
      autoClose: 4500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      <div className="step-section">
        <div className="card">
          <div className="card-body">
            <IoArrowBackSharp
              className="edutech-back-arrow  mb-3"
              onClick={() => goBack()}
            />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="dropdown_bg_grey">
                <Form.Label>Are you ?</Form.Label>
                {/* <Dropdown alignRight>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="text-left"
                    name="independentProvider"
                  >
                    {provider ? provider : "Select"}
                    <IoMdArrowDropdown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow">
                    <Dropdown.Item onClick={() => setProvider("Yes")}>
                      Yes
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setProvider("No")}>
                      No
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Dropdown alignRight>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="text-left"
                    name="locationId"
                  >
                    {provider ? provider : "Select"}
                    <IoMdArrowDropdown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow provider_class search_field">
                    {INDEPENDENT_PROVIDER &&
                      INDEPENDENT_PROVIDER.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setProvider(item.text);
                              setProviderId(item.lookupId);
                            }}
                          >
                            {item.text}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group className="dropdown_bg_grey height_adjust_dropdown">
                <Form.Label>Select your location</Form.Label>
                <Dropdown alignRight>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="text-left"
                    name="locationId"
                  >
                    {Locationselect ? Locationselect : "Select"}
                    <IoMdArrowDropdown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow search_field">
                    <div className="search_field">
                      <Form.Control
                        name="searchLocation"
                        type="text"
                        autocomplete="off"
                        value={searchValue}
                        onChange={(e) => {
                          updateSearch(e.target.value);
                        }}
                        ref={register()}
                      />
                    </div>
                    {searchList &&
                      searchList.map((country, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setLocationselect(country.value);
                              setLocationselectId(country.countryId);
                            }}
                          >
                            {country.value}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group className="dropdown_bg_grey height_adjust_dropdown">
                <Form.Label>
                  What region are you providing your services?
                </Form.Label>
                {/* <Form.Control
                  className="mt-1"
                  name="nicheMarket"
                  type="text"
                  autocomplete="off"
                  value={nicheMarket}
                  onChange={(e) => {
                    setNicheMarket(e.target.value);
                  }}
                  style={{
                    borderColor: errors && errors.nicheMarket ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {nicheMarket !== "" ? (
                  <FiCheckCircle className="check-icon" />
                ) : (
                  ""
                )}
                {errors.nicheMarket && (
                  <FieldError message={"This Field is Required"} />
                )} */}
                <Dropdown alignRight>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="text-left"
                    name="locationId"
                  >
                    {nicheMarket ? nicheMarket : "Select"}
                    <IoMdArrowDropdown />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow search_field">
                    {PROVIDING_SERVICES &&
                      PROVIDING_SERVICES.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              setNicheMarket(item.text);
                              setNicheMarketId(item.lookupId);
                            }}
                          >
                            {item.text}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group>
                <Form.Label>How many years of experience?</Form.Label>
                <Form.Control
                  className="mt-1"
                  name="experience"
                  type="number"
                  autocomplete="off"
                  value={experience}
                  onChange={(e) => {
                    setExperience(e.target.value);
                  }}
                  style={{
                    borderColor: errors && errors.experience ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {experience !== "" ? (
                  <FiCheckCircle className="check-icon" />
                ) : (
                  ""
                )}
                {errors.experience && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
              <Form.Group>
                {signUp && signUp.registerProviderLoading === false && (
                  <Button type="submit" className="next_button">
                    {"Done"}
                  </Button>
                )}
                {signUp && signUp.registerProviderLoading === true && (
                  <Loader />
                )}
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
