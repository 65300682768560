import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../config";
import {
  FaHome,
  FaFileContract,
  FaQuestion,
  FaChalkboardTeacher,
  FaCriticalRole,
} from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import {
  MdOutlineManageAccounts,
  MdSubscriptions,
  MdPayment,
  MdCreate,
} from "react-icons/md";
import { FiGitPullRequest, FiSettings } from "react-icons/fi";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { IoIosContact } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { GetMenuPermissionById } from "../../redux/actions";
export default function Sidebar() {
  // const [cookies, remove] = useCookies(['alfa_care_user']);
  let history = useHistory();
  let auth = useAuth();
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetMenuPermissionById(auth?.edu_tech_user?.userId, auth));
  }, [dispatch, auth]);
  let rolePermission = useSelector((state) => state.permission);
  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            {auth.edu_tech_user.roleId === 4 && (
              <>
                {rolePermission?.getMenuPermissionById?.data?.map(
                  (course, index) => {
                    return (
                      <>
                        {course.sideBarMenuId === 21 ? (
                          <>
                            <li
                              className={
                                history.location.pathname ===
                                  PATH.TEACHERCOURSEINFORMATION ||
                                history.location.pathname ===
                                  PATH.ADDLECTURES ||
                                history.location.pathname ===
                                  PATH.PRICINGPLAN ||
                                history.location.pathname ===
                                  PATH.TEACHERCURRICULUMPUBLISH ||
                                history.location.pathname ===
                                  PATH.TEACHERCURRICULUMPREVIEW ||
                                history.location.pathname ===
                                  PATH.CREATENEWSECTION ||
                                history.location.pathname ===
                                  PATH.ADMIN_COURSE_PREVIEW ||
                                history.location.pathname ===
                                  PATH.TEACHERPRICINGLIST ||
                                history.location.pathname ===
                                  PATH.TEACHERCURRICULUMLIST ||
                                history.location.pathname ===
                                  PATH.TEACHERNEWCOURSE
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to={{
                                  pathname: PATH.TEACHERCURRICULUMPUBLISH,
                                }}
                              >
                                {" "}
                                <FaFileContract />
                                <span>Courses</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {course.sideBarMenuId === 22 ? (
                          <>
                            <li
                              className={
                                history.location.pathname ===
                                  PATH.TEACHERCREATECOACHING ||
                                history.location.pathname ===
                                  PATH.TEACHINGCLASS ||
                                history.location.pathname ===
                                  PATH.TEACHERGROUP ||
                                history.location.pathname ===
                                  PATH.TEACHERONETOONE ||
                                history.location.pathname ===
                                  PATH.FINISHUPCOACHING ||
                                history.location.pathname ===
                                  PATH.TEACHERUPDATECOACHING ||
                                history.location.pathname ===
                                  PATH.TEACHERCREATECOACHING ||
                                history.location.pathname === PATH.COACHINGLIST
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to={{
                                  pathname: PATH.COACHINGLIST,
                                }}
                              >
                                {" "}
                                <FaChalkboardTeacher></FaChalkboardTeacher>
                                <span>Coaching</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {course.sideBarMenuId === 23 ? (
                          <>
                            <li
                              className={
                                history.location.pathname ===
                                PATH.ADMINPRICINGPLAN
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to={{
                                  pathname: PATH.ADMINPRICINGPLAN,
                                }}
                              >
                                {" "}
                                <MdSubscriptions />
                                <span>Pricing Plan</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {course.sideBarMenuId === 24 ? (
                          <>
                            <li
                              className={
                                history.location.pathname ===
                                PATH.PAYMENT_DETAIL
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to={{
                                  pathname: PATH.PAYMENT_DETAIL,
                                }}
                              >
                                {" "}
                                <MdPayment />
                                <span>Payment Detail</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {course.sideBarMenuId === 25 ? (
                          <>
                            <li
                              className={
                                history.location.pathname === PATH.MANAGE_USER
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to={{
                                  pathname: PATH.MANAGE_USER,
                                }}
                              >
                                {" "}
                                <MdOutlineManageAccounts />
                                <span>Manage Users</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                        {course.sideBarMenuId === 26 ? (
                          <>
                            <li
                              className={
                                history.location.pathname === PATH.REQUEST_TOPIC
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to={{
                                  pathname: PATH.REQUEST_TOPIC,
                                }}
                              >
                                {" "}
                                <FiGitPullRequest />
                                <span>Category Requests</span>
                              </Link>
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                )}
                <li
                  className={
                    history.location.pathname === PATH.ADMIN_FAQ ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.ADMIN_FAQ,
                    }}
                  >
                    {" "}
                    <FaQuestion />
                    <span>FAQ's</span>
                  </Link>
                </li>
              </>
            )}
            {auth.edu_tech_user.roleId === 3 && (
              <>
                <li
                  className={
                    history.location.pathname === PATH.DASHBOARD ||
                    history.location.pathname === PATH.DASHBOARD_BREAKTHROUGH ||
                    history.location.pathname ===
                      PATH.DASHBOARD_ADDTOCARTCOURSE ||
                    history.location.pathname ===
                      PATH.Dashboard_COURSE_PREVIEW ||
                    history.location.pathname ===
                      PATH.EXPLOREDASHBOARDCATEGORY ||
                    history.location.pathname === PATH.COMPLETE_COURSE
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.DASHBOARD,
                    }}
                  >
                    {" "}
                    <FaHome></FaHome>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.COURSE ||
                    history.location.pathname === PATH.COURSES_BREAKTHROUGH ||
                    history.location.pathname === PATH.COURSE_ADDTOCARTCOURSE ||
                    history.location.pathname ===
                      PATH.WISHLIST_COURSE_PREVIEW ||
                    history.location.pathname === PATH.MY_COURSE_PREVIEW ||
                    history.location.pathname === PATH.COURSE_PREVIEW
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.COURSE,
                    }}
                  >
                    {" "}
                    <FaFileContract />
                    <span>My Courses</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.MY_COACHING_PREVIEW ||
                    history.location.pathname === PATH.MY_COACHING
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.MY_COACHING,
                    }}
                  >
                    {" "}
                    <FaFileContract />
                    <span>My Coachings</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.EXPLORE ||
                    history.location.pathname === PATH.EXPLOREPRICINGPLANS ||
                    history.location.pathname === PATH.EXPLORE_BREAKTHROUGH ||
                    history.location.pathname === PATH.EXPLORE_COURSE_PREVIEW ||
                    history.location.pathname === PATH.COACHING_PREVIEW ||
                    history.location.pathname === PATH.EXPLORECATEGORY ||
                    history.location.pathname === PATH.EXPLORE_ADDTOCARTCOURSE
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.EXPLORE,
                    }}
                  >
                    {" "}
                    <BsSearch></BsSearch>
                    <span>Explore</span>
                  </Link>
                </li>
                {/* <li
                  className={
                    history.location.pathname === PATH.CUSTOMERVOUCHER
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.CUSTOMERVOUCHER,
                    }}
                  >
                    {" "}
                    <RiMessage2Fill></RiMessage2Fill>
                    <span>Bulletin Board</span>
                  </Link>
                </li> */}
                <li
                  className={
                    history.location.pathname === PATH.STUDENT_FAQ
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.STUDENT_FAQ,
                    }}
                  >
                    {" "}
                    <FaQuestion />
                    <span>FAQ's</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.SETTINGS ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.SETTINGS,
                    }}
                  >
                    {" "}
                    <FiSettings></FiSettings>
                    <span>Settings</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.CONTACT_US
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.CONTACT_US,
                    }}
                  >
                    {" "}
                    <IoIosContact></IoIosContact>
                    <span>Contact Us</span>
                  </Link>
                </li>
              </>
            )}
            {auth.edu_tech_user.roleId === 2 && (
              <>
                <li
                  className={
                    history.location.pathname === PATH.DASHBOARD ||
                    history.location.pathname === PATH.TEACHERDASHBOARD ||
                    history.location.pathname === PATH.TEACHER_TOTAL_COURSES ||
                    history.location.pathname === PATH.TEACHER_TOTAL_STUDENT ||
                    history.location.pathname === PATH.TEACHER_TOTAL_SALE ||
                    history.location.pathname === PATH.TEACHER_TOTAL_CERTIFICATE
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.TEACHERDASHBOARD,
                    }}
                  >
                    {" "}
                    <FaHome></FaHome>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname ===
                      PATH.TEACHERCOURSEINFORMATION ||
                    history.location.pathname === PATH.ADDLECTURES ||
                    history.location.pathname === PATH.PRICINGPLAN ||
                    history.location.pathname ===
                      PATH.TEACHERCURRICULUMPUBLISH ||
                    history.location.pathname === PATH.CREATENEWSECTION ||
                    history.location.pathname === PATH.TEACHER_COURSE_PREVIEW ||
                    history.location.pathname ===
                      PATH.TEACHERCURRICULUMPREVIEW ||
                    (history.location.pathname ===
                      history.location.pathname) ===
                      PATH.TEACHERPRICINGLIST ||
                    history.location.pathname === PATH.TEACHERCOURSESGROUP ||
                    history.location.pathname === PATH.TEACHERCURRICULUMLIST ||
                    history.location.pathname === PATH.TEACHERNEWCOURSE
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.TEACHERCOURSESGROUP,
                    }}
                  >
                    {" "}
                    <FaFileContract />
                    <span>Courses</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.TEACHERCREATECOACHING ||
                    history.location.pathname === PATH.TEACHINGCLASS ||
                    history.location.pathname === PATH.TEACHERGROUP ||
                    history.location.pathname === PATH.TEACHERONETOONE ||
                    history.location.pathname === PATH.FINISHUPCOACHING ||
                    history.location.pathname === PATH.REVIEW_AND_SAVE ||
                    history.location.pathname === PATH.TEACHERUPDATECOACHING ||
                    history.location.pathname === PATH.TEACHERCREATECOACHING ||
                    history.location.pathname === PATH.COACHINGLIST
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.COACHINGLIST,
                    }}
                  >
                    {" "}
                    <FaChalkboardTeacher></FaChalkboardTeacher>
                    <span>Coaching</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.TEACHER_FAQ
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.TEACHER_FAQ,
                    }}
                  >
                    {" "}
                    <FaQuestion />
                    <span>FAQ's</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.CONTACT_US
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.CONTACT_US,
                    }}
                  >
                    {" "}
                    <IoIosContact></IoIosContact>
                    <span>Contact Us</span>
                  </Link>
                </li>
                {/* <li
                  className={
                    history.location.pathname === PATH.SETTINGS ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.SETTINGS,
                    }}
                  >
                    {" "}
                    <FiSettings></FiSettings>
                    <span>Settings</span>
                  </Link>
                </li> */}
              </>
            )}
            {auth.edu_tech_user.roleId === 1 && (
              <>
                <li
                  className={
                    history.location.pathname === PATH.DASHBOARD ||
                    history.location.pathname === PATH.ADMIN_DASHBOARD ||
                    history.location.pathname === PATH.DASHBOARDBREAKTHROUGH ||
                    history.location.pathname === PATH.COMPLETE_COURSE ||
                    history.location.pathname === PATH.ADMIN_TOTAL_USERS ||
                    history.location.pathname === PATH.ADMIN_TOTAL_COACHING ||
                    history.location.pathname === PATH.ADMIN_TOTAL_COURSES ||
                    history.location.pathname === PATH.ADMIN_TOTAL_SALES
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.ADMIN_DASHBOARD,
                    }}
                  >
                    {" "}
                    <FaHome></FaHome>
                    <span>Dashboard</span>
                  </Link>
                </li>

                <li
                  className={
                    history.location.pathname === PATH.MANAGE_USER
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.MANAGE_USER,
                    }}
                  >
                    {" "}
                    <MdOutlineManageAccounts />
                    <span>Manage Users</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname ===
                      PATH.TEACHERCOURSEINFORMATION ||
                    history.location.pathname === PATH.ADDLECTURES ||
                    history.location.pathname === PATH.PRICINGPLAN ||
                    history.location.pathname ===
                      PATH.TEACHERCURRICULUMPUBLISH ||
                    history.location.pathname ===
                      PATH.TEACHERCURRICULUMPREVIEW ||
                    history.location.pathname === PATH.CREATENEWSECTION ||
                    history.location.pathname === PATH.ADMIN_COURSE_PREVIEW ||
                    history.location.pathname === PATH.TEACHERPRICINGLIST ||
                    history.location.pathname === PATH.TEACHERCURRICULUMLIST ||
                    history.location.pathname === PATH.TEACHERNEWCOURSE
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.TEACHERCURRICULUMPUBLISH,
                    }}
                  >
                    {" "}
                    <FaFileContract />
                    <span>Courses</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.TEACHERCREATECOACHING ||
                    history.location.pathname === PATH.TEACHINGCLASS ||
                    history.location.pathname === PATH.TEACHERGROUP ||
                    history.location.pathname === PATH.TEACHERONETOONE ||
                    history.location.pathname === PATH.FINISHUPCOACHING ||
                    history.location.pathname === PATH.TEACHERUPDATECOACHING ||
                    history.location.pathname === PATH.TEACHERCREATECOACHING ||
                    history.location.pathname === PATH.COACHINGLIST
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.COACHINGLIST,
                    }}
                  >
                    {" "}
                    <FaChalkboardTeacher></FaChalkboardTeacher>
                    <span>Coaching</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.REQUEST_TOPIC
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.REQUEST_TOPIC,
                    }}
                  >
                    {" "}
                    <FiGitPullRequest />
                    <span>Category Requests</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.ROLE_AND_PERMISSION
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.ROLE_AND_PERMISSION,
                    }}
                  >
                    {" "}
                    <FaCriticalRole />
                    <span>permissions</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.CREATE_NEW_STAFF
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.CREATE_NEW_STAFF,
                    }}
                  >
                    {" "}
                    <MdCreate />
                    <span>Create New Staff</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.ADMINPRICINGPLAN
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.ADMINPRICINGPLAN,
                    }}
                  >
                    {" "}
                    <MdSubscriptions />
                    <span>Pricing Plan</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.PAYMENT_DETAIL
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.PAYMENT_DETAIL,
                    }}
                  >
                    {" "}
                    <MdPayment />
                    <span>Payment Detail</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.CONTACT_US_ADMIN
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.CONTACT_US_ADMIN,
                    }}
                  >
                    {" "}
                    <IoIosContact />
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li
                  className={
                    history.location.pathname === PATH.ADMIN_FAQ ? "active" : ""
                  }
                >
                  <Link
                    to={{
                      pathname: PATH.ADMIN_FAQ,
                    }}
                  >
                    {" "}
                    <FaQuestion />
                    <span>FAQ's</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
