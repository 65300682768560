import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { FieldError } from "../../../../../assets";
import { useHistory, useLocation } from "react-router-dom";
export default function AddTranscript(props) {
  const { register, handleSubmit, errors } = useForm();
  const CoursesData = props?.CoursesData;
  console.log("asxzcxzcdsd122123212111", CoursesData)
  const [lectureTranscript, setLectureTranscript] = useState(
    CoursesData && CoursesData.lectureTranscript ? CoursesData.lectureTranscript : ""
  );
  function onSubmit() {
    CoursesData.lectureTranscript = lectureTranscript
    props.setSelectedView(4)
  }
  return (
    <>
      <h3 className="font-weight-bold mb-3">Add Transcript</h3>
      <Form onSubmit={handleSubmit(onSubmit)} name="myform" id="myform" className="text-left">
        <CKEditor
          className="edutech-ckeditor"
          editor={ClassicEditor}
          data={lectureTranscript}
          name="lectureTranscript"
          onReady={(lectureTranscript) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", lectureTranscript);
          }}
          onChange={(event, lectureTranscript) => {
            setLectureTranscript(lectureTranscript.getData());
            // console.log({ event, editor, data });
          }}
          onBlur={(event, lectureTranscript) => {
            console.log("Blur.", lectureTranscript);
          }}
          onFocus={(event, lectureTranscript) => {
            console.log("Focus.", lectureTranscript);
          }}
        />
        <input
          hidden
          type="text"
          name="editor"
          value={lectureTranscript}
          ref={register({ required: true })}
        />
        {errors.lectureTranscript && (
          <div className="mt-2">
            <FieldError message={"This Field is Required"} />
          </div>
        )}
        <div className="mt-5">
          <Button className="edutech-curriculum-action-button edutech-pull-right my-2 font-weight-bold" type="submit">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
