import { createContact, getByFilterConatctUs } from "../api/contactUsApi";
import { request, success, failure, CONTACT_US_REQUEST } from "./utilities";

export function CreateContact(data, auth, Notificiation, moveToNext) {
  return (dispatch) => {
    dispatch(request(CONTACT_US_REQUEST.CREATE_REQUEST));
    createContact(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(CONTACT_US_REQUEST.CREATE_SUCCESS, response.data.data)
          );
          Notificiation(response.data.message, "success");
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(CONTACT_US_REQUEST.CREATE_FAILURE, response.data.message)
          );
          Notificiation(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            CONTACT_US_REQUEST.CREATE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetByFilterConatctUs() {
  return (dispatch) => {
    dispatch(request(CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_REQUEST));
    getByFilterConatctUs().then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
