import { APP_SETTINGS } from "../../config";
import axios from "axios";
import { LINKEDIN_CLIENT_ID, LINKED_IN_SECRET_ID, LINKED_IN_REDIRECT_URL } from "../../config";

export function registerProvider(data) {
  return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerProvider, data);
}
export function registerStudent(data) {
  return axios.post(APP_SETTINGS.API_PATH.REGISTER.registerStudent, data);
}
export function getCredits(auth) {
  return axios.get(APP_SETTINGS.API_PATH.REGISTER.getCredits, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function socialMedia(socialKey, email) {
  return axios.post(`${APP_SETTINGS.API_PATH.REGISTER.socialMedia.replace(":socialKey", socialKey)}?email=${email}`);
}
export function getLinkedInProfile(data) {
  let newData={accessToken: data}
  return axios.post(APP_SETTINGS.API_PATH.REGISTER.getLinkedInProfile, newData);
}
export function isEmailExist(data) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.REGISTER.isEmailExist}?request=${data}`
  );
}
export function login(data) {
  return axios.post(APP_SETTINGS.API_PATH.REGISTER.login, data);
}
export function studentLogin(data) {
  return axios.post(APP_SETTINGS.API_PATH.REGISTER.studentLogin, data);
}
export function getLinkedinTokenKey(code) {
  return axios.post(APP_SETTINGS.API_PATH.REGISTER.getLinkedinTokenKey, code);
}
export function getAccessToken(code) {
  return axios.get(`https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=${LINKEDIN_CLIENT_ID}&client_secret=${LINKED_IN_SECRET_ID}&code=${code}&redirect_uri=${LINKED_IN_REDIRECT_URL}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', 
      "Access-Control-Allow-Origin": "*"
    },
  })
}