import moment from "moment";
function getTodayDate() {
  var today = new Date();
  return today;
}
function getPreviousDate(date, interval) {
  const toDate = new Date(date);
  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() - interval);
  return fromDate;
}
function getNextDate(date, interval) {
  const toDate = new Date(date);
  const fromDate = new Date(toDate);
  fromDate.setDate(fromDate.getDate() + interval);
  return fromDate;
}
function monthOfYearAsString(monthIndex) {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][monthIndex];
}
function dayOfWeekAsString(dayIndex) {
  return ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"][dayIndex];
}
function fullMonthOfYearAsString(monthIndex) {
  return [
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][monthIndex];
}
function getDateInFormat(date) {
  var dateToChange = new Date(date);
  var month = dateToChange.getMonth();
  var date_ = dateToChange.getDate();
  var year = dateToChange.getFullYear();
  return `${month + 1}/${date_}/${year}`;
}
function getDateInFormat1(date) {
  var dateToChange = new Date(date);
  var month = dateToChange.getMonth();
  var date_ = dateToChange.getDate();
  var year = dateToChange.getFullYear();
  return `${year}-${month + 1}-${date_}`;
}
function getTodayDateWithTimeFormat() {
  var currentDate = new Date();
  var month = currentDate.getMonth() + 1;
  var date_ = currentDate.getDate();
  var year = currentDate.getFullYear();
  if (month.toString().length < 2) {
    month = `0${month}`;
  }
  if (date_.toString().length < 2) {
    date_ = `0${date_}`;
  }
  return `${year}-${month}-${date_}T00:00`;
}
function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
function getInitialName(name) {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;
  return initials.toUpperCase();
}
function getDateNTime(date) {
  var dateToChange = new Date(date);
  var month = dateToChange.getMonth();
  var date_ = dateToChange.getDate();
  var year = dateToChange.getFullYear();
  return `${month + 1}/${date_}/${year}`;
}
function getTimeFormat(time) {
  var timeToChange = new Date(time);
  var hours = timeToChange.getHours();
  var minutes = timeToChange.getMinutes();
  return `${hours}:${minutes}`;
}
function dateTimeConvert(date) {
  // return (moment(date).format('MMMM Do YYYY, h:mm:ss a'))
  // return (moment(date).format('MMMM Do YYYY, h:mm:ss a'))
  return moment(date).add(5, "hour").format("MMMM Do YYYY, h:mm:ss a");
}
function createImageFromInitials(size, name, color) {
  if (name == null) return;
  name = getInitialName(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;
  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);
  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);
  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, size / 2, size / 2);
  return canvas.toDataURL();
}
function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
function listOfNextYears(number) {
  let expYearArray = [];
  for (let i = 0; i <= number; i++) {
    expYearArray.push(new Date().getFullYear() + i);
  }
  return expYearArray;
}
function listOfMonths() {
  return [
    {
      id: 0,
      value: 1,
      month: "January",
    },
    {
      id: 1,
      value: 2,
      month: "Feburary",
    },
    {
      id: 2,
      value: 3,
      month: "March",
    },
    {
      id: 3,
      value: 4,
      month: "April",
    },
    {
      id: 4,
      value: 5,
      month: "May",
    },
    {
      id: 5,
      value: 6,
      month: "June",
    },
    {
      id: 6,
      value: 7,
      month: "July",
    },
    {
      id: 7,
      value: 8,
      month: "August",
    },
    {
      id: 8,
      value: 9,
      month: "September",
    },
    {
      id: 9,
      value: 10,
      month: "October",
    },
    {
      id: 10,
      value: 11,
      month: "November",
    },
    {
      id: 11,
      value: 12,
      month: "December",
    },
  ];
}
function expiryDateCheck(expiryDate) {
  var GivenDate = expiryDate;
  var CurrentDate = new Date();
  GivenDate = new Date(GivenDate);
  if (GivenDate > CurrentDate) {
    return true;
  } else {
    return false;
  }
}
function dateDifference(createdDate) {
  var date1 = new Date(createdDate);
  var date2 = new Date();
  var diffDays = date2.getDate() - date1.getDate();
  if (diffDays < 0) {
    diffDays = diffDays * -1;
  }
  return diffDays;
}
function getCountryTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
function allMonthList() {
  return [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
}
function getFileExtension(file) {
  let lastIndexOfDot = file.lastIndexOf(".");
  let ext = file.slice(lastIndexOfDot + 1, file.length);
  return ext;
}
function landlordTenantByMonthData(role, data) {
  let monthList = allMonthList();
  let chartData = monthList.map((month) => {
    let monthData = data.find((rec) => rec.month === month);
    if (monthData) return monthData.price;
    return 0;
  });

  return chartData;
  // let monthList = allMonthList()
  // let dataArray = [];
  // let value = 0;
  // switch (role) {
  //   case "landlord":
  //     while (value <= 5) {
  //       for (let i = 0; i < data.length; i++) {
  //         if (data[i].month === monthList[value]) {
  //           dataArray.push(data[i].price)
  //         }
  //         else {
  //           dataArray.push(0);
  //         }
  //       }
  //       value++;
  //     }
  //     return dataArray;
  //   default:
  //     dataArray = [];
  // }
}
function dataByTopCourses(data) {
  let stateData = [];
  for (let i = 0; i < data.length; i++) {
    stateData.push({
      name: data[i].courseTitle,
      y: data[i].subscribedCourses,
    });
  }
  return stateData;
}
function dataByTopCategory(data) {
  let stateData = [];
  for (let i = 0; i < data.length; i++) {
    stateData.push({
      name: data[i].description,
      y: data[i].subscribedCourses,
    });
  }
  return stateData;
}
function alertDialog(msg, yesFunc, params) {
  return window.confirm(msg) && yesFunc(params);
}
function getCouponDiscountedAmount(couponDiscount) {
  if (couponDiscount) {
    let finalAmount =
      (couponDiscount.bonusPercentage / 100) * couponDiscount.price;
    return {
      bonusPercentage: finalAmount,
      total:
        couponDiscount.price + finalAmount >= 0
          ? couponDiscount.price + finalAmount
          : 0,
    };
  }
}
function subscriptionData(subscription, data) {
  let monthList = allMonthList();

  let chartData = monthList.map((month) => {
    let monthData = data.find((rec) => rec.month === month);
    if (monthData) return monthData.subscribedCourses;
    return 0;
  });

  return chartData;
}
function getSimpleTimeIn12Format(date) {
  const timeString12hr = new Date(
    "1970-01-01T" + date + "Z"
  ).toLocaleTimeString("en-US", {
    timeZone: "UTC",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  return timeString12hr;
}
function formatInMintAndSec(time) {
  if (time) {
    const latestDate = new Date(time).toISOString().split("T")[1].split(":");

    return latestDate[0] + ":" + latestDate[1];
  }
}

export {
  getTodayDate,
  getPreviousDate,
  formatInMintAndSec,
  getNextDate,
  monthOfYearAsString,
  dayOfWeekAsString,
  getInitialName,
  getFileExtension,
  getDateInFormat,
  getSimpleTimeIn12Format,
  dateTimeConvert,
  convertBase64,
  createImageFromInitials,
  getRandomColor,
  listOfNextYears,
  listOfMonths,
  expiryDateCheck,
  fullMonthOfYearAsString,
  dateDifference,
  getCountryTimeZone,
  subscriptionData,
  allMonthList,
  landlordTenantByMonthData,
  dataByTopCourses,
  dataByTopCategory,
  getDateInFormat1,
  alertDialog,
  getDateNTime,
  getTimeFormat,
  getCouponDiscountedAmount,
  getTodayDateWithTimeFormat,
};
