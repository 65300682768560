const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const PATH = {
  NOPAGE: "*",
  LOGIN: "/login",
  FORGOTPASSWORD: "/forgot-password",
  SIGNUP: "/",
  SIGNUPSTEPS: "/signup-steps",
  LOGINEXPLOREDASHBOARD: "/login/explore",
  EXPLORE: "/student/explore",
  EXPLORE_ADDTOCARTCOURSE: "/explore/add-to-cart",
  DASHBOARD_ADDTOCARTCOURSE: "/dashboard/add-to-cart",
  COURSE_ADDTOCARTCOURSE: "/course/add-to-cart",
  COURSE: "/course",
  TEACHER_CREATE_COURSE: "/teaher/create-course",
  DASHBOARD: "/dashboard",
  COURSE_PREVIEW: "/course/course-preview",
  MY_COURSE_PREVIEW: "/course/course-preview",
  EXPLORE_COURSE_PREVIEW: "/explore/course-preview",
  Dashboard_COURSE_PREVIEW: "/dashboard/course-preview",
  WISHLIST_COURSE_PREVIEW: "/wishlist/course-preview",
  COMPLETE_COURSE: "/complete-course",
  DASHBOARD_BREAKTHROUGH: "/dashboard/breakthrough",
  EXPLORE_BREAKTHROUGH: "/explore/breakthrough",
  COURSES_BREAKTHROUGH: "/course/breakthrough",
  SETTINGS: "/settings",
  STUDENT_PROFILEEDIT: "/student/profile-edit",
  STUDENT_PROFILESHOW: "/student/profile",
  TEACHER_PROFILEEDIT: "/teacher/profile-edit",
  TEACHER_PROFILESHOW: "/teacher/profile",
  EXPLORECATEGORY: "/explpore-category",
  EXPLOREDASHBOARDCATEGORY: "/explpore-dashboard-category",
  PRICINGPLAN: "/teacher/pricing-plan",
  TEACHERCOURSEINFORMATION: "/teaher/course-information",
  TEACHER_COURSE_PREVIEW: "/teaher/course-Preview",
  ADMIN_COURSE_PREVIEW: "/course-Preview",
  TEACHERNEWCOURSE: "/teaher/new-course",
  TEACHERCURRICULUMLIST: "/teacher/curriculm",
  ADDLECTURES: "/teacher/add-lectures",
  TEACHERCREATECOACHING: "/teacher/create-coaching",
  TEACHERUPDATECOACHING: "/teacher/update-coaching",
  TEACHERGROUP: "/teacher/coaching",
  TEACHERDASHBOARD: "/teacher/dashboard",
  TEACHERCURRICULUMPUBLISH: "/teacher/curriculm-publish",
  BOLLETIN_CARD: "/teacher/bolletincard",
  TEACHERSTEPS: "/teacher-steps",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMINPRICINGPLAN: "/admin/pricing-plan",
  MANAGE_COURSE: "/manage-course",
  MANAGE_USER: "/admin/manage-users",
  ADMIN_QUERY: "/Admin/Query",
  STUDENT_FAQ: "/Student/Faq",
  TEACHER_FAQ: "/Teacher/Faq",
  ADMIN_COURSE_REQUESTS: "/Admin/CourseRequest",
  PAYMENT_DETAIL: "/Admin/PaymentDtails",
  TEACHINGCOACHINGINFORMATION: "/teacher/coaching/information",
  FINISHUPCOACHING: "/teacher/coaching/finishup",
  COACHINGLIST: "/teacher/coachingList",
  TEACHERCOACHINGPREVIEW: "/teacher/coachingpreview",
  CREATENEWSECTION: "/teacher/createNewSection",
  ADMIN_COACHING_REQUESTS: "/AdminManageCoaching",
  SUBSCRIPTIONPLANS: "/subscriptionplan",
  TEACHERPRICINGLIST: "/teacher/pricing-list",
  EXPLOREPRICINGPLANS: "/explore/pricing-plans",
  TEACHERCURRICULUMPREVIEW: "/teacher/curriculum/preview",
  WISHLIST: "/wishlist",
  LOGINSUPPORTDASHBOARD: "/support",
  ADMIN_TOTAL_USERS: "/Admin/Dashboard/totalUsers",
  ADMIN_TOTAL_COACHING: "/Admin/Dashboard/totalCoaching",
  ADMIN_TOTAL_COURSES: "/Admin/Dashboard/TotalCourse",
  ADMIN_TOTAL_SALES: "/Admin/Dashboard/totalSales",
  TEACHER_TOTAL_COURSES: "/teacher/total-courses",
  TEACHER_TOTAL_STUDENT: "/teacher/total-student",
  TEACHER_TOTAL_SALE: "/teacher/total-sale",
  TEACHER_TOTAL_CERTIFICATE: "/teacher/total-certificate",
  LOGIN_EXPLORE_COURSE: "/explore-course",
  LOGIN_ADD_CART: "/add-cart",
  REQUEST_TOPIC: "/request-topic",
  STUDENT_CREDITS: "/Student/Credits",
  ADMIN_FAQ: "/Admin/Faq",
  COACHING_PREVIEW: "/coaching-preview",
  MY_COACHING_PREVIEW: "/my-coaching-preview",
  UPCOMING_COACHING_PREVIEW: "/upcoming-coaching-preview",
  MY_COACHING: "/student/mycoaching",
  PAYMENT_PLAN: "/paymentplan",
  REVIEW_AND_SAVE: "/teacher/coaching-preview",
  TEACHERCOURSESGROUP: "/teacher/courses",
  PURCHASECREDIT: "/student/purchaseCredit",
  LINKEDIN_REDIRECT: "/linkedin/redirect",
  CONTACT_US: "/contact-us",
  CONTACT_US_ADMIN: "/contact-us-admin",
  CERTIFICATE: "/certificate",
  CREATE_NEW_STAFF: "/new-staff",
  ROLE_AND_PERMISSION: "/role-permission",
  PRIVACY_POLICY: "/Privacy&Policy",
};

// const baseUrl = "https://edutechapi.xeventechnologies.com/api/";y
// const baseUrl = "https://edutechapi.azurewebsites.net/api/";
const baseUrl = "https://edutechapi.xeventechnologies.com/api/";
// const baseUrl = "https://pifapidev.xevensolutions.com/api/";
// const baseUrl = "https://localhost:44396/api/";
const APP_SETTINGS = {
  API_PATH: {
    REGISTER: {
      // registerProvider: baseUrl + "Account/Register/Provider",
      registerProvider: baseUrl + "Provider/create",
      registerStudent: baseUrl + "Student/create",
      getCredits: baseUrl + "Student/getCredits",
      // socialMedia: baseUrl + "Account/Login/SocialMedia/:socialKey",
      socialMedia: baseUrl + "Login/login/socialMedia/:socialKey ",
      getLinkedInProfile: baseUrl + "Login/social/getLinkedInProfile",
      getLinkedinTokenKey: baseUrl + "Login/getLinkedinTokenKey",
      // getLinkedInProfile: baseUrl + "Account/social/getLinkedInProfile",
      isEmailExist: baseUrl + "Provider/IsEmailExist",
      login: baseUrl + "Login/login",
      studentLogin: baseUrl + "Login/studentLogin",
    },
    COUNTRY: {
      getAllCountry: baseUrl + "Lookup/getAllCountries",
      countryByName: baseUrl + "Lookup/getCountriesByName",
      getIndustry: baseUrl + "Lookup/get",
    },
    DASHBOARD: {
      getAdminDashboardTiles: baseUrl + "Dashboard/getAdminDashboardTiles",
      getTotalCoachingSales: baseUrl + "Dashboard/getTotalCoachingSales",
      getTotalCoursesSales: baseUrl + "Dashboard/getTotalCoursesSales",
      getProviderDashboardTiles:
        baseUrl + "Dashboard/getProviderDashboardTiles",
      getCertificationsAwardedList:
        baseUrl + "Dashboard/getCertificationsAwardedList",
      getCourseSalesList: baseUrl + "Dashboard/getCourseSalesList",
      getCoachingSalesList: baseUrl + "Dashboard/getCoachingSalesList",
      getCourseStudentsList: baseUrl + "Dashboard/getCourseStudentsList",
      getCoachingStudentsList: baseUrl + "Dashboard/getCoachingStudentsList",
      getQuotation: baseUrl + "Dashboard/getQuotation",
      adminDashboardRevenue: baseUrl + "Dashboard/adminDashboardRevenue",
      adminDashboardTopCoursesGraph:
        baseUrl + "Dashboard/adminDashboardTopCoursesGraph",
      adminDashboardTopCategoriesGraph:
        baseUrl + "Dashboard/adminDashboardTopCategoriesGraph",
      providerDashboardTotalEarnings:
        baseUrl + "Dashboard/providerDashboardTotalEarnings",
      // providerDashboardStatusOfCourses: baseUrl + "Dashboard/providerDashboardStatusOfCourses",
      providerDashboardStatusOfCourses:
        baseUrl + "Dashboard/dashboardStatusOfCourses",
    },
    LOOKUP: {
      lookUp: baseUrl + "LookUp/Get/ROLE",
      AZURE: {
        uploadAzure: baseUrl + "Azure/uploadToAzure",
        uploadImage: baseUrl + "Azure/UploadImage",
      },
    },
    ADMIN: {
      MANAGE_USER: {
        manageUser: baseUrl + "Admin/userManager",
      },
    },
    USER_PROFILE: {
      getUserProfile: baseUrl + "Provider/getById",
      getUserStudentProfile: baseUrl + "Student/getById",
      updateProviderProfile: baseUrl + "Provider/update",
      updateStudentProfile: baseUrl + "Student/update",
    },
    RSS_FEED: {
      getRSSFeedInvesting: baseUrl + "RSSFeed/getRSSFeedInvesting",
      getRSSFeedFT: baseUrl + "RSSFeed/getRSSFeedFT",
      getRSSFeedFortune: baseUrl + "RSSFeed/getRSSFeedFortune",
    },
    STAFF_MEMBER: {
      createStaffMember: baseUrl + "StaffMember/create",
      updateStaffMember: baseUrl + "StaffMember/update",
      getStaffProfile: baseUrl + "StaffMember/getProfile",
    },
    FORGET_PASSWORD: {
      checkEmail: baseUrl + "ForgetPassword/checkEmail",
      checkCode: baseUrl + "ForgetPassword/checkCode",
      put: baseUrl + "ForgetPassword/put",
    },
    FEED_BACK: {
      createFeedback: baseUrl + "Feedback/create",
      createCoachingFeedback: baseUrl + "Feedback/createCoachingFeedback",
    },
    MENU_PERMISSION: {
      createPermission: baseUrl + "MenuPermission/create",
      getMenuPermissionById: baseUrl + "MenuPermission/getMenuPermissionById",
    },
    STUDENT_PAYMENT: {
      getStudentPaymentDetails: baseUrl + "Student/getStudentPaymentDetails",
    },
    COACHING: {
      addUpdateCoaching: baseUrl + "Coaching/create",
      getCoachingList: baseUrl + "Coaching/GetCoachingList",
      getPurchasedCoaching: baseUrl + "Coaching/getPurchasedCoaching",
      deleteCoaching: baseUrl + "Coaching/DeleteCoaching",
      publishCoaching: baseUrl + "Coaching/publish",
      purchaseCoaching: baseUrl + "Coaching/purchaseCoaching",
      updateCoaching: baseUrl + "Coaching/getCoachingById",
      getUpcomingCoaching: baseUrl + "Coaching/getUpcomingCoaching",
      getCompletedCoaching: baseUrl + "Coaching/getCompletedCoaching",
      getTodaysCoaching: baseUrl + "Coaching/getTodaysCoaching",
      getTopCoaching: baseUrl + "Coaching/getTopCoaching",
      getTodaysCoachingLP: baseUrl + "Coaching/getTodaysCoachingLP",
      getUpcomingCoachingLP: baseUrl + "Coaching/getUpcomingCoachingLP",
      updateAdminCoaching: baseUrl + "Coaching/update",
      markCoachingAsCompleted: baseUrl + "Coaching/markCoachingAsCompleted",
    },
    COURSES: {
      createCourses: baseUrl + "Course/createCourse",
      createSection: baseUrl + "Course/createSection",
      getSectionByFilter: baseUrl + "Course/getSectionByFilter",
      getCourseList: baseUrl + "Course/getCourseList",
      createCoursePricing: baseUrl + "Course/createCoursePricing",
      createLecture: baseUrl + "Course/createLecture",
      publishCourse: baseUrl + "Course/publish",
      certificateReceived: baseUrl + "Course/certificateReceived",
      markCourseAsStarted: baseUrl + "Course/markCourseAsStarted",
      updateVideoTimeStamp: baseUrl + "Course/updateVideoTimeStamp",
      purchaseCourse: baseUrl + "Course/purchaseCourse",
      getById: baseUrl + "Course/getById",
      getLectureByFilter: baseUrl + "Course/getLectureByFilter",
      getTopCategoriesCourses: baseUrl + "Course/getTopCategoriesCourses",
      getRelatedCourses: baseUrl + "Course/getRelatedCourses",
      getTopCourses: baseUrl + "Course/getTopCourses",
      getPurchasedCourses: baseUrl + "Course/getPurchasedCourses",
      getVideoTimeStamp: baseUrl + "Course/getVideoTimeStamp",
      getCourseCertification: baseUrl + "Course/getCourseCertification",
      checkQuizAnswer: baseUrl + "Course/checkQuizAnswer",
      purchasecredit: baseUrl + "Course/internalPayment",
      certificateConversion: baseUrl + "Course/certificateConversion",
      getCompletedCourseListWithCertificates:
        baseUrl + "Course/getCompletedCourseListWithCertificates",
      getInProgressCourseList: baseUrl + "Course/getInProgressCourseList",
    },
    FAQS: {
      createFaqs: baseUrl + "FAQ/create",
      getFAQByStudent: baseUrl + "FAQ/getFAQByStudent",
      getFAQByProvider: baseUrl + "FAQ/getFAQByProvider",
      updateFaqs: baseUrl + "FAQ/update",
    },
    LECTURENOTES: {
      createNotes: baseUrl + "LectureNote/create",
      getAllNotesByLecture: baseUrl + "LectureNote/getAllNotesByLecture",
    },
    CONTACTUS: {
      createContact: baseUrl + "ContactUs/create",
      getByFilterConatctUs: baseUrl + "ContactUs/getByFilter",
    },
    NOTIFICATION: {
      MarkAsRead: baseUrl + "Notification/MarkAsRead",
      GetNotificationByUserId: baseUrl + "Notification/GetNotificationByUserId",
    },
    COURSEWISHLIST: {
      create: baseUrl + "CourseWishlist/create",
      remove: baseUrl + "CourseWishlist/remove",
      getWishlistCourses: baseUrl + "CourseWishlist/getWishlistCourses",
    },
    INTEREST: {
      interest: baseUrl + "Interest/getByFilter",
      getAllApprovedInterest: baseUrl + "Interest/getAllApprovedInterest",
      getAllUnapprovedInterest: baseUrl + "Interest/getAllUnapprovedInterest",
      getSelectedInterestsByStudents:
        baseUrl + "Interest/getSelectedInterestsByStudents",
      createInterest: baseUrl + "Interest/create",
      updateSelectedInterests: baseUrl + "Interest/updateSelectedInterests",
      publishInterest: baseUrl + "Interest/approve",
      deleteInterest: baseUrl + "Interest/delete",
    },
    PRICING_PLAN: {
      pricingPlan: baseUrl + "PricingPlan/create",
      pricingPlanByFilter: baseUrl + "PricingPlan/getByFilter",
      getSelectedPricing: baseUrl + "PricingPlan/getSelectedPricing",
      deletePricingPlan: baseUrl + "PricingPlan/delete",
      createManualPriceRange: baseUrl + "PricingPlan/createManualPriceRange",
      selectPricingPlans: baseUrl + "PricingPlan/selectPricingPlans",
      getManualPricingRange: baseUrl + "PricingPlan/getManualPricingRange",
      priceCheckWithinRange: baseUrl + "PricingPlan/priceCheckWithinRange",
    },
  },
};
const AREA_INTEREST = [
  {
    lookupId: 1,
    type: "areaInterest",
    text: "Road To IPO",
    value: "RoadToIPO",
    order: 1,
  },
  {
    lookupId: 2,
    type: "areaInterest",
    text: "Entrepreneurship",
    value: "Entrepreneurship",
    order: 2,
  },
  {
    lookupId: 3,
    type: "areaInterest",
    text: "Trade Finance",
    value: "TradeFinance",
    order: 3,
  },
  {
    lookupId: 4,
    type: "areaInterest",
    text: "Investing",
    value: "Investing",
    order: 4,
  },
  {
    lookupId: 5,
    type: "areaInterest",
    text: "CrowdFunding",
    value: "CrowdFunding",
    order: 5,
  },
  {
    lookupId: 6,
    type: "areaInterest",
    text: "Business Law",
    value: "BusinessLaw",
    order: 6,
  },
  {
    lookupId: 7,
    type: "areaInterest",
    text: "Merger & Acquisitions",
    value: "MergerndAcquisitions",
    order: 7,
  },
  {
    lookupId: 8,
    type: "areaInterest",
    text: "Capital Raising",
    value: "CapitalRaising",
    order: 8,
  },
];
const GOOGLE_APP_ID =
  "74129056560-gs9rgqq68k2igscitfj3siq8vulmpfaf.apps.googleusercontent.com";
// const FB_APP_ID = "325922876236840";
const FB_APP_ID = "608154404286240";
const LINKED_IN_SECRET_ID = "RATloYEcd0JGkaGf";
// const LINKED_IN_EMBEDED_URL = process.env.NODE_ENV === 'production' ? "https%3A%2F%2Fpif.superfastech.com%2Flinkedin%2Fredirect" : "http%3A%2F%2Flocalhost%3A3000%2Flinkedin%2Fredirect";
const LINKED_IN_EMBEDED_URL =
  process.env.NODE_ENV === "production"
    ? "https%3A%2F%2Fedutech.xeventechnologies.com%2Flinkedin%2Fredirect"
    : "http%3A%2F%2Flocalhost%3A3000%2Flinkedin%2Fredirect";
const LINKEDIN_CLIENT_ID = "78ej2qxj8eji6k";
// const LINKED_IN_REDIRECT_URL = "https://www.linkedin.com/developers/tools/oauth/redirect";
// const LINKED_IN_REDIRECT_URL = process.env.NODE_ENV === 'production' ? "https://pif.superfastech.com/linkedin/redirect" : "http://localhost:3000/linkedin/redirect";
const LINKED_IN_REDIRECT_URL =
  process.env.NODE_ENV === "production"
    ? "https://edutech.xeventechnologies.com/linkedin/redirect"
    : "http://localhost:3000/linkedin/redirect";
const SOCIAL_ACCOUNTS_LOGIN = {
  CREDENTIAL: 10,
  FACEBOOK: 11,
  GOOGLE: 12,
  LINKEDIN: 13,
};
const INDEPENDENT_PROVIDER = [
  {
    lookupId: 300,
    type: "independentProvider",
    text: "Course Partner",
    value: "CoursePartner",
    order: 1,
  },
  {
    lookupId: 301,
    type: "independentProvider",
    text: "Independent Provider",
    value: "IndependentProvider",
    order: 2,
  },
  {
    lookupId: 302,
    type: "independentProvider",
    text: "Others",
    value: "Others",
    order: 3,
  },
];
const PROVIDING_SERVICES = [
  {
    lookupId: 350,
    type: "providingservices",
    text: "Africa",
    value: "Africa",
    order: 1,
  },
  {
    lookupId: 351,
    type: "providingservices",
    text: "Asia",
    value: "Asia",
    order: 2,
  },
  {
    lookupId: 352,
    type: "providingservices",
    text: "Caribbean",
    value: "Caribbean",
    order: 3,
  },
  {
    lookupId: 353,
    type: "providingservices",
    text: "Central America",
    value: "CentralAmerica",
    order: 3,
  },
  {
    lookupId: 354,
    type: "providingservices",
    text: "Europe",
    value: "Europe",
    order: 3,
  },
  {
    lookupId: 355,
    type: "providingservices",
    text: "North America",
    value: "NorthAmerica",
    order: 3,
  },
  {
    lookupId: 356,
    type: "providingservices",
    text: "Oceania",
    value: "Oceania",
    order: 3,
  },
  {
    lookupId: 357,
    type: "providingservices",
    text: "South America",
    value: "SouthAmerica",
    order: 3,
  },
];

export {
  ERROR,
  PATH,
  APP_SETTINGS,
  AREA_INTEREST,
  GOOGLE_APP_ID,
  SOCIAL_ACCOUNTS_LOGIN,
  FB_APP_ID,
  PROVIDING_SERVICES,
  INDEPENDENT_PROVIDER,
  LINKEDIN_CLIENT_ID,
  LINKED_IN_REDIRECT_URL,
  LINKED_IN_SECRET_ID,
  LINKED_IN_EMBEDED_URL,
};

//  // "start": "set HTTPS=true&&set SSL_CRT_FILE=C:/Windows/System32/cert.crt&&set SSL_KEY_FILE=C:/Windows/System32/cert.key&&react-scripts start",
