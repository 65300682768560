import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CoachingDashboard from "./CoachingDashboard";
import ExploreCourse from "./ExploreCourse";
import ExploreDashboard from "./ExploreDashboard";

export default function Explore() {
  useEffect(() => {
    document.title = `Explore || EduTech`;
  }, []);
  const [showExplore, setExplore] = useState(true);
  const [key, setKey] = useState("Courses");
  return showExplore === true ? (
    <Tabs
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 explore_tabs"
    >
      <Tab eventKey="Courses" title="Courses">
        <ExploreDashboard setExplore={setExplore} />
      </Tab>
      <Tab eventKey="Coaching" title="Coaching">
        <CoachingDashboard setExplore={setExplore} />
      </Tab>
    </Tabs>
  ) : (
    <ExploreCourse />
  );
}
