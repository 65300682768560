function request(actionType) {
  return {
    type: actionType,
  };
}

function success(actionType, response) {
  return {
    type: actionType,
    payload: response,
  };
}

function failure(actionType, error) {
  return {
    type: actionType,
    payload: error,
  };
}

const ACCOUNTS_REQUEST = {
  REGISTER_PROVIDER_REQUEST: "REGISTER_PROVIDER_REQUEST",
  REGISTER_PROVIDER_SUCCESS: "REGISTER_PROVIDER_SUCCESS",
  REGISTER_PROVIDER_FAILURE: "REGISTER_PROVIDER_FAILURE",

  REGISTER_STUDENT_REQUEST: "REGISTER_STUDENT_REQUEST",
  REGISTER_STUDENT_SUCCESS: "REGISTER_STUDENT_SUCCESS",
  REGISTER_STUDENT_FAILURE: "REGISTER_STUDENT_FAILURE",

  GET_CREDITS_REQUEST: "GET_CREDITS_REQUEST",
  GET_CREDITS_SUCCESS: "GET_CREDITS_SUCCESS",
  GET_CREDITS_FAILURE: "GET_CREDITS_FAILURE",

  GET_LINKEDIN_PROFILE_REQUEST: "GET_LINKEDIN_PROFILE_REQUEST",
  GET_LINKEDIN_PROFILE_SUCCESS: "GET_LINKEDIN_PROFILE_SUCCESS",
  GET_LINKEDIN_PROFILE_FAILURE: "GET_LINKEDIN_PROFILE_FAILURE",

  GET_LINKEDIN_TOKEN_KEY_REQUEST: "GET_LINKEDIN_TOKEN_KEY_REQUEST",
  GET_LINKEDIN_TOKEN_KEY_SUCCESS: "GET_LINKEDIN_TOKEN_KEY_SUCCESS",
  GET_LINKEDIN_TOKEN_KEY_FAILURE: "GET_LINKEDIN_TOKEN_KEY_FAILURE",

  SOCIAL_MEDIA_REQUEST: "SOCIAL_MEDIA_REQUEST",
  SOCIAL_MEDIA_SUCCESS: "SOCIAL_MEDIA_SUCCESS",
  SOCIAL_MEDIA_FAILURE: "SOCIAL_MEDIA_FAILURE",

  EMAIL_EXIST_REQUEST: "EMAIL_EXIST_REQUEST",
  EMAIL_EXIST_SUCCESS: "EMAIL_EXIST_SUCCESS",
  EMAIL_EXIST_FAILURE: "EMAIL_EXIST_FAILURE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_RESET: "LOGIN_RESET",

  STUDENT_LOGIN_REQUEST: "LOGIN_REQUEST",
  STUDENT_LOGIN_SUCCESS: "LOGIN_SUCCESS",
  STUDENT_LOGIN_FAILURE: "LOGIN_FAILURE",
  STUDENT_LOGIN_RESET: "LOGIN_RESET",
};
const LOACTION_REQUEST = {
  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE: "GET_COUNTRIES_FAILURE",

  COUNTRIES_BY_NAME_REQUEST: "COUNTRIES_BY_NAME_REQUEST",
  COUNTRIES_BY_NAME_SUCCESS: "COUNTRIES_BY_NAME_SUCCESS",
  COUNTRIES_BY_NAME_FAILURE: "COUNTRIES_BY_NAME_FAILURE",

  INDUSTRY_BY_TYPE_REQUEST: "INDUSTRY_BY_TYPE_REQUEST",
  INDUSTRY_BY_TYPE_SUCCESS: "INDUSTRY_BY_TYPE_SUCCESS",
  INDUSTRY_BY_TYPE_FAILURE: "INDUSTRY_BY_TYPE_FAILURE",
};
const LOOKUP_REQUEST = {
  LOOKUP_REQUEST: "LOOKUP_REQUEST",
  LOOKUP_SUCCESS: "LOOKUP_SUCCESS",
  LOOKUP_FAILURE: "LOOKUP_FAILURE",

  UPLOAD_TO_AZURE_REQUEST: "UPLOAD_TO_AZURE_REQUEST",
  UPLOAD_TO_AZURE_SUCCESS: "UPLOAD_TO_AZURE_SUCCESS",
  UPLOAD_TO_AZURE_FAILURE: "UPLOAD_TO_AZURE_FAILURE",

  UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
};
const ADMIN_REQUEST = {
  MANAGE_USER_REQUEST: "MANAGE_USER_REQUEST",
  MANAGE_USER_SUCCESS: "MANAGE_USER_SUCCESS",
  MANAGE_USER_FAILURE: "MANAGE_USER_FAILURE",
};
const FEED_BACK_REQUEST = {
  FEED_BACK_REQUEST: "FEED_BACK_REQUEST",
  FEED_BACK_SUCCESS: "FEED_BACK_SUCCESS",
  FEED_BACK_FAILURE: "FEED_BACK_FAILURE",

  COACHING_FFED_BACK_REQUEST: "COACHING_FFED_BACK_REQUEST",
  COACHING_FFED_BACK_SUCCESS: "COACHING_FFED_BACK_SUCCESS",
  COACHING_FFED_BACK_FAILURE: "COACHING_FFED_BACK_FAILURE",
};
const STAFF_MEMBER_REQUEST = {
  CREATE_STAFF_MEMBER_REQUEST: "CREATE_STAFF_MEMBER_REQUEST",
  CREATE_STAFF_MEMBER_SUCCESS: "CREATE_STAFF_MEMBER_SUCCESS",
  CREATE_STAFF_MEMBER_FAILURE: "CREATE_STAFF_MEMBER_FAILURE",

  UPDATE_STAFF_MEMBER_REQUEST: "UPDATE_STAFF_MEMBER_REQUEST",
  UPDATE_STAFF_MEMBER_SUCCESS: "UPDATE_STAFF_MEMBER_SUCCESS",
  UPDATE_STAFF_MEMBER_FAILURE: "UPDATE_STAFF_MEMBER_FAILURE",

  GET_STAFF_MEMBER_REQUEST: "GET_STAFF_MEMBER_REQUEST",
  GET_STAFF_MEMBER_SUCCESS: "GET_STAFF_MEMBER_SUCCESS",
  GET_STAFF_MEMBER_FAILURE: "GET_STAFF_MEMBER_FAILURE",
};
const DASHBOARD_TILES_REQUEST = {
  GET_ADMIN_DASHBOARD_TILES_REQUEST: "GET_ADMIN_DASHBOARD_TILES_REQUEST",
  GET_ADMIN_DASHBOARD_TILES_SUCCESS: "GET_ADMIN_DASHBOARD_TILES_SUCCESS",
  GET_ADMIN_DASHBOARD_TILES_FAILURE: "GET_ADMIN_DASHBOARD_TILES_FAILURE",

  GET_TOTAL_COACHING_SALE_REQUEST: "GET_TOTAL_COACHING_SALE_REQUEST",
  GET_TOTAL_COACHING_SALE_SUCCESS: "GET_TOTAL_COACHING_SALE_SUCCESS",
  GET_TOTAL_COACHING_SALE_FAILURE: "GET_TOTAL_COACHING_SALE_FAILURE",

  GET_TOTAL_COURSES_SALE_REQUEST: "GET_TOTAL_COURSES_SALE_REQUEST",
  GET_TOTAL_COURSES_SALE_SUCCESS: "GET_TOTAL_COURSES_SALE_SUCCESS",
  GET_TOTAL_COURSES_SALE_FAILURE: "GET_TOTAL_COURSES_SALE_FAILURE",

  GET_PROVIDER_DASHBOARD_TILES_REQUEST: "GET_PROVIDER_DASHBOARD_TILES_REQUEST",
  GET_PROVIDER_DASHBOARD_TILES_SUCCESS: "GET_PROVIDER_DASHBOARD_TILES_SUCCESS",
  GET_PROVIDER_DASHBOARD_TILES_FAILURE: "GET_PROVIDER_DASHBOARD_TILES_FAILURE",

  GET_CERTIFICATION_AWARDED_LIST_REQUEST:
    "GET_CERTIFICATION_AWARDED_LIST_REQUEST",
  GET_CERTIFICATION_AWARDED_LIST_SUCCESS:
    "GET_CERTIFICATION_AWARDED_LIST_SUCCESS",
  GET_CERTIFICATION_AWARDED_LIST_FAILURE:
    "GET_CERTIFICATION_AWARDED_LIST_FAILURE",

  GET_COURSES_SALES_LIST_REQUEST: "GET_COURSES_SALES_LIST_REQUEST",
  GET_COURSES_SALES_LIST_SUCCESS: "GET_COURSES_SALES_LIST_SUCCESS",
  GET_COURSES_SALES_LIST_FAILURE: "GET_COURSES_SALES_LIST_FAILURE",

  GET_COURSES_STUDENTS_LIST_REQUEST: "GET_COURSES_STUDENTS_LIST_REQUEST",
  GET_COURSES_STUDENTS_LIST_SUCCESS: "GET_COURSES_STUDENTS_LIST_SUCCESS",
  GET_COURSES_STUDENTS_LIST_FAILURE: "GET_COURSES_STUDENTS_LIST_FAILURE",

  GET_COACHING_SALES_LIST_REQUEST: "GET_COACHING_SALES_LIST_REQUEST",
  GET_COACHING_SALES_LIST_SUCCESS: "GET_COACHING_SALES_LIST_SUCCESS",
  GET_COACHING_SALES_LIST_FAILURE: "GET_COACHING_SALES_LIST_FAILURE",

  GET_COACHING_STUDENTS_LIST_REQUEST: "GET_COACHING_STUDENTS_LIST_REQUEST",
  GET_COACHING_STUDENTS_LIST_SUCCESS: "GET_COACHING_STUDENTS_LIST_SUCCESS",
  GET_COACHING_STUDENTS_LIST_FAILURE: "GET_COACHING_STUDENTS_LIST_FAILURE",

  ADMIN_DASHBOARD_REVENUE_REQUEST: "ADMIN_DASHBOARD_REVENUE_REQUEST",
  ADMIN_DASHBOARD_REVENUE_SUCCESS: "ADMIN_DASHBOARD_REVENUE_SUCCESS",
  ADMIN_DASHBOARD_REVENUE_FAILURE: "ADMIN_DASHBOARD_REVENUE_FAILURE",

  PROVIDER_DASHBOARD_STATUS_OF_COURSES_REQUEST:
    "PROVIDER_DASHBOARD_STATUS_OF_COURSES_REQUEST",
  PROVIDER_DASHBOARD_STATUS_OF_COURSES_SUCCESS:
    "PROVIDER_DASHBOARD_STATUS_OF_COURSES_SUCCESS",
  PROVIDER_DASHBOARD_STATUS_OF_COURSES_FAILURE:
    "PROVIDER_DASHBOARD_STATUS_OF_COURSES_FAILURE",

  GET_QUOTATIONS_REQUEST: "GET_QUOTATIONS_REQUEST",
  GET_QUOTATIONS_SUCCESS: "GET_QUOTATIONS_SUCCESS",
  GET_QUOTATIONS_FAILURE: "GET_QUOTATIONS_FAILURE",

  ADMIN_DASHBOARD_TOP_COURSES_GRAPH_REQUEST:
    "ADMIN_DASHBOARD_TOP_COURSES_GRAPH_REQUEST",
  ADMIN_DASHBOARD_TOP_COURSES_GRAPH_SUCCESS:
    "ADMIN_DASHBOARD_TOP_COURSES_GRAPH_SUCCESS",
  ADMIN_DASHBOARD_TOP_COURSES_GRAPH_FAILURE:
    "ADMIN_DASHBOARD_TOP_COURSES_GRAPH_FAILURE",

  ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_REQUEST:
    "ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_REQUEST",
  ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_SUCCESS:
    "ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_SUCCESS",
  ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_FAILURE:
    "ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_FAILURE",

  PROVIDER_DASHBOARD_TOTAL_EARNING_REQUEST:
    "PROVIDER_DASHBOARD_TOTAL_EARNING_REQUEST",
  PROVIDER_DASHBOARD_TOTAL_EARNING_SUCCESS:
    "PROVIDER_DASHBOARD_TOTAL_EARNING_SUCCESS",
  PROVIDER_DASHBOARD_TOTAL_EARNING_FAILURE:
    "PROVIDER_DASHBOARD_TOTAL_EARNING_FAILURE",
};
const USER_PROFILE_REQUEST = {
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",

  GET_USER_STUDENT_PROFILE_REQUEST: "GET_USER_STUDENT_PROFILE_REQUEST",
  GET_USER_STUDENT_PROFILE_SUCCESS: "GET_USER_STUDENT_PROFILE_SUCCESS",
  GET_USER_STUDENT_PROFILE_FAILURE: "GET_USER_STUDENT_PROFILE_FAILURE",

  UPDATE_PROVIDER_PROFILE_REQUEST: "UPDATE_PROVIDER_PROFILE_REQUEST",
  UPDATE_PROVIDER_PROFILE_SUCCESS: "UPDATE_PROVIDER_PROFILE_SUCCESS",
  UPDATE_PROVIDER_PROFILE_FAILURE: "UPDATE_PROVIDER_PROFILE_FAILURE",

  UPDATE_STUDENT_PROFILE_REQUEST: "UPDATE_STUDENT_PROFILE_REQUEST",
  UPDATE_STUDENT_PROFILE_SUCCESS: "UPDATE_STUDENT_PROFILE_SUCCESS",
  UPDATE_STUDENT_PROFILE_FAILURE: "UPDATE_STUDENT_PROFILE_FAILURE",
};
const RSS_FEED_REQUEST = {
  GET_RSS_FEED_INVESTING_REQUEST: "GET_RSS_FEED_INVESTING_REQUEST",
  GET_RSS_FEED_INVESTING_SUCCESS: "GET_RSS_FEED_INVESTING_SUCCESS",
  GET_RSS_FEED_INVESTING_FAILURE: "GET_RSS_FEED_INVESTING_FAILURE",

  GET_RSS_FEED_FT_REQUEST: "GET_RSS_FEED_FT_REQUEST",
  GET_RSS_FEED_FT_SUCCESS: "GET_RSS_FEED_FT_SUCCESS",
  GET_RSS_FEED_FT_FAILURE: "GET_RSS_FEED_FT_FAILURE",

  GET_RSS_FEED_FORTUNE_REQUEST: "GET_RSS_FEED_FORTUNE_REQUEST",
  GET_RSS_FEED_FORTUNE_SUCCESS: "GET_RSS_FEED_FORTUNE_SUCCESS",
  GET_RSS_FEED_FORTUNE_FAILURE: "GET_RSS_FEED_FORTUNE_FAILURE",
};
const FORGET_PASSWORD_REQUEST = {
  CHECK_EMAIL_REQUEST: "CHECK_EMAIL_REQUEST",
  CHECK_EMAIL_SUCCESS: "CHECK_EMAIL_SUCCESS",
  CHECK_EMAIL_FAILURE: "CHECK_EMAIL_FAILURE",

  CHECK_CODE_REQUEST: "CHECK_CODE_REQUEST",
  CHECK_CODE_SUCCESS: "CHECK_CODE_SUCCESS",
  CHECK_CODE_FAILURE: "CHECK_CODE_FAILURE",

  PUT_REQUEST: "PUT_REQUEST",
  PUT_SUCCESS: "PUT_SUCCESS",
  PUT_FAILURE: "PUT_FAILURE",
};
const STUDENT_PAYMENT_REQUEST = {
  GET_STUDENT_PAYMENT_DETAIL_REQUEST: "GET_STUDENT_PAYMENT_DETAIL_REQUEST",
  GET_STUDENT_PAYMENT_DETAIL_SUCCESS: "GET_STUDENT_PAYMENT_DETAIL_SUCCESS",
  GET_STUDENT_PAYMENT_DETAIL_FAILURE: "GET_STUDENT_PAYMENT_DETAIL_FAILURE",
};
const MENU_PERMISSION_REQUEST = {
  MENU_PERMISSION_REQUEST: "MENU_PERMISSION_REQUEST",
  MENU_PERMISSION_SUCCESS: "MENU_PERMISSION_SUCCESS",
  MENU_PERMISSION_FAILURE: "MENU_PERMISSION_FAILURE",

  GET_PERMISSIONBY_ID_REQUEST: "GET_PERMISSIONBY_ID_REQUEST",
  GET_PERMISSIONBY_ID_SUCCESS: "GET_PERMISSIONBY_ID_SUCCESS",
  GET_PERMISSIONBY_ID_FAILURE: "GET_PERMISSIONBY_ID_FAILURE",
};
const COACHING_REQUEST = {
  ADD_UPDATE_COACHING_REQUEST: "ADD_UPDATE_COACHING_REQUEST",
  ADD_UPDATE_COACHING_SUCCESS: "ADD_UPDATE_COACHING_SUCCESS",
  ADD_UPDATE_COACHING_FAILURE: "ADD_UPDATE_COACHING_FAILURE",

  UPDATE_ADMIN_COACHING_REQUEST: "UPDATE_ADMIN_COACHING_REQUEST",
  UPDATE_ADMIN_COACHING_SUCCESS: "UPDATE_ADMIN_COACHING_SUCCESS",
  UPDATE_ADMIN_COACHING_FAILURE: "UPDATE_ADMIN_COACHING_FAILURE",

  MARK_COACHING_COMPELTE_REQUEST: "MARK_COACHING_COMPELTE_REQUEST",
  MARK_COACHING_COMPELTE_SUCCESS: "MARK_COACHING_COMPELTE_SUCCESS",
  MARK_COACHING_COMPELTE_FAILURE: "MARK_COACHING_COMPELTE_FAILURE",

  GET_COACHING_LIST_REQUEST: "GET_COACHING_LIST_REQUEST",
  GET_COACHING_LIST_SUCCESS: "GET_COACHING_LIST_SUCCESS",
  GET_COACHING_LIST_FAILURE: "GET_COACHING_LIST_FAILURE",

  GET_PURCHASED_COACHING_REQUEST: "GET_PURCHASED_COACHING_REQUEST",
  GET_PURCHASED_COACHING_SUCCESS: "GET_PURCHASED_COACHING_SUCCESS",
  GET_PURCHASED_COACHING_FAILURE: "GET_PURCHASED_COACHING_FAILURE",

  DELETE_COACHING_REQUEST: "DELETE_COACHING_REQUEST",
  DELETE_COACHING_SUCCESS: "DELETE_COACHING_SUCCESS",
  DELETE_COACHING_FAILURE: "DELETE_COACHING_FAILURE",

  PUBLISH_COACHING_REQUEST: "PUBLISH_COACHING_REQUEST",
  PUBLISH_COACHING_SUCCESS: "PUBLISH_COACHING_SUCCESS",
  PUBLISH_COACHING_FAILURE: "PUBLISH_COACHING_FAILURE",

  PURCHASE_COACHING_REQUEST: "PURCHASE_COACHING_REQUEST",
  PURCHASE_COACHING_SUCCESS: "PURCHASE_COACHING_SUCCESS",
  PURCHASE_COACHING_FAILURE: "PURCHASE_COACHING_FAILURE",

  UPDATE_COACHING_REQUEST: "UPDATE_COACHING_REQUEST",
  UPDATE_COACHING_SUCCESS: "UPDATE_COACHING_SUCCESS",
  UPDATE_COACHING_FAILURE: "UPDATE_COACHING_FAILURE",

  GET_UPCOMING_COACHING_REQUEST: "GET_UPCOMING_COACHING_REQUEST",
  GET_UPCOMING_COACHING_SUCCESS: "GET_UPCOMING_COACHING_SUCCESS",
  GET_UPCOMING_COACHING_FAILURE: "GET_UPCOMING_COACHING_FAILURE",

  GET_COMPLETED_COACHING_REQUEST: "GET_COMPLETED_COACHING_REQUEST",
  GET_COMPLETED_COACHING_SUCCESS: "GET_COMPLETED_COACHING_SUCCESS",
  GET_COMPLETED_COACHING_FAILURE: "GET_COMPLETED_COACHING_FAILURE",

  GET_TODAYS_COACHING_REQUEST: "GET_TODAYS_COACHING_REQUEST",
  GET_TODAYS_COACHING_SUCCESS: "GET_TODAYS_COACHING_SUCCESS",
  GET_TODAYS_COACHING_FAILURE: "GET_TODAYS_COACHING_FAILURE",

  GET_TOP_COACHING_REQUEST: "GET_TOP_COACHING_REQUEST",
  GET_TOP_COACHING_SUCCESS: "GET_TOP_COACHING_SUCCESS",
  GET_TOP_COACHING_FAILURE: "GET_TOP_COACHING_FAILURE",

  GET_TODAYS_COACHING_LP_REQUEST: "GET_TODAYS_COACHING_LP_REQUEST",
  GET_TODAYS_COACHING_LP_SUCCESS: "GET_TODAYS_COACHING_LP_SUCCESS",
  GET_TODAYS_COACHING_LP_FAILURE: "GET_TODAYS_COACHING_LP_FAILURE",

  GET_UPCOMING_COACHING_LP_REQUEST: "GET_UPCOMING_COACHING_LP_REQUEST",
  GET_UPCOMING_COACHING_LP_SUCCESS: "GET_UPCOMING_COACHING_LP_SUCCESS",
  GET_UPCOMING_COACHING_LP_FAILURE: "GET_UPCOMING_COACHING_LP_FAILURE",
};
const COURSES_REQUEST = {
  CREATE_COURSES_REQUEST: "CREATE_COURSES_REQUEST",
  CREATE_COURSES_SUCCESS: "CREATE_COURSES_SUCCESS",
  CREATE_COURSES_FAILURE: "CREATE_COURSES_FAILURE",

  CREATE_SECTION_REQUEST: "CREATE_SECTION_REQUEST",
  CREATE_SECTION_SUCCESS: "CREATE_SECTION_SUCCESS",
  CREATE_SECTION_FAILURE: "CREATE_SECTION_FAILURE",

  CREATE_LECTURE_REQUEST: "CREATE_LECTURE_REQUEST",
  CREATE_LECTURE_SUCCESS: "CREATE_LECTURE_SUCCESS",
  CREATE_LECTURE_FAILURE: "CREATE_LECTURE_FAILURE",

  PURCHASE_CREDITS_REQUEST: "PURCHASE_CREDITS_REQUEST",
  PURCHASE_CREDITS_SUCCESS: "PURCHASE_CREDITS_SUCCESS",
  PURCHASE_CREDITS_FAILURE: "PURCHASE_CREDITS_FAILURE",

  GET_COURSE_LIST_REQUEST: "GET_COURSE_LIST_REQUEST",
  GET_COURSE_LIST_SUCCESS: "GET_COURSE_LIST_SUCCESS",
  GET_COURSE_LIST_FAILURE: "GET_COURSE_LIST_FAILURE",

  GET_BY_ID_REQUEST: "GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_BY_ID_FAILURE",

  CERTIFICATE_CONVERSION_REQUEST: "CERTIFICATE_CONVERSION_REQUEST",
  CERTIFICATE_CONVERSION_SUCCESS: "CERTIFICATE_CONVERSION_SUCCESS",
  CERTIFICATE_CONVERSION_FAILURE: "CERTIFICATE_CONVERSION_FAILURE",

  PUBLISH_COURSE_REQUEST: "PUBLISH_COURSE_REQUEST",
  PUBLISH_COURSE_SUCCESS: "PUBLISH_COURSE_SUCCESS",
  PUBLISH_COURSE_FAILURE: "PUBLISH_COURSE_FAILURE",

  CERTIFICATE_RECEIVED_REQUEST: "CERTIFICATE_RECEIVED_REQUEST",
  CERTIFICATE_RECEIVED_SUCCESS: "CERTIFICATE_RECEIVED_SUCCESS",
  CERTIFICATE_RECEIVED_FAILURE: "CERTIFICATE_RECEIVED_FAILURE",

  MARK_COURSE_AS_STARTED_REQUEST: "MARK_COURSE_AS_STARTED_REQUEST",
  MARK_COURSE_AS_STARTED_SUCCESS: "MARK_COURSE_AS_STARTED_SUCCESS",
  MARK_COURSE_AS_STARTED_FAILURE: "MARK_COURSE_AS_STARTED_FAILURE",

  UPDATE_VIDEO_TIME_STAMP_REQUEST: "UPDATE_VIDEO_TIME_STAMP_REQUEST",
  UPDATE_VIDEO_TIME_STAMP_SUCCESS: "UPDATE_VIDEO_TIME_STAMP_SUCCESS",
  UPDATE_VIDEO_TIME_STAMP_FAILURE: "UPDATE_VIDEO_TIME_STAMP_FAILURE",

  PURCHASE_COURSE_REQUEST: "PURCHASE_COURSE_REQUEST",
  PURCHASE_COURSE_SUCCESS: "PURCHASE_COURSE_SUCCESS",
  PURCHASE_COURSE_FAILURE: "PURCHASE_COURSE_FAILURE",

  CREATE_COURSE_PRICING_REQUEST: "CREATE_COURSE_PRICING_REQUEST",
  CREATE_COURSE_PRICING_SUCCESS: "CREATE_COURSE_PRICING_SUCCESS",
  CREATE_COURSE_PRICING_FAILURE: "CREATE_COURSE_PRICING_FAILURE",

  GET_SECTION_BY_FILTER_REQUEST: "GET_SECTION_BY_FILTER_REQUEST",
  GET_SECTION_BY_FILTER_SUCCESS: "GET_SECTION_BY_FILTER_SUCCESS",
  GET_SECTION_BY_FILTER_FAILURE: "GET_SECTION_BY_FILTER_FAILURE",

  GET_LECTURE_BY_FILTER_REQUEST: "GET_LECTURE_BY_FILTER_REQUEST",
  GET_LECTURE_BY_FILTER_SUCCESS: "GET_LECTURE_BY_FILTER_SUCCESS",
  GET_LECTURE_BY_FILTER_FAILURE: "GET_LECTURE_BY_FILTER_FAILURE",

  GET_TOP_CATEGORIES_COURSES_REQUEST: "GET_TOP_CATEGORIES_COURSES_REQUEST",
  GET_TOP_CATEGORIES_COURSES_SUCCESS: "GET_TOP_CATEGORIES_COURSES_SUCCESS",
  GET_TOP_CATEGORIES_COURSES_FAILURE: "GET_TOP_CATEGORIES_COURSES_FAILURE",

  GET_TOP_COURSES_REQUEST: "GET_TOP_COURSES_REQUEST",
  GET_TOP_COURSES_SUCCESS: "GET_TOP_COURSES_SUCCESS",
  GET_TOP_COURSES_FAILURE: "GET_TOP_COURSES_FAILURE",

  GET_VIDEO_TIME_STAMP_REQUEST: "GET_VIDEO_TIME_STAMP_REQUEST",
  GET_VIDEO_TIME_STAMP_SUCCESS: "GET_VIDEO_TIME_STAMP_SUCCESS",
  GET_VIDEO_TIME_STAMP_FAILURE: "GET_VIDEO_TIME_STAMP_FAILURE",

  GET_RELATED_COURSES_REQUEST: "GET_RELATED_COURSES_REQUEST",
  GET_RELATED_COURSES_SUCCESS: "GET_RELATED_COURSES_SUCCESS",
  GET_RELATED_COURSES_FAILURE: "GET_RELATED_COURSES_FAILURE",

  CHECK_QUIZ_ANSWER_REQUEST: "CHECK_QUIZ_ANSWER_REQUEST",
  CHECK_QUIZ_ANSWER_SUCCESS: "CHECK_QUIZ_ANSWER_SUCCESS",
  CHECK_QUIZ_ANSWER_FAILURE: "CHECK_QUIZ_ANSWER_FAILURE",

  GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_REQUEST:
    "GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_REQUEST",
  GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_SUCCESS:
    "GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_SUCCESS",
  GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_FAILURE:
    "GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_FAILURE",

  GET_PURCHASED_COURSES_REQUEST: "GET_PURCHASED_COURSES_REQUEST",
  GET_PURCHASED_COURSES_SUCCESS: "GET_PURCHASED_COURSES_SUCCESS",
  GET_PURCHASED_COURSES_FAILURE: "GET_PURCHASED_COURSES_FAILURE",

  GET_INPROGRESS_COURSE_LIST_REQUEST: "GET_INPROGRESS_COURSE_LIST_REQUEST",
  GET_INPROGRESS_COURSE_LIST_SUCCESS: "GET_INPROGRESS_COURSE_LIST_SUCCESS",
  GET_INPROGRESS_COURSE_LIST_FAILURE: "GET_INPROGRESS_COURSE_LIST_FAILURE",

  GET_COURSE_CERTIFICATION_REQUEST: "GET_COURSE_CERTIFICATION_REQUEST",
  GET_COURSE_CERTIFICATION_SUCCESS: "GET_COURSE_CERTIFICATION_SUCCESS",
  GET_COURSE_CERTIFICATION_FAILURE: "GET_COURSE_CERTIFICATION_FAILURE",
};
const FAQS_REQUEST = {
  CREATE_FAQS_REQUEST: "CREATE_FAQS_REQUEST",
  CREATE_FAQS_SUCCESS: "CREATE_FAQS_SUCCESS",
  CREATE_FAQS_FAILURE: "CREATE_FAQS_FAILURE",

  UPDATE_FAQS_REQUEST: "UPDATE_FAQS_REQUEST",
  UPDATE_FAQS_SUCCESS: "UPDATE_FAQS_SUCCESS",
  UPDATE_FAQS_FAILURE: "UPDATE_FAQS_FAILURE",

  GET_FAQ_BY_STUDENT_REQUEST: "GET_FAQ_BY_STUDENT_REQUEST",
  GET_FAQ_BY_STUDENT_SUCCESS: "GET_FAQ_BY_STUDENT_SUCCESS",
  GET_FAQ_BY_STUDENT_FAILURE: "GET_FAQ_BY_STUDENT_FAILURE",

  GET_FAQ_BY_PROVIDER_REQUEST: "GET_FAQ_BY_PROVIDER_REQUEST",
  GET_FAQ_BY_PROVIDER_SUCCESS: "GET_FAQ_BY_PROVIDER_SUCCESS",
  GET_FAQ_BY_PROVIDER_FAILURE: "GET_FAQ_BY_PROVIDER_FAILURE",
};
const LECTURE_NOTES_REQUEST = {
  LECTURE_NOTES_REQUEST: "LECTURE_NOTES_REQUEST",
  LECTURE_NOTES_SUCCESS: "LECTURE_NOTES_SUCCESS",
  LECTURE_NOTES_FAILURE: "LECTURE_NOTES_FAILURE",

  GET_ALL_NOTES_BY_LECTURE_REQUEST: "GET_ALL_NOTES_BY_LECTURE_REQUEST",
  GET_ALL_NOTES_BY_LECTURE_SUCCESS: "GET_ALL_NOTES_BY_LECTURE_SUCCESS",
  GET_ALL_NOTES_BY_LECTURE_FAILURE: "GET_ALL_NOTES_BY_LECTURE_FAILURE",
};
const CONTACT_US_REQUEST = {
  CREATE_REQUEST: "CREATE_REQUEST",
  CREATE_SUCCESS: "CREATE_SUCCESS",
  CREATE_FAILURE: "CREATE_FAILURE",

  GET_BY_FILTER_CONTACT_US_REQUEST: "GET_BY_FILTER_CONTACT_US_REQUEST",
  GET_BY_FILTER_CONTACT_US_SUCCESS: "GET_BY_FILTER_CONTACT_US_SUCCESS",
  GET_BY_FILTER_CONTACT_US_FAILURE: "GET_BY_FILTER_CONTACT_US_FAILURE",
};
const COURSE_WISH_LIST_REQUEST = {
  CREATE_REQUEST: "CREATE_REQUEST",
  CREATE_SUCCESS: "CREATE_SUCCESS",
  CREATE_FAILURE: "CREATE_FAILURE",

  REMOVE_REQUEST: "REMOVE_REQUEST",
  REMOVE_SUCCESS: "REMOVE_SUCCESS",
  REMOVE_FAILURE: "REMOVE_FAILURE",

  GET_WISH_LIST_COURSES_REQUEST: "GET_WISH_LIST_COURSES_REQUEST",
  GET_WISH_LIST_COURSES_SUCCESS: "GET_WISH_LIST_COURSES_SUCCESS",
  GET_WISH_LIST_COURSES_FAILURE: "GET_WISH_LIST_COURSES_FAILURE",
};
const NOTIFICATION_REQUEST = {
  MARK_AS_READ_REQUEST: "MARK_AS_READ_REQUEST",
  MARK_AS_READ_SUCCESS: "MARK_AS_READ_SUCCESS",
  MARK_AS_READ_FAILURE: "MARK_AS_READ_FAILURE",

  GET_NOTIFICATION_BY_USER_ID_REQUEST: "GET_NOTIFICATION_BY_USER_ID_REQUEST",
  GET_NOTIFICATION_BY_USER_ID_SUCCESS: "GET_NOTIFICATION_BY_USER_ID_SUCCESS",
  GET_NOTIFICATION_BY_USER_ID_FAILURE: "GET_NOTIFICATION_BY_USER_ID_FAILURE",
};
const INTEREST_REQUEST = {
  INTEREST_REQUEST: "INTEREST_REQUEST",
  INTEREST_SUCCESS: "INTEREST_SUCCESS",
  INTEREST_FAILURE: "INTEREST_FAILURE",

  GET_ALL_APPROVED_INTEREST_REQUEST: "GET_ALL_APPROVED_INTEREST_REQUEST",
  GET_ALL_APPROVED_INTEREST_SUCCESS: "GET_ALL_APPROVED_INTEREST_SUCCESS",
  GET_ALL_APPROVED_INTEREST_FAILURE: "GET_ALL_APPROVED_INTEREST_FAILURE",

  GET_ALL_UNAPPROVED_INTEREST_REQUEST: "GET_ALL_UNAPPROVED_INTEREST_REQUEST",
  GET_ALL_UNAPPROVED_INTEREST_SUCCESS: "GET_ALL_UNAPPROVED_INTEREST_SUCCESS",
  GET_ALL_UNAPPROVED_INTEREST_FAILURE: "GET_ALL_UNAPPROVED_INTEREST_FAILURE",

  GET_SELECTED_INTERESTS_BY_STUDENT_REQUEST:
    "GET_SELECTED_INTERESTS_BY_STUDENT_REQUEST",
  GET_SELECTED_INTERESTS_BY_STUDENT_SUCCESS:
    "GET_SELECTED_INTERESTS_BY_STUDENT_SUCCESS",
  GET_SELECTED_INTERESTS_BY_STUDENT_FAILURE:
    "GET_SELECTED_INTERESTS_BY_STUDENT_FAILURE",

  CREATE_INTEREST_REQUEST: "CREATE_INTEREST_REQUEST",
  CREATE_INTEREST_SUCCESS: "CREATE_INTEREST_SUCCESS",
  CREATE_INTEREST_FAILURE: "CREATE_INTEREST_FAILURE",

  UPDATE_SELECTED_INTEREST_REQUEST: "UPDATE_SELECTEDE_INTEREST_REQUEST",
  UPDATE_SELECTED_INTEREST_SUCCESS: "UPDATE_SELECTED_INTEREST_SUCCESS",
  UPDATE_SELECTED_INTEREST_FAILURE: "UPDATE_SELECTED_INTEREST_FAILURE",

  PUBLISH_INTEREST_REQUEST: "PUBLISH_INTEREST_REQUEST",
  PUBLISH_INTEREST_SUCCESS: "PUBLISH_INTEREST_SUCCESS",
  PUBLISH_INTEREST_FAILURE: "PUBLISH_INTEREST_FAILURE",

  DELETE_INTEREST_REQUEST: "DELETE_INTEREST_REQUEST",
  DELETE_INTEREST_SUCCESS: "DELETE_INTEREST_SUCCESS",
  DELETE_INTEREST_FAILURE: "DELETE_INTEREST_FAILURE",
};
const PRICING_PLAN_REQUEST = {
  PRICING_PLAN_REQUEST: "PRICING_PLAN_REQUEST",
  PRICING_PLAN_SUCCESS: "PRICING_PLAN_SUCCESS",
  PRICING_PLAN_FAILURE: "PRICING_PLAN_FAILURE",

  SELECT_PRICING_PLAN_REQUEST: "SELECT_PRICING_PLAN_REQUEST",
  SELECT_PRICING_PLAN_SUCCESS: "SELECT_PRICING_PLAN_SUCCESS",
  SELECT_PRICING_PLAN_FAILURE: "SELECT_PRICING_PLAN_FAILURE",

  PRICING_PLAN_BY_FILTER_REQUEST: "PRICING_PLAN_BY_FILTER_REQUEST",
  PRICING_PLAN_BY_FILTER_SUCCESS: "PRICING_PLAN_BY_FILTER_SUCCESS",
  PRICING_PLAN_BY_FILTER_FAILURE: "PRICING_PLAN_BY_FILTER_FAILURE",

  GET_SELECTED_PRICING_REQUEST: "GET_SELECTED_PRICING_REQUEST",
  GET_SELECTED_PRICING_SUCCESS: "GET_SELECTED_PRICING_SUCCESS",
  GET_SELECTED_PRICING_FAILURE: "GET_SELECTED_PRICING_FAILURE",

  DELETE_PRICING_PLAN_REQUEST: "DELETE_PRICING_PLAN_REQUEST",
  DELETE_PRICING_PLAN_SUCCESS: "DELETE_PRICING_PLAN_SUCCESS",
  DELETE_PRICING_PLAN_FAILURE: "DELETE_PRICING_PLAN_FAILURE",

  CREATE_MANUAL_PRICING_RANGE_REQUEST: "CREATE_MANUAL_PRICING_RANGE_REQUEST",
  CREATE_MANUAL_PRICING_RANGE_SUCCESS: "CREATE_MANUAL_PRICING_RANGE_SUCCESS",
  CREATE_MANUAL_PRICING_RANGE_FAILURE: "CREATE_MANUAL_PRICING_RANGE_FAILURE",

  GET_MANUAL_PRICING_RANGE_REQUEST: "GET_MANUAL_PRICING_RANGE_REQUEST",
  GET_MANUAL_PRICING_RANGE_SUCCESS: "GET_MANUAL_PRICING_RANGE_SUCCESS",
  GET_MANUAL_PRICING_RANGE_FAILURE: "GET_MANUAL_PRICING_RANGE_FAILURE",

  PRICE_CHECK_WITH_RANGE_REQUEST: "PRICE_CHECK_WITH_RANGE_REQUEST",
  PRICE_CHECK_WITH_RANGE_SUCCESS: "PRICE_CHECK_WITH_RANGE_SUCCESS",
  PRICE_CHECK_WITH_RANGE_FAILURE: "PRICE_CHECK_WITH_RANGE_FAILURE",
};

export {
  request,
  success,
  failure,
  ACCOUNTS_REQUEST,
  NOTIFICATION_REQUEST,
  COURSES_REQUEST,
  FORGET_PASSWORD_REQUEST,
  RSS_FEED_REQUEST,
  STAFF_MEMBER_REQUEST,
  FAQS_REQUEST,
  LECTURE_NOTES_REQUEST,
  COURSE_WISH_LIST_REQUEST,
  STUDENT_PAYMENT_REQUEST,
  LOACTION_REQUEST,
  LOOKUP_REQUEST,
  FEED_BACK_REQUEST,
  ADMIN_REQUEST,
  CONTACT_US_REQUEST,
  DASHBOARD_TILES_REQUEST,
  USER_PROFILE_REQUEST,
  COACHING_REQUEST,
  INTEREST_REQUEST,
  PRICING_PLAN_REQUEST,
  MENU_PERMISSION_REQUEST,
};
