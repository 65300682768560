import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { ErrorMessage, FieldError, Loader } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { uploadImage } from "../../../redux/api";
import {
  CreateStaffMember,
  ManageUsersAdmin,
  UpdateStaffMember,
} from "../../../redux/actions";
import { FaEdit } from "react-icons/fa";

export default function CreateNewStaff(props) {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Courses || EduTech`;
  }, []);
  console.log("here is the console", props);
  let dispatch = useDispatch();

  let user = useSelector((state) => state.admin);

  let data = user && user.manageUser;

  useEffect(() => {
    dispatch(ManageUsersAdmin(4));
  }, [dispatch]);

  console.log("consoleData", user);
  const [editModalShow, setEditModalShow] = React.useState(false);
  let columns = [
    {
      dataField: "firstName",
      text: "First Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        return <>{row?.roleId === 4 ? "Staff" : ""}</>;
      },
    },
    // {
    //   dataField: "more",
    //   text: "Actions",
    //   classes: "align-middle table-right-hover-class edutech",
    //   formatter: (cell, row) => {
    //     return (
    //       <>
    //         <EditStaffModal row={row} />
    //       </>
    //     );
    //   },
    //   headerStyle: (column, colIndex) => {
    //     return {
    //       width: "75px",
    //     };
    //   },
    // },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <ToastContainer />
      <div className="teacher-curriculum-dashboard ">
        <div className="d-flex align-items-center justify-content-end ">
          <div className="">
            <button
              className="create_course w-100"
              type="button"
              onClick={() => setModalShow(true)}
            >
              Create New Staff
            </button>
          </div>
        </div>
        <NewStaffModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
      <hr />
      <>
        {user &&
          user.manageUserLoading === true &&
          user.manageUserSuccess === false &&
          user.manageUserFailure === false && <Loader />}
        {user &&
          user.manageUserLoading === false &&
          user.manageUserSuccess === false &&
          user.manageUserFailure === true && (
            <ErrorMessage message={user.manageUserError} />
          )}
        {user &&
          user.manageUserLoading === false &&
          user.manageUserSuccess === true &&
          user.manageUserFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              <>
                <MyDataTable
                  data={data}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  // handleDataChange={handleDataChange}
                />
              </>
            </div>
          )}
      </>
    </>
  );
}

function NewStaffModal(props) {
  const { register, handleSubmit, errors } = useForm();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const onChangeImagePreview = (e) => {
    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const fsize = e.target.files.item(i).size;
        // const file = Math.round(fsize / 1024);
        // The size of the file.
        if (fsize >= 4135700) {
          alert("File too Big, please select a file less than 4mb");
        } else {
          let file = e.target.files[0];
          const formData = new FormData();
          formData.append("file", file, file.name);
          if (file) {
            setImagePreview(!imagePreview);
            uploadImage(formData).then(
              (response) => {
                if (response.data.succeeded === true) {
                  setImagePreview(response.data.data);
                }
              },
              (error) => {}
            );
          }
        }
      }
    }
  };
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };
  let auth = useAuth();
  let dispatch = useDispatch();
  function onSubmit(data) {
    let finaldata = {
      image: imagePreview,
      firstName: data.firstName,
      lastName: data.secondName,
      email: data.email,
      password: data.password,
      roleId: 4,
      loginTypeId: 10,
    };
    dispatch(CreateStaffMember(finaldata, auth, Notification, moveToNext));
  }

  function moveToNext() {
    setImagePreview();
    setEmail();
    setSecondName();
    setFirstName();
    setPasswordShown1();
    props.onHide();
    dispatch(ManageUsersAdmin(4));
  }

  function Notification(data) {
    toast.success(data, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create New Staff
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div>
              <Form.Group>
                <Form.Label className="font-weight-bold">First Name</Form.Label>
                <Form.Control
                  className="font-weight-bold new-staff-parent edutech-coaching-name-text"
                  name="firstName"
                  type="text"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  style={{
                    borderColor: errors && errors.firstName ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.firstName && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold">Last Name</Form.Label>
                <Form.Control
                  className="font-weight-bold new-staff-parent edutech-coaching-name-text"
                  name="secondName"
                  type="text"
                  placeholder="Enter First Name"
                  value={secondName}
                  onChange={(e) => setSecondName(e.target.value)}
                  style={{
                    borderColor: errors && errors.secondName ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.secondName && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label className="font-weight-bold">Email</Form.Label>
                <Form.Control
                  className="font-weight-bold new-staff-parent edutech-coaching-name-text"
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    borderColor: errors && errors.email ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.email && (
                  <FieldError message={"This Field is Required"} />
                )}
              </Form.Group>
              <Form.Group className="password-shown-parent">
                <Form.Label className="font-weight-bold">Password</Form.Label>
                <Form.Control
                  className="font-weight-bold new-staff-parent edutech-coaching-name-text "
                  name="password"
                  type={passwordShown1 ? "text" : "password"}
                  placeholder="Enter Password"
                  style={{
                    borderColor: errors && errors.password ? "#a80000" : "",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  ref={register({
                    required: true,
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^%*?&.()-_=+])[A-Za-z\d@$!%#^*?&.()-_=+]{8,}$/,
                  })}
                />
                {passwordShown1 === false ? (
                  <AiFillEyeInvisible
                    className="show_pswd"
                    onClick={togglePasswordVisiblity1}
                  />
                ) : (
                  <AiFillEye
                    className="show_pswd"
                    onClick={togglePasswordVisiblity1}
                  />
                )}
                {errors && errors.password && (
                  <FieldError
                    message={
                      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                    }
                  />
                )}
              </Form.Group>
              <div className="col-md-6 col-lg-6 col-sm-12 pt-4 ">
                <h4>Upload Profile Image</h4>
                <button type="button" className="upload_thumbnail_btn mt-3">
                  <Form.File
                    custom
                    bsCustomPrefix="form-file-input"
                    name="files"
                    id="upload-file"
                    className="upload_thumbnail_input mt-1"
                    data-browse="UPLOAD"
                    accept="image/*"
                    onChange={(e) => {
                      onChangeImagePreview(e);
                    }}
                  />
                  Upload file
                </button>
                <div className="card border-0  bg-white set-card-radius mt-3 ">
                  {/* <div className="d-flex justify-content-between px-2 mb-2 pb-4 pt-4 mt-2 align-items-center"> */}
                  <div className=" ">
                    <img
                      src={imagePreview ? imagePreview : ""}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-admin-create-faq"
              variant="primary"
              type="submit"
              // onClick={() => {
              //   handleClose();
              // }}
            >
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
function EditStaffModal(props) {
  const [show, setShow] = useState(false);
  console.log("asaaaa", props);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit, errors } = useForm();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const onChangeImagePreview = (e) => {
    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const fsize = e.target.files.item(i).size;
        // const file = Math.round(fsize / 1024);
        // The size of the file.
        if (fsize >= 4135700) {
          alert("File too Big, please select a file less than 4mb");
        } else {
          let file = e.target.files[0];
          const formData = new FormData();
          formData.append("file", file, file.name);
          if (file) {
            setImagePreview(!imagePreview);
            uploadImage(formData).then(
              (response) => {
                if (response.data.succeeded === true) {
                  setImagePreview(response.data.data);
                }
              },
              (error) => {}
            );
          }
        }
      }
    }
  };

  let auth = useAuth();
  let dispatch = useDispatch();
  function onSubmit(data) {
    let finaldata = {
      userId: props?.row?.userId,
      image: imagePreview ? imagePreview : props?.row?.photo,
      firstName: firstName ? firstName : props?.row?.firstName,
      lastName: secondName ? secondName : props?.row?.lastName,
    };
    dispatch(UpdateStaffMember(finaldata, auth, Notification, moveToNext));
  }

  useEffect(() => {
    setFirstName(props?.row?.firstName ? props?.row?.firstName : "");
    setSecondName(props?.row?.lastName ? props?.row?.lastName : "");
    setEmail(props?.row?.email ? props?.row?.email : "");
    setImagePreview(props?.row?.photo ? props?.row?.photo : "");
  }, [props]);

  function moveToNext() {
    setImagePreview();
    setEmail();
    setSecondName();
    setFirstName();
    setPasswordShown1();
    handleClose();
    dispatch(ManageUsersAdmin(4));
  }

  function Notification(data) {
    toast.success(data, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      <FaEdit onClick={handleShow} className="cursor" />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Staff
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <div>
                <Form.Group>
                  <Form.Label className="font-weight-bold">
                    First Name
                  </Form.Label>
                  <Form.Control
                    className="font-weight-bold new-staff-parent edutech-coaching-name-text"
                    name="firstName"
                    type="text"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{
                      borderColor: errors && errors.firstName ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.firstName && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="font-weight-bold">
                    Last Name
                  </Form.Label>
                  <Form.Control
                    className="font-weight-bold new-staff-parent edutech-coaching-name-text"
                    name="secondName"
                    type="text"
                    placeholder="Enter First Name"
                    value={secondName}
                    onChange={(e) => setSecondName(e.target.value)}
                    style={{
                      borderColor: errors && errors.secondName ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.secondName && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="font-weight-bold">Email</Form.Label>
                  <Form.Control
                    className="font-weight-bold new-staff-parent edutech-coaching-name-text"
                    name="email"
                    type="email"
                    disabled
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      borderColor: errors && errors.email ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.email && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>

                <div className="col-md-6 col-lg-6 col-sm-12 pt-4 ">
                  <h4>Upload Profile Image</h4>
                  <button type="button" className="upload_thumbnail_btn mt-3">
                    <Form.File
                      custom
                      bsCustomPrefix="form-file-input"
                      name="files"
                      id="upload-file"
                      className="upload_thumbnail_input mt-1"
                      data-browse="UPLOAD"
                      accept="image/*"
                      onChange={(e) => {
                        onChangeImagePreview(e);
                      }}
                    />
                    Upload file
                  </button>
                  <div className="card border-0  bg-white set-card-radius mt-3 ">
                    {/* <div className="d-flex justify-content-between px-2 mb-2 pb-4 pt-4 mt-2 align-items-center"> */}
                    <div className=" ">
                      <img
                        src={imagePreview ? imagePreview : ""}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-admin-create-faq"
                variant="primary"
                type="submit"
                // onClick={() => {
                //   handleClose();
                // }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
