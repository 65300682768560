
import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../../config";
import { BsArrowDown, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  ErrorMessage,
  Loader,
} from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { GetCourseList, PublishCourse } from "../../../redux/actions/coursesAction";
import { RiArrowUpDownFill } from "react-icons/ri";

export default function TotalCourses() {
  useEffect(() => {
    document.title = `Courses || EduTech`;
  }, []);
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.courses);
  useEffect(() => {
    dispatch(GetCourseList(auth));
    document.title = `Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getCourseList;
  let columns = [
    // {
    //   dataField: "courseId",
    //   text: "Course ID",
    // },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "courseTitle",
      text: "Course Title",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "status",
      text: "Status",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">{row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""} </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            {row.price === 0 ? "FREE" : row.price}
          </span>
        );
      },
    },
    // {
    //   dataField: "pricePlan",
    //   text: "Price Plan",
    // },
  ];
  let columns2 = [
    // {
    //   dataField: "courseId",
    //   text: "Course ID",
    // },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "courseTitle",
      text: "Course Title",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "status",
      text: "Status",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">{row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""} </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            {row.price ? ((row.price) - (row.discountedPrice)) : "FREE"}
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <ToastContainer />
      <Link to={PATH.TEACHERDASHBOARD}>
        <BsArrowLeft size={35} className="mr-2" />
      </Link>
      <div
        className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Total Courses</h3>
          </div>
        </div>

      </div>
      <hr />
      <>
        {getListing &&
          getListing.getCourseListLoading === true &&
          getListing.getCourseListSuccess === false &&
          getListing.getCourseListFailure === false && <Loader />}
        {getListing &&
          getListing.getCourseListLoading === false &&
          getListing.getCourseListSuccess === false &&
          getListing.getCourseListFailure === true && (
            <ErrorMessage message={getListing.getCourseListError} />
          )}
        {getListing &&
          getListing.getCourseListLoading === false &&
          getListing.getCourseListSuccess === true &&
          getListing.getCourseListFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              {
                auth.edu_tech_user.roleId === 1 ?
                  <>
                    <MyDataTable
                      data={data}
                      columns={columns}
                      defaultSorted={defaultSorted}
                    // handleDataChange={handleDataChange}
                    />
                  </>
                  :
                  <>
                    <MyDataTable
                      data={data}
                      columns={columns2}
                      defaultSorted={defaultSorted}
                    // handleDataChange={handleDataChange}
                    />
                  </>
              }

            </div>
          )}
      </>
    </>
  );
}