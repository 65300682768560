import React, { useState } from "react";
import { Button, Card, Dropdown, Modal } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { PATH } from "../../../../../config";
import { useHistory } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import {
  dateTimeConvert,
  FieldError,
  getCountryTimeZone,
  getTodayDateWithTimeFormat,
  Loader,
} from "../../../../../assets";
import ReactPlayer from "react-player";
import {
  FIXEDDATE,
  FREEPLAN,
  PRICEPLAN,
  RECURRENTDATE,
  UNPUBLISHED,
} from "../../../../../assets/LookUpdata";
import {
  AddUpdateCoaching,
  UpdateAdminCoaching,
} from "../../../../../redux/actions/coachingAction";
import { useAuth } from "../../../../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../../../../redux/api";
import ReactHtmlParser from "react-html-parser";
const moment = require("moment-timezone");

export default function ReviewandSave(props) {
  const { coaching, setCoaching } = props;
  console.log("here is the data of props", props);
  let history = useHistory();
  let auth = useAuth();
  let dispatch = useDispatch();
  let update = useSelector((state) => state.coaching);
  const { handleSubmit } = useForm();
  const [backtoCoachingList, setCoachinglist] = useState(
    // location && location.state && location.state.coachinglist
    //   ? location.state.coachinglist
    //   : false
    coaching && coaching.coachingId ? coaching.coachingId : null
  );
  console.log("test data", coaching);
  const [coachingName, setCoachingName] = useState(
    coaching && coaching.coachingName ? coaching.coachingName : ""
  );
  const [companyTitle, setCompanyTitle] = useState(
    coaching && coaching.companyTitle ? coaching.companyTitle : ""
  );
  // const [Locationselect, setLocationselect] = useState(
  //   coaching && coaching.interestId ? coaching.interestId : ""
  // );
  const [pricePlanType, setpricePlanType] = useState(
    coaching && coaching.pricePlan ? coaching.pricePlan : ""
  );
  const [price, setPrice] = useState(
    coaching && coaching.price ? coaching.price : 0
  );
  const [dateType, setDateType] = useState(
    coaching && coaching.fixedDate ? coaching.fixedDate : ""
  );
  console.log("dat");
  const [fixedDate, setFixedDate] = useState(
    coaching && coaching.fixedDate ? coaching.fixedDate : null
  );
  const [startDate, SetStartDate] = useState(
    coaching && coaching.startRecurrentDate ? coaching.startRecurrentDate : null
  );
  const [endDate, SetEndDate] = useState(
    coaching && coaching.endRecurrentDate ? coaching.endRecurrentDate : null
  );
  const [Descriptions, SetDescripton] = useState(
    coaching && coaching.description ? coaching.description : ""
  );
  const [zoomId, setZoomId] = useState(
    coaching && coaching.zoomId ? coaching.zoomId : ""
  );
  const [zoomLink, setZoomLink] = useState(
    coaching && coaching.zoomLink ? coaching.zoomLink : ""
  );
  const [zoomPassCode, setZoomPassCode] = useState(
    coaching && coaching.zoomPassCode ? coaching.zoomPassCode : ""
  );
  const [videoFile, setvideoFile] = useState(
    coaching && coaching.videoURL ? coaching.videoURL : ""
  );
  // const [showinputfield1, setinputfield1] = useState(
  //   dateType && dateType === RECURRENTDATE ? true : false
  // );
  function goBack() {
    if (backtoCoachingList > 0) {
      history.push(PATH.COACHINGLIST);
    } else {
      props.handleBack(4);
      props.changeView(4);
    }
  }
  function onSubmit() {
    coaching.userId = auth.edu_tech_user.userId;
    coaching.roleId = auth.edu_tech_user.roleId;
    coaching.status = UNPUBLISHED;
    coaching.price = parseInt(price);
    if (coaching && coaching.coachingId) {
      dispatch(UpdateAdminCoaching(coaching, auth, moveToNext));
    } else {
      dispatch(AddUpdateCoaching(coaching, auth, moveToNext));
    }
  }
  function moveToNext() {
    history.push(PATH.COACHINGLIST);
  }
  const [nameModalShow, setNameModalShow] = React.useState(false);
  const [priceModalShow, setPriceModalShow] = React.useState(false);
  const [datesModalShow, setDatesModalShow] = React.useState(false);
  const [descriptionModalShow, setDescriptionModalShow] = React.useState(false);
  console.log("ldsjfjds", fixedDate, fixedDate !== null);
  const timeZone = getCountryTimeZone();
  return (
    <React.Fragment>
      <h2
        className="d-flex align-items-center cursor font-weight-bold"
        onClick={() => goBack()}
      >
        <BsArrowLeft size={35} className="mr-2" /> Review your coaching service
      </h2>
      <hr className="edutech-hr-colors" />
      <p className="mt-4 font-weight-bold">
        You can always change the details of your coaching service later.
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className="p-5 ReviewandSaveCard">
          <div className="publish_view_modal">
            <>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="mb-0"> Name</h4>
                      <h4 className="mb-0">
                        <FaRegEdit onClick={() => setNameModalShow(true)} />
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="d-flex responsive_small_screen">
                        <h4>Coaching Name:</h4>
                        <p className="pl-2"> {coachingName}</p>
                      </div>
                      <div className="d-flex responsive_small_screen">
                        <h4>Company Name:</h4>
                        <p className="pl-2"> {companyTitle}</p>
                      </div>
                      {/* <div className="d-flex responsive_small_screen">
                        <h4>Category Type:</h4>
                        <p className="pl-2"> {Locationselect}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="mb-0"> PRICING PLAN</h4>
                      <h4 className="mb-0">
                        <FaRegEdit onClick={() => setPriceModalShow(true)} />
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        {pricePlanType === PRICEPLAN ? (
                          <>
                            <div className="d-flex">
                              <h4 className="mb-1">Currency:</h4>
                              <p className="mb-1 pl-2">SGD</p>
                            </div>
                            <div className="d-flex">
                              <h4 className="mb-1">Price:</h4>
                              <p className="pl-2">{price}</p>
                            </div>
                          </>
                        ) : (
                          <div className="d-flex">
                            <h4 className="mb-1">{price ? price : "Free"}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="mb-0"> Date </h4>
                      <h4 className="mb-0">
                        <FaRegEdit onClick={() => setDatesModalShow(true)} />
                      </h4>
                    </div>
                    <div className="card-body">
                      {fixedDate !== null ? (
                        <div className="d-flex responsive_small_screen">
                          <h4>Fixed date:</h4>

                          {/* <p className="pl-2">{getDateNTime(fixedDate) + getTimeFormat(fixedDate) ? getDateNTime(fixedDate) + (' ') + getTimeFormat(fixedDate) : ""}</p> */}
                          <p className="pl-2">
                            {/* {dateTimeConvert(fixedDate)
                              ? dateTimeConvert(fixedDate)
                              : fixedDate} */}
                            {fixedDate.split("T")[0] +
                              " , " +
                              moment(
                                fixedDate.split("T")[1],
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                          </p>
                        </div>
                      ) : (
                        <div className="d-flex responsive_small_screen">
                          <h4>Recurrent date:</h4>
                          {/* <p className="pl-2">{getDateNTime(startDate) + getTimeFormat(startDate) ? getDateNTime(startDate) + (' ') + getTimeFormat(startDate) : ""}</p>
                          <p className="pl-5">{getDateNTime(endDate) + getTimeFormat(endDate) ? getDateNTime(endDate) + (' ') + getTimeFormat(endDate) : ""}</p> */}
                          <p className="pl-2">
                            {startDate.split("T")[0] +
                              " , " +
                              moment(
                                startDate.split("T")[1],
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                            {/* 
                            {moment
                              .utc(startDate)
                              .tz(timeZone)
                              .format("hh:mm A")} */}
                          </p>
                          <p className="pl-5">
                            {endDate.split("T")[0] +
                              " , " +
                              moment(endDate.split("T")[1], "HH:mm:ss").format(
                                "hh:mm A"
                              )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h4 className="mb-0"> Description</h4>
                      <h4 className="mb-0">
                        <FaRegEdit
                          onClick={() => setDescriptionModalShow(true)}
                        />
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="d-flex responsive_small_screen">
                        <p className=""> {ReactHtmlParser(Descriptions)}</p>
                      </div>
                      <div className="d-flex responsive_small_screen">
                        <h4>Zoom Id:</h4>
                        <p className="pl-2">{zoomId}</p>
                      </div>
                      <div className="d-flex responsive_small_screen">
                        <h4>Zoom Link:</h4>
                        <p className="pl-2">{zoomLink}</p>
                      </div>
                      <div className="d-flex responsive_small_screen">
                        <h4>Zoom Passcode:</h4>
                        <p className="pl-2"> {zoomPassCode}</p>
                      </div>
                      <div className="d-flex responsive_small_screen">
                        <ReactPlayer
                          url={videoFile ? videoFile : ""}
                          width="100%"
                          className="video_show_set mb-0"
                          height="100%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
          <div className="mt-2 text-right">
            {update && update.updateCoachingLoading === false && (
              <Button
                type="submit"
                className="edutech-curriculum-action-button description-rounded"
              >
                {" "}
                Submit
              </Button>
            )}
            {update && update.updateCoachingLoading === true && <Loader />}
          </div>
        </Card>
      </Form>
      <Name
        coachingName={coachingName}
        setCoachingName={setCoachingName}
        companyTitle={companyTitle}
        setCompanyTitle={setCompanyTitle}
        coaching={coaching}
        setCoaching={setCoaching}
        show={nameModalShow}
        onHide={() => setNameModalShow(false)}
      />
      <Price
        coaching={coaching}
        setCoaching={setCoaching}
        pricePlanType={pricePlanType}
        setpricePlanType={setpricePlanType}
        price={price}
        setPrice={setPrice}
        show={priceModalShow}
        onHide={() => setPriceModalShow(false)}
      />
      <Dates
        dateType={dateType}
        setDateType={setDateType}
        fixedDate={fixedDate}
        setFixedDate={setFixedDate}
        startDate={startDate}
        SetStartDate={SetStartDate}
        endDate={endDate}
        SetEndDate={SetEndDate}
        coaching={coaching}
        setCoaching={setCoaching}
        show={datesModalShow}
        onHide={() => setDatesModalShow(false)}
      />
      <Description
        Descriptions={Descriptions}
        SetDescripton={SetDescripton}
        zoomId={zoomId}
        setZoomId={setZoomId}
        zoomLink={zoomLink}
        setZoomLink={setZoomLink}
        zoomPassCode={zoomPassCode}
        setZoomPassCode={setZoomPassCode}
        coaching={coaching}
        setCoaching={setCoaching}
        videoFile={videoFile}
        setvideoFile={setvideoFile}
        show={descriptionModalShow}
        onHide={() => setDescriptionModalShow(false)}
      />
    </React.Fragment>
  );
}
function Name(props) {
  const { coaching, setCoaching } = props;
  const { register, handleSubmit, errors } = useForm();
  const [name, setName] = useState(
    props && props.coachingName ? props.coachingName : null
  );
  const [companyTitle, setCompanyTitle] = useState(
    props && props.companyTitle ? props.companyTitle : null
  );
  function onSubmit(data) {
    props.setCoachingName(data.coachingName);
    props.setCompanyTitle(data.companyTitle);
    coaching.coachingName = data.coachingName;
    coaching.companyTitle = data.companyTitle;
    setCoaching(coaching);
    props.onHide();
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="header-description"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Name</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <label className="font-weight-bold mb-1">Coaching Name:</label>
              <input
                className="form-control"
                value={name}
                name="coachingName"
                // pattern="^[a-zA-Z][\sa-zA-Z0-9]*"
                pattern="/[^\s]+/,]"
                onChange={(e) => setName(e.target.value)}
                style={{
                  borderColor: errors && errors.name ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />

              {errors.coachingName && (
                <FieldError message={"This Field is Required"} />
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <label className="font-weight-bold mb-1">Company Name:</label>
              <input
                className="form-control"
                name="companyTitle"
                // pattern="^[a-zA-Z][\sa-zA-Z0-9]*"
                // pattern="(\s*\S\s*)+"
                pattern="/[^\s]+/,]"
                value={companyTitle}
                style={{
                  borderColor: errors && errors.name ? "#a80000" : "",
                }}
                ref={register({ required: true })}
                onChange={(e) => setCompanyTitle(e.target.value)}
              />
              {errors.companyTitle && (
                <FieldError message={"This Field is Required"} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="date-modal">
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function Price(props) {
  const { coaching, setCoaching } = props;
  const { register, handleSubmit, errors } = useForm();
  const [price, setPrice] = useState(props && props.price ? props.price : "");
  const [planType, setPlanType] = useState(
    props && props.pricePlanType ? props.pricePlanType : null
  );
  const [showPriceField, setShowPriceField] = useState(
    props && props.pricePlanType === FREEPLAN ? false : true
  );
  function onSubmit() {
    if (planType === FREEPLAN) {
      props.setpricePlanType(FREEPLAN);
      props.setPrice(0);
      setPrice(null);
      coaching.price = 0;
      coaching.pricePlan = FREEPLAN;
    } else {
      props.setpricePlanType(PRICEPLAN);
      props.setPrice(price);
      setPrice(price);
      coaching.price = price;
      coaching.pricePlan = PRICEPLAN;
    }
    setCoaching(coaching);
    props.onHide();
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="header-description"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Pricing Plan</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 d-flex">
              <input
                type="radio"
                id="free"
                name="fav_language"
                value="free"
                checked={planType === FREEPLAN ? true : false}
                className="edutech-coaching-checkbox cursor"
                onClick={() => {
                  setShowPriceField(false);
                  setPlanType(FREEPLAN);
                }}
              />
              <label for="free" className="ml-3 font-weight-bold cursor">
                Free
                <br />
                <label className="edutech-second-label">
                  Allow access to your content free of charge.
                </label>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 d-flex">
              <input
                type="radio"
                id="onetime"
                name="fav_language"
                value="onetime"
                checked={planType === PRICEPLAN ? true : false}
                className="edutech-coaching-checkbox cursor"
                onClick={() => {
                  setShowPriceField(true);
                  setPlanType(PRICEPLAN);
                }}
              />
              <label for="onetime" className="ml-3 font-weight-bold cursor">
                Price
                <br />
                <label className="edutech-second-label">
                  Set up a one-time payment.
                </label>
              </label>
            </div>
          </div>
          {showPriceField && (
            <div className="row mt-2">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="d-flex">
                  <div className="dropdown_bg_grey mr-2 ml-4">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="text-center form-control"
                      >
                        {"SGD"}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                  <Form.Group className="mb-0" controlId="">
                    <Form.Control
                      type="number"
                      name="price"
                      min="1"
                      placeholder="Amount"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                      style={{
                        borderColor: errors && errors.price ? "#a80000" : "",
                      }}
                      ref={register({ required: true })}
                    />
                    {errors.price && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="date-modal">
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function Dates(props) {
  console.log("datadey", props);
  const { coaching, setCoaching } = props;
  const { register, handleSubmit, errors } = useForm();
  const [dateType, setDateType] = useState(
    props && props.dateType ? props.dateType : ""
  );
  const [showinputfield, setinputfield] = useState(
    props && props.dateType && props.dateType === FIXEDDATE ? true : false
  );
  const [showinputfield1, setinputfield1] = useState(
    props && props.dateType && props.dateType === RECURRENTDATE ? true : false
  );
  const [showinputfield2, setinputfield2] = useState(
    showinputfield1 && showinputfield1 ? true : false
  );
  const [fixedDate, setFixedDate] = useState(
    props && props.fixedDate ? props.fixedDate : new Date()
  );
  const [startDate, SetStartDate] = useState(
    props && props.startDate ? props.startDate : new Date()
  );
  const [endDate, SetEndDate] = useState(
    props && props.endDate ? props.endDate : new Date()
  );

  var CreateEndSlotdate = moment(new Date((endDate + "").split("T")[0])).format(
    "YYYY-MM-DD"
  );

  var CreateStartSlotdate = moment(
    new Date((startDate + "").split("T")[0])
  ).format("YYYY-MM-DD");
  var CreateFixedSlotdate = moment(
    new Date((fixedDate + "").split("T")[0])
  ).format("YYYY-MM-DD");

  let fixedTimeWithZone = moment(
    (fixedDate + "").split("T")[1],
    "h:mm a"
  ).format("HH:mm:ssZ");

  let startTimeWithZone = moment(
    (startDate + "").split("T")[1],
    "h:mm a"
  ).format("HH:mm:ssZ");

  let endTimeWithZone = moment((endDate + "").split("T")[1], "h:mm a").format(
    "HH:mm:ssZ"
  );

  let finalFixedDateTime = moment(
    CreateFixedSlotdate + " " + fixedTimeWithZone
  );
  let finalStartDateTime = moment(
    CreateStartSlotdate + " " + startTimeWithZone
  );
  let finalEndDateTime = moment(CreateEndSlotdate + " " + endTimeWithZone);

  function onSubmit() {
    let startTimeForData = new Date(finalStartDateTime).toISOString();
    let endTimeForData = new Date(finalEndDateTime).toISOString();
    let finalStartDateTimeFormat = moment(startTimeForData).format();
    if (!(dateType === undefined || dateType === null)) {
      if (dateType === RECURRENTDATE) {
        if (showinputfield2 !== false) {
          coaching.startRecurrentDate =
            moment(startTimeForData).format("YYYY-MM-DDTHH:mm");
          coaching.endRecurrentDate =
            moment(endTimeForData).format("YYYY-MM-DDTHH:mm");
          coaching.fixedDate = null;
          coaching.dateType = dateType;

          props.setFixedDate(null);
          props.SetStartDate(startDate);
          props.SetEndDate(endDate);

          setFixedDate(null);
          setCoaching(coaching);
          props.onHide();
        }
      } else {
        props.setFixedDate(fixedDate);
        props.SetStartDate(null);
        props.SetEndDate(null);
        coaching.fixedDate = moment(
          new Date(finalFixedDateTime).toISOString()
        ).format("YYYY-MM-DDTHH:mm:ss");
        coaching.startRecurrentDate = null;
        coaching.endRecurrentDate = null;
        SetStartDate(null);
        SetEndDate(null);
      }
      props.setDateType(dateType);
      coaching.dateType = dateType;
      setCoaching(coaching);
      props.onHide();
    }
    console.log("datadey", coaching);
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="header-description"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Date </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className=" d-flex">
            <input
              type="radio"
              id="fixeddate"
              name="fav_language"
              value="fixeddate"
              checked={showinputfield && showinputfield ? true : false}
              className="edutech-coaching-checkbox"
              onClick={() => {
                setinputfield(true);
                setinputfield1(false);
                setinputfield2(false);
                setDateType(FIXEDDATE);
                SetStartDate(null);
                SetEndDate(null);
              }}
            />{" "}
            <label for="fixeddate" className="ml-3 font-weight-bold cursor">
              {" "}
              On a fixed date
              <br />
              <label className="edutech-second-label">
                {" "}
                This is useful for recurring group classes and enrollment
                periods with firm start dates.
              </label>
            </label>
          </div>
          {showinputfield === true ? (
            <div className="col-sm-12 col-xl-10 d-flex">
              <div className="ml-4">
                <Form.Control
                  className="mt-1"
                  name="fixedDate"
                  type="datetime-local"
                  defaultValue={fixedDate}
                  min={getTodayDateWithTimeFormat()}
                  onChange={(e) => {
                    setFixedDate(e.target.value);
                  }}
                  style={{
                    borderColor: errors && errors.fixedDate ? "#a80000" : "",
                  }}
                  ref={register({ required: true })}
                />
                {errors.fixedDate && (
                  <FieldError message={"This Field is Required"} />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className=" d-flex mt-3">
            {" "}
            <input
              type="radio"
              id="enrollmentdate"
              name="fav_language"
              value="enrollmentdate"
              className="edutech-coaching-checkbox"
              checked={showinputfield1 && showinputfield1 ? true : false}
              onClick={() => {
                setinputfield1(true);
                setinputfield(false);
                setinputfield2(false);
                setDateType(RECURRENTDATE);
                setFixedDate(null);
              }}
            />{" "}
            <label
              for="enrollmentdate"
              className="ml-3 font-weight-bold cursor mb-0"
            >
              Recurrent Date
              <br />
              <label className="edutech-second-label">
                {" "}
                This is useful for 1:1 coaching where client enrollment is
                ongoing.
              </label>
            </label>
          </div>
          {showinputfield1 === true ? (
            <>
              <div className="col-sm-12 col-xl-10 d-flex "></div>
              <div className="col-sm-12 col-xl-10 d-flex">
                <div
                  className="d-flex align-items-center mr-3 ml-4 cursor"
                  onClick={() => setinputfield2(true)}
                >
                  <input
                    type="radio"
                    checked={showinputfield2 && showinputfield2 ? true : false}
                    name="recurrent"
                    className="edutech-coaching-checkbox"
                  />{" "}
                  <label className="ml-2 mb-0 font-weight-bold">Yes</label>
                </div>
                <div
                  className="d-flex align-items-center cursor"
                  onClick={() => setinputfield2(false)}
                >
                  <input
                    type="radio"
                    name="recurrent"
                    className="edutech-coaching-checkbox"
                  />{" "}
                  <label className="ml-2 mb-0 font-weight-bold ">No</label>
                </div>
              </div>
              {showinputfield2 === true ? (
                <>
                  <div className="col-sm-12 col-xl-10 d-flex mt-2">
                    <div className="ml-4">
                      <label className="mb-1 fw-600"> Start date</label>
                      <Form.Control
                        className="mt-1"
                        name="startRecurrentDate"
                        type="datetime-local"
                        min={getTodayDateWithTimeFormat()}
                        defaultValue={startDate}
                        onChange={(e) => {
                          SetStartDate(e.target.value);
                        }}
                        style={{
                          borderColor:
                            errors && errors.startRecurrentDate
                              ? "#a80000"
                              : "",
                        }}
                        ref={register({ required: true })}
                      />
                      {errors.startRecurrentDate && (
                        <FieldError message={"This Field is Required"} />
                      )}
                    </div>
                    <div className="ml-4">
                      <label className="mb-1 fw-600"> End date</label>
                      <Form.Control
                        className="mt-1"
                        name="endRecurrentDate"
                        type="datetime-local"
                        defaultValue={endDate}
                        min={getTodayDateWithTimeFormat()}
                        onChange={(e) => {
                          SetEndDate(e.target.value);
                        }}
                        style={{
                          borderColor:
                            errors && errors.endRecurrentDate ? "#a80000" : "",
                        }}
                        ref={register({ required: true })}
                      />
                      {errors.endRecurrentDate && (
                        <FieldError message={"This Field is Required"} />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="date-modal">
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
function Description(props) {
  const { coaching, setCoaching } = props;
  let [descriptions, setdescription] = useState(
    props && props.Descriptions ? props.Descriptions : ""
  );
  console.log("test data", setdescription);
  const [zoomlink, setZooomLink] = useState(
    props && props.zoomLink ? props.zoomLink : ""
  );
  const [zoomId, setZoomId] = useState(
    props && props.zoomId ? props.zoomId : ""
  );
  const [zoomPasscode, setZooomPasscode] = useState(
    props && props.zoomPassCode ? props.zoomPassCode : ""
  );
  const [videoFile, setvideoFile] = useState(
    coaching && coaching.videoURL ? coaching.videoURL : ""
  );
  const onChangeProfilePicture = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    if (file) {
      setvideoFile(true);
      uploadImage(formData).then(
        (response) => {
          console.log("respi", response);
          if (response.data.succeeded === true) {
            setvideoFile(response.data.data);
          }
        },
        (error) => {}
      );
    }
  };
  const { register, handleSubmit, errors } = useForm();
  const [Descreptionerror, SetDescreptionerror] = useState(false);

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "underline",
      "numberedlist",
      "bulletedList",
      // "link",
    ],
  };

  function onSubmit() {
    if (descriptions !== undefined) {
      coaching.description = descriptions;
      coaching.zoomId = zoomId;
      coaching.zoomLink = zoomlink;
      coaching.zoomPassCode = zoomPasscode;
      coaching.videoURL = videoFile;
      props.SetDescripton(descriptions);
      props.setZoomId(zoomId);
      props.setZoomLink(zoomlink);
      props.setZoomPassCode(zoomPasscode);
      props.setvideoFile(videoFile);
      setCoaching(coaching);
      props.onHide();
    } else {
      SetDescreptionerror(true);
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="header-description"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Description</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-group ckeditor-modification">
            <label for="email" className="font-weight-bold mb-4">
              Description:
            </label>
            <CKEditor
              className="edutech-ckeditor"
              editor={ClassicEditor}
              config={editorConfiguration}
              data={descriptions}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                descriptions = editor.getData();
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            {Descreptionerror && (
              <FieldError message={"This Field is Required"} />
            )}
          </div>
          <div className="row align-items-center">
            <div className="col-sm-4 col-md-4">
              <p className="calendar-fs mb-0 ml-2">Zoom ID :</p>
            </div>
            <div className="col-sm-8 col-md-8">
              <input
                type="text"
                className="form-control"
                name="zoomID"
                placeholder="Add zoom id"
                // pattern="^[a-zA-Z][\sa-zA-Z0-9]*"
                pattern="^\d{3}[-\s]\d{3}[-\s]\d{4}$"
                value={zoomId}
                onChange={(e) => {
                  setZoomId(e.target.value);
                }}
                style={{
                  borderColor: errors && errors.name ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {errors.zoomId && (
                <FieldError message={"This Field is Required"} />
              )}
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-sm-4 col-md-4">
              <p className="calendar-fs mb-0 ml-2">Zoom Link :</p>
            </div>
            <div className="col-sm-8 col-md-8">
              <input
                type="text"
                className="form-control"
                id="zoom"
                name="zoomlink"
                value={zoomlink}
                // pattern="^[a-zA-Z][\sa-zA-Z0-9]*"
                placeholder="Add zoom link"
                onChange={(e) => {
                  setZooomLink(e.target.value);
                }}
                style={{
                  borderColor: errors && errors.name ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {errors.zoomlink && (
                <FieldError message={"This Field is Required"} />
              )}
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-sm-4 col-md-4">
              <p className="calendar-fs mb-0 ml-2">Zoom Passcode :</p>
            </div>
            <div className="col-sm-8 col-md-8">
              <input
                type="text"
                name="zoompasscode"
                className="form-control"
                id="passcode"
                value={zoomPasscode}
                pattern="^\d{8,11}$"
                onChange={(e) => {
                  setZooomPasscode(e.target.value);
                }}
                style={{
                  borderColor: errors && errors.name ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {errors.zoompasscode && (
                <FieldError message={"This Field is Required"} />
              )}
            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-sm-4 col-md-4">
              <p className="calendar-fs mb-0 ml-2">Upload Video :</p>
            </div>
            <div className="col-sm-8 col-md-8">
              {videoFile === true ? (
                <Loader />
              ) : (
                <>
                  <ReactPlayer
                    url={videoFile ? videoFile : ""}
                    width="100%"
                    height="100%"
                    className={` ${videoFile !== null ? "video_show_set" : ""}`}
                  />
                  <button type="button" className="upload_video_btn">
                    <input
                      type="file"
                      name="video"
                      data-browse="UPLOAD"
                      className="upload_video_input"
                      accept="video/*"
                      onChange={(e) => {
                        onChangeProfilePicture(e);
                      }}
                    />
                    Upload file
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="date-modal">
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
