import { request, success, failure, RSS_FEED_REQUEST } from "./utilities";
import {
  getRSSFeedFortune,
  getRSSFeedFT,
  getRSSFeedInvesting,
} from "../api";
export function GetRSSFeedInvesting(auth) {
  return (dispatch) => {
    dispatch(request(RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_REQUEST));
    getRSSFeedInvesting(auth).then(
      (response) => {

        if (response.data.succeeded === true) {
          dispatch(
            success(
              RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_SUCCESS,
              response.data
            )
          );
        } else {
          dispatch(
            failure(
              RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            RSS_FEED_REQUEST.GET_RSS_FEED_INVESTING_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetRSSFeedFT(auth) {
  return (dispatch) => {
    dispatch(request(RSS_FEED_REQUEST.GET_RSS_FEED_FT_REQUEST));
    getRSSFeedFT(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              RSS_FEED_REQUEST.GET_RSS_FEED_FT_SUCCESS,
              response.data
            )
          );
        } else {
          dispatch(
            failure(
              RSS_FEED_REQUEST.GET_RSS_FEED_FT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            RSS_FEED_REQUEST.GET_RSS_FEED_FT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetRSSFeedFortune(auth) {
  return (dispatch) => {
    dispatch(request(RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_REQUEST));
    getRSSFeedFortune(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            RSS_FEED_REQUEST.GET_RSS_FEED_FORTUNE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
