import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function createStaffMember(data, auth) {
  return axios.post(
    APP_SETTINGS.API_PATH.STAFF_MEMBER.createStaffMember,
    data,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function updateStaffMember(data, auth) {
  return axios.post(
    APP_SETTINGS.API_PATH.STAFF_MEMBER.updateStaffMember,
    data,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getStaffProfile(auth) {
  return axios.get(APP_SETTINGS.API_PATH.STAFF_MEMBER.getStaffProfile, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
