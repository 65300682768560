import React, { useState } from "react";
import { Button, Card, Dropdown, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
import { FieldError } from "../../../../../assets";
import { FREEPLAN, PRICEPLAN } from "../../../../../assets/LookUpdata";

export default function SetPrice(props) {
  const { Coaching, setCoaching } = props;
  const { register, handleSubmit, errors } = useForm();

  console.log("setPrice", Coaching);
  function onSubmit() {
    if (!(planType === undefined || planType === null)) {
      if (planType === FREEPLAN) {
        Coaching.pricePlan = FREEPLAN;
        Coaching.price = 0;
        Coaching.isPaid = false;

      } else {
        Coaching.pricePlan = planType;
        Coaching.price = price;
        Coaching.isPaid = true;
      }
      setCoaching(Coaching);

      props.changeView(3);
      props.handleNext(3);
    }
  }
  function goBack() {
    Coaching.pricePlan = 0;
    Coaching.isPaid = null;
    Coaching.price = 0;
    setCoaching(Coaching);
    props.changeView(0);
    props.handleBack(0);
  }

  const [planType, setPlanType] = useState(
    Coaching && Coaching.pricePlan ? Coaching.pricePlan : null
  );
  const [price, setPrice] = useState(
    Coaching && Coaching.price ? Coaching.price : null
  );
  const [showfield, setfield] = useState(
    planType && planType === PRICEPLAN ? 1 : 0
  );
  return (
    <div>
      <h2
        className="d-flex align-items-center cursor font-weight-bold"
        onClick={() => goBack()}
      >
        <BsArrowLeft size={35} className="mr-2" /> Set a price for your coaching
      </h2>
      <hr className="edutech-hr-colors" />
      {/* <p className="mt-4 font-weight-bold">
        What will you charge for your coaching service?
      </p> */}
      <Card className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className=" d-flex">
            {" "}
            <input
              type="radio"
              id="free"
              name="fav_language"
              value="free"
              checked={planType === FREEPLAN ? true : false}
              className="edutech-coaching-checkbox"
              // onClick={() => setfield(0)}
              onClick={() => {
                setPlanType(FREEPLAN);
                setfield(0);
              }}
            />{" "}
            <label for="free" className="ml-3 font-weight-bold cursor">
              Free
              <br />
              <label className="edutech-second-label">
                {" "}
                Allow access to your content free of charge.
              </label>
            </label>
          </div>
          <br />
          <div className=" d-flex">
            <input
              type="radio"
              id="onetime"
              name="fav_language"
              value="onetime"
              checked={planType === PRICEPLAN ? true : false}
              className="edutech-coaching-checkbox"
              onClick={() => {
                setPlanType(PRICEPLAN);
                setfield(1);
              }}
            />{" "}
            <label for="onetime" className="ml-3 font-weight-bold cursor">
              {" "}
              Price
              <br />
              <label className="edutech-second-label">
                {" "}
                Set up a one-time payment.
              </label>
            </label>
          </div>
          {showfield === 1 ? (
            <div className="row">
              <div className="col-12  ml-4 pl-4 mt-2">
                <p className="text-muted ml-1">
                  Price{" "}
                  <FaQuestionCircle
                    className="ml-2  mb-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=" minimum $9.99"
                  />
                </p>
              </div>
              <div className="col-10 d-flex ml-4">
                <div className="dropdown_bg_grey mr-2 ml-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="text-center form-control"
                    >
                      {"SGD"}
                      {/* <IoMdArrowDropdown /> */}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
                <div>
                  <Form.Group className="mb-0" controlId="">
                    <Form.Control
                      type="number"
                      min="1"
                      name="price"
                      placeholder="Amount"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                      style={{
                        borderColor: errors && errors.price ? "#a80000" : "",
                      }}
                      ref={register({ required: true })}
                    />
                    {errors.price && (
                      <FieldError message={"This Field is Required"} />
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mt-5">
            <Button
              type="submit"
              className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold"
            >
              Next
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}
