import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";

export default function SelectSettings(props) {
  function moveNext() {
    props.changeView(2);
    props.handleNext(2);
  }
  function goBack() {
    props.handleBack(0)
    props.changeView(0);
  }
  return (
    <div>
      <h2
        className="d-flex align-items-center cursor font-weight-bold"
        onClick={() => goBack()}
      >
        <BsArrowLeft size={35} className="mr-2" /> Set a client intake flow
      </h2>
      <hr className="edutech-hr-colors" />
      <p className="mt-4 font-weight-bold">
        {" "}
        How do you want to receive new clients?
      </p>
      <Card className="p-5">
        <Form>
          <div className=" d-flex">
             {" "}
            <input
              type="radio"
              id="directly"
              name="fav_language"
              value="directly"
              className="edutech-coaching-checkbox"
            />
             {" "}
            <label for="directly" className="ml-3 font-weight-bold">
              Directly
              <br />
              <label className="edutech-second-label">
                {" "}
                New clients can go directly to checkout and sign up.
              </label>
            </label>
          </div>
          <br />
          <div className=" d-flex">
             
            <input
              type="radio"
              id="intake"
              name="fav_language"
              value="intake"
              className="edutech-coaching-checkbox"
            />
             {" "}
            <label for="intake" className="ml-3 font-weight-bold">
              {" "}
              With an intake form
              <br />
              <label className="edutech-second-label">
                {" "}
                New clients will fill out an intake form and be immediately sent
                to checkout.
              </label>
            </label>
          </div>
          <br />
          <div className=" d-flex">
             {" "}
            <input
              type="radio"
              id="approval"
              name="fav_language"
              value="approval"
              className="edutech-coaching-checkbox"
            />
             {" "}
            <label for="approval" className="ml-3 font-weight-bold">
              {" "}
              With an intake form and your approval
              <br />
              <label className="edutech-second-label">
                {" "}
                New clients will fill out an intake form and wait for your
                approval before they proceed to checkout.
              </label>
            </label>
          </div>
          <br />
          {/* <div className="edutech-member-list-main-div">
            <h4 className="font-weight-bold">
              {" "}
              Set Member limit{" "}
              <span className="edutech-member-list-span">50</span>
            </h4>
          </div> */}
        </Form>
        <div className="mt-5">
          <Button
            onClick={() => moveNext()}
            className="edutech-curriculum-action-button edutech-pull-right my-2 mx-2 font-weight-bold"
          >
            Next
          </Button>
        </div>
      </Card>
    </div>
  );
}
