import React, { useState } from "react";
import { Card, Button, Form, Row, Col, Modal, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "react-credit-cards/es/styles-compiled.css";

import Cards from "react-credit-cards";
import { IoMdArrowDropdown } from "react-icons/io";
import ReactTooltip from "react-tooltip";
export default function ExplorePricingPlans() {
  const { register, handleSubmit } = useForm();
  const [buttonState, setButtonState] = useState(false);
  const Subscription_list = [
    {
      id: 1,
      text: "1000 (5%)",
      value: "1000 (5%)",
    },
    {
      id: 2,
      text: "1200 (10%)",
      value: "1200 (10%)",
    },
    {
      id: 3,
      text: "1400 (15%)",
      value: "1400 (15%)",
    },
  ];
  function onSubmit() {}
  const [selectPrice, setSelectPrice] = useState(null);
  return (
    <div className="container subscription_plan">
      <div className="row">
        <div className="col-12 col-md-12">
          <div
            className={`mb-4 card card-custom w-100 bg-dark admin-course-request`}
          >
            <div className="card-header text-center">
              <h2 className="font-weight-700 mb-0">Pricing Plan</h2>
            </div>
          </div>
          <Card>
            <Card.Body className="text-center proceed_free_btn d-flex align-items-center justify-content-between">
              <h5>Free Trial (7 Days)</h5>
              <PaymentModal />
            </Card.Body>
          </Card>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center">
          {/* <div className="col-12 col-md-4">
            <Card>
              <Card.Body>
                <div className="pricing_btn">
                  {Subscription_list &&
                    Subscription_list.map((interest, index) => {
                      if (index > 4) {
                        return null;
                      }
                      return (
                        <>
                          <ButtonSection
                            setButtonState={setButtonState}
                            buttonState={buttonState}
                            interest={interest}
                            index={index}
                          />
                        </>
                      );
                    })}
                </div>
              </Card.Body>
            </Card>
          </div> */}
          <div className="col-12 col-md-4">
            <Card>
              <Card.Body>
                <div className="pricing_btn">
                  {Subscription_list &&
                    Subscription_list.map((interest, index) => {
                      return (
                        <>
                          <ButtonSection
                            setButtonState={setButtonState}
                            buttonState={buttonState}
                            interest={interest}
                            index={index}
                          />
                        </>
                      );
                    })}
                </div>
                <Form.Group className="dropdown_bg_grey">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="form-label"
                    >
                      {selectPrice ? selectPrice : "Other Plan"}
                      <IoMdArrowDropdown />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow">
                      <Dropdown.Item
                        onClick={() => setSelectPrice("1000 (5%)")}
                      >
                        1000 (5%)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setSelectPrice("1200 (15%)")}
                      >
                        1200 (15%)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setSelectPrice("1400 (20%)")}
                      >
                        1400 (20%)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setSelectPrice("1600 (25%)")}
                      >
                        1600 (25%)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
          {/* <div className="col-12 col-md-4">
            <Card>
              <Card.Body>
                <div className="pricing_btn">
                  {Subscription_list &&
                    Subscription_list.map((interest, index) => {
                      if (index <= 9) {
                        return null;
                      }
                      return (
                        <>
                          <ButtonSection
                            setButtonState={setButtonState}
                            buttonState={buttonState}
                            interest={interest}
                            index={index}
                          />
                        </>
                      );
                    })}
                </div>
              </Card.Body>
            </Card>
          </div> */}
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <Card>
              <Card.Body className="proceed_btn">
                <Form.Group as={Row} className="mb-0">
                  <Form.Label column sm="2">
                    Your Investment
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="lastName"
                      type="number"
                      ref={register()}
                    />
                  </Col>
                  <Col sm="2">
                    {/* <Button type="button">Proceed</Button> */}
                    <PaymentModal />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Form>
    </div>
  );
}
function ButtonSection(props) {
  let { index } = props;
  return (
    <>
      <div
        data-for="credits"
        data-tip="You will recieve 15 credits."
        data-iscapture="true"
      >
        <Button
          type="button"
          key={index}
          onClick={() => props.setButtonState(props.interest.id)}
          className={`cursor ${
            props.buttonState === props.interest.id ? "active" : ""
          }`}
        >
          {props.interest.text}
        </Button>
      </div>
      <ReactTooltip
        id="credits"
        place="top"
        type="dark"
        effect="solid"
        multiline={true}
      />
    </>
  );
}
class PaymentPlan extends React.Component {
  state = {
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div id="PaymentForm">
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
        />
        <Form>
          <Form.Group className="container mt-2">
            <div className="row">
              <div className="col-12">
                <Form.Control
                  className="my-2  edutech-payment-inout-field"
                  name="number"
                  type="number"
                  maxlength="16"
                  autocomplete="off"
                  placeholder="Enter Valid number"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-12">
                <Form.Control
                  className="my-2  edutech-payment-inout-field"
                  name="name"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter Your Name"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-6">
                <Form.Control
                  className="mt-2  edutech-payment-inout-field"
                  name="expiry"
                  type="number"
                  maxlength={"4"}
                  autocomplete="off"
                  placeholder="Enter Expiry number"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-6">
                <Form.Control
                  className="mt-2  edutech-payment-inout-field"
                  name="cvc"
                  type="number"
                  maxlength="3"
                  autocomplete="off"
                  placeholder="Enter CVC  number"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
function PricingPaymentModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Payment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaymentPlan />
      </Modal.Body>
      <div className="d-flex justify-content-center align-items-center mb-4">
        <Button className="payment-primary" onClick={props.onHide}>
          {" "}
          Submit
        </Button>
      </div>
    </Modal>
  );
}
function PaymentModal() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Proceed
      </Button>

      <PricingPaymentModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
