import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function getAllCountry() {
  return axios.get(`${APP_SETTINGS.API_PATH.COUNTRY.getAllCountry}`);
}
export function countryByName(data) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COUNTRY.countryByName}?name=${data}`
  );
}
export function getIndustry(lookupType ) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COUNTRY.getIndustry}?lookupType=${lookupType}`
  );
}
