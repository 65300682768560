import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Popover,
  OverlayTrigger,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import Slider from "react-slick";
import { ErrorMessage, FieldError, Loader } from "../../assets";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../config";
import {
  Create,
  GetInProgressCourseList,
  GetPurchasedCourses,
  GetRelatedCourses,
  Remove,
} from "../../redux/actions";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCheck } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { MdFeedback } from "react-icons/md";
import { useForm } from "react-hook-form";
import { CreateFeedback } from "../../redux/actions";

export default function Courses() {
  let auth = useAuth();
  const dispatch = useDispatch();
  const setCategories = useRef();
  const setCategories2 = useRef();
  useEffect(() => {
    document.title = "My Courses || EduTech";
    dispatch(GetPurchasedCourses(auth));
    dispatch(GetRelatedCourses(auth));
    dispatch(GetInProgressCourseList(auth));
  }, []);
  let purchaseCourseValue = useSelector((state) => state.courses);
  let relatedCourseData =
    purchaseCourseValue && purchaseCourseValue.getRelatedCourses;
  let purchasedCourses =
    purchaseCourseValue && purchaseCourseValue.getPurchasedCourses;
  let InProgressCourses =
    purchaseCourseValue && purchaseCourseValue.getInProgressCourseList;
  function nextslide() {
    setCategories.current.slickNext();
  }
  function prevslide() {
    setCategories.current.slickPrev();
  }
  function nextslide1() {
    setCategories2.current.slickNext();
  }
  function prevslide1() {
    setCategories2.current.slickPrev();
  }
  var myProgress = {
    centerMode: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 3,
    dots: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings = {
    centerMode: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 3,
    dots: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let history = useHistory();
  function MyProgressFunction(e, list) {
    history.push({
      pathname: PATH.COURSES_BREAKTHROUGH,
      state: {
        myprogressCourseDataList: list,
        Courses: "MYPROGRESSBREAKTHROUGH",
      },
    });
    e.stopPropagation();
  }

  return (
    <>
      <ToastContainer />
      <div className="myCourses">
        {/* <div className="container-fluid">
          <div className="d-md-flex align-items-center justify-content-between main_header "></div>

          <div className="firstcard mt-3 pendingCourses  position-relative">
            <div className="container-fluid">
              <h2 className="progresss">My Progress</h2>
              {InProgressCourses.length === 0 ? (
                <h4 className="text-center  pt-4">No Record Found</h4>
              ) : (
                <>
                  <div className=" icons right-icon prev-arrow-position Myprogress-course-icons ml-lg-n3 cursor">
                    <BsArrowLeftShort onClick={prevslide1} />
                  </div>
                  <div className=" icons edutech-icon right-icon Myprogress-course-icons cursor">
                    <BsArrowRightShort onClick={nextslide1} />
                  </div>
                  <div className="mt-3 margin_set_progress">
                    {purchaseCourseValue &&
                      purchaseCourseValue.getInProgressCourseListLoading ===
                        true &&
                      purchaseCourseValue.getInProgressCourseListSuccess ===
                        false &&
                      purchaseCourseValue.getInProgressCourseListFailure ===
                        false && <Loader />}
                    {purchaseCourseValue &&
                      purchaseCourseValue.getInProgressCourseListLoading ===
                        false &&
                      purchaseCourseValue.getInProgressCourseListSuccess ===
                        false &&
                      purchaseCourseValue.getInProgressCourseListFailure ===
                        true && (
                        <ErrorMessage
                          message={
                            purchaseCourseValue.getInProgressCourseListError
                          }
                        />
                      )}
                    {purchaseCourseValue &&
                      purchaseCourseValue.getInProgressCourseListLoading ===
                        false &&
                      purchaseCourseValue.getInProgressCourseListSuccess ===
                        true &&
                      purchaseCourseValue.getInProgressCourseListFailure ===
                        false && (
                        <Slider {...myProgress} ref={setCategories2}>
                          {InProgressCourses.map((list, index) => {
                            return (
                              <>
                                <div
                                  onClick={(e) => {
                                    MyProgressFunction(e, list);
                                  }}
                                >
                                  <MyProgressCourseBody
                                    list={list}
                                    index={index}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </Slider>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div> */}

        <div className="pendingCourses mt-3 position-relative">
          <div className="container-fluid">
            <h2 className="progresss">My Progress</h2>
            {InProgressCourses.length === 0 ? (
              <h4 className="text-center  pt-4">No Record Found</h4>
            ) : (
              <>
                <div className=" icons right-icon">
                  <BsArrowRightShort onClick={nextslide1} />
                </div>
                <div className=" icons right-icon prev-arrow-position ml-1 cursor">
                  <BsArrowLeftShort onClick={prevslide1} />
                </div>

                {purchaseCourseValue &&
                  purchaseCourseValue.getPurchasedCoursesFailure === true && (
                    <ErrorMessage
                      message={purchaseCourseValue.getInProgressCourseListError}
                    />
                  )}
                {purchaseCourseValue &&
                  purchaseCourseValue.getInProgressCourseListSuccess ===
                    true && (
                    <>
                      <div className="margin_set_progress">
                        <Slider {...myProgress} ref={setCategories2}>
                          {InProgressCourses.map((list, index) => {
                            return (
                              <>
                                <div
                                  onClick={(e) => {
                                    MyProgressFunction(e, list);
                                  }}
                                >
                                  <MyProgressCourseBody
                                    list={list}
                                    index={index}
                                  />
                                </div>
                              </>
                            );
                          })}
                        </Slider>
                      </div>
                    </>
                  )}
              </>
            )}
          </div>
        </div>

        <div className="pendingCourses relatedbackground mt-3 position-relative">
          <div className="container-fluid">
            <h2 className="progresss">My Purchased Courses</h2>
            {purchasedCourses.length === 0 ? (
              <h4 className="text-center  pt-4">No Record Found</h4>
            ) : (
              <>
                <div className=" icons right-icon">
                  <BsArrowRightShort onClick={nextslide} />
                </div>
                <div className=" icons right-icon prev-arrow-position ml-1 cursor">
                  <BsArrowLeftShort onClick={prevslide} />
                </div>

                {purchaseCourseValue &&
                  purchaseCourseValue.getPurchasedCoursesLoading === false &&
                  purchaseCourseValue.getPurchasedCoursesSuccess === false &&
                  purchaseCourseValue.getPurchasedCoursesFailure === true && (
                    <ErrorMessage
                      message={purchaseCourseValue.getPurchasedCoursesError}
                    />
                  )}
                {purchaseCourseValue &&
                  purchaseCourseValue.getPurchasedCoursesLoading === false &&
                  purchaseCourseValue.getPurchasedCoursesSuccess === true &&
                  purchaseCourseValue.getPurchasedCoursesFailure === false && (
                    <>
                      <div className="margin_set_progress">
                        <Slider {...settings} ref={setCategories}>
                          {purchasedCourses.map((list, index) => {
                            return (
                              <Link
                                to={{
                                  pathname: PATH.COURSE_PREVIEW,
                                  state: {
                                    purchasedCourseDataList: list,
                                    Courses: "PURCHASEDCOURSES",
                                  },
                                }}
                              >
                                <Card className="edutech-dashboard-second-card mt-3">
                                  <Card.Body className="p-3">
                                    <div className="header-top-section">
                                      <div className="bg-white edutech-dashboard-icon">
                                        <img
                                          // src={IMAGES.IPO}
                                          src={list.coursePreviewPictureURL}
                                          className="edutech-course-img-size"
                                          alt="img"
                                        />
                                        {/* <BsFillFileEarmarkTextFill size={35} /> */}
                                      </div>
                                      <div className="d-block w-100 left-side">
                                        <div className="d-flex align-item-center justify-content-between">
                                          <h4 className="mb-0">
                                            <b>
                                              {/* MASTERING <br /> CAPITAL WISDOM */}
                                              {list.courseSubtitle}
                                            </b>
                                          </h4>
                                          {/* <HiDotsHorizontal className="cursor" size={28} /> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-4">
                                      <hr className="w-100 my-2" />
                                      <h2 className="mb-0 longtextdottedone">
                                        {/* Learn Python Programming Masterclass */}
                                        {list.courseTitle}
                                      </h2>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Link>
                            );
                          })}
                        </Slider>
                      </div>
                    </>
                  )}
              </>
            )}
          </div>
        </div>

        <div className=" related-courses  pt-3 pb-4 pl-2 position-relative">
          <div className="container-fluid">
            <h2 className="mb-0 mt-1 ml-2 fw-800 ">
              {" "}
              Courses That People In Your Industry Are Currently Learning
            </h2>
            {/* <p className="topcourses mb-0 ml-2 mt-3">
            Recommended courses based on your topic selection
          </p> */}

            <div className="firstcard mt-3  position-relative">
              {/* <div className=" icons left-icon d-none">
                <BsArrowLeftShort />
              </div>
              <div className=" icons right-icon cursor">
                <BsArrowRightShort onClick={nextslide2} />
              </div> */}
              {/* <Slider {...settings} ref={setCategories2}>
                {list3.map((list) => {
                  return (
                    <RelatedCoursesBody list={list} SetExplore={SetExplore} />
                  );
                })}
              </Slider> */}
              {relatedCourseData.length === 0 ? (
                <h4 className="text-center  pt-4">No Record Found</h4>
              ) : (
                <>
                  <div className="row">
                    {purchaseCourseValue &&
                      purchaseCourseValue.getRelatedCoursesLoading === false &&
                      purchaseCourseValue.getRelatedCoursesSuccess === false &&
                      purchaseCourseValue.getRelatedCoursesFailure === true && (
                        <ErrorMessage
                          message={purchaseCourseValue.getRelatedCoursesError}
                        />
                      )}
                    {purchaseCourseValue &&
                      purchaseCourseValue.getRelatedCoursesLoading === false &&
                      purchaseCourseValue.getRelatedCoursesSuccess === true &&
                      purchaseCourseValue.getRelatedCoursesFailure ===
                        false && (
                        <>
                          {relatedCourseData.map((list) => {
                            return <RelatedCoursesBody list={list} />;
                          })}
                        </>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function MyProgressCourseBody(props) {
  // function createMyProgress() {
  //   history.push({
  //     pathname: PATH.COURSES_BREAKTHROUGH,
  //     state: {
  //       relatedCourseData: props.list,
  //       Courses: "RELATEDCOURSES",
  //     },

  //   },
  //     window.location.reload(true),
  //     window.location.reload(false),
  //   );
  //   // history(PATH.COURSES_BREAKTHROUGH)

  // }

  return (
    <>
      <Card className="edutech-dashboard-second-card mt-3" key={props.index}>
        <Card.Body className="p-3">
          <div className="header-top-section">
            <div className="bg-white edutech-dashboard-icon">
              <img
                // src={IMAGES.IPO}
                src={props.list.coursePreviewPictureURL}
                className="edutech-course-img-size"
                alt="img"
              />
              {/* <div className="feedback-icon-styling">
                <MdFeedback
                  className=""
                  size={35}
                />
              </div> */}
              <div className="feedback-icon-styling">
                <FeedbackIconFunction
                  // addWishList={addWishList}
                  // setAddWishList={setAddWishList}
                  listData={props.list}
                />
              </div>
            </div>
            <div className="d-block w-100 left-side">
              <div className="d-flex align-item-center justify-content-between">
                <h4 className="mb-0">
                  <b>
                    {/* MASTERING <br /> CAPITAL WISDOM */}
                    {props.list.courseSubtitle}
                  </b>
                </h4>
                {/* <HiDotsHorizontal className="cursor" size={28} /> */}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <hr className="w-100 my-2" />
            <h2 className="mb-0 longtextdottedone">
              {/* Learn Python Programming Masterclass */}
              {props.list.courseTitle}
            </h2>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
function RelatedCoursesBody(props) {
  const [addWishList, setAddWishList] = useState(null);
  let history = useHistory();
  function moveToNext() {
    history.push({
      pathname: PATH.COURSE_PREVIEW,
      state: {
        relatedCourseData: props.list,
        Courses: "RELATEDCOURSES",
      },
    });
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title className="popover-headre" data-toggle="popover" as="h3">
        {props.list.courseTitle}
      </Popover.Title>
      <Popover.Content>
        <p>
          {props.list.courseDescription
            ? props.list.courseDescription
            : "No Description Yet"}
        </p>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">2.5 hours on demand</p>
        </div>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">6 articles</p>
        </div>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">11 downloadable resources</p>
        </div>
        <div className="d-flex  ">
          <AiOutlineCheck size={16} className="mt-1" />
          <p className="ml-4 ">Full lifetime access</p>
        </div>
        <div className="text-center pb-3">
          <button className="btn-add-to-cart" onClick={() => moveToNext()}>
            View
          </button>
        </div>
      </Popover.Content>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="auto"
        rootClose
        overlay={popover}
      >
        <div className="col-md-3 mt-2 cursor">
          <Card className="mx-2 my-2">
            <Card.Body className="p-0">
              <div className="Courseiframeheight">
                <div className="embed-responsive embed-responsive-16by9 video-height wishlist_icon_img">
                  <img
                    className="img-fluid embed-responsive-item"
                    src={props.list.coursePreviewPictureURL}
                    alt="img"
                  />
                  <div className="wishlist_border">
                    <WishListIcon
                      addWishList={addWishList}
                      setAddWishList={setAddWishList}
                      listData={props.list}
                    />
                  </div>
                </div>
              </div>
              <div className="pl-2 py-2">
                <p className="firstpara text-left mb-0 longtextdottedone">
                  {" "}
                  {props.list.courseTitle}
                </p>
                <p className="text-muted text-left mb-0">
                  {" "}
                  {props.list.providerName}
                </p>
                <p className="firstpara text-left mt-0 pt-0">
                  {" "}
                  $
                  {props.list.price - props.list.discountedPrice
                    ? props.list.price - props.list.discountedPrice
                    : "Free"}{" "}
                  {props.list.price === 0 ? (
                    ""
                  ) : (
                    <span className=" ml-3 mb-0 pricing-discount-line text-muted float-right pr-2">
                      ${props.list.discountedPrice}
                    </span>
                  )}
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </OverlayTrigger>
    </>
  );
}
function WishListIcon(props) {
  let auth = useAuth();
  let dispatch = useDispatch();
  function wishlist(e, val) {
    e.stopPropagation();
    props.setAddWishList(val);
    if (props.addWishList !== true) {
      dispatch(Create(props.listData.courseId, auth, Notification));
    } else {
      dispatch(Remove(props.listData.courseId, auth, NotificationRemove));
    }
  }
  function Notification(data) {
    toast.success("Added to wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  function NotificationRemove(data) {
    toast.error("Removed from wishlist", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      {props.addWishList === true ? (
        <RiHeartFill onClick={(e) => wishlist(e, false)} />
      ) : (
        <RiHeartLine onClick={(e) => wishlist(e, true)} />
      )}
    </>
  );
}
function FeedbackIconFunction(props) {
  const [modalShow, setModalShow] = React.useState(false);
  function wishlist(e) {
    e.stopPropagation();
  }
  return (
    <>
      <MdFeedback
        onClick={(e) => {
          wishlist(e);
          setModalShow(true);
        }}
      />
      <FeedBackModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        listData={props.listData}
      />
    </>
  );
}
function FeedBackModal(props) {
  function FunctionForStop(e) {
    return e.stopPropagation();
  }
  let dispatch = useDispatch();
  const [description, setDescription] = useState(null);
  const [subject, setSubject] = useState("");
  const { register, handleSubmit, errors } = useForm();
  let auth = useAuth();
  function onSubmit(data) {
    let finalData = {
      subject: data?.subject,
      description: data?.description,
      courseId: props?.listData?.courseId,
    };
    dispatch(CreateFeedback(finalData, auth, Notification));
  }
  function Notification() {
    toast.success("Feedback has been sent to  the course provider", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    setSubject(null);
    setDescription(null);
    props.onHide();
  }
  function ModalClickFunction(e) {
    return e.stopPropagation();
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        onClick={(e) => ModalClickFunction(e)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">FeedBack</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Subject:</Form.Label>
              <Form.Control
                className="mt-1 px-4 py-0 "
                name="subject"
                type="text"
                placeholder="Enter Your subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                style={{
                  borderColor: errors && errors.subject ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
              {errors.subject && (
                <FieldError message={"This Field is Required"} />
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Description:</Form.Label>
              <Form.Control
                className="mt-1"
                name="description"
                as="textarea"
                maxlength="300"
                pattern="(/[^\s]+/,)"
                rows={3}
                required
                requiredMessage="This field is required."
                errorTarget="under"
                placeholder="Enter Your Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                style={{
                  borderColor: errors && errors.description ? "#a80000" : "",
                }}
                ref={register({ required: true })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className="form-group text-right mb-0">
              <Button
                type="submit"
                className="download-btn mb-2 bg-default-light mt-3 ml-4"
                variant="primary"
                onClick={(e) => {
                  FunctionForStop(e);
                  // props.onHide();
                }}
              >
                Send
              </Button>
            </div>
            {/* <Button
          onClick={(e) => {
            props.onHide();
            FunctionForStop(e);
          }}
        >
          Close
        </Button> */}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
