import { ErrorMessage, IMAGES, Loader } from "../../assets";
import { PATH } from "../../config";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetUserProfile, GetUserStudentProfile } from "../../redux/actions";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
export default function ProfileShow() {
  let history = useHistory();
  return (
    <div className="container">
      <div className="card">
        {history.location.pathname === PATH.STUDENT_PROFILESHOW && (
          <StudentProfile />
        )}
        {history.location.pathname === PATH.TEACHER_PROFILESHOW && (
          <TeacherProfile />
        )}
      </div>
      {history.location.pathname === PATH.TEACHER_PROFILESHOW && (
        <div className="card">
          <TeacherCommission />
        </div>
      )}
    </div>
  );
}
function StudentProfile() {
  let auth = useAuth();
  let dispatch = useDispatch();
  let profile = useSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(GetUserStudentProfile(auth));
  }, [dispatch]);
  return (
    <>
      {profile &&
        profile.getUserStudentProfileLoading === true &&
        profile.getUserStudentProfileSuccess === false &&
        profile.getUserStudentProfileFailure === false && <Loader />}
      {profile &&
        profile.getUserStudentProfileLoading === false &&
        profile.getUserStudentProfileSuccess === false &&
        profile.getUserStudentProfileFailure === true && (
          <ErrorMessage message={profile.getUserStudentProfileError} />
        )}
      {profile &&
        profile.getUserStudentProfileLoading === false &&
        profile.getUserStudentProfileSuccess === true &&
        profile.getUserStudentProfileFailure === false && (
          <>
            {profile &&
              profile.getUserStudentProfile &&
              profile.getUserStudentProfile.map((data, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-12 col-md-5 col-sm-12 text-center Profile">
                      <img
                        className="card-img-top img-fluid"
                        src={data && data.image ? data.image : IMAGES.AVATAR}
                        alt=""
                      />
                      <p className="proNameStyling text-white">
                        {data && data.fullName ? data.fullName : "N/A"}
                      </p>
                      <p className="proNameStyling text-white mb-0">
                        {data && data.email ? data.email : "N/A"}
                      </p>
                    </div>
                    <div className="col-12 col-md-7 col-sm-12 profile_info">
                      <h3>Information</h3>
                      <hr />
                      <div className="row">
                        <div className="col-sm-6">
                          <h4>Industry</h4>
                          <h6 className="text-muted">
                            {data && data.industryWorkName
                              ? data.industryWorkName
                              : "N/A"}
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <h4>Job title</h4>
                          <h6 className="text-muted">
                            {data && data.jobTitle ? data.jobTitle : "N/A"}
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <h4>Experience</h4>
                          <h6 className="text-muted">
                            {data && data.experience ? data.experience : "N/A"}
                            <span className="pl-1">years</span>
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <h4>Area of interests</h4>
                          <h6 className="text-muted">
                            {data &&
                              data.studentInterestDto &&
                              data.studentInterestDto.map((industry, index) => {
                                return (
                                  <>
                                    {industry && industry.interestName
                                      ? `${industry.interestName} ${
                                          index + 1 <
                                          data.studentInterestDto.length
                                            ? ", "
                                            : ""
                                        }`
                                      : "N/A"}
                                  </>
                                );
                              })}
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <h4>About me</h4>
                          <h6 className="text-muted">
                            {data && data.aboutUs ? data.aboutUs : "N/A"}
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        {profile &&
                          profile.getUserStudentProfileLoading === false && (
                            <Link
                              to={PATH.STUDENT_PROFILEEDIT}
                              className="text-white w-100 text-center"
                            >
                              <button
                                type="button"
                                className="btn btn-primary border-0"
                              >
                                Edit Profile
                              </button>
                            </Link>
                          )}
                        {profile &&
                          profile.getUserStudentProfileLoading === true && (
                            <Loader />
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        )}
    </>
  );
}
function TeacherProfile() {
  let auth = useAuth();
  let dispatch = useDispatch();
  let profile = useSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(GetUserProfile(auth));
  }, [dispatch]);
  return (
    <>
      {profile &&
        profile.getUserProfileLoading === true &&
        profile.getUserProfileSuccess === false &&
        profile.getUserProfileFailure === false && <Loader />}
      {profile &&
        profile.getUserProfileLoading === false &&
        profile.getUserProfileSuccess === false &&
        profile.getUserProfileFailure === true && (
          <ErrorMessage message={profile.getUserProfileError} />
        )}
      {profile &&
        profile.getUserProfileLoading === false &&
        profile.getUserProfileSuccess === true &&
        profile.getUserProfileFailure === false && (
          <>
            {profile &&
              profile.getUserProfile &&
              profile.getUserProfile.map((data, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-12 col-md-5 col-sm-12 text-center Profile">
                      <img
                        className="card-img-top img-fluid"
                        src={data && data.photo ? data.photo : IMAGES.AVATAR}
                        alt=""
                      />
                      <p className="proNameStyling text-white">
                        {data && data.fullName ? data.fullName : "N/A"}
                      </p>
                      <p className="proNameStyling text-white mb-0">
                        {data && data.email ? data.email : "N/A"}
                      </p>
                    </div>
                    <div className="col-12 col-md-7 col-sm-12 profile_info">
                      <h3>Information</h3>
                      <hr />
                      <div className="row">
                        <div className="col-sm-6">
                          <h4> Provider Status</h4>
                          <h6 className="text-muted">
                            {data &&
                            data.providerStatus &&
                            data.providerStatus 
                              ? data.providerStatus
                              : "N/A"}
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <h4>Region</h4>
                          <h6 className="text-muted">
                            {data && data.region
                              ? data.region
                              : "N/A"}
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <h4>Experience</h4>
                          <h6 className="text-muted">
                            {data && data.experience ? data.experience : "N/A"}

                            <span className="pl-1">years</span>
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <h4>Location</h4>
                          <h6 className="text-muted">
                            {data && data.locationName
                              ? data.locationName
                              : "N/A"}
                          </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <h4>About me</h4>
                          <h6 className="text-muted">
                            {data && data.aboutUs ? data.aboutUs : "N/A"}
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-3">
                        {profile && profile.getUserProfileLoading === false && (
                          <Link
                            to={PATH.TEACHER_PROFILEEDIT}
                            className="text-white w-100 text-center"
                          >
                            <button
                              type="button"
                              className="btn btn-primary border-0"
                            >
                              Edit Profile
                            </button>
                          </Link>
                        )}
                        {profile && profile.getUserProfileLoading === true && (
                          <Loader />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        )}
    </>
  );
}
function TeacherCommission() {
  return (
    <div className="commission_section">
      <div className="row mb-3">
        <div className="col-12 col-md-12 col-sm-12 text-center">
          <h3>Commission: 15%</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12 col-sm-12">
          <div className="checkbox-border">
            <input type="checkbox" id="agree" defaultChecked />
            <label htmlFor="agree">
              It is an agreement between a course provider and an admin. Terms
              and conditions are usually determined by the admin and outline
              rules and regulations associated with the usage of the website or
              app.{" "}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
