import React from "react";
import { ChatButton, Header } from ".";
import { ChatButtonAdmin } from "..";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
// import ChatButton from ""
import Sidebar from "./SideBar";
// import swal from 'sweetalert2'
export function ParentComponentwithoutContainer({ children, ...rest }) {
  let auth = useAuth();
  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper">{children}</div>
{/* 
      {auth.edu_tech_user.roleId === 3 && (
        <>
          <ChatButton />
        </>
      )}
      {auth.edu_tech_user.roleId === 2 && (
        <>
          <ChatButton />
        </>
      )}
      {auth.edu_tech_user.roleId === 1 && (
        <>
          <ChatButtonAdmin />
        </>
      )} */}
    </div>
  );
}
