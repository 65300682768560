import { request, success, failure, STAFF_MEMBER_REQUEST } from "./utilities";
import { getStaffProfile, createStaffMember, updateStaffMember } from "../api";

export function CreateStaffMember(data, auth, Notification, moveToNext) {
  return (dispatch) => {
    dispatch(request(STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_REQUEST));
    createStaffMember(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_SUCCESS,
              response.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            STAFF_MEMBER_REQUEST.CREATE_STAFF_MEMBER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UpdateStaffMember(data, auth, Notification, moveToNext) {
  return (dispatch) => {
    dispatch(request(STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_REQUEST));
    updateStaffMember(data, auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_SUCCESS,
              response.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            STAFF_MEMBER_REQUEST.UPDATE_STAFF_MEMBER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetStaffProfile(auth) {
  return (dispatch) => {
    dispatch(request(STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_REQUEST));
    getStaffProfile(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_SUCCESS,
              response.data
            )
          );
        } else {
          dispatch(
            failure(
              STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            STAFF_MEMBER_REQUEST.GET_STAFF_MEMBER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
