import React, { useState, useEffect } from "react";
import { Card, Col, Dropdown, ProgressBar, Row } from "react-bootstrap";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { FaUserGraduate, FaUserTie } from "react-icons/fa";
import { IMAGES } from "../../../assets";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import { RiMedalLine } from "react-icons/ri";
import { ColumnChart } from "./ColumnChart";
import { IoMdArrowDropdown } from "react-icons/io";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { GetproviderDashboardStatusOfCourses, GetProviderDashboardTiles, ProviderDashboardStatusOfCourses } from "../../../redux/actions/dashboardTilesAction";
import { useDispatch, useSelector } from "react-redux";
import { providerDashboardStatusOfCourses } from "../../../redux/api";
export default function TeacherDashboard() {
  let dispatch = useDispatch();
  let GetProviderTilesCount = useSelector((state) => state.dashboardTiles);
  let data = GetProviderTilesCount && GetProviderTilesCount.getProviderDashboardTiles;
  let statusOfCourseData = GetProviderTilesCount && GetProviderTilesCount.providerDashboardStatusOfCourses;
  const [isSelected, setIsSelected] = useState("")
  useEffect(() => {
    // Update the document title using the browser API
    dispatch(GetProviderDashboardTiles(auth));
    dispatch(ProviderDashboardStatusOfCourses(auth));
    document.title = `Dashboard || EduTech`;
  }, []);
  console.log("akjshdkjsahd", GetProviderTilesCount)
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Dashboard || EduTech`;
    providerDashboardStatusOfCourses(auth).then((response) => {
      console.log("response=>>>", response)
      setIsSelected({
        flag: response.data
      })
    }).catch((error) => {
      console.log("error=>>>", error)

    })
  }, []);
  let auth = useAuth();
  let teacherName =
    auth.edu_tech_user.firstName + " " + auth.edu_tech_user.lastName;
  const [filterSelect, setFilterSelect] = useState(null);
  return (
    <div className="Maindashboard">
      <div className="d-sm-flex justify-content-sm-between ">
        <div className="d-flex heading pt-1">
          <h2 className="mb-0 heading-fs">Welcome </h2>
          <h2 className="ml-2 h2color mb-0 heading-fs">
            {" "}
            {teacherName ? teacherName : "N/A"}
          </h2>
        </div>
        {/* <div className="create position-relative mt-2 mt-sm-0">
          <Link to={PATH.TEACHER_CREATE_COURSE}>
            <AiOutlinePlus className="plusIcon" />
            <button className="btn ">Create Course</button>
          </Link>
        </div> */}
      </div>
      <hr />
      <Row>
        <Col md={6} sm={12} lg={6} xl={3}>
          <Link to={PATH.TEACHER_TOTAL_COURSES}>
            <div className="card firstcard p-3">
              <div className="d-flex align-items-center">
                <div className="iconPd">
                  <BsFillFileEarmarkTextFill className="texticon" />
                </div>
                <div className="ml-3  ml-lg-3 ml-xl-3">
                  <p className="mb-0 para-alpha ">Total Courses</p>
                  <p className="mb-0 para-num font-weight-bold ">{data.totalCourses}</p>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} sm={12} lg={6} xl={3}>
          <Link to={PATH.TEACHER_TOTAL_SALE}>
            <div className="card firstcard p-3 mt-2 mt-md-0">
              <div className="d-flex align-items-center">
                <div className="iconPd-second">
                  <FaUserTie className="texticon" />
                </div>
                <div className="ml-3 ml-lg-3 ml-xl-3">
                  <p className="mb-0 para-alpha">Total Sales</p>
                  <p className="mb-0 para-num font-weight-bold ">{data.totalSales}</p>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} sm={12} lg={6} xl={3}>
          <Link to={PATH.TEACHER_TOTAL_CERTIFICATE}>
            <div className="card firstcard p-3 mt-2  mt-md-0">
              <div className="d-flex align-items-center">
                <div className="iconPd">
                  <RiMedalLine className="texticon" />
                </div>
                <div className="ml-3  ml-lg-3 ml-xl-3">
                  <p className="mb-0 para-alpha">Certifications Awarded</p>
                  <p className="mb-0 para-num font-weight-bold ">{data.certificationsAwarded}</p>
                </div>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} sm={12} lg={6} xl={3}>
          <Link to={PATH.TEACHER_TOTAL_STUDENT}>
            <div className="card firstcard p-3 mt-2  mt-md-0">
              <div className="d-flex align-items-center">
                <div className="iconPd-second">
                  <FaUserGraduate className="texticon" />
                </div>
                <div className="ml-3  ml-lg-3 ml-xl-3">
                  <p className="mb-0 para-alpha">Total Students</p>
                  <p className="mb-0 para-num font-weight-bold ">{data.totalStudents}</p>
                </div>
              </div>
            </div>
          </Link>
        </Col>
      </Row>

      <div className="d-sm-flex justify-content-sm-between ">
        <div className="d-flex heading pt-1">
          <h2 className="mb-0 heading-fs">
            What product do you want to create?{" "}
          </h2>
        </div>
      </div>
      <hr />
      <div className="row mt-4">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 pt-2 edutech-card-padding cursor">
          <Card className="bg-color:white edutech-sm-margin ">
            <Card.Body>
              {/* <Link to={PATH.TEACHERCURRICULUMPUBLISH}> */}
              <Link to={PATH.TEACHERCOURSESGROUP}>
                <Card className="py-3 mb-0">
                  <Card.Img
                    variant="top"
                    src={IMAGES.GROUP}
                    class="img-fluid m-auto"
                  />
                  <Card.Body className="p-3">
                    <p className="edutech-card-course-text">Course</p>
                    <p className="px-3 mb-0 edutech-card-inner-text">
                      {" "}
                      With a course, you can build a curriculum for your
                      students that can be self-paced or guided directly by you,
                      the instructor.
                    </p>
                  </Card.Body>
                </Card>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 pt-2 edutech-card-padding cursor">
          <Card className="bg-color:white edutech-sm-margin ">
            <Card.Body>
              <Link to={PATH.COACHINGLIST}>
                <Card className="py-3 mb-0">
                  <Card.Img
                    variant="top"
                    src={IMAGES.FRAME}
                    class="img-fluid m-auto"
                  />
                  <Card.Body className="p-3">
                    <p className="edutech-card-course-text">Coaching</p>
                    <p className="px-3 mb-0 edutech-card-inner-text">
                      {" "}
                      With coaching, you can collaborate with your students
                      more, helping them reach milestones through scheduled
                      video calls.
                    </p>
                  </Card.Body>
                </Card>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pt-2">
          <Card className="edutech-sm-margin graph h-auto">
            <Card.Body>
              <div className="row mb-2 align-items-center">
                <div className="col-12 col-md-5">
                  <h3>{"Total Earnings"}</h3>
                </div>
                <div className="col-12 col-md-7 text-right">
                  {/* <div className="dropdown_bg_grey">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="text-left w-75"
                      >
                        {filterSelect ? filterSelect : "Select"}
                        <IoMdArrowDropdown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="shadow">
                        <Dropdown.Item
                          onClick={() => setFilterSelect("Monthly")}
                        >
                          Monthly
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => setFilterSelect("Yearly")}
                        >
                          Yearly
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                </div>
              </div>
              <ColumnChart />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="mt-md-2 mt-4 ml-xl-0">
        <Row>
          <Col md={6} className="position-relative">
            <div className="card form-card">
              <h3 className="mb-0">Status of Courses</h3>
              <div className="mt-2">
                <p className="mb-0">Published</p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="prgbar in-progress">
                    <ProgressBar now={isSelected?.flag?.publishedPercentage} />
                  </div>
                  <p className="mb-0 progress-styling">{isSelected?.flag?.publishedPercentage}%</p>
                </div>
              </div>
              <div className="mt-2">
                <p className="mb-0">Unpublished</p>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="prgbar completed">
                    <ProgressBar now={isSelected?.flag?.unPublishedPercentage} />
                  </div>
                  <p className="mb-0 progress-styling">{isSelected?.flag?.unPublishedPercentage}%</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="position-relative">
            <div className="card form-card">
              <h3 className="mb-0 d-flex justify-content-between">
                Reminders <span className="f-17 cursor">More</span>
              </h3>
              <div className="scroll-height-adjust">
                <p className="text-center pt-4">No Data Available</p>
                {/* <div className="mt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex  align-items-center">
                      <span class="avatar avatar-sm mr-2">
                        <img
                          className="avatar-img rounded-circle"
                          alt="User"
                          src={IMAGES.AVATAR}
                        />
                      </span>
                      <div>
                        <h4 className="w-100 mt-3">
                          Effective interactive teaching strategies to encourage
                          speech
                        </h4>
                        <div className="d-flex  align-items-center">
                          <span className="d-flex  align-items-center text-muted cursor">
                            5
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="mb-0 text-muted">Today 03:45 PM</p>
                  </div>
                </div> */}

              </div>
            </div>
          </Col>
        </Row>
      </div>

      <br></br>
    </div>
  );
}
