import { APP_SETTINGS } from "../../config";
import axios from "axios";


export function checkEmail(emailAddress) {
  return axios.get(`${APP_SETTINGS.API_PATH.FORGET_PASSWORD.checkEmail}?emailAddress=${emailAddress}`, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  }
  );
}
export function checkCode(emailAddress, code) {
  return axios.get(`${APP_SETTINGS.API_PATH.FORGET_PASSWORD.checkCode}?emailAddress=${emailAddress}&code=${code} `, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  }
  );
}
export function put(data) {
  return axios.post(APP_SETTINGS.API_PATH.FORGET_PASSWORD.put, data, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}