import { ACCOUNTS_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  registerProviderLoading: false,
  registerProviderSuccess: false,
  registerProviderFailure: false,
  registerProviderError: null,
  registerProvider: [],

  registerStudentLoading: false,
  registerStudentSuccess: false,
  registerStudentFailure: false,
  registerStudentError: null,
  registerStudent: [],

  getCreditsLoading: false,
  getCreditsSuccess: false,
  getCreditsFailure: false,
  getCreditsError: null,
  getCredits: [],

  socialMediaLoading: false,
  socialMediaSuccess: false,
  socialMediaFailure: false,
  socialMediaError: null,
  socialMedia: [],

  emailExistLoading: false,
  emailExistSuccess: false,
  emailExistFailure: false,
  emailExistError: null,
  emailExist: [],

  loginLoading: false,
  loginSuccess: false,
  loginFailure: false,
  loginError: null,
  login: [],

  studentLoginLoading: false,
  studentLoginSuccess: false,
  studentLoginFailure: false,
  studentLoginError: null,
  studentLogin: [],

  getLinkedinTokenKeyLoading: false,
  getLinkedinTokenKeySuccess: false,
  getLinkedinTokenKeyFailure: false,
  getLinkedinTokenKeyError: null,
  getLinkedinTokenKey: [],
};

export const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACCOUNTS_REQUEST.REGISTER_PROVIDER_REQUEST:
      return {
        ...state,
        registerProviderLoading: true,
        registerProviderSuccess: false,
        registerProviderFailure: false,
        registerProviderError: null,
        registerProvider: [],
      };
    case ACCOUNTS_REQUEST.REGISTER_PROVIDER_SUCCESS:
      return {
        ...state,
        registerProviderLoading: false,
        registerProviderSuccess: true,
        registerProviderFailure: false,
        registerProviderError: null,
        registerProvider: action.payload,
      };
    case ACCOUNTS_REQUEST.REGISTER_PROVIDER_FAILURE:
      return {
        ...state,
        registerProviderLoading: false,
        registerProviderSuccess: false,
        registerProviderFailure: true,
        registerProviderError: action.payload,
      };

    case ACCOUNTS_REQUEST.REGISTER_STUDENT_REQUEST:
      return {
        ...state,
        registerStudentLoading: true,
        registerStudentSuccess: false,
        registerStudentFailure: false,
        registerStudentError: null,
        registerStudent: [],
      };
    case ACCOUNTS_REQUEST.REGISTER_STUDENT_SUCCESS:
      return {
        ...state,
        registerStudentLoading: false,
        registerStudentSuccess: true,
        registerStudentFailure: false,
        registerStudentError: null,
        registerStudent: action.payload,
      };
    case ACCOUNTS_REQUEST.REGISTER_STUDENT_FAILURE:
      return {
        ...state,
        registerStudentLoading: false,
        registerStudentSuccess: false,
        registerStudentFailure: true,
        registerStudentError: action.payload,
      };

    case ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_REQUEST:
      return {
        ...state,
        getLinkedinTokenKeyLoading: true,
        getLinkedinTokenKeySuccess: false,
        getLinkedinTokenKeyFailure: false,
        getLinkedinTokenKeyError: null,
        getLinkedinTokenKey: [],
      };
    case ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_SUCCESS:
      return {
        ...state,
        getLinkedinTokenKeyLoading: false,
        getLinkedinTokenKeySuccess: true,
        getLinkedinTokenKeyFailure: false,
        getLinkedinTokenKeyError: null,
        getLinkedinTokenKey: action.payload,
      };
    case ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_FAILURE:
      return {
        ...state,
        getLinkedinTokenKeyLoading: false,
        getLinkedinTokenKeySuccess: false,
        getLinkedinTokenKeyFailure: true,
        getLinkedinTokenKeyError: action.payload,
      };

    case ACCOUNTS_REQUEST.GET_CREDITS_REQUEST:
      return {
        ...state,
        getCreditsLoading: true,
        getCreditsSuccess: false,
        getCreditsFailure: false,
        getCreditsError: null,
        getCredits: [],
      };
    case ACCOUNTS_REQUEST.GET_CREDITS_SUCCESS:
      return {
        ...state,
        getCreditsLoading: false,
        getCreditsSuccess: true,
        getCreditsFailure: false,
        getCreditsError: null,
        getCredits: action.payload,
      };
    case ACCOUNTS_REQUEST.GET_CREDITS_FAILURE:
      return {
        ...state,
        getCreditsLoading: false,
        getCreditsSuccess: false,
        getCreditsFailure: true,
        getCreditsError: action.payload,
      };

    case ACCOUNTS_REQUEST.SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        socialMediaLoading: true,
        socialMediaSuccess: false,
        socialMediaFailure: false,
        socialMediaError: null,
        socialMedia: [],
      };
    case ACCOUNTS_REQUEST.SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        socialMediaLoading: false,
        socialMediaSuccess: true,
        socialMediaFailure: false,
        socialMediaError: null,
        socialMedia: action.payload,
      };
    case ACCOUNTS_REQUEST.SOCIAL_MEDIA_FAILURE:
      return {
        ...state,
        socialMediaLoading: false,
        socialMediaSuccess: false,
        socialMediaFailure: true,
        socialMediaError: action.payload,
      };

    case ACCOUNTS_REQUEST.EMAIL_EXIST_REQUEST:
      return {
        ...state,
        emailExistLoading: true,
        emailExistSuccess: false,
        emailExistFailure: false,
        emailExistError: null,
        emailExist: [],
      };
    case ACCOUNTS_REQUEST.EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        emailExistLoading: false,
        emailExistSuccess: true,
        emailExistFailure: false,
        emailExistError: null,
        emailExist: action.payload,
      };
    case ACCOUNTS_REQUEST.EMAIL_EXIST_FAILURE:
      return {
        ...state,
        emailExistLoading: false,
        emailExistSuccess: false,
        emailExistFailure: true,
        emailExistError: action.payload,
      };

    case ACCOUNTS_REQUEST.STUDENT_LOGIN_REQUEST:
      return {
        ...state,
        studentLoginLoading: true,
        studentLoginSuccess: false,
        studentLoginFailure: false,
        studentLoginError: null,
        studentLogin: [],
      };
    case ACCOUNTS_REQUEST.STUDENT_LOGIN_SUCCESS:
      return {
        ...state,
        studentLoginLoading: false,
        studentLoginSuccess: false,
        studentLoginFailure: true,
        studentLoginError: null,
        studentLogin: action.payload,
      };
    case ACCOUNTS_REQUEST.STUDENT_LOGIN_FAILURE:
      return {
        ...state,
        studentLoginLoading: false,
        studentLoginSuccess: false,
        studentLoginFailure: true,
        studentLoginError: null,
        studentLogin: action.payload,
      };
    case ACCOUNTS_REQUEST.STUDENT_LOGIN_RESET:
      return {
        ...state,
        studentLoginLoading: false,
        studentLoginSuccess: false,
        studentLoginFailure: false,
        studentLoginError: null,
      };

    case ACCOUNTS_REQUEST.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
        loginSuccess: false,
        loginFailure: false,
        loginError: null,
        login: [],
      };
    case ACCOUNTS_REQUEST.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginFailure: true,
        loginError: null,
        login: action.payload,
      };
    case ACCOUNTS_REQUEST.LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginFailure: true,
        loginError: null,
        login: action.payload,
      };
    case ACCOUNTS_REQUEST.LOGIN_RESET:
      return {
        ...state,
        loginLoading: false,
        loginSuccess: false,
        loginFailure: false,
        loginError: null,
      };

    default:
      return state;
  }
};
