import { DASHBOARD_TILES_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {


  getAdminDashboardTilesLoading: false,
  getAdminDashboardTilesSuccess: false,
  getAdminDashboardTilesFailure: false,
  getAdminDashboardTilesError: null,
  getAdminDashboardTiles: [],

  getTotalCoachingSalesLoading: false,
  getTotalCoachingSalesSuccess: false,
  getTotalCoachingSalesFailure: false,
  getTotalCoachingSalesError: null,
  getTotalCoachingSales: [],

  getTotalCoursesSalesLoading: false,
  getTotalCoursesSalesSuccess: false,
  getTotalCoursesSalesFailure: false,
  getTotalCoursesSalesError: null,
  getTotalCoursesSales: [],

  getProviderDashboardTilesLoading: false,
  getProviderDashboardTilesSuccess: false,
  getProviderDashboardTilesFailure: false,
  getProviderDashboardTilesError: null,
  getProviderDashboardTiles: [],

  getCertificationsAwardedListLoading: false,
  getCertificationsAwardedListSuccess: false,
  getCertificationsAwardedListFailure: false,
  getCertificationsAwardedListError: null,
  getCertificationsAwardedList: [],

  getCourseSalesListLoading: false,
  getCourseSalesListSuccess: false,
  getCourseSalesListFailure: false,
  getCourseSalesListError: null,
  getCourseSalesList: [],

  getCoachingSalesListLoading: false,
  getCoachingSalesListSuccess: false,
  getCoachingSalesListFailure: false,
  getCoachingSalesListError: null,
  getCoachingSalesList: [],

  getCourseStudentsListLoading: false,
  getCourseStudentsListSuccess: false,
  getCourseStudentsListFailure: false,
  getCourseStudentsListError: null,
  getCourseStudentsList: [],

  getCoachingStudentsListLoading: false,
  getCoachingStudentsListSuccess: false,
  getCoachingStudentsListFailure: false,
  getCoachingStudentsListError: null,
  getCoachingStudentsList: [],

  providerDashboardStatusOfCoursesLoading: false,
  providerDashboardStatusOfCoursesSuccess: false,
  providerDashboardStatusOfCoursesFailure: false,
  providerDashboardStatusOfCoursesError: null,
  providerDashboardStatusOfCourses: [],

  getQuotationLoading: false,
  getQuotationSuccess: false,
  getQuotationFailure: false,
  getQuotationError: null,
  getQuotation: [],

  adminDashboardTopCoursesGraphLoading: false,
  adminDashboardTopCoursesGraphSuccess: false,
  adminDashboardTopCoursesGraphFailure: false,
  adminDashboardTopCoursesGraphError: null,
  adminDashboardTopCoursesGraph: [],

  adminDashboardTopCategoriesGraphLoading: false,
  adminDashboardTopCategoriesGraphSuccess: false,
  adminDashboardTopCategoriesGraphFailure: false,
  adminDashboardTopCategoriesGraphError: null,
  adminDashboardTopCategoriesGraph: [],

  providerDashboardTotalEarningsLoading: false,
  providerDashboardTotalEarningsSuccess: false,
  providerDashboardTotalEarningsFailure: false,
  providerDashboardTotalEarningsError: null,
  providerDashboardTotalEarnings: [],

  adminDashboardRevenueLoading: false,
  adminDashboardRevenueSuccess: false,
  adminDashboardRevenueFailure: false,
  adminDashboardRevenueError: null,
  adminDashboardRevenue: [],

};

export const DashbaordTilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_REQUEST:
      return {
        ...state,
        getAdminDashboardTilesLoading: true,
        getAdminDashboardTilesSuccess: false,
        getAdminDashboardTilesFailure: false,
        getAdminDashboardTilesError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_SUCCESS:
      return {
        ...state,
        getAdminDashboardTilesLoading: false,
        getAdminDashboardTilesSuccess: true,
        getAdminDashboardTilesFailure: false,
        getAdminDashboardTilesError: null,
        getAdminDashboardTiles: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_ADMIN_DASHBOARD_TILES_FAILURE:
      return {
        ...state,
        getAdminDashboardTilesLoading: false,
        getAdminDashboardTilesSuccess: false,
        getAdminDashboardTilesFailure: true,
        getAdminDashboardTilesError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_REQUEST:
      return {
        ...state,
        getTotalCoursesSalesLoading: true,
        getTotalCoursesSalesSuccess: false,
        getTotalCoursesSalesFailure: false,
        getTotalCoursesSalesError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_SUCCESS:
      return {
        ...state,
        getTotalCoursesSalesLoading: false,
        getTotalCoursesSalesSuccess: true,
        getTotalCoursesSalesFailure: false,
        getTotalCoursesSalesError: null,
        getTotalCoursesSales: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_TOTAL_COURSES_SALE_FAILURE:
      return {
        ...state,
        getTotalCoursesSalesLoading: false,
        getTotalCoursesSalesSuccess: false,
        getTotalCoursesSalesFailure: true,
        getTotalCoursesSalesError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_REQUEST:
      return {
        ...state,
        getTotalCoachingSalesLoading: true,
        getTotalCoachingSalesSuccess: false,
        getTotalCoachingSalesFailure: false,
        getTotalCoachingSalesError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_SUCCESS:
      return {
        ...state,
        getTotalCoachingSalesLoading: false,
        getTotalCoachingSalesSuccess: true,
        getTotalCoachingSalesFailure: false,
        getTotalCoachingSalesError: null,
        getTotalCoachingSales: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_TOTAL_COACHING_SALE_FAILURE:
      return {
        ...state,
        getTotalCoachingSalesLoading: false,
        getTotalCoachingSalesSuccess: false,
        getTotalCoachingSalesFailure: true,
        getTotalCoachingSalesError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_REQUEST:
      return {
        ...state,
        getProviderDashboardTilesLoading: true,
        getProviderDashboardTilesSuccess: false,
        getProviderDashboardTilesFailure: false,
        getProviderDashboardTilesError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_SUCCESS:
      return {
        ...state,
        getProviderDashboardTilesLoading: false,
        getProviderDashboardTilesSuccess: true,
        getProviderDashboardTilesFailure: false,
        getProviderDashboardTilesError: null,
        getProviderDashboardTiles: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_PROVIDER_DASHBOARD_TILES_FAILURE:
      return {
        ...state,
        getProviderDashboardTilesLoading: false,
        getProviderDashboardTilesSuccess: false,
        getProviderDashboardTilesFailure: true,
        getProviderDashboardTilesError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_REQUEST:
      return {
        ...state,
        getCertificationsAwardedListLoading: true,
        getCertificationsAwardedListSuccess: false,
        getCertificationsAwardedListFailure: false,
        getCertificationsAwardedListError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_SUCCESS:
      return {
        ...state,
        getCertificationsAwardedListLoading: false,
        getCertificationsAwardedListSuccess: true,
        getCertificationsAwardedListFailure: false,
        getCertificationsAwardedListError: null,
        getCertificationsAwardedList: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_CERTIFICATION_AWARDED_LIST_FAILURE:
      return {
        ...state,
        getCertificationsAwardedListLoading: false,
        getCertificationsAwardedListSuccess: false,
        getCertificationsAwardedListFailure: true,
        getCertificationsAwardedListError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_REQUEST:
      return {
        ...state,
        getCourseSalesListLoading: true,
        getCourseSalesListSuccess: false,
        getCourseSalesListFailure: false,
        getCourseSalesListError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_SUCCESS:
      return {
        ...state,
        getCourseSalesListLoading: false,
        getCourseSalesListSuccess: true,
        getCourseSalesListFailure: false,
        getCourseSalesListError: null,
        getCourseSalesList: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_COURSES_SALES_LIST_FAILURE:
      return {
        ...state,
        getCourseSalesListLoading: false,
        getCourseSalesListSuccess: false,
        getCourseSalesListFailure: true,
        getCourseSalesListError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_REQUEST:
      return {
        ...state,
        getCoachingSalesListLoading: true,
        getCoachingSalesListSuccess: false,
        getCoachingSalesListFailure: false,
        getCoachingSalesListError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_SUCCESS:
      return {
        ...state,
        getCoachingSalesListLoading: false,
        getCoachingSalesListSuccess: true,
        getCoachingSalesListFailure: false,
        getCoachingSalesListError: null,
        getCoachingSalesList: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_COACHING_SALES_LIST_FAILURE:
      return {
        ...state,
        getCoachingSalesListLoading: false,
        getCoachingSalesListSuccess: false,
        getCoachingSalesListFailure: true,
        getCoachingSalesListError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_REQUEST:
      return {
        ...state,
        getCourseStudentsListLoading: true,
        getCourseStudentsListSuccess: false,
        getCourseStudentsListFailure: false,
        getCourseStudentsListError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_SUCCESS:
      return {
        ...state,
        getCourseStudentsListLoading: false,
        getCourseStudentsListSuccess: true,
        getCourseStudentsListFailure: false,
        getCourseStudentsListError: null,
        getCourseStudentsList: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_COURSES_STUDENTS_LIST_FAILURE:
      return {
        ...state,
        getCourseStudentsListLoading: false,
        getCourseStudentsListSuccess: false,
        getCourseStudentsListFailure: true,
        getCourseStudentsListError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_REQUEST:
      return {
        ...state,
        getCoachingStudentsListLoading: true,
        getCoachingStudentsListSuccess: false,
        getCoachingStudentsListFailure: false,
        getCoachingStudentsListError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_SUCCESS:
      return {
        ...state,
        getCoachingStudentsListLoading: false,
        getCoachingStudentsListSuccess: true,
        getCoachingStudentsListFailure: false,
        getCoachingStudentsListError: null,
        getCoachingStudentsList: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_COACHING_STUDENTS_LIST_FAILURE:
      return {
        ...state,
        getCoachingStudentsListLoading: false,
        getCoachingStudentsListSuccess: false,
        getCoachingStudentsListFailure: true,
        getCoachingStudentsListError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_REQUEST:
      return {
        ...state,
        getQuotationLoading: true,
        getQuotationSuccess: false,
        getQuotationFailure: false,
        getQuotationError: null,
      };
    case DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_SUCCESS:
      return {
        ...state,
        getQuotationLoading: false,
        getQuotationSuccess: true,
        getQuotationFailure: false,
        getQuotationError: null,
        getQuotation: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.GET_QUOTATIONS_FAILURE:
      return {
        ...state,
        getQuotationLoading: false,
        getQuotationSuccess: false,
        getQuotationFailure: true,
        getQuotationError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_REQUEST:
      return {
        ...state,
        providerDashboardTotalEarningsLoading: true,
        providerDashboardTotalEarningsSuccess: false,
        providerDashboardTotalEarningsFailure: false,
        providerDashboardTotalEarningsError: null,
      };
    case DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_SUCCESS:
      return {
        ...state,
        providerDashboardTotalEarningsLoading: false,
        providerDashboardTotalEarningsSuccess: true,
        providerDashboardTotalEarningsFailure: false,
        providerDashboardTotalEarningsError: null,
        providerDashboardTotalEarnings: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_TOTAL_EARNING_FAILURE:
      return {
        ...state,
        providerDashboardTotalEarningsLoading: false,
        providerDashboardTotalEarningsSuccess: false,
        providerDashboardTotalEarningsFailure: true,
        providerDashboardTotalEarningsError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_REQUEST:
      return {
        ...state,
        adminDashboardTopCategoriesGraphLoading: true,
        adminDashboardTopCategoriesGraphSuccess: false,
        adminDashboardTopCategoriesGraphFailure: false,
        adminDashboardTopCategoriesGraphError: null,
      };
    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_SUCCESS:
      return {
        ...state,
        adminDashboardTopCategoriesGraphLoading: false,
        adminDashboardTopCategoriesGraphSuccess: true,
        adminDashboardTopCategoriesGraphFailure: false,
        adminDashboardTopCategoriesGraphError: null,
        adminDashboardTopCategoriesGraph: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_CATEGORY_GRAPH_FAILURE:
      return {
        ...state,
        adminDashboardTopCategoriesGraphLoading: false,
        adminDashboardTopCategoriesGraphSuccess: false,
        adminDashboardTopCategoriesGraphFailure: true,
        adminDashboardTopCategoriesGraphError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_REQUEST:
      return {
        ...state,
        adminDashboardTopCoursesGraphLoading: true,
        adminDashboardTopCoursesGraphSuccess: false,
        adminDashboardTopCoursesGraphFailure: false,
        adminDashboardTopCoursesGraphError: null,
      };
    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_SUCCESS:
      return {
        ...state,
        adminDashboardTopCoursesGraphLoading: false,
        adminDashboardTopCoursesGraphSuccess: true,
        adminDashboardTopCoursesGraphFailure: false,
        adminDashboardTopCoursesGraphError: null,
        adminDashboardTopCoursesGraph: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_TOP_COURSES_GRAPH_FAILURE:
      return {
        ...state,
        adminDashboardTopCoursesGraphLoading: false,
        adminDashboardTopCoursesGraphSuccess: false,
        adminDashboardTopCoursesGraphFailure: true,
        adminDashboardTopCoursesGraphError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_REQUEST:
      return {
        ...state,
        providerDashboardStatusOfCoursesLoading: true,
        providerDashboardStatusOfCoursesSuccess: false,
        providerDashboardStatusOfCoursesFailure: false,
        providerDashboardStatusOfCoursesError: null,
      };
    case DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_SUCCESS:
      return {
        ...state,
        providerDashboardStatusOfCoursesLoading: false,
        providerDashboardStatusOfCoursesSuccess: true,
        providerDashboardStatusOfCoursesFailure: false,
        providerDashboardStatusOfCoursesError: null,
        providerDashboardStatusOfCourses: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.PROVIDER_DASHBOARD_STATUS_OF_COURSES_FAILURE:
      return {
        ...state,
        providerDashboardStatusOfCoursesLoading: false,
        providerDashboardStatusOfCoursesSuccess: false,
        providerDashboardStatusOfCoursesFailure: true,
        providerDashboardStatusOfCoursesError: action.payload,
      };

    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_REQUEST:
      return {
        ...state,
        adminDashboardRevenueLoading: true,
        adminDashboardRevenueSuccess: false,
        adminDashboardRevenueFailure: false,
        adminDashboardRevenueError: null,
      };
    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_SUCCESS:
      return {
        ...state,
        adminDashboardRevenueLoading: false,
        adminDashboardRevenueSuccess: true,
        adminDashboardRevenueFailure: false,
        adminDashboardRevenueError: null,
        adminDashboardRevenue: action.payload,
      };
    case DASHBOARD_TILES_REQUEST.ADMIN_DASHBOARD_REVENUE_FAILURE:
      return {
        ...state,
        adminDashboardRevenueLoading: false,
        adminDashboardRevenueSuccess: false,
        adminDashboardRevenueFailure: true,
        adminDashboardRevenueError: action.payload,
      };

    default:
      return state;
  }
};
