import { registerProvider, registerStudent, isEmailExist, login, socialMedia, studentLogin, getCredits, getLinkedInProfile, getLinkedinTokenKey } from "../api";
import { request, success, failure, ACCOUNTS_REQUEST } from "./utilities";

export function RegisterProvider(data, moveToNext, notification) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.REGISTER_PROVIDER_REQUEST));
    registerProvider(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              ACCOUNTS_REQUEST.REGISTER_PROVIDER_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              ACCOUNTS_REQUEST.REGISTER_PROVIDER_FAILURE,
              response.data.message
            )
          );
          notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.REGISTER_PROVIDER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function RegisterStudent(data, moveToNext, notification) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.REGISTER_STUDENT_REQUEST));
    registerStudent(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              ACCOUNTS_REQUEST.REGISTER_STUDENT_SUCCESS,
              response.data.data
            )
          );
          if (moveToNext) {
            moveToNext();
          }
          notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              ACCOUNTS_REQUEST.REGISTER_STUDENT_FAILURE,
              response.data.message
            )
          );
          notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.REGISTER_STUDENT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetCredits(auth) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.GET_CREDITS_REQUEST));
    getCredits(auth).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              ACCOUNTS_REQUEST.GET_CREDITS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              ACCOUNTS_REQUEST.GET_CREDITS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.GET_CREDITS_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function SocialMedia(data) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.SOCIAL_MEDIA_REQUEST));
    socialMedia(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              ACCOUNTS_REQUEST.SOCIAL_MEDIA_SUCCESS,
              response.data.data
            )
          );
          // if (moveToNext) {
          //   moveToNext();
          // }
          // notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              ACCOUNTS_REQUEST.SOCIAL_MEDIA_FAILURE,
              response.data.message
            )
          );
          // notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.SOCIAL_MEDIA_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetLinkedInProfile(data) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.GET_LINKEDIN_PROFILE_REQUEST));
    getLinkedInProfile(data).then(
      (response) => {
        //
        if (response.data.succeeded === true) {
          dispatch(
            success(
              ACCOUNTS_REQUEST.GET_LINKEDIN_PROFILE_SUCCESS,
              response.data.data
            )
          );
          // if (moveToNext) {
          //   moveToNext();
          // }
          // notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              ACCOUNTS_REQUEST.GET_LINKEDIN_PROFILE_FAILURE,
              response.data.message
            )
          );
          // notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.GET_LINKEDIN_PROFILE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function GetLinkedinTokenKey(code) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_REQUEST));
    getLinkedinTokenKey(code).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(
              ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_SUCCESS,
              response.data.data
            )
          );
          // notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_FAILURE,
              response.data.message
            )
          );
          // notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.GET_LINKEDIN_TOKEN_KEY_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function IsEmailExist(data, moveToNext, notification) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.EMAIL_EXIST_REQUEST));
    isEmailExist(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(ACCOUNTS_REQUEST.EMAIL_EXIST_SUCCESS, response.data.data)
          );
          if (moveToNext) {
            moveToNext();
          }
        } else {
          dispatch(
            failure(ACCOUNTS_REQUEST.EMAIL_EXIST_FAILURE, response.data.message)
          );
          notification(response.data.message);
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.EMAIL_EXIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function UserLogin(data, setCookiesforUser, notification) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.LOGIN_REQUEST));
    login(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(ACCOUNTS_REQUEST.LOGIN_SUCCESS, response.data.data));
          if (setCookiesforUser) {
            setCookiesforUser(response.data.data);
          }
        } else {
          dispatch(
            failure(ACCOUNTS_REQUEST.LOGIN_FAILURE, response.data.message)
          );
          notification(response.data.message);
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.LOGIN_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function StudentUserLogin(data, setCookiesforUser, notification) {
  return (dispatch) => {
    dispatch(request(ACCOUNTS_REQUEST.STUDENT_LOGIN_REQUEST));
    studentLogin(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(success(ACCOUNTS_REQUEST.STUDENT_LOGIN_SUCCESS, response.data.data));
          if (setCookiesforUser) {
            setCookiesforUser(response.data.data);
          }
        } else {
          dispatch(
            failure(ACCOUNTS_REQUEST.STUDENT_LOGIN_FAILURE, response.data.message)
          );
          notification(response.data.message);
        }
      },
      (error) => {
        dispatch(
          failure(
            ACCOUNTS_REQUEST.STUDENT_LOGIN_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
