import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { WEB_PAGES } from "../components";
import { PATH } from "../config";
import PublicRoute from "./Auth/PublicRoute";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { useLocation } from "react-router-dom";
import PrivateRoute from "./Auth/PrivateRoute";
export const RouterConfig = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Switch>
        <PublicRoute exact path={PATH.LINKEDIN_REDIRECT}>
          <LinkedInCallback />
        </PublicRoute>
        <PublicRoute exact path={PATH.CERTIFICATE}>
          <WEB_PAGES.CERTIFICATE />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGIN}>
          <WEB_PAGES.LOGIN />
        </PublicRoute>
        <PublicRoute exact path={PATH.FORGOTPASSWORD}>
          <WEB_PAGES.FORGOTPASSWORD />
        </PublicRoute>
        <PublicRoute exact path={PATH.SIGNUP}>
          <WEB_PAGES.SIGNUP />
        </PublicRoute>
        <PublicRoute exact path={PATH.SUBSCRIPTIONPLANS}>
          <WEB_PAGES.SUBSCRIPTIONPLANS />
        </PublicRoute>
        <PublicRoute exact path={PATH.SIGNUPSTEPS}>
          <WEB_PAGES.SIGNUPSTEPS />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGINEXPLOREDASHBOARD}>
          <WEB_PAGES.LOGINEXPLOREDASHBOARD />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGINSUPPORTDASHBOARD}>
          <WEB_PAGES.LOGINSUPPORTDASHBOARD />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGIN_EXPLORE_COURSE}>
          <WEB_PAGES.LOGIN_EXPLORE_COURSE />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGIN_ADD_CART}>
          <WEB_PAGES.LOGIN_ADD_CART />
        </PublicRoute>
        <PublicRoute exact path={PATH.PRIVACY_POLICY}>
          <WEB_PAGES.PRIVACY_POLICY />
        </PublicRoute>

        <PrivateRoute exact path={PATH.TEACHER_CREATE_COURSE}>
          <WEB_PAGES.TEACHER_CREATE_COURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PURCHASECREDIT}>
          <WEB_PAGES.PURCHASECREDIT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.WISHLIST}>
          <WEB_PAGES.WISHLIST />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.DASHBOARD}>
          <WEB_PAGES.DASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLORE}>
          <WEB_PAGES.EXPLORE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COURSE_PREVIEW}>
          <WEB_PAGES.COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MY_COURSE_PREVIEW}>
          <WEB_PAGES.MY_COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLORE_COURSE_PREVIEW}>
          <WEB_PAGES.COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.Dashboard_COURSE_PREVIEW}>
          <WEB_PAGES.COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.WISHLIST_COURSE_PREVIEW}>
          <WEB_PAGES.COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COMPLETE_COURSE}>
          <WEB_PAGES.COMPLETE_COURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COURSE}>
          <WEB_PAGES.COURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.SETTINGS}>
          <WEB_PAGES.SETTINGS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.STUDENT_PROFILESHOW}>
          <WEB_PAGES.PROFILESHOW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_PROFILESHOW}>
          <WEB_PAGES.PROFILESHOW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.STUDENT_PROFILEEDIT}>
          <WEB_PAGES.PROFILEEDIT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_PROFILEEDIT}>
          <WEB_PAGES.PROFILEEDIT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLORECATEGORY}>
          <WEB_PAGES.EXPLORECATEGORY />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLOREDASHBOARDCATEGORY}>
          <WEB_PAGES.EXPLOREDASHBOARDCATEGORY />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.DASHBOARD_BREAKTHROUGH}>
          <WEB_PAGES.DASHBOARDBREAKTHROUGH />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLORE_BREAKTHROUGH}>
          <WEB_PAGES.DASHBOARDBREAKTHROUGH />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COURSES_BREAKTHROUGH}>
          <WEB_PAGES.DASHBOARDBREAKTHROUGH />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PRICINGPLAN}>
          <WEB_PAGES.PRICINGPLAN />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.CONTACT_US_ADMIN}>
          <WEB_PAGES.CONTACT_US_ADMIN />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCOURSEINFORMATION}>
          <WEB_PAGES.TEACHERCOURSEINFORMATION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCURRICULUMLIST}>
          <WEB_PAGES.TEACHERCURRICULUMLIST />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERDASHBOARD}>
          <WEB_PAGES.TEACHERDASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADDLECTURES}>
          <WEB_PAGES.ADDLECTURES />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.CONTACT_US}>
          <WEB_PAGES.CONTACT_US />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCREATECOACHING}>
          <WEB_PAGES.TEACHERCREATECOACHING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERUPDATECOACHING}>
          <WEB_PAGES.TEACHERUPDATECOACHING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERGROUP}>
          <WEB_PAGES.TEACHERGROUP />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCOURSESGROUP}>
          <WEB_PAGES.TEACHERCOURSESGROUP />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCURRICULUMPUBLISH}>
          <WEB_PAGES.TEACHERCURRICULUMPUBLISH />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERNEWCOURSE}>
          <WEB_PAGES.TEACHERNEWCOURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COACHING_PREVIEW}>
          <WEB_PAGES.COACHING_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MY_COACHING_PREVIEW}>
          <WEB_PAGES.MY_COACHING_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.UPCOMING_COACHING_PREVIEW}>
          <WEB_PAGES.COACHING_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MY_COACHING}>
          <WEB_PAGES.MY_COACHING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERSTEPS}>
          <WEB_PAGES.TEACHERSTEPS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_DASHBOARD}>
          <WEB_PAGES.ADMIN_DASHBOARD />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_QUERY}>
          <WEB_PAGES.ADMIN_QUERY />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.STUDENT_FAQ}>
          <WEB_PAGES.STUDENT_FAQ />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_FAQ}>
          <WEB_PAGES.TEACHER_FAQ />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_COURSE_REQUESTS}>
          <WEB_PAGES.ADMIN_COURSE_REQUESTS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PAYMENT_DETAIL}>
          <WEB_PAGES.PAYMENT_DETAIL />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMINPRICINGPLAN}>
          <WEB_PAGES.ADMINPRICINGPLAN />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MANAGE_COURSE}>
          <WEB_PAGES.MANAGE_COURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLOREPRICINGPLANS}>
          <WEB_PAGES.EXPLOREPRICINGPLANS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.PAYMENT_PLAN}>
          <WEB_PAGES.PAYMENT_PLAN />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.MANAGE_USER}>
          <WEB_PAGES.MANAGE_USER />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHINGCOACHINGINFORMATION}>
          <WEB_PAGES.TEACHINGCOACHINGINFORMATION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.FINISHUPCOACHING}>
          <WEB_PAGES.FINISHUPCOACHING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COACHINGLIST}>
          <WEB_PAGES.COACHINGLIST />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCOACHINGPREVIEW}>
          <WEB_PAGES.TEACHERCOACHINGPREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.CREATENEWSECTION}>
          <WEB_PAGES.CREATENEWSECTION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.REVIEW_AND_SAVE}>
          <WEB_PAGES.REVIEW_AND_SAVE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_COACHING_REQUESTS}>
          <WEB_PAGES.ADMIN_COACHING_REQUESTS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERPRICINGLIST}>
          <WEB_PAGES.TEACHERPRICINGLIST />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.EXPLORE_ADDTOCARTCOURSE}>
          <WEB_PAGES.ADDTOCARTCOURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.DASHBOARD_ADDTOCARTCOURSE}>
          <WEB_PAGES.ADDTOCARTCOURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.COURSE_ADDTOCARTCOURSE}>
          <WEB_PAGES.ADDTOCARTCOURSE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_TOTAL_COACHING}>
          <WEB_PAGES.ADMIN_TOTAL_COACHING />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_TOTAL_COURSES}>
          <WEB_PAGES.ADMIN_TOTAL_COURSES />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_TOTAL_SALES}>
          <WEB_PAGES.ADMIN_TOTAL_SALES />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_TOTAL_USERS}>
          <WEB_PAGES.ADMIN_TOTAL_USERS />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_TOTAL_COURSES}>
          <WEB_PAGES.TEACHER_TOTAL_COURSES />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_TOTAL_STUDENT}>
          <WEB_PAGES.TEACHER_TOTAL_STUDENT />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_TOTAL_SALE}>
          <WEB_PAGES.TEACHER_TOTAL_SALE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_TOTAL_CERTIFICATE}>
          <WEB_PAGES.TEACHER_TOTAL_CERTIFICATE />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.REQUEST_TOPIC}>
          <WEB_PAGES.REQUEST_TOPIC />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_FAQ}>
          <WEB_PAGES.ADMIN_FAQ />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHER_COURSE_PREVIEW}>
          <WEB_PAGES.TEACHER_COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ADMIN_COURSE_PREVIEW}>
          <WEB_PAGES.ADMIN_COURSE_PREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.CREATE_NEW_STAFF}>
          <WEB_PAGES.CREATE_NEW_STAFF />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.ROLE_AND_PERMISSION}>
          <WEB_PAGES.ROLE_AND_PERMISSION />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.TEACHERCURRICULUMPREVIEW}>
          <WEB_PAGES.TEACHERCURRICULUMPREVIEW />
        </PrivateRoute>
        <PrivateRoute exact path={PATH.STUDENT_CREDITS}>
          <WEB_PAGES.STUDENT_CREDITS />
        </PrivateRoute>

        <Route path={PATH.NOPAGE} component={WEB_PAGES.NOPAGE} />
      </Switch>
    </div>
  );
};
