import React from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../../../assets/images";
import { PATH } from "../../../../../config";

export default function FinishUp() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <React.Fragment>
      <div className="container">
        <div className="row ReviewandSaveCard finishUp">
          <div className="col-md-10 offset-md-1">
            <h2 className="text-center cursor font-weight-bold">
              Your coaching service is set up.
            </h2>
            <hr className="edutech-hr-colors" />
            <Card>
              <Card.Body>
                <div className="row">
                  <div className="col-4">
                    <img src={IMAGES.FINISHUP1} className="img-fluid" alt="" />
                  </div>
                  <div className="col-8 my-auto">
                    <h5>Preview your enrolled space</h5>
                    <p>
                      Preview your enrolled space to get a sense of the student
                      experience and to make sure that your first Milestone is
                      ready for your clients.
                    </p>
                    <button
                      className="btn-finishUp mt-3"
                      onClick={() => setModalShow(true)}
                    >
                      Preview
                    </button>{" "}
                  </div>
                  <PreviewModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
function PreviewModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter fw-600">
          <h3>Review your coaching service</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="publish_view_modal">
        <>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-header">Name</div>
                <div className="card-body">
                  <p>Learn to grow coaching centre</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-header">PRICING</div>
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <h4 className="mb-1">Price:</h4>
                      <p className="mb-1 pl-2"> 3333</p>
                    </div>
                    <div className="d-flex">
                      <h4 className="mb-1">Currency:</h4>
                      <p className="mb-1 pl-2"> SGD</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-header">Date</div>
                <div className="card-body">
                  <div className="d-flex responsive_small_screen">
                    <h4>Fixed date:</h4>
                    <p className="pl-2"> 11/24/2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-header">Description</div>
                <div className="card-body">
                  <div className="d-flex responsive_small_screen">
                    <p className="">
                      {" "}
                      After all the esteemed faculty will be a drawing factor in
                      your business. The only other thing to keep in mind is
                      that you choose the name with care. You need to understand
                      the psyche of the students and their parents and then come
                      up with Coaching name suggestions that will enable them to
                      understand just exactly what you are offering and also
                      tell them how will they benefit from joining your coaching
                      classes.
                    </p>
                  </div>
                  <div className="d-flex responsive_small_screen">
                    <h4>Zoom Link:</h4>
                    <p className="pl-2"> https://zoom.us/j/5551112222</p>
                  </div>
                  <div className="d-flex responsive_small_screen">
                    <h4>Zoom Passcode:</h4>
                    <p className="pl-2"> H67P7M</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Link to={PATH.COACHINGLIST}>
          <Button className="edutech-curriculum-action-button description-rounded">
            {" "}
            Submit
          </Button>
        </Link>
      </Modal.Footer>
    </Modal>
  );
}
