import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { BsCheckLg, BsCheckCircle, BsDownload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "../../assets/images";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import {
  CertificateReceived,
  GetCompletedCourseListWithCertificates,
} from "../../redux/actions";
import pdfMake from "pdfmake/build/pdfmake";
import html2canvas from "html2canvas";
import { Document, Page } from "react-pdf";
import { Link } from "react-router-dom";

export default function CompleteCourse() {
  const [imageSetter, setImageSetter] = useState(0);
  let auth = useAuth();
  const dispatch = useDispatch();
  let studentName =
    auth.edu_tech_user.firstName + " " + auth.edu_tech_user.lastName;
  useEffect(() => {
    dispatch(GetCompletedCourseListWithCertificates(auth));
  }, [dispatch]);
  let getCertificateList = useSelector((state) => state.courses);
  const [pic, setPic] = useState(
    getCertificateList?.getCompletedCourseListWithCertificates?.[imageSetter]
      ?.courseCertificateURL
  );
  useEffect(() => {
    setPic(
      getCertificateList?.getCompletedCourseListWithCertificates?.[imageSetter]
        ?.courseCertificateURL
    );
  }, [getCertificateList]);
  function downloadApiCall() {
    dispatch(
      CertificateReceived(
        getCertificateList?.getCompletedCourseListWithCertificates?.[
          imageSetter
        ]?.courseId,
        auth
      )
    );
  }
  let courseCertificate =
    getCertificateList?.getCompletedCourseListWithCertificates?.[imageSetter]
      ?.courseCertificateURL;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const printToPdf = () => {
    // html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
    //   debugger;
    //   var data = canvas.toDataURL();
    //   var pdfExportSetting = {
    //     // pageOrientation: "A2",
    //     // compress: false,y
    //     // pageSize: {
    //     //   // width: 900,
    //     //   // height: 600,
    //     //   padding: 0,
    //     // },
    //     // pageMargins: [40, 60, 40, 60],
    //     content: [
    //       // {
    //       //   image: data,
    //       //   width: 480,
    //       //   height: 500,
    //       // },
    //       {
    //         image: data,
    //         // width: 830,
    //         // height: 530,
    //       },
    //     ],
    //   };
    //   // pdfMake.createPdf(pdfExportSetting).download("Certificate.pdf");
    //   pdfMake.createPdf(pdfExportSetting).open();
    // });
    window.open("print_to_pdf");
  };

  // const printToPdf = () => {
  //   html2canvas(document.getElementById("print_to_pdf")).then((canvas) => {
  //     var data = canvas.toDataURL();
  //     // var img = canvas.toDataURL();
  //     var pdfExportSetting = {
  //       content: [
  //         {
  //           image: data,
  //           width: 530,
  //         },
  //       ],
  //     };
  //     pdfMake.createPdf(pdfExportSetting).download("Prescription.pdf");
  //   });
  // };

  return (
    <div>
      <div className="row">
        <>
          <div className="col-12 col-md-5">
            <Card>
              <div className="d-flex align-items-center px-5 pt-4 pb-3 ">
                <h2 className="font-weight-bold">
                  {studentName ? studentName : "N/A"}
                </h2>{" "}
                <BsCheckLg className="edutech-complte-course-text " />
              </div>
              {getCertificateList.getCompletedCourseListWithCertificates
                .length !== 0 ? (
                <h2 className="pl-5 edutech-complete-congratulate">
                  <u> Congratulations!</u>
                </h2>
              ) : (
                ""
              )}

              <hr className="mx-5 " />
              {/* <h3 className="px-5 py-3 font-weight-bold">COMPLETED COURSES</h3> */}
              {getCertificateList.getCompletedCourseListWithCertificates
                .length === 0 ? (
                <p className=" text-center">No Record Found</p>
              ) : (
                <>
                  <div className="row px-5">
                    {getCertificateList &&
                      getCertificateList.getCompletedCourseListWithCertificates.map(
                        (certList, index) => {
                          return (
                            <>
                              <div className="col-12 col-md-6 ">
                                <Card
                                  className="px-4 py-4 edutech-complete-card-color cursor "
                                  key={index}
                                  onClick={() => {
                                    setImageSetter(index);
                                  }}
                                >
                                  <h4>{certList.courseTitle}</h4>
                                  <p className="d-flex justify-content-between mt-3 mb-0 text-muted">
                                    Jun 30,2020 - Jul 1,2020{" "}
                                    <BsCheckCircle className="edutech-complete-check-icon" />
                                  </p>
                                </Card>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                </>
              )}
            </Card>
          </div>
          <div className="col-12 col-md-7">
            <Card className="p-4 overflow-auto">
              <h3 className="font-weight-bold">
                Your Professional Certificate!
              </h3>
              <h4 className="font-weight-bold">
                {
                  getCertificateList?.getCompletedCourseListWithCertificates?.[
                    imageSetter
                  ]?.courseTitle
                }
              </h4>
              {/* certificate testing */}
              {/* <a
                href="https://www.africau.edu/images/default/sample.pdf"
                download
              >
                Click to download
              </a> */}
              {/* <div className="display-none">
                <Document
                  file="https://www.africau.edu/images/default/sample.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <img
                  src="https://www.africau.edu/images/default/sample.pdf"
                  alt=""
                />
              </div> */}

              <div className="background-image-certificate">
                <div className="row pl-5 py-5 pr-2">
                  <div className="col-md-8 col-6">
                    <p className="cert-text-size mb-0">CERTIFICATE</p>
                    <p className="appreciate-text">OF APPRECIATION</p>
                  </div>
                  <div className="col-md-4 col-6 pt-4 parent-class">
                    <span className="f-20 query ml-5">November 12th 2022</span>
                  </div>
                </div>
                <div className="row pl-5">
                  <div className="col-md-12 col-12 ">
                    <p className="f-20 ">This award is proudly given to:</p>
                    <p className="name-style text-uppercase">
                      {studentName ? studentName : "N/A"}
                    </p>
                    <p className="f-20 mb-0 text-uppercase">
                      Completed :{" "}
                      {getCertificateList
                        ?.getCompletedCourseListWithCertificates?.[imageSetter]
                        ?.courseTitle
                        ? getCertificateList
                            ?.getCompletedCourseListWithCertificates?.[
                            imageSetter
                          ]?.courseTitle
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row p-5">
                  <div className="col-md-8">
                    <p className="fw-600 mb-4 authorize-text">Authorized by:</p>
                    <p className="mb-0">____________</p>
                    <p className="ml-3 Principal-text">The Principal</p>
                    <span className="signature-style">
                      <img
                        src={IMAGES.SIGNCERT}
                        alt="signature"
                        className="img-fluid "
                      />
                    </span>
                  </div>
                  <div className="col-md-4 ">
                    <img
                      src={IMAGES.EDUTECH_LOGO_SMALL}
                      className="img-fluid candletech-logo"
                      alt="edutech-logo"
                    />
                  </div>
                </div>
              </div>
              <span>
                {/* <a href={props?.certificateURL} download>
                  <button className="notes-download-btn">
                    <BiDownload className="download-btn-style" />
                    {"Download"}
                  </button>
                </a> */}
                <a
                  href={
                    getCertificateList
                      ?.getCompletedCourseListWithCertificates?.[imageSetter]
                      ?.certificateURL
                  }
                  download
                >
                  <Button
                    className="download-btn mb-2 bg-default-light mt-3 ml-4"
                    variant="danger"
                    // onClick={() => {
                    //   downloadApiCall();
                    //   printToPdf();
                    // }}
                  >
                    <BsDownload className="mr-3" />
                    Download
                  </Button>
                </a>
              </span>
            </Card>
          </div>
        </>
      </div>
    </div>
  );
}
