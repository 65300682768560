import { STUDENT_PAYMENT_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  getStudentPaymentDetailsLoading: false,
  getStudentPaymentDetailsSuccess: false,
  getStudentPaymentDetailsFailure: false,
  getStudentPaymentDetailsError: null,
  getStudentPaymentDetails: [],


};

export const studentPaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_REQUEST:
      return {
        ...state,
        getStudentPaymentDetailsLoading: true,
        getStudentPaymentDetailsSuccess: false,
        getStudentPaymentDetailsFailure: false,
        getStudentPaymentDetailsError: null,
      };
    case STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_SUCCESS:
      return {
        ...state,
        getStudentPaymentDetailsLoading: false,
        getStudentPaymentDetailsSuccess: true,
        getStudentPaymentDetailsFailure: false,
        getStudentPaymentDetailsError: null,
        getStudentPaymentDetails: action.payload,
      };
    case STUDENT_PAYMENT_REQUEST.GET_STUDENT_PAYMENT_DETAIL_FAILURE:
      return {
        ...state,
        getStudentPaymentDetailsLoading: false,
        getStudentPaymentDetailsSuccess: false,
        getStudentPaymentDetailsFailure: true,
        getStudentPaymentDetailsError: action.payload,
      };

    default:
      return state;
  }
};
