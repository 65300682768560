import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import { BsArrowDown, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { GetCoachingList } from "../../../redux/actions/coachingAction";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { ToastContainer } from "react-toastify";
import { ErrorMessage, Loader } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
export default function TotalCoachng() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.coaching);
  useEffect(() => {
    dispatch(GetCoachingList(auth));
    document.title = `Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getCoaching;
  let columns = [
    {
      dataField: "coachingId",
      text: "Coaching ID",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "companyTitle",
      text: "Company Name",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">
                {row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""}{" "}
              </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: "true",
      sortCaret: (order, column) => {
        if (!order) {
          return (
            <RiArrowUpDownFill
              className="left-margin-2 cursor text-brand-primary"
              size={18}
            />
          );
        }
        return (
          <React.Fragment>
            {order === "asc" && (
              <BsArrowUp size={20} className="text-brand-primary cursor" />
            )}
            {order === "desc" && (
              <BsArrowDown size={20} className="text-brand-primary cursor" />
            )}
          </React.Fragment>
        );
      },
      formatter: (cell, row) => {
        return <span>{row.price === 0 ? "FREE" : row.price}</span>;
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="teacher-curriculum-dashboard ">
        <Link to={PATH.ADMIN_DASHBOARD}>
          <BsArrowLeft size={35} className="mr-2" />
        </Link>
        <div
          className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Total Coaching</h3>
            </div>
          </div>
        </div>
      </div>
      <>
        {getListing &&
          getListing.getCoachingLoading === true &&
          getListing.getCoachingSuccess === false &&
          getListing.getCoachingFailure === false && <Loader />}
        {getListing &&
          getListing.getCoachingLoading === false &&
          getListing.getCoachingSuccess === false &&
          getListing.getCoachingFailure === true && (
            <ErrorMessage message={getListing.getCoachingError} />
          )}
        {getListing &&
          getListing.getCoachingLoading === false &&
          getListing.getCoachingSuccess === true &&
          getListing.getCoachingFailure === false && (
            <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
              <MyDataTable
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                // handleDataChange={handleDataChange}
              />
            </div>
          )}
      </>
    </>
  );
}
