import React from "react";
import { Card, Modal } from "react-bootstrap";
import { GenericSearchField, IMAGES } from "../../assets";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RiYoutubeLine } from "react-icons/ri";
import { BsFileEarmark } from "react-icons/bs";
import { MdOutlineAssignment } from "react-icons/md";
import { HiOutlineFolderDownload } from "react-icons/hi";
import { ImInfinite } from "react-icons/im";
import { VscDeviceMobile } from "react-icons/vsc";
import { AiOutlineTrophy } from "react-icons/ai";
import { BiLock } from "react-icons/bi";
import { PATH } from "../../config";
export default function AddToCartCourse() {
  const [addToCartModalShow, setAddToCartModalShow] = React.useState(false);
  let history = useHistory();
  const location = useLocation()
  const CoursessssData = location?.state?.coursesData
  var relevantCourses = [
    {
      id: 0,
      image: IMAGES.VENTURE,
    },
    {
      id: 1,
      image: IMAGES.VENTURE,
    },
    {
      id: 2,
      image: IMAGES.VENTURE,
    },
  ];
  function reloadPage() {
    window.location.reload();
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="font-weight-bold">
          <u>Course Preview</u>
        </h2>
        {/* <GenericSearchField placeholder="Search" /> */}
      </div>
      <div className="row mt-5 edutech-sm-mt-5">
        <div className="col-12 col-sm-12 col-md-8 mb-md-2">
          <Card className=" p-4">
            <div className="embed-responsive embed-responsive-16by9 video-height">
              <iframe
                title="dashboard-video"
                className="embed-responsive-item"
                src="//www.youtube.com/embed/zB4I68XVPzQ"
              ></iframe>
              <div className="edutech-video-span">
                <p className="edutech-video-span-determine-text">
                  What determines
                </p>
                <p className="edutech-video-span-profit-text">Good Profits?</p>
              </div>
            </div>
            <div className="text-capitilize b-500 px-3 py-2 pt-3 mt-4">
              <h3 className="mb-3 font-weight-bold">
                Master Capital Wisdom - M1
              </h3>
              <h5 className="mb-3">COURSE DESCRIPTION</h5>
              <p className="text-justify mb-0">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet. Amet minim
                mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                Velit officia consequat duis enim velit mollit. Exercitation
                veniam consequat sunt nostrud amet. Velit officia consequat duis
                enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet. Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </Card>
        </div>
        <div className="col-12 col-sm-12 col-md-4 mb-md-2 add_to_cart">
          <Card>
            <div>
              <div className="add_cart_price_section">
                <h3>Price: $99</h3>
                <h2 className="text-white d-flex justify-content-center align-items-center">
                  Credits: 99
                  {/* <BsCoin className="pl-2" /> */}
                </h2>
                {/* <Link to={PATH.EXPLOREPRICINGPLANS}>
                  <button type="button" className="buy_now_btn">
                    Buy now
                  </button>
                </Link> */}
              </div>
              <div className="course_include_section">
                <h3>This course includes:</h3>
                <p>
                  <RiYoutubeLine />
                  2.5 hours on demand
                </p>
                <p>
                  <BsFileEarmark />6 articles
                </p>
                <p>
                  <HiOutlineFolderDownload />
                  11 downloadable resources
                </p>
                <p>
                  <ImInfinite />
                  Full lifetime access
                </p>
                <p>
                  <VscDeviceMobile />
                  Access on mobile and TV
                </p>
                <p>
                  <MdOutlineAssignment />
                  Assignments
                </p>
                <p>
                  <AiOutlineTrophy />
                  Certificate of completion
                </p>
              </div>
            </div>
            <Link to={PATH.EXPLOREPRICINGPLANS}>
              <button type="button" className="pricing_plan_btn">
                Buy now
              </button>
            </Link>
          </Card>
        </div>
      </div>
      <div className="row edutech-sm-mt-5">
        <div className="col-12 col-md-8 mb-md-2">
          <Card className="px-4 pt-4 edutech-sm-mt-5">
            <h3 className="mb-4">Other Relevant Courses</h3>
            {history.location.pathname === PATH.EXPLORE_ADDTOCARTCOURSE && (
              <div className="row">
                {relevantCourses.map((item, index) => {
                  return (
                    <div className="col-12 col-md-4 " key={index}>
                      <div className="card" onClick={() => reloadPage()}>
                        {" "}
                        <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                          <Link to={PATH.EXPLORE_COURSE_PREVIEW}>
                            <img
                              className="img-fluid embed-responsive-item"
                              src={item.image}
                            />
                          </Link>
                        </div>
                        <div className="pl-3 my-2">
                          <p className="firstpara text-left m-0">
                            {" "}
                            Venture Capital
                          </p>
                          <p className="text-muted text-left mb-0">
                            {" "}
                            Company Name
                          </p>
                          <p className="firstpara text-left m-0 p-0">
                            {" "}
                            $9.99{" "}
                            <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                              $24.99
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {history.location.pathname === PATH.DASHBOARD_ADDTOCARTCOURSE && (
              <div className="row">
                {relevantCourses.map((item, index) => {
                  return (
                    <div className="col-12 col-md-4 " key={index}>
                      <div className="card" onClick={() => reloadPage()}>
                        {" "}
                        <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                          <Link to={PATH.Dashboard_COURSE_PREVIEW}>
                            <img
                              className="img-fluid embed-responsive-item"
                              src={item.image}
                            />
                          </Link>
                        </div>
                        <div className="pl-3 my-2">
                          <p className="firstpara text-left m-0">
                            {" "}
                            Venture Capital
                          </p>
                          <p className="text-muted text-left mb-0">
                            {" "}
                            Company Name
                          </p>
                          <p className="firstpara text-left m-0 p-0">
                            {" "}
                            $9.99{" "}
                            <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                              $24.99
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {history.location.pathname === PATH.COURSE_ADDTOCARTCOURSE && (
              <div className="row">
                {relevantCourses.map((item, index) => {
                  return (
                    <div className="col-12 col-md-4 " key={index}>
                      <div className="card" onClick={() => reloadPage()}>
                        {" "}
                        <div className="embed-responsive embed-responsive-16by9 video-height cursor">
                          <Link to={PATH.COURSE_PREVIEW}>
                            <img
                              className="img-fluid embed-responsive-item"
                              src={item.image}
                            />
                          </Link>
                        </div>
                        <div className="pl-3 my-2">
                          <p className="firstpara text-left m-0">
                            {" "}
                            Venture Capital
                          </p>
                          <p className="text-muted text-left mb-0">
                            {" "}
                            Company Name
                          </p>
                          <p className="firstpara text-left m-0 p-0">
                            {" "}
                            $9.99{" "}
                            <span className=" ml-3 mb-0 pricing-discount-line text-muted">
                              $24.99
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Card>
        </div>
        <div className="col-12 col-md-4 mb-md-2"></div>
      </div>
      <AddToCartModal
        show={addToCartModalShow}
        onHide={() => setAddToCartModalShow(false)}
      />
    </>
  );
}
function AddToCartModal(props) {
  var AddtocartListing = [
    {
      id: 0,
      image: IMAGES.IPO2,
      heading: "Master Capital Wisdom -M1",
      smallText: "By Phill Ebiner",
      price: "$99.9",
    },
    {
      id: 1,
      image: IMAGES.IPO2,
      heading: "Master Capital Wisdom -M1",
      smallText: "By Phill Ebiner",
      price: "$99.9",
    },
    {
      id: 2,
      image: IMAGES.IPO2,
      heading: "Master Capital Wisdom -M1",
      smallText: "By Phill Ebiner",
      price: "$99.9",
    },
  ];
  return (
    <Modal
      className="shoping-modal-style"
      {...props}
      size="lg"
      aria-labelledby="contained-m odal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h3 className="diversity-font-weight-900 mb-0"> Shopping Cart</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-shopping ">
          {AddtocartListing.map((list, index) => {
            return (
              <>
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between mb-2 mt-2 flex-direction-set"
                >
                  <div className="d-flex align-items-center flex-direction-set">
                    <div className="set-view-small-screen mr-4">
                      <img src={list.image} className="img-fluid" />
                    </div>
                    <div className="set-view-small-screen">
                      <h4 className="pt-1 mb-0 pb-1 diversity-font-weight-900">
                        {list.heading}
                      </h4>
                      <p className="">{list.smallText} </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="price-color">{list.price}</h3>
                  </div>
                </div>
                <hr />
              </>
            );
          })}
          <div className=" d-flex justify-content-center">
            <div className="col-md-2 col-lg-2 set-smal-screen-size text-left">
              <h5 className="">Subtotal</h5>
            </div>
            <div className="col-md-2 col-lg-2 set-smal-screen-size text-center">
              <h4 className="price-color">$297</h4>
            </div>
          </div>
          {/* <div className=" d-flex justify-content-center mt-2">
            <div className="col-md-2 col-lg-2 set-smal-screen-size text-left">
              <h5 className="text-dark">Shipping</h5>
            </div>
            <div className="col-md-2 col-lg-2 set-smal-screen-size  text-center">
              <h4 className="price-color">$12</h4>
            </div>
          </div>
          <div className=" d-flex justify-content-center mt-2">
            <div className="col-md-2 col-lg-2 set-smal-screen-size text-left">
              <h4 className="">Total</h4>
            </div>
            <div className="col-md-2 col-lg-2 set-smal-screen-size text-center ">
              <h3 className="price-color">$309</h3>
            </div>
          </div> */}
          <div className="row d-flex justify-content-center mt-3">
            <div className="px-2">
              <button className="back-to-shopping" onClick={props.onHide}>
                Back to shipping
              </button>
            </div>
            <div className="px-2">
              <button className="confirm-to-shopping" onClick={props.onHide}>
                <BiLock className="mb-1 mr-2 loock-icon-font-size" />
                Confirm Shipping
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
