import React, { useEffect, useState } from "react";
import { BsArrowDown, BsArrowUp, BsCoin } from "react-icons/bs";
import { Button, Tab, Tabs } from "react-bootstrap";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { GetPurchasedCoaching } from "../../../redux/actions/coachingAction";
import { ErrorMessage, Loader } from "../../../assets";
import { GetCredits } from "../../../redux/actions/accountAction";
import { GetPurchasedCourses } from "../../../redux/actions";
import { RiArrowUpDownFill } from "react-icons/ri";
export default function Credits() {
  const [key, setKey] = useState("Courses");
  let dispatch = useDispatch();
  let auth = useAuth();
  let getCoachingCredits = useSelector((state) => state.account);
  useEffect(() => {
    dispatch(GetCredits(auth));
    document.title = `Credits || EduTech`;
  }, [dispatch]);
  let data = getCoachingCredits && getCoachingCredits.getCredits;
  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="add_cart_price_section">
          {/* <div className="text-white text-left d-flex justify-content-between align-items-center">
            <h4>
              Total Credits:
            </h4>
            <h4>
              100
              <BsCoin className="pl-2 bs-icon-font-size" />
            </h4>
          </div> */}

          <div className="text-white text-left d-flex justify-content-between align-items-center">
            <h4>
              Available Credit:
            </h4>
            <h4 className="ml-5">
              {data.availableCredit}
              <BsCoin className="pl-2 bs-icon-font-size" />
            </h4>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="explore_tabs"
      >
        <Tab eventKey="Courses" title="Courses">
          <Courses />
        </Tab>
        <Tab eventKey="Coaching" title="Coaching">
          <Coaching />
        </Tab>
      </Tabs>
    </>
  );
}
function Courses() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.courses);
  useEffect(() => {
    dispatch(GetPurchasedCourses(auth));
    document.title = `My Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getPurchasedCourses;
  const columns = [
    {
      dataField: "courseTitle",
      text: "Course Title",
    },
    {
      dataField: "courseSubtitle",
      text: "Course Subtitle",
    },
    {
      dataField: "providerName",
      text: "Provider Name",
    },
    {
      dataField: "price",
      text: "Price",
      formatter: (cell, row) => {
        return (
          <span>
            {row.price === 0 ? "FREE" : row.price}
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {getListing &&
        getListing.getPurchasedCoursesLoading === true &&
        getListing.getPurchasedCoursesSuccess === false &&
        getListing.getPurchasedCoursesFailure === false && <Loader />}
      {getListing &&
        getListing.getPurchasedCoursesLoading === false &&
        getListing.getPurchasedCoursesSuccess === false &&
        getListing.getPurchasedCoursesFailure === true && (
          <ErrorMessage message={getListing.getPurchasedCoursesError} />
        )}
      {getListing &&
        getListing.getPurchasedCoursesLoading === false &&
        getListing.getPurchasedCoursesSuccess === true &&
        getListing.getPurchasedCoursesFailure === false && (
          <div className="admin-side-table-styling card p-4 mt-5">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            />
          </div>
        )}
    </>
  );
}
function Coaching() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let getListing = useSelector((state) => state.coaching);
  useEffect(() => {
    dispatch(GetPurchasedCoaching(auth));
    document.title = `My Coaching || EduTech`;
  }, [dispatch]);
  let data = getListing && getListing.getPurchasedCoaching;
  let columns = [
    {
      dataField: "coachingId",
      text: "Coaching ID",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "companyTitle",
      text: "Company Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "status",
      text: "Status",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {row.status !== "UNPUBLISHED" ? (
              <span className="publish-btn-color">{row.status !== "UNPUBLISHED" ? "PUBLISHED" : ""} </span>
            ) : (
              <span className="unpublish-btn-color">
                {" "}
                {row.status !== "UNPUBLISHED" ? "" : "UNPUBLISHED"}
              </span>
            )}
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        return (
          <span>
            {row.price === 0 ? "FREE" : row.price}
          </span>
        );
      },
    },
    // {
    //   dataField: "count",
    //   text: "Student",
    //   formatter: (cell, row) => {
    //     return (
    //       <span>
    //         {row.count === 0 ? row.count : "N/A"}
    //       </span>
    //     );
    //   },
    // },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {getListing &&
        getListing.getPurchasedCoachingLoading === true &&
        getListing.getPurchasedCoachingSuccess === false &&
        getListing.getPurchasedCoachingFailure === false && <Loader />}
      {getListing &&
        getListing.getPurchasedCoachingLoading === false &&
        getListing.getPurchasedCoachingSuccess === false &&
        getListing.getPurchasedCoachingFailure === true && (
          <ErrorMessage message={getListing.getPurchasedCoachingError} />
        )}
      {getListing &&
        getListing.getPurchasedCoachingLoading === false &&
        getListing.getPurchasedCoachingSuccess === true &&
        getListing.getPurchasedCoachingFailure === false && (
          <div className="admin-side-table-styling card p-4 teacher-curriculum coaching_list_table">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}
