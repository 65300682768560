import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function createNotes(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.LECTURENOTES.createNotes, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getAllNotesByLecture(LectureId, auth) {
    return axios.get(`${APP_SETTINGS.API_PATH.LECTURENOTES.getAllNotesByLecture}?LectureId=${LectureId}`, {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
    );
  }