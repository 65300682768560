import { COURSES_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  createCoursesLoading: false,
  createCoursesSuccess: false,
  createCoursesFailure: false,
  createCoursesError: null,
  createCourses: [],

  createSectionLoading: false,
  createSectionSuccess: false,
  createSectionFailure: false,
  createSectionError: null,
  createSection: [],

  getCourseListLoading: false,
  getCourseListSuccess: false,
  getCourseListFailure: false,
  getCourseListError: null,
  getCourseList: [],

  publishCourseLoading: false,
  publishCourseSuccess: false,
  publishCourseFailure: false,
  publishCourseError: null,
  publishCourse: [],

  purchaseCourseLoading: false,
  purchaseCourseSuccess: false,
  purchaseCourseFailure: false,
  purchaseCourseError: null,
  purchaseCourse: [],

  certificateConversionLoading: false,
  certificateConversionSuccess: false,
  certificateConversionFailure: false,
  certificateConversionError: null,
  certificateConversion: [],

  createLectureLoading: false,
  createLectureSuccess: false,
  createLectureFailure: false,
  createLectureError: null,
  createLecture: [],

  getByIdLoading: false,
  getByIdSuccess: false,
  getByIdFailure: false,
  getByIdError: null,
  getById: null,

  getSectionByFilterLoading: false,
  getSectionByFilterSuccess: false,
  getSectionByFilterFailure: false,
  getSectionByFilterError: null,
  getSectionByFilter: [],

  getLectureByFilterLoading: false,
  getLectureByFilterSuccess: false,
  getLectureByFilterFailure: false,
  getLectureByFilterError: null,
  getLectureByFilter: [],

  checkQuizAnswerLoading: false,
  checkQuizAnswerSuccess: false,
  checkQuizAnswerFailure: false,
  checkQuizAnswerError: null,
  checkQuizAnswer: [],

  getTopCategoriesCoursesLoading: false,
  getTopCategoriesCoursesSuccess: false,
  getTopCategoriesCoursesFailure: false,
  getTopCategoriesCoursesError: null,
  getTopCategoriesCourses: [],

  getRelatedCoursesLoading: false,
  getRelatedCoursesSuccess: false,
  getRelatedCoursesFailure: false,
  getRelatedCoursesError: null,
  getRelatedCourses: [],

  getTopCoursesLoading: false,
  getTopCoursesSuccess: false,
  getTopCoursesFailure: false,
  getTopCoursesError: null,
  getTopCourses: [],

  getPurchasedCoursesLoading: false,
  getPurchasedCoursesSuccess: false,
  getPurchasedCoursesFailure: false,
  getPurchasedCoursesError: null,
  getPurchasedCourses: [],

  certificateReceivedLoading: false,
  certificateReceivedSuccess: false,
  certificateReceivedFailure: false,
  certificateReceivedError: null,
  certificateReceived: [],

  markCourseAsStartedLoading: false,
  markCourseAsStartedSuccess: false,
  markCourseAsStartedFailure: false,
  markCourseAsStartedError: null,
  markCourseAsStarted: [],

  updateVideoTimeStampLoading: false,
  updateVideoTimeStampSuccess: false,
  updateVideoTimeStampFailure: false,
  updateVideoTimeStampError: null,
  updateVideoTimeStamp: [],

  getCourseCertificationLoading: false,
  getCourseCertificationSuccess: false,
  getCourseCertificationFailure: false,
  getCourseCertificationError: null,
  getCourseCertification: [],

  getCompletedCourseListWithCertificatesLoading: false,
  getCompletedCourseListWithCertificatesSuccess: false,
  getCompletedCourseListWithCertificatesFailure: false,
  getCompletedCourseListWithCertificatesError: null,
  getCompletedCourseListWithCertificates: [],

  purchasecreditLoading: false,
  purchasecreditSuccess: false,
  purchasecreditFailure: false,
  purchasecreditError: null,
  purchasecredit: [],

  getInProgressCourseListLoading: false,
  getInProgressCourseListSuccess: false,
  getInProgressCourseListFailure: false,
  getInProgressCourseListError: null,
  getInProgressCourseList: [],

  getVideoTimeStampLoading: false,
  getVideoTimeStampSuccess: false,
  getVideoTimeStampFailure: false,
  getVideoTimeStampError: null,
  getVideoTimeStamp: null,
};

export const CoursesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COURSES_REQUEST.CREATE_COURSES_REQUEST:
      return {
        ...state,
        createCoursesLoading: true,
        createCoursesSuccess: false,
        createCoursesFailure: false,
        createCoursesError: null,
        createCourses: [],
      };
    case COURSES_REQUEST.CREATE_COURSES_SUCCESS:
      return {
        ...state,
        createCoursesLoading: false,
        createCoursesSuccess: true,
        createCoursesFailure: false,
        createCoursesError: null,
        createCourses: action.payload,
      };
    case COURSES_REQUEST.CREATE_COURSES_FAILURE:
      return {
        ...state,
        createCoursesLoading: false,
        createCoursesSuccess: false,
        createCoursesFailure: true,
        createCoursesError: action.payload,
      };

    case COURSES_REQUEST.GET_BY_ID_REQUEST:
      return {
        ...state,
        getByIdLoading: true,
        getByIdSuccess: false,
        getByIdFailure: false,
        getByIdError: null,
        getById: null,
      };
    case COURSES_REQUEST.GET_BY_ID_SUCCESS:
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: true,
        getByIdFailure: false,
        getByIdError: null,
        getById: action.payload,
      };
    case COURSES_REQUEST.GET_BY_ID_FAILURE:
      return {
        ...state,
        getByIdLoading: false,
        getByIdSuccess: false,
        getByIdFailure: true,
        getByIdError: action.payload,
      };

    case COURSES_REQUEST.GET_COURSE_LIST_REQUEST:
      return {
        ...state,
        getCourseListLoading: true,
        getCourseListSuccess: false,
        getCourseListFailure: false,
        getCourseListError: null,
        getCourseList: [],
      };
    case COURSES_REQUEST.GET_COURSE_LIST_SUCCESS:
      return {
        ...state,
        getCourseListLoading: false,
        getCourseListSuccess: true,
        getCourseListFailure: false,
        getCourseListError: null,
        getCourseList: action.payload,
      };
    case COURSES_REQUEST.GET_COURSE_LIST_FAILURE:
      return {
        ...state,
        getCourseListLoading: false,
        getCourseListSuccess: false,
        getCourseListFailure: true,
        getCourseListError: action.payload,
      };

    case COURSES_REQUEST.CREATE_SECTION_REQUEST:
      return {
        ...state,
        createSectionLoading: true,
        createSectionSuccess: false,
        createSectionFailure: false,
        createSectionError: null,
        createSection: [],
      };
    case COURSES_REQUEST.CREATE_SECTION_SUCCESS:
      return {
        ...state,
        createSectionLoading: false,
        createSectionSuccess: true,
        createSectionFailure: false,
        createSectionError: null,
        createSection: action.payload,
      };
    case COURSES_REQUEST.CREATE_SECTION_FAILURE:
      return {
        ...state,
        createSectionLoading: false,
        createSectionSuccess: false,
        createSectionFailure: true,
        createSectionError: action.payload,
      };

    case COURSES_REQUEST.PUBLISH_COURSE_REQUEST:
      return {
        ...state,
        publishCourseLoading: true,
        publishCourseSuccess: false,
        publishCourseFailure: false,
        publishCourseError: null,
        publishCourse: [],
      };
    case COURSES_REQUEST.PUBLISH_COURSE_SUCCESS:
      debugger;
      const coursesData = state.getCourseList;
      for (let i = 0; i < coursesData.length; i++) {
        if (coursesData[i].CourseId === action.payload.CourseId) {
          coursesData[i].status = action.payload.status;
        }
      }
      return {
        ...state,
        publishCourseLoading: false,
        publishCourseSuccess: true,
        publishCourseFailure: false,
        publishCourseError: null,
        publishCourse: action.payload,
        getCourseList: coursesData,
      };
    case COURSES_REQUEST.PUBLISH_COURSE_FAILURE:
      return {
        ...state,
        publishCourseLoading: false,
        publishCourseSuccess: false,
        publishCourseFailure: true,
        publishCourseError: action.payload,
      };

    case COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_REQUEST:
      return {
        ...state,
        getCompletedCourseListWithCertificatesLoading: true,
        getCompletedCourseListWithCertificatesSuccess: false,
        getCompletedCourseListWithCertificatesFailure: false,
        getCompletedCourseListWithCertificatesError: null,
        getCompletedCourseListWithCertificates: [],
      };
    case COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_SUCCESS:
      return {
        ...state,
        getCompletedCourseListWithCertificatesLoading: false,
        getCompletedCourseListWithCertificatesSuccess: true,
        getCompletedCourseListWithCertificatesFailure: false,
        getCompletedCourseListWithCertificatesError: null,
        getCompletedCourseListWithCertificates: action.payload,
      };
    case COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_FAILURE:
      return {
        ...state,
        getCompletedCourseListWithCertificatesLoading: false,
        getCompletedCourseListWithCertificatesSuccess: false,
        getCompletedCourseListWithCertificatesFailure: true,
        getCompletedCourseListWithCertificatesError: action.payload,
      };

    case COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_REQUEST:
      return {
        ...state,
        getCompletedCourseListWithCertificatesLoading: true,
        getCompletedCourseListWithCertificatesSuccess: false,
        getCompletedCourseListWithCertificatesFailure: false,
        getCompletedCourseListWithCertificatesError: null,
        getCompletedCourseListWithCertificates: [],
      };
    case COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_SUCCESS:
      return {
        ...state,
        getCompletedCourseListWithCertificatesLoading: false,
        getCompletedCourseListWithCertificatesSuccess: true,
        getCompletedCourseListWithCertificatesFailure: false,
        getCompletedCourseListWithCertificatesError: null,
        getCompletedCourseListWithCertificates: action.payload,
      };
    case COURSES_REQUEST.GET_COMPLETED_COURSE_LIST_WITH_CERTIFICATE_FAILURE:
      return {
        ...state,
        getCompletedCourseListWithCertificatesLoading: false,
        getCompletedCourseListWithCertificatesSuccess: false,
        getCompletedCourseListWithCertificatesFailure: true,
        getCompletedCourseListWithCertificatesError: action.payload,
      };

    case COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_REQUEST:
      return {
        ...state,
        getInProgressCourseListLoading: true,
        getInProgressCourseListSuccess: false,
        getInProgressCourseListFailure: false,
        getInProgressCourseListError: null,
        getInProgressCourseList: [],
      };
    case COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_SUCCESS:
      return {
        ...state,
        getInProgressCourseListLoading: false,
        getInProgressCourseListSuccess: true,
        getInProgressCourseListFailure: false,
        getInProgressCourseListError: null,
        getInProgressCourseList: action.payload,
      };
    case COURSES_REQUEST.GET_INPROGRESS_COURSE_LIST_FAILURE:
      return {
        ...state,
        getInProgressCourseListLoading: false,
        getInProgressCourseListSuccess: false,
        getInProgressCourseListFailure: true,
        getInProgressCourseListError: action.payload,
      };

    case COURSES_REQUEST.PURCHASE_CREDITS_REQUEST:
      return {
        ...state,
        purchasecreditLoading: true,
        purchasecreditSuccess: false,
        purchasecreditFailure: false,
        purchasecreditError: null,
        purchasecredit: [],
      };
    case COURSES_REQUEST.PURCHASE_CREDITS_SUCCESS:
      return {
        ...state,
        purchasecreditLoading: false,
        purchasecreditSuccess: true,
        purchasecreditFailure: false,
        purchasecreditError: null,
        purchasecredit: action.payload,
      };
    case COURSES_REQUEST.PURCHASE_CREDITS_FAILURE:
      return {
        ...state,
        purchasecreditLoading: false,
        purchasecreditSuccess: false,
        purchasecreditFailure: true,
        purchasecreditError: action.payload,
      };

    case COURSES_REQUEST.PURCHASE_CREDITS_REQUEST:
      return {
        ...state,
        purchaseCourseLoading: true,
        purchaseCourseSuccess: false,
        purchaseCourseFailure: false,
        purchaseCourseError: null,
        purchaseCourse: [],
      };
    case COURSES_REQUEST.PURCHASE_COURSE_SUCCESS:
      return {
        ...state,
        purchaseCourseLoading: false,
        purchaseCourseSuccess: true,
        purchaseCourseFailure: false,
        purchaseCourseError: null,
        purchaseCourse: action.payload,
      };
    case COURSES_REQUEST.PURCHASE_COURSE_FAILURE:
      return {
        ...state,
        purchaseCourseLoading: false,
        purchaseCourseSuccess: false,
        purchaseCourseFailure: true,
        purchaseCourseError: action.payload,
      };

    case COURSES_REQUEST.CREATE_LECTURE_REQUEST:
      return {
        ...state,
        createLectureLoading: true,
        createLectureSuccess: false,
        createLectureFailure: false,
        createLectureError: null,
        createLecture: [],
      };
    case COURSES_REQUEST.CREATE_LECTURE_SUCCESS:
      let obj = {
        lectureId: 1,
        lectureNmae: action.payload.lectureName,
      };

      let tempLectureList = state.getLectureByFilter;
      tempLectureList.push(obj);
      return {
        ...state,
        createLectureLoading: false,
        createLectureSuccess: true,
        createLectureFailure: false,
        createLectureError: null,
        // createLecture: action.payload,
        getLectureByFilter: tempLectureList,
      };
    case COURSES_REQUEST.CREATE_LECTURE_FAILURE:
      return {
        ...state,
        createLectureLoading: false,
        createLectureSuccess: false,
        createLectureFailure: true,
        createLectureError: action.payload,
      };

    case COURSES_REQUEST.CERTIFICATE_CONVERSION_REQUEST:
      return {
        ...state,
        certificateConversionLoading: true,
        certificateConversionSuccess: false,
        certificateConversionFailure: false,
        certificateConversionError: null,
        certificateConversion: [],
      };
    case COURSES_REQUEST.CERTIFICATE_CONVERSION_SUCCESS:
      return {
        ...state,
        certificateConversionLoading: false,
        certificateConversionSuccess: true,
        certificateConversionFailure: false,
        certificateConversionError: null,
        certificateConversion: action.payload,
      };
    case COURSES_REQUEST.CERTIFICATE_CONVERSION_FAILURE:
      return {
        ...state,
        certificateConversionLoading: false,
        certificateConversionSuccess: false,
        certificateConversionFailure: true,
        certificateConversionError: action.payload,
      };

    case COURSES_REQUEST.CREATE_COURSE_PRICING_REQUEST:
      return {
        ...state,
        createCoursePricingLoading: true,
        createCoursePricingSuccess: false,
        createCoursePricingFailure: false,
        createCoursePricingError: null,
        createCoursePricing: [],
      };
    case COURSES_REQUEST.CREATE_COURSE_PRICING_SUCCESS:
      return {
        ...state,
        createCoursePricingLoading: false,
        createCoursePricingSuccess: true,
        createCoursePricingFailure: false,
        createCoursePricingError: null,
        createCoursePricing: action.payload,
      };
    case COURSES_REQUEST.CREATE_COURSE_PRICING_FAILURE:
      return {
        ...state,
        createCoursePricingLoading: false,
        createCoursePricingSuccess: false,
        createCoursePricingFailure: true,
        createCoursePricingError: action.payload,
      };

    case COURSES_REQUEST.GET_LECTURE_BY_FILTER_REQUEST:
      return {
        ...state,
        getLectureByFilterLoading: true,
        getLectureByFilterSuccess: false,
        getLectureByFilterFailure: false,
        getLectureByFilterError: null,
        getLectureByFilter: [],
      };
    case COURSES_REQUEST.GET_LECTURE_BY_FILTER_SUCCESS:
      return {
        ...state,
        getLectureByFilterLoading: false,
        getLectureByFilterSuccess: true,
        getLectureByFilterFailure: false,
        getLectureByFilterError: null,
        getLectureByFilter: action.payload,
      };
    case COURSES_REQUEST.GET_LECTURE_BY_FILTER_FAILURE:
      return {
        ...state,
        getLectureByFilterLoading: false,
        getLectureByFilterSuccess: false,
        getLectureByFilterFailure: true,
        getLectureByFilterError: action.payload,
      };

    case COURSES_REQUEST.GET_SECTION_BY_FILTER_REQUEST:
      return {
        ...state,
        getSectionByFilterLoading: true,
        getSectionByFilterSuccess: false,
        getSectionByFilterFailure: false,
        getSectionByFilterError: null,
        getSectionByFilter: [],
      };
    case COURSES_REQUEST.GET_SECTION_BY_FILTER_SUCCESS:
      return {
        ...state,
        getSectionByFilterLoading: false,
        getSectionByFilterSuccess: true,
        getSectionByFilterFailure: false,
        getSectionByFilterError: null,
        getSectionByFilter: action.payload,
      };
    case COURSES_REQUEST.GET_SECTION_BY_FILTER_FAILURE:
      return {
        ...state,
        getSectionByFilterLoading: false,
        getSectionByFilternSuccess: false,
        getSectionByFilterFailure: true,
        getSectionByFilterError: action.payload,
      };

    case COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_REQUEST:
      return {
        ...state,
        getTopCategoriesCoursesLoading: true,
        getTopCategoriesCoursesSuccess: false,
        getTopCategoriesCoursesFailure: false,
        getTopCategoriesCoursesError: null,
        getTopCategoriesCourses: [],
      };
    case COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_SUCCESS:
      return {
        ...state,
        getTopCategoriesCoursesLoading: false,
        getTopCategoriesCoursesSuccess: true,
        getTopCategoriesCoursesFailure: false,
        getTopCategoriesCoursesError: null,
        getTopCategoriesCourses: action.payload,
      };
    case COURSES_REQUEST.GET_TOP_CATEGORIES_COURSES_FAILURE:
      return {
        ...state,
        getTopCategoriesCoursesLoading: false,
        getTopCategoriesCoursesSuccess: false,
        getTopCategoriesCoursesFailure: true,
        getTopCategoriesCoursesError: action.payload,
      };

    case COURSES_REQUEST.GET_RELATED_COURSES_REQUEST:
      return {
        ...state,
        getRelatedCoursesLoading: true,
        getRelatedCoursesSuccess: false,
        getRelatedCoursesFailure: false,
        getRelatedCoursesError: null,
        getRelatedCourses: [],
      };
    case COURSES_REQUEST.GET_RELATED_COURSES_SUCCESS:
      return {
        ...state,
        getRelatedCoursesLoading: false,
        getRelatedCoursesSuccess: true,
        getRelatedCoursesFailure: false,
        getRelatedCoursesError: null,
        getRelatedCourses: action.payload,
      };
    case COURSES_REQUEST.GET_RELATED_COURSES_FAILURE:
      return {
        ...state,
        getRelatedCoursesLoading: false,
        getRelatedCoursesSuccess: false,
        getRelatedCoursesFailure: true,
        getRelatedCoursesError: action.payload,
      };

    case COURSES_REQUEST.GET_VIDEO_TIME_STAMP_REQUEST:
      return {
        ...state,
        getVideoTimeStampLoading: true,
        getVideoTimeStampSuccess: false,
        getVideoTimeStampFailure: false,
        getVideoTimeStampError: null,
        getVideoTimeStamp: null,
      };
    case COURSES_REQUEST.GET_VIDEO_TIME_STAMP_SUCCESS:
      return {
        ...state,
        getVideoTimeStampLoading: false,
        getVideoTimeStampSuccess: true,
        getVideoTimeStampFailure: false,
        getVideoTimeStampError: null,
        getVideoTimeStamp: action.payload,
      };
    case COURSES_REQUEST.GET_VIDEO_TIME_STAMP_FAILURE:
      return {
        ...state,
        getVideoTimeStampLoading: false,
        getVideoTimeStampSuccess: false,
        getVideoTimeStampFailure: true,
        getVideoTimeStampError: action.payload,
      };

    case COURSES_REQUEST.GET_TOP_COURSES_REQUEST:
      return {
        ...state,
        getTopCoursesLoading: true,
        getTopCoursesSuccess: false,
        getTopCoursesFailure: false,
        getTopCoursesError: null,
        getTopCourses: [],
      };
    case COURSES_REQUEST.GET_TOP_COURSES_SUCCESS:
      return {
        ...state,
        getTopCoursesLoading: false,
        getTopCoursesSuccess: true,
        getTopCoursesFailure: false,
        getTopCoursesError: null,
        getTopCourses: action.payload,
      };
    case COURSES_REQUEST.GET_TOP_COURSES_FAILURE:
      return {
        ...state,
        getTopCoursesLoading: false,
        getTopCoursesSuccess: false,
        getTopCoursesFailure: true,
        getTopCoursesError: action.payload,
      };

    case COURSES_REQUEST.CHECK_QUIZ_ANSWER_REQUEST:
      return {
        ...state,
        checkQuizAnswerLoading: true,
        checkQuizAnswerSuccess: false,
        checkQuizAnswerFailure: false,
        checkQuizAnswerError: null,
        checkQuizAnswer: [],
      };
    case COURSES_REQUEST.CHECK_QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        checkQuizAnswerLoading: false,
        checkQuizAnswerSuccess: true,
        checkQuizAnswerFailure: false,
        checkQuizAnswerError: null,
        checkQuizAnswer: action.payload,
      };
    case COURSES_REQUEST.CHECK_QUIZ_ANSWER_FAILURE:
      return {
        ...state,
        checkQuizAnswerLoading: false,
        checkQuizAnswerSuccess: false,
        checkQuizAnswerFailure: true,
        checkQuizAnswerError: action.payload,
      };

    case COURSES_REQUEST.GET_PURCHASED_COURSES_REQUEST:
      return {
        ...state,
        getPurchasedCoursesLoading: true,
        getPurchasedCoursesSuccess: false,
        getPurchasedCoursesFailure: false,
        getPurchasedCoursesError: null,
        getPurchasedCourses: [],
      };
    case COURSES_REQUEST.GET_PURCHASED_COURSES_SUCCESS:
      return {
        ...state,
        getPurchasedCoursesLoading: false,
        getPurchasedCoursesSuccess: true,
        getPurchasedCoursesFailure: false,
        getPurchasedCoursesError: null,
        getPurchasedCourses: action.payload,
      };
    case COURSES_REQUEST.GET_PURCHASED_COURSES_FAILURE:
      return {
        ...state,
        getPurchasedCoursesLoading: false,
        getPurchasedCoursesSuccess: false,
        getPurchasedCoursesFailure: true,
        getPurchasedCoursesError: action.payload,
      };

    case COURSES_REQUEST.MARK_COURSE_AS_STARTED_REQUEST:
      return {
        ...state,
        markCourseAsStartedLoading: true,
        markCourseAsStartedSuccess: false,
        markCourseAsStartedFailure: false,
        markCourseAsStartedError: null,
        markCourseAsStarted: [],
      };
    case COURSES_REQUEST.MARK_COURSE_AS_STARTED_SUCCESS:
      return {
        ...state,
        markCourseAsStartedLoading: false,
        markCourseAsStartedSuccess: true,
        markCourseAsStartedFailure: false,
        markCourseAsStartedError: null,
        markCourseAsStarted: action.payload,
      };
    case COURSES_REQUEST.MARK_COURSE_AS_STARTED_FAILURE:
      return {
        ...state,
        markCourseAsStartedLoading: false,
        markCourseAsStartedSuccess: false,
        markCourseAsStartedFailure: true,
        markCourseAsStartedError: action.payload,
      };

    case COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_REQUEST:
      return {
        ...state,
        updateVideoTimeStampLoading: true,
        updateVideoTimeStampSuccess: false,
        updateVideoTimeStampFailure: false,
        updateVideoTimeStampError: null,
        updateVideoTimeStamp: [],
      };
    case COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_SUCCESS:
      return {
        ...state,
        updateVideoTimeStampLoading: false,
        updateVideoTimeStampSuccess: true,
        updateVideoTimeStampFailure: false,
        updateVideoTimeStampError: null,
        updateVideoTimeStamp: action.payload,
      };
    case COURSES_REQUEST.UPDATE_VIDEO_TIME_STAMP_FAILURE:
      return {
        ...state,
        updateVideoTimeStampLoading: false,
        updateVideoTimeStampSuccess: false,
        updateVideoTimeStampFailure: true,
        updateVideoTimeStampError: action.payload,
      };

    case COURSES_REQUEST.CERTIFICATE_RECEIVED_REQUEST:
      return {
        ...state,
        certificateReceivedLoading: true,
        certificateReceivedSuccess: false,
        certificateReceivedFailure: false,
        certificateReceivedError: null,
        certificateReceived: [],
      };
    case COURSES_REQUEST.CERTIFICATE_RECEIVED_SUCCESS:
      return {
        ...state,
        certificateReceivedLoading: false,
        certificateReceivedSuccess: true,
        certificateReceivedFailure: false,
        certificateReceivedError: null,
        certificateReceived: action.payload,
      };
    case COURSES_REQUEST.CERTIFICATE_RECEIVED_FAILURE:
      return {
        ...state,
        certificateReceivedLoading: false,
        certificateReceivedSuccess: false,
        certificateReceivedFailure: true,
        certificateReceivedError: action.payload,
      };

    case COURSES_REQUEST.GET_COURSE_CERTIFICATION_REQUEST:
      return {
        ...state,
        getCourseCertificationLoading: true,
        getCourseCertificationSuccess: false,
        getCourseCertificationFailure: false,
        getCourseCertificationError: null,
        getCourseCertification: [],
      };
    case COURSES_REQUEST.GET_COURSE_CERTIFICATION_SUCCESS:
      return {
        ...state,
        getCourseCertificationLoading: false,
        getCourseCertificationSuccess: true,
        getCourseCertificationFailure: false,
        getCourseCertificationError: null,
        getCourseCertification: action.payload,
      };
    case COURSES_REQUEST.GET_COURSE_CERTIFICATION_FAILURE:
      return {
        ...state,
        getCourseCertificationLoading: false,
        getCourseCertificationSuccess: false,
        getCourseCertificationFailure: true,
        getCourseCertificationError: action.payload,
      };

    default:
      return state;
  }
};
