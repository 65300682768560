import { request, success, failure, ADMIN_REQUEST } from "./utilities";
import { manageUser } from "../api";
export function ManageUsersAdmin(data) {
  return (dispatch) => {
    dispatch(request(ADMIN_REQUEST.MANAGE_USER_REQUEST));
    manageUser(data).then(
      (response) => {
        if (response.data.succeeded === true) {
          dispatch(
            success(ADMIN_REQUEST.MANAGE_USER_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(ADMIN_REQUEST.MANAGE_USER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            ADMIN_REQUEST.MANAGE_USER_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
