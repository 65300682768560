import { LECTURE_NOTES_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {


    createNotesLoading: false,
    createNotesSuccess: false,
    createNotesFailure: false,
    createNotesError: null,
    createNotes: [],

    getAllNotesByLectureLoading: false,
    getAllNotesByLectureSuccess: false,
    getAllNotesByLectureFailure: false,
    getAllNotesByLectureError: null,
    getAllNotesByLecture: [],

};

export const lectureNotesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LECTURE_NOTES_REQUEST.LECTURE_NOTES_REQUEST:
            return {
                ...state,
                createNotesLoading: true,
                createNotesSuccess: false,
                createNotesFailure: false,
                createNotesError: null,
            };
        case LECTURE_NOTES_REQUEST.LECTURE_NOTES_SUCCESS:
            return {
                ...state,
                createNotesLoading: false,
                createNotesSuccess: true,
                createNotesFailure: false,
                createNotesError: null,
                createfaqs: action.payload,
            };
        case LECTURE_NOTES_REQUEST.LECTURE_NOTES_FAILURE:
            return {
                ...state,
                createNotesLoading: false,
                createNotesSuccess: false,
                createNotesFailure: true,
                createNotesError: action.payload,
            };

        case LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_REQUEST:
            return {
                ...state,
                getAllNotesByLectureLoading: true,
                getAllNotesByLectureSuccess: false,
                getAllNotesByLectureFailure: false,
                getAllNotesByLectureError: null,
            };
        case LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_SUCCESS:
            return {
                ...state,
                getAllNotesByLectureLoading: false,
                getAllNotesByLectureSuccess: true,
                getAllNotesByLectureFailure: false,
                getAllNotesByLectureError: null,
                getAllNotesByLecture: action.payload,
            };
        case LECTURE_NOTES_REQUEST.GET_ALL_NOTES_BY_LECTURE_FAILURE:
            return {
                ...state,
                getAllNotesByLectureLoading: false,
                getAllNotesByLectureSuccess: false,
                getAllNotesByLectureFailure: true,
                getAllNotesByLectureError: action.payload,
            };

        default:
            return state;
    }
};
