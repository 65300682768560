import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownButton,
  Button,
  Modal,
} from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;

export default function AdminQuery() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Queries || EduTech`;
  }, []);
  return (
    <>
      <div className="admin-support">
        <Querry />
      </div>
    </>
  );
}

class Querry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
    };
  }

  columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "	Email",
    },
    {
      dataField: "subject",
      text: "Subject",
    },
    {
      dataField: "description",
      text: "Description",
    },

    {
      dataField: "more",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div
            className="float-right font-600 text-secondary d-flex"
            id="clinician-table-three-button-dropdown"
          >
            <div
              className="cursor manage-user"
              id="clinician-table-three-button-dropdown"
            >
              <DropdownButton
                id="clinician-table-three-button-dropdown"
                title={
                  <BsThreeDots
                    id="clinician-table-three-button-dropdown"
                    className="mr-2 cursor"
                  />
                }
                size="sm"
                alignRight
                bsPrefix="three-dot-icon"
                className="edutech-admin-three-dot-icon  clinician-drop-down"
              >
                {/* <Dropdown.Item as="button" className="add-org-field-dropdown"     >

                              <div className="edutech-admin-dots-inner" >

                                  {"View"}
                              </div>

                          </Dropdown.Item> */}
                <Dropdown.Item as="button" className="delete-field-dropdown">
                  <div className="edutech-admin-dots-inner">{"Approved"}</div>
                </Dropdown.Item>
                <Dropdown.Item as="button" className="delete-field-dropdown">
                  <div className="edutech-admin-dots-inner">{"Rejected"}</div>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        );
      },
      headerStyle: (column, colIndex) => {
        return {
          width: "75px",
        };
      },
    },
  ];

  products = [
    {
      name: "Muzamal",
      email: "example@gmail.com",
      subject: "Meeting",
      description: <QuerryModal />,
    },
    {
      name: "Haris",
      email: "example@gmail.com",
      subject: "Meeting",
      description: <QuerryModal />,
    },
  ];

  defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];

  handleDataChange = ({ dataSize }) => {
    this.setState({ rowCount: dataSize });
  };
  render() {
    return (
      <div>
        <div
          className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
        >
          <div className="card-header ">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className=" font-weight-700 mb-0">Queries Details</h3>
            </div>
          </div>
        </div>
        <div className="admin-side-table-styling card p-4">
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={this.products}
            columns={this.columns}
            columnToggle
            search
          >
            {(props) => (
              <div>
                <div className="text-left pt-3 pb-4 mt-0">
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: "250px", height: "40px" }}
                  />
                </div>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  {...props.baseProps}
                  filter={filterFactory()}
                  pagination={paginationFactory()}
                  defaultSorted={this.defaultSorted}
                  onDataSizeChange={this.handleDataChange}
                  noDataIndication="There is no record"
                  hover
                  condensed
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    );
  }
}
function QuerryModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span>Accelerated Reader...</span>
      <Button
        variant="primary"
        className="edutech-query-text"
        onClick={handleShow}
      >
        See More
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Accelerated Reader quizzes are available for many of our books. The AR
          Logo will appear where quizzes are available. For more about AR,
          please click here.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
