import { COURSE_WISH_LIST_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {


    createLoading: false,
    createSuccess: false,
    createFailure: false,
    createError: null,
    create: [],

    removeLoading: false,
    removeSuccess: false,
    removeFailure: false,
    removeError: null,
    remove: [],

    getWishlistCoursesLoading: false,
    getWishlistCoursesSuccess: false,
    getWishlistCoursesFailure: false,
    getWishlistCoursesError: null,
    getWishlistCourses: [],

};

export const courseWishListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COURSE_WISH_LIST_REQUEST.CREATE_REQUEST:
            return {
                ...state,
                createLoading: true,
                createSuccess: false,
                createFailure: false,
                createError: null,
            };
        case COURSE_WISH_LIST_REQUEST.CREATE_SUCCESS:
            return {
                ...state,
                createLoading: false,
                createSuccess: true,
                createFailure: false,
                createError: null,
                create: action.payload,
            };
        case COURSE_WISH_LIST_REQUEST.CREATE_FAILURE:
            return {
                ...state,
                createLoading: false,
                createSuccess: false,
                createFailure: true,
                createError: action.payload,
            };

        case COURSE_WISH_LIST_REQUEST.REMOVE_REQUEST:
            return {
                ...state,
                removeLoading: true,
                removeSuccess: false,
                removeFailure: false,
                removeError: null,
            };
        case COURSE_WISH_LIST_REQUEST.REMOVE_SUCCESS:
            return {
                ...state,
                removeLoading: false,
                removeSuccess: true,
                removeFailure: false,
                removeError: null,
                remove: action.payload,
            };
        case COURSE_WISH_LIST_REQUEST.REMOVE_FAILURE:
            return {
                ...state,
                removeLoading: false,
                removeSuccess: false,
                removeFailure: true,
                removeError: action.payload,
            };

        case COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_REQUEST:
            return {
                ...state,
                getWishlistCoursesLoading: true,
                getWishlistCoursesSuccess: false,
                getWishlistCoursesFailure: false,
                getWishlistCoursesError: null,
            };
        case COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_SUCCESS:
            return {
                ...state,
                getWishlistCoursesLoading: false,
                getWishlistCoursesSuccess: true,
                getWishlistCoursesFailure: false,
                getWishlistCoursesError: null,
                getWishlistCourses: action.payload,
            };
        case COURSE_WISH_LIST_REQUEST.GET_WISH_LIST_COURSES_FAILURE:
            return {
                ...state,
                getWishlistCoursesLoading: false,
                getWishlistCoursesSuccess: false,
                getWishlistCoursesFailure: true,
                getWishlistCoursesError: action.payload,
            };

        default:
            return state;
    }
};
