import { FAQS_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  createfaqsLoading: false,
  createfaqsSuccess: false,
  createfaqsFailure: false,
  createfaqsError: null,
  createfaqs: [],

  updateFaqsLoading: false,
  updateFaqsSuccess: false,
  updateFaqsFailure: false,
  updateFaqsError: null,
  updateFaqs: [],

  getFAQByStudentLoading: false,
  getFAQByStudentSuccess: false,
  getFAQByStudentFailure: false,
  getFAQByStudentError: null,
  getFAQByStudent: [],

  getFAQByProviderLoading: false,
  getFAQByProviderSuccess: false,
  getFAQByProviderFailure: false,
  getFAQByProviderError: null,
  getFAQByProvider: [],
};

export const FaqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAQS_REQUEST.CREATE_FAQS_REQUEST:
      return {
        ...state,
        createfaqsLoading: true,
        createfaqsSuccess: false,
        createfaqsFailure: false,
        createfaqsError: null,
      };
    case FAQS_REQUEST.CREATE_FAQS_SUCCESS:
      return {
        ...state,
        createfaqsLoading: false,
        createfaqsSuccess: true,
        createfaqsFailure: false,
        createfaqsError: null,
        createfaqs: action.payload,
      };
    case FAQS_REQUEST.CREATE_FAQS_FAILURE:
      return {
        ...state,
        createfaqsLoading: false,
        createfaqsSuccess: false,
        createfaqsFailure: true,
        createfaqsError: action.payload,
      };

    case FAQS_REQUEST.UPDATE_FAQS_REQUEST:
      return {
        ...state,
        updateFaqsLoading: true,
        updateFaqsSuccess: false,
        updateFaqsFailure: false,
        updateFaqsError: null,
      };
    case FAQS_REQUEST.UPDATE_FAQS_SUCCESS:
      return {
        ...state,
        updateFaqsLoading: false,
        updateFaqsSuccess: true,
        updateFaqsFailure: false,
        updateFaqsError: null,
        updateFaqs: action.payload,
      };
    case FAQS_REQUEST.UPDATE_FAQS_FAILURE:
      return {
        ...state,
        updateFaqsLoading: false,
        updateFaqsSuccess: false,
        updateFaqsFailure: true,
        updateFaqsError: action.payload,
      };

    case FAQS_REQUEST.GET_FAQ_BY_STUDENT_REQUEST:
      return {
        ...state,
        getFAQByStudentLoading: true,
        getFAQByStudentSuccess: false,
        getFAQByStudentFailure: false,
        getFAQByStudentError: null,
      };
    case FAQS_REQUEST.GET_FAQ_BY_STUDENT_SUCCESS:
      return {
        ...state,
        getFAQByStudentLoading: false,
        getFAQByStudentSuccess: true,
        getFAQByStudentFailure: false,
        getFAQByStudentError: null,
        getFAQByStudent: action.payload,
      };
    case FAQS_REQUEST.GET_FAQ_BY_STUDENT_FAILURE:
      return {
        ...state,
        getFAQByStudentLoading: false,
        getFAQByStudentSuccess: false,
        getFAQByStudentFailure: true,
        getFAQByStudentError: action.payload,
      };

    case FAQS_REQUEST.GET_FAQ_BY_PROVIDER_REQUEST:
      return {
        ...state,
        getFAQByProviderLoading: true,
        getFAQByProviderSuccess: false,
        getFAQByProviderFailure: false,
        getFAQByProviderError: null,
      };
    case FAQS_REQUEST.GET_FAQ_BY_PROVIDER_SUCCESS:
      return {
        ...state,
        getFAQByProviderLoading: false,
        getFAQByProviderSuccess: true,
        getFAQByProviderFailure: false,
        getFAQByProviderError: null,
        getFAQByProvider: action.payload,
      };
    case FAQS_REQUEST.GET_FAQ_BY_PROVIDER_FAILURE:
      return {
        ...state,
        getFAQByProviderLoading: false,
        getFAQByProviderSuccess: false,
        getFAQByProviderFailure: true,
        getFAQByProviderError: action.payload,
      };
    default:
      return state;
  }
};
