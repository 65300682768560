import React, { useEffect } from "react";
import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { PATH } from "../../../../config";
import { useHistory } from "react-router";
import { IoMdArrowDropdown } from "react-icons/io";
import { useForm } from "react-hook-form";
import { FieldError, Loader } from "../../../../assets";
import { GetAllApprovedInterest } from "../../../../redux/actions/interestAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { CreateCourses } from "../../../../redux/actions/coursesAction";
import { useAuth } from "../../../../Navigation/Auth/ProvideAuth";
import { uploadImage } from "../../../../redux/api";
import ReactPlayer from "react-player";
export default function TeacherNewCourse() {
  const { register, handleSubmit, errors } = useForm();
  const [courseTitle, setCourseTitle] = useState("");
  const [courseSubtitle, setCourseSubtitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  let history = useHistory();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllApprovedInterest());
  }, [dispatch]);
  let interestValue = useSelector((state) => state.interest);
  let CourseValue = useSelector((state) => state.courses);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryText, setCategoryText] = useState(null);
  function goBack() {
    history.push(PATH.TEACHERCURRICULUMPUBLISH);
  }
  let auth = useAuth();
  function onSubmit(data) {
    let finaldata = {
      courseTitle: data.courseTitle,
      courseSubtitle: data.courseSubtitle,
      companyName: data.companyName,
      categoryId: categoryId,
      // courseCertificateURL: profilePicture,
      coursePreviewPictureURL: imagePreview,
      coursePreviewVideoURL: lectureVideo,
      courseDescription: courseDescription,
    };
    {
      if (imagePreview && lectureVideo) {
        dispatch(CreateCourses(finaldata, auth, moveToNext));
      } else {
        toast.error("Please Upload file", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  function moveToNext() {
    history.push({
      pathname: PATH.TEACHERCURRICULUMLIST,
      CourseValue: CourseValue,
      // state: { fromPreviewPage: false },
      // finaldata: finaldata, categoryId: categoryId, profilePicture: profilePicture
    });
  }
  const [profilePicture, setProfilePicture] = useState(null);
  const onChangeProfilePicture = (e) => {
    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const fsize = e.target.files.item(i).size;
        // const file = Math.round(fsize / 1024);
        // The size of the file.
        if (fsize >= 20000) {
          alert("File too Big, please select a file less than 4mb");
        } else {
          let file = e.target.files[0];
          const formData = new FormData();
          formData.append("file", file, file.name);
          if (file) {
            setProfilePicture(!profilePicture);
            uploadImage(formData).then(
              (response) => {
                if (response.data.succeeded === true) {
                  setProfilePicture(response.data.data);
                }
              },
              (error) => {}
            );
          }
        }
      }
    }
    // let file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("file", file, file.name);
    // if (file) {
    //   setProfilePicture(!profilePicture);
    //   uploadImage(formData).then(
    //     (response) => {
    //       if (response.data.succeeded === true) {
    //         setProfilePicture(response.data.data);
    //       }
    //     },
    //     (error) => {}
    //   );
    // }
  };
  const [imagePreview, setImagePreview] = useState(null);
  const onChangeImagePreview = (e) => {
    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const fsize = e.target.files.item(i).size;
        // const file = Math.round(fsize / 1024);
        // The size of the file.
        debugger;
        if (fsize >= 4135700) {
          alert("File too Big, please select a file less than 4mb");
        } else {
          let file = e.target.files[0];
          const formData = new FormData();
          formData.append("file", file, file.name);
          if (file) {
            setImagePreview(!imagePreview);
            uploadImage(formData).then(
              (response) => {
                if (response.data.succeeded === true) {
                  setImagePreview(response.data.data);
                }
              },
              (error) => {}
            );
          }
        }
      }
    }

    // let file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("file", file, file.name);
    // if (file) {
    //   setImagePreview(!imagePreview);
    //   uploadImage(formData).then(
    //     (response) => {
    //       if (response.data.succeeded === true) {
    //         setImagePreview(response.data.data);
    //       }
    //     },
    //     (error) => {}
    //   );
    // }
  };
  const [lectureVideo, setLectureVideo] = useState("");
  // const onChangeProfilePicture = (e) => {
  //   let file = e.target.files[0];
  //   if (file) {
  //     convertBase64(file).then((getValue) => {
  //       setProfilePicture((getValue));
  //     });
  //   }
  // };
  const onChangeVideoUploader = (e) => {
    if (e.target.files.length > 0) {
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        const fsize = e.target.files.item(i).size;
        // const file = Math.round(fsize / 1024);
        // The size of the file.
        // if (fsize >= 10000000) {
        //   alert("File too Big, please select a file less than 10MB");
        // } else {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file, file.name);
        if (file) {
          setLectureVideo(!lectureVideo);
          uploadImage(formData).then(
            (response) => {
              if (response.data.succeeded === true) {
                setLectureVideo(response.data.data);
              }
            },
            (error) => {}
          );
          // }
        }
      }
    }
    // let file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("file", file, file.name);
    // if (file) {
    //   setLectureVideo(!lectureVideo);
    //   uploadImage(formData).then(
    //     (response) => {
    //       if (response.data.succeeded === true) {
    //         setLectureVideo(response.data.data);
    //       }
    //     },
    //     (error) => {}
    //   );
    // }
  };
  return (
    <>
      <ToastContainer />
      <div className=" teacher-dashboard ">
        <h3
          className="d-flex align-items-center cursor"
          onClick={() => goBack()}
        >
          <BsArrowLeft size={35} className="mr-2" /> Course Information
        </h3>
        <hr />
        <p className="pb-2">
          Add basic information about the course and the company name.
          {/* To add author
          image and bio, please visit Site Bios. Don’t worry, you can always
          change these details later. */}
        </p>

        <div className="card p-5 set-card-radius ">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-8 ">
                <Form.Group>
                  <Form.Label>Course title</Form.Label>
                  <Form.Control
                    className="mt-1"
                    name="courseTitle"
                    type="text"
                    placeholder="e.g. ‘Advanced Photoshop Techniques’ or ‘Watercolor for Dummies’"
                    value={courseTitle}
                    onChange={(e) => {
                      setCourseTitle(e.target.value);
                    }}
                    style={{
                      borderColor:
                        errors && errors.courseTitle ? "#a80000" : "",
                    }}
                    ref={register({ required: true })}
                  />
                  {errors.courseTitle && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Course subtitle </Form.Label>
                  <Form.Control
                    className="mt-1"
                    name="courseSubtitle"
                    type="text"
                    placeholder="e.g. ‘Everything you need to know about video editing’"
                    value={courseSubtitle}
                    onChange={(e) => {
                      setCourseSubtitle(e.target.value);
                    }}
                    ref={register({ required: true })}
                    style={{
                      borderColor:
                        errors && errors.courseSubtitle ? "#a80000" : "",
                    }}
                  />
                  {errors.courseSubtitle && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <Form.Group className="dropdown_bg_grey height_adjust_dropdown">
                  <Form.Label>Category</Form.Label>
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="text-left"
                      value={categoryText}
                      ref={register({ required: true })}
                      onChange={(e) => {
                        setCategoryText(e.target.value);
                      }}
                      style={{
                        borderColor:
                          errors && errors.categoryText ? "#a80000" : "",
                      }}
                    >
                      {categoryText ? categoryText : "Select "}
                      <IoMdArrowDropdown />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow search_field">
                      {interestValue.getAllApprovedInterest.map(
                        (item, index) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                setCategoryText(item.description);
                                setCategoryId(item.interestId);
                              }}
                              key={index}
                            >
                              {item.description}
                            </Dropdown.Item>
                          );
                        }
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.categoryText && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Company name (Optional)</Form.Label>
                  <Form.Control
                    className="mt-1"
                    name="companyName"
                    type="text"
                    placeholder="e.g. Company Name"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    ref={register()}
                    // style={{
                    //   borderColor: errors && errors.companyName ? "#a80000" : "",
                    // }}
                  />
                  {/* {errors.companyName && (
                    <FieldError message={"This Field is Required"} />
                  )} */}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Course Description</Form.Label>
                  <Form.Control
                    className="mt-1"
                    name="courseDescription"
                    as="textarea"
                    rows={1}
                    placeholder="e.g. Course Description"
                    value={courseDescription}
                    onChange={(e) => {
                      setCourseDescription(e.target.value);
                    }}
                    ref={register({ required: true })}
                    style={{
                      borderColor:
                        errors && errors.courseDescription ? "#a80000" : "",
                    }}
                  />
                  {errors.courseDescription && (
                    <FieldError message={"This Field is Required"} />
                  )}
                </Form.Group>
                <div className=" row d-flex justify-content-between px-2 pt-4 mt-4">
                  <div className="col-md-6 col-lg-6 col-sm-12 pt-4 ">
                    <h4>Upload Preview Image</h4>
                    <button type="button" className="upload_thumbnail_btn mt-3">
                      <Form.File
                        custom
                        bsCustomPrefix="form-file-input"
                        name="files"
                        id="upload-file"
                        className="upload_thumbnail_input mt-1"
                        data-browse="UPLOAD"
                        accept="image/*"
                        onChange={(e) => {
                          onChangeImagePreview(e);
                        }}
                      />
                      Upload file
                    </button>
                    <div className="card border-0  bg-white set-card-radius mt-3 ">
                      {/* <div className="d-flex justify-content-between px-2 mb-2 pb-4 pt-4 mt-2 align-items-center"> */}
                      <div className=" ">
                        <img
                          src={imagePreview ? imagePreview : ""}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-12 py-4 ">
                    <h4>Upload Course Video</h4>
                    <div className="  mt-4">
                      {lectureVideo === true || lectureVideo === false ? (
                        <Loader />
                      ) : (
                        <>
                          <button
                            type="button"
                            className="upload_video_btn mb-3"
                          >
                            <input
                              type="file"
                              name="video"
                              placeholder="Uploading......"
                              data-browse="UPLOAD"
                              className="upload_video_input"
                              accept="video/*"
                              onChange={(e) => {
                                onChangeVideoUploader(e);
                              }}
                            />
                            Upload file{" "}
                            <span className="video-size-class text-light">
                              (Upto 10MB)
                            </span>
                          </button>
                          <ReactPlayer
                            url={lectureVideo ? lectureVideo : ""}
                            width="100%"
                            height="100%"
                            className={` ${
                              lectureVideo !== null ? "video_show_set" : ""
                            }`}
                          />
                          {/* {
                        setvideoFile(null)} */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4 upload-cert">
                <Uploadcertificate
                  profilePicture={profilePicture}
                  setProfilePicture={setProfilePicture}
                  onChangeProfilePicture={onChangeProfilePicture}
                />
              </div> */}
            </div>
            <div className="row justify-content-md-end justify-content-center mt-4 pt-3 mb-1 pr-3">
              <button type="submit" className="next-course-btn btn-save w-25">
                Next
              </button>
            </div>
          </Form>
        </div>
        <hr></hr>
      </div>
    </>
  );
}
function Uploadcertificate(props) {
  const { register } = useForm();
  return (
    <>
      <div className=" row d-flex justify-content-between px-2  ">
        <div className="col-md-12 col-lg-12 col-sm-12 py-4 certificate-btn">
          <button type="button" className="upload_thumbnail_btn mt-3">
            Upload Certificate
            <Form.File
              custom
              bsCustomPrefix="form-file-input"
              id="upload-file"
              className="upload_thumbnail_input"
              data-browse="UPLOAD"
              accept="image/*"
              onChange={(e) => {
                props.onChangeProfilePicture(e);
              }}
              ref={register({ required: true })}
            />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12  ">
          <div className="card border-0 bg-white set-card-radius ">
            {/* <div className="d-flex justify-content-between px-2 mb-2 pb-4 pt-4 mt-2 align-items-center"> */}
            <div className="col-md-12 col-lg-12 col-sm-12 ">
              <img
                src={props.profilePicture ? props.profilePicture : ""}
                className="img-fluid"
                alt=""
              />
            </div>

            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
function VideoInput(props) {
  const { height } = props;
  const inputRef = React.useRef();
  const [source, setSource] = React.useState();
  if (source) {
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
    props.setLectureVideo(url);
  };
  const handleChoose = (event) => {
    inputRef.current.click();
  };
  return (
    <>
      {source ? (
        <>
          <input
            ref={inputRef}
            // className="VideoInput_input"
            className="VideoInput_input_after_upload"
            type="file"
            onChange={handleFileChange}
            accept=".mov,.mp4"
          />
          <video
            className="VideoInput_video "
            width="100%"
            height={height}
            fluid={false}
            controls
            src={source}
          />
        </>
      ) : (
        <div className="VideoInput">
          <input
            ref={inputRef}
            className="VideoInput_input"
            type="file"
            onChange={handleFileChange}
            accept=".mov,.mp4"
          />
          {!source && (
            <button
              onClick={handleChoose}
              className="video-btn-style"
              type="button"
            >
              <span> Click to Browse your video</span>
            </button>
          )}
          {source && (
            <video
              className="VideoInput_video "
              width="100%"
              height={height}
              controls
              src={source}
            />
          )}
          <div className="">{source || ""}</div>
        </div>
      )}
    </>
  );
}
