import React from "react";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Loader } from "../../../assets/genericComponents/Loader";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { ErrorMessage } from "./../../../assets";
export default function CourseProviders() {
  let user = useSelector((state) => state.admin);
  let data = user && user.manageUser;
  let columns = [
    {
      dataField: "fullName",
      text: "Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "email",
      text: "Email",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "provider.providerStatus",
      text: "Provider Status",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "provider.region",
      text: "Region ",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
    {
      dataField: "provider.experience",
      text: "Experience",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];

  return (
    <>
      {user &&
        user.manageUserLoading === true &&
        user.manageUserSuccess === false &&
        user.manageUserFailure === false && <Loader />}
      {user &&
        user.manageUserLoading === false &&
        user.manageUserSuccess === false &&
        user.manageUserFailure === true && (
          <ErrorMessage message={user.manageUserError} />
        )}
      {user &&
        user.manageUserLoading === false &&
        user.manageUserSuccess === true &&
        user.manageUserFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>
  );
}
