import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets";
import { PATH } from "../../../config";

export default function TeacherCreateCourse() {
  var [showbordercolor, setbordercolor] = useState(0);
  function setborder(val) {
    setbordercolor(val);
  }
  return (
    <div>
      <h2 className="font-weight-bold">What product do you want to create?</h2>
      <hr className="edutech-hr-colors" />
      <div className="container">
        <Card className="bg-color:white mx-5 my-5 mb-0 edutech-sm-margin ">
          <Card.Body>
            <div className="row mt-4">
              <div className="col-12 col-md-6 col-lg-6 pt-2 edutech-card-padding cursor">
                {showbordercolor === 1 ? (
                  <span className="edutech-bordercolor-check">
                    {" "}
                    <BsCheckLg />
                  </span>
                ) : (
                  ""
                )}
                <Card
                  className={`${
                    showbordercolor === 1 ? "edutech-bordercolor" : ""
                  } bg-light edutech-courses-cards pt-3 mb-0 edutech-sm-margin`}
                  onClick={() => setborder(1)}
                >
                  <Card.Img
                    variant="top"
                    src={IMAGES.GROUP}
                    class=" mx-auto d-block  "
                  />
                  <Card.Body>
                    <p className="edutech-card-course-text">Courses</p>
                    <p className="px-3 mb-0 edutech-card-inner-text">
                      {" "}
                      With a course, you can build a curriculum for your
                      students that can be self-paced or guided directly by you,
                      the instructor.
                    </p>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-12 col-md-6 col-lg-6 pt-2 edutech-card2-padding cursor">
                {showbordercolor === 2 ? (
                  <span className="edutech-bordercolor-check">
                    {" "}
                    <BsCheckLg />
                  </span>
                ) : (
                  ""
                )}
                <Card
                  className={`${
                    showbordercolor === 2 ? "edutech-bordercolor" : ""
                  } bg-light edutech-courses-cards pt-3 mb-0 edutech-sm-margin`}
                  onClick={() => setborder(2)}
                >
                  <Card.Img
                    variant="top"
                    src={IMAGES.FRAME}
                    class="rounded mx-auto d-block "
                  />
                  <Card.Body>
                    <p className="edutech-card-course-text">Coaching</p>
                    <p className="px-3 mb-0 edutech-card-inner-text">
                      {" "}
                      With coaching, you can collaborate with your students
                      more, helping them reach milestones through scheduled
                      video calls.
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-5 mb-4 edutech-sm-button-padding">
              <div className="col-12 col-md-4">
                {showbordercolor === 1 ? (
                  <Link
                    to={PATH.TEACHERCOURSEINFORMATION}
                    className="text-white"
                  >
                    <Button className={`py-3 edutech-button-color`} block>
                      {"Next"}
                    </Button>
                  </Link>
                ) : showbordercolor === 2 ? (
                  <Link to={PATH.TEACHERCREATECOACHING} className="text-white">
                    <Button className={`py-3 edutech-button-color`} block>
                      {"Next"}
                    </Button>
                  </Link>
                ) : (
                  <Button className={`py-3 edutech-button-color`} block>
                    {"Next"}
                  </Button>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
