import { request, success, failure, FORGET_PASSWORD_REQUEST } from "./utilities";
import { checkCode, checkEmail, put } from "../api/forgetPasswordApi";

export function CheckEmail(emailAddress, moveToNext, Notification) {
  return (dispatch) => {
    dispatch(request(FORGET_PASSWORD_REQUEST.CHECK_EMAIL_REQUEST));
    checkEmail(emailAddress).then(
      (response) => {

        if (response.data.succeeded === true) {
          dispatch(
            success(
              FORGET_PASSWORD_REQUEST.CHECK_EMAIL_SUCCESS,
              response.data
            )

          );
          if (moveToNext) {
            moveToNext()
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              FORGET_PASSWORD_REQUEST.CHECK_EMAIL_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            FORGET_PASSWORD_REQUEST.CHECK_EMAIL_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function CheckCode(emailAddress, code, moveToNext, Notification) {
  return (dispatch) => {
    dispatch(request(FORGET_PASSWORD_REQUEST.CHECK_CODE_REQUEST));
    checkCode(emailAddress, code).then(
      (response) => {

        if (response.data.succeeded === true) {
          dispatch(
            success(
              FORGET_PASSWORD_REQUEST.CHECK_CODE_SUCCESS,
              response.data
            )
          );
          if (moveToNext) {
            moveToNext()
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              FORGET_PASSWORD_REQUEST.CHECK_CODE_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            FORGET_PASSWORD_REQUEST.CHECK_CODE_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function Put(data, moveToNext, Notification) {
  return (dispatch) => {
    dispatch(request(FORGET_PASSWORD_REQUEST.PUT_REQUEST));
    put(data).then(
      (response) => {

        if (response.data.succeeded === true) {
          dispatch(
            success(
              FORGET_PASSWORD_REQUEST.PUT_SUCCESS,
              response.data
            )
          );
          if (moveToNext) {
            moveToNext()
          }
          Notification(response.data.message, "success");
        } else {
          dispatch(
            failure(
              FORGET_PASSWORD_REQUEST.PUT_FAILURE,
              response.data.message
            )
          );
          Notification(response.data.message, "error");
        }
      },
      (error) => {
        dispatch(
          failure(
            FORGET_PASSWORD_REQUEST.PUT_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
