import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function MarkAsRead(notificationId) {
  return axios.get(`${APP_SETTINGS.API_PATH.NOTIFICATION.MarkAsRead}?notificationId=${notificationId}`, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  }
  );
}
export function GetNotificationByUserId(auth) {
  return axios.get(`${APP_SETTINGS.API_PATH.NOTIFICATION.GetNotificationByUserId}`, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  }
  );
}