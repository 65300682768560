// A wrapper for <Route> that redirects to the login

import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { PATH } from "../../config";
import { useAuth } from "./ProvideAuth";
import { ParentComponentWithSideBar } from "../../assets";
import { ParentComponentwithoutContainer } from "../../assets/genericComponents/ParentComponentwithoutContainer";
import { toast } from "react-toastify";
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  const setGoalTimeOut = +window.localStorage.getItem('goalTime')
  console.log("asdsadasdsa", setGoalTimeOut)
  useEffect(() => {
    const setGoalTimeOut = +window.localStorage.getItem('goalTime')
    if (setGoalTimeOut !== null) {
      if (setGoalTimeOut === 15) {
        setTimeout(() => {
          // console.log('Hello, World!')
          NotificationAlert()
        }, 150000);
      } else if (setGoalTimeOut === 30) {
        setTimeout(() => {
          // console.log('Hello, missyyyy!')
          NotificationAlert()
        }, 300000);
      } else if (setGoalTimeOut === 60) {
        setTimeout(() => {
          // console.log('Hello, missyyyy!')
          NotificationAlert()
        }, 600000);
      } else if (setGoalTimeOut === 120) {
        setTimeout(() => {
          // console.log('Hello, missyyyy!')
          NotificationAlert()
        }, 1200000);
      }
    }
  }, [window.localStorage.getItem('goalTime')])
  function NotificationAlert(data) {
    window.localStorage.removeItem('goalTime');
    toast.success("Your Goal has Timed out", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return (
    <>
      <Route
        {...rest}
        onUpdate={() => window.scrollTo(0, 0)}
        render={({ location }) =>
          auth.edu_tech_user && auth.edu_tech_user.token ? (
            <>
              {rest.path === PATH.COURSE || rest.path === PATH.EXPLORE ? (
                <ParentComponentwithoutContainer>
                  {children}
                </ParentComponentwithoutContainer>
              ) : (
                <ParentComponentWithSideBar>
                  {children}
                </ParentComponentWithSideBar>
              )}
            </>
          ) : (
            <Redirect
              to={{
                pathname: PATH.SIGNUP,
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
}
export default PrivateRoute;
