import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function createPermission(data, auth) {
  return axios.post(
    APP_SETTINGS.API_PATH.MENU_PERMISSION.createPermission,
    data,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getMenuPermissionById(userId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.MENU_PERMISSION.getMenuPermissionById}?userId=${userId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
