import { CONTACT_US_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  createLoading: false,
  createSuccess: false,
  createFailure: false,
  createError: null,
  create: [],

  getByFilterConatctUsLoading: false,
  getByFilterConatctUsSuccess: false,
  getByFilterConatctUsFailure: false,
  getByFilterConatctUsError: null,
  getByFilterConatctUs: [],
};

export const contactUsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONTACT_US_REQUEST.CREATE_REQUEST:
      return {
        ...state,
        createLoading: true,
        createSuccess: false,
        createFailure: false,
        createError: null,
      };
    case CONTACT_US_REQUEST.CREATE_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createSuccess: true,
        createFailure: false,
        createError: null,
        create: action.payload,
      };
    case CONTACT_US_REQUEST.CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createSuccess: false,
        createFailure: true,
        createError: action.payload,
      };

    case CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_REQUEST:
      return {
        ...state,
        getByFilterConatctUsLoading: true,
        getByFilterConatctUsSuccess: false,
        getByFilterConatctUsFailure: false,
        getByFilterConatctUsError: null,
      };
    case CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_SUCCESS:
      return {
        ...state,
        getByFilterConatctUsLoading: false,
        getByFilterConatctUsSuccess: true,
        getByFilterConatctUsFailure: false,
        getByFilterConatctUsError: null,
        getByFilterConatctUs: action.payload,
      };
    case CONTACT_US_REQUEST.GET_BY_FILTER_CONTACT_US_FAILURE:
      return {
        ...state,
        getByFilterConatctUsLoading: false,
        getByFilterConatctUsSuccess: false,
        getByFilterConatctUsFailure: true,
        getByFilterConatctUsError: action.payload,
      };

    default:
      return state;
  }
};
