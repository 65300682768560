import { APP_SETTINGS } from "../../config";
import axios from "axios";

export function createCourses(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.createCourses, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function createSection(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.createSection, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function createCoursePricing(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.createCoursePricing, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function createLecture(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.createLecture, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getSectionByFilter(CourseId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.getSectionByFilter}?CourseId=${CourseId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getLectureByFilter(CourseId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.getLectureByFilter}?CourseId=${CourseId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function checkQuizAnswer(QuizOptionId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.checkQuizAnswer}?QuizOptionId=${QuizOptionId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function certificateConversion(data, auth) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COURSES.certificateConversion}?courseId=${data.courseId}`,
    { courseId: data.courseId },
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getTopCategoriesCourses(CategoryId) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.getTopCategoriesCourses}?CategoryId=${CategoryId}`,
    {
      // headers: {
      //   Authorization: "Bearer " + auth.edu_tech_user.token,
      // },
    }
  );
}
export function getVideoTimeStamp(courseId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.getVideoTimeStamp}?courseId=${courseId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getRelatedCourses(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COURSES.getRelatedCourses, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getTopCourses(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COURSES.getTopCourses, {
    // headers: {
    //   Authorization: "Bearer " + auth.edu_tech_user.token,
    // },
  });
}
export function getPurchasedCourses(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COURSES.getPurchasedCourses, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getInProgressCourseList(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COURSES.getInProgressCourseList, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getCompletedCourseListWithCertificates(auth) {
  return axios.get(
    APP_SETTINGS.API_PATH.COURSES.getCompletedCourseListWithCertificates,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getById(CourseId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.getById}?CourseId=${CourseId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getCourseCertification(CourseId, auth) {
  return axios.get(
    `${APP_SETTINGS.API_PATH.COURSES.getCourseCertification}?CourseId=${CourseId}`,
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function getCourseList(auth) {
  return axios.get(APP_SETTINGS.API_PATH.COURSES.getCourseList, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function publishCourse(data, auth) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COURSES.publishCourse}?CourseId=${data.CourseId}`,
    { CourseId: data.CourseId },
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function certificateReceived(CourseId, auth) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COURSES.certificateReceived}?CourseId=${CourseId}`,
    { CourseId: CourseId },
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function markCourseAsStarted(CourseId, auth) {
  return axios.post(
    `${APP_SETTINGS.API_PATH.COURSES.markCourseAsStarted}?CourseId=${CourseId}`,
    { CourseId: CourseId },
    {
      headers: {
        Authorization: "Bearer " + auth.edu_tech_user.token,
      },
    }
  );
}
export function purchasecredit(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.purchasecredit, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function updateVideoTimeStamp(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.updateVideoTimeStamp, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function purchaseCourse(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.COURSES.purchaseCourse, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
