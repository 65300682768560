import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { ProviderDashboardTotalEarnings } from "../../../redux/actions/dashboardTilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { ErrorMessage, Loader, subscriptionData } from "../../../assets";

export function Subscriber() {
  let dispatch = useDispatch();
  let auth = useAuth();
  let report = useSelector((state) => state.dashboardTiles);
  let CoursesData = report && report.providerDashboardTotalEarnings;
  useEffect(() => {
    dispatch(ProviderDashboardTotalEarnings(auth));
  }, [dispatch]);
  const options = {
    title: {
      text: "Subscribers",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Subscribed Courses",
      },
    },
    xAxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    labels: {
      items: [
        {
          html: "Total Subscribers",
          style: {
            left: "50px",
            top: "18px",
            color:
              // theme
              (Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color) ||
              "black",
          },
        },
      ],
    },
    series: [
      {
        name: "Subscriber",
        type: "column",
        data: subscriptionData(
          "diamond",
          report.providerDashboardTotalEarnings
        ),
        color: Highcharts.getOptions().colors[1],
      },
    ],
  };
  return (
    <>
      {report && report.providerDashboardTotalEarningsLoading === true && (
        <Loader />
      )}
      {
        // report
        // &&
        // report.providerDashboardTotalEarningsSuccess === true
        // &&
        <PieChart highcharts={Highcharts} options={options} />
      }
      {report &&
        report.providerDashboardTotalEarningsLoading === false &&
        report.providerDashboardTotalEarningsFailure === true &&
        report.providerDashboardTotalEarningsSuccess === false &&
        report.providerDashboardTotalEarningsError && (
          <ErrorMessage message={report.providerDashboardTotalEarningsError} />
        )}
    </>
  );
}
