import React from "react";

export default function NoPage() {
    return (
        <div>
            <h2>
                {"No Page Found."}
            </h2>
        </div>
    )
}