import React, { useEffect, useState } from "react";
import { BsArrowDown, BsArrowLeft, BsArrowUp } from "react-icons/bs";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { PATH } from "../../../config";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetCoachingSalesList, GetCourseSalesList, GetTotalCoachingSales, GetTotalCoursesSales } from "../../../redux/actions";
import { useAuth } from "../../../Navigation/Auth/ProvideAuth";
import { dateTimeConvert, ErrorMessage, Loader } from "../../../assets";
import MyDataTable from "../../../assets/genericComponents/MyDataTable";
import { RiArrowUpDownFill } from "react-icons/ri";
const { SearchBar } = Search;
export default function TotalSales() {
  const [key, setKey] = useState(3);
  let dispatch = useDispatch();
  let auth = useAuth()
  useEffect(() => {
    dispatch(GetCourseSalesList(auth));
    dispatch(GetCoachingSalesList(auth));
  }, []);
  return (
    <>
      <Link to={PATH.TEACHERDASHBOARD}>
        <BsArrowLeft size={35} className="mr-2" />
      </Link>
      <div
        className={`my-4 card card-custom w-100 bg-dark admin-course-request`}
      >
        <div className="card-header ">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className=" font-weight-700 mb-0">Total Sales</h3>
          </div>
        </div>
      </div>
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        // onSelect={(k) => Call(k)}
        className="explore_tabs"
      >
        <Tab eventKey={3} title="Courses">
          <TotalCourses />
        </Tab>
        <Tab eventKey={2} title="Coaching">
          <TotalCoaching />
        </Tab>
      </Tabs>
    </>
  );
}
function TotalCourses() {
  let totalCoursesSale = useSelector((state) => state.dashboardTiles);
  let data = totalCoursesSale && totalCoursesSale.getCourseSalesList;
  console.log("sadksagdhsadsa", data)
  let columns = [
    {
      text: 'ID',
      dataField: 'id',
      formatter: (cell, row, index) => {
        return (
          <>
            {index + 1}
          </>
        )
      }
    },
    {
      dataField: "courseTitle",
      text: "Course Title",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.courseTitle ? row.courseTitle : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.companyName ? row.companyName : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {dateTimeConvert(row.date) ? dateTimeConvert(row.date) : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "enrollments",
      text: "Enrollment",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.enrollments ? row.enrollments : "N/A"}
            </>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {totalCoursesSale &&
        totalCoursesSale.getCourseSalesListLoading === true &&
        totalCoursesSale.getCourseSalesListSuccess === false &&
        totalCoursesSale.getCourseSalesListFailure === false && <Loader />}
      {totalCoursesSale &&
        totalCoursesSale.getCourseSalesListLoading === false &&
        totalCoursesSale.getCourseSalesListSuccess === false &&
        totalCoursesSale.getCourseSalesListFailure === true && (
          <ErrorMessage message={totalCoursesSale.getCourseSalesListError} />
        )}
      {totalCoursesSale &&
        totalCoursesSale.getCourseSalesListLoading === false &&
        totalCoursesSale.getCourseSalesListSuccess === true &&
        totalCoursesSale.getCourseSalesListFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>

  )
}
function TotalCoaching() {
  let totalCoachingSale = useSelector((state) => state.dashboardTiles);
  let data = totalCoachingSale && totalCoachingSale.getCoachingSalesList;
  console.log("sakdgsahdwq", data)
  let columns = [
    {
      text: 'ID',
      dataField: 'id',
      formatter: (cell, row, index) => {
        return (
          <>
            {index + 1}
          </>
        )
      }
    },
    {
      dataField: "coachingId",
      text: "CoachingID",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.coachingId ? row.coachingId : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "coachingName",
      text: "Coaching Name",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.coachingName ? row.coachingName : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.price ? row.price : "Free"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "date",
      text: "Date",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {dateTimeConvert(row.date) ? dateTimeConvert(row.date) : "N/A"}
            </>
          </span>
        );
      },
    },
    {
      dataField: "enrollments",
      text: "Enrollment",
      sort: 'true',
			sortCaret: (order, column) => {
				if (!order) {
					return <RiArrowUpDownFill className="left-margin-2 cursor text-brand-primary" size={18} />
				}
				return (
					<React.Fragment>
						{
							order === 'asc' &&
							<BsArrowUp size={20} className="text-brand-primary cursor" />
						}
						{
							order === 'desc' &&
							<BsArrowDown size={20} className="text-brand-primary cursor" />
						}
					</React.Fragment>
				)
			},
      formatter: (cell, row) => {
        console.log("row", cell, row)
        return (
          <span>
            <>
              {row.enrollments ? row.enrollments : "N/A"}
            </>
          </span>
        );
      },
    },
  ];
  let defaultSorted = [
    {
      dataField: "addCatagory",
      order: "asc",
    },
  ];
  return (
    <>
      {totalCoachingSale &&
        totalCoachingSale.getCoachingSalesListLoading === true &&
        totalCoachingSale.getCoachingSalesListSuccess === false &&
        totalCoachingSale.getCoachingSalesListFailure === false && <Loader />}
      {totalCoachingSale &&
        totalCoachingSale.getCoachingSalesListLoading === false &&
        totalCoachingSale.getCoachingSalesListSuccess === false &&
        totalCoachingSale.getCoachingSalesListFailure === true && (
          <ErrorMessage message={totalCoachingSale.getCoachingSalesListError} />
        )}
      {totalCoachingSale &&
        totalCoachingSale.getCoachingSalesListLoading === false &&
        totalCoachingSale.getCoachingSalesListSuccess === true &&
        totalCoachingSale.getCoachingSalesListFailure === false && (
          <div className="admin-side-table-styling card p-4">
            <MyDataTable
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
            // handleDataChange={handleDataChange}
            />
          </div>
        )}
    </>

  )
}

