import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
export default function MyDataTable(props) {
  const { SearchBar } = Search;
  return (
    <>
      {props.data && props.data.length > 0 ? (
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={props.data}
          columns={props.columns}
          columnToggle
          search
        >
          {(props) => (
            <div>
              <div className="d-flex justify-content-between pt-3 pb-4 mt-0">
                <SearchBar
                  {...props.searchProps}
                  style={{ width: "250px", height: "40px" }}
                />
              </div>
              <BootstrapTable
                wrapperClasses="table-responsive"
                {...props.baseProps}
                // filter={filterFactory()}
                pagination={paginationFactory()}
                defaultSorted={props.defaultSorted}
                onDataSizeChange={props.handleDataChange}
                noDataIndication="No matching results found"
                hover
                condensed
              />
            </div>
          )}
        </ToolkitProvider>
      ) : (
        <p className="text-center">No Record Found</p>
      )}
    </>
  );
}
