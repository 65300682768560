import { ADMIN_REQUEST } from "../actions/utilities";
const INITIAL_STATE = {
  manageUserLoading: false,
  manageUserSuccess: false,
  manageUserFailure: false,
  manageUserError: null,
  manageUser: [],
};

export const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_REQUEST.MANAGE_USER_REQUEST:
      return {
        ...state,
        manageUserLoading: true,
        manageUserSuccess: false,
        manageUserFailure: false,
        manageUserError: null,
      };
    case ADMIN_REQUEST.MANAGE_USER_SUCCESS:
      return {
        ...state,
        manageUserLoading: false,
        manageUserSuccess: true,
        manageUserFailure: false,
        manageUserError: null,
        manageUser: action.payload,
      };
    case ADMIN_REQUEST.MANAGE_USER_FAILURE:
      return {
        ...state,
        manageUserLoading: false,
        manageUserSuccess: false,
        manageUserFailure: true,
        manageUserError: action.payload,
      };
    default:
      return state;
  }
};
