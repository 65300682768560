import axios from "axios";
import { APP_SETTINGS } from "../../config";
export function createContact(data, auth) {
  return axios.post(APP_SETTINGS.API_PATH.CONTACTUS.createContact, data, {
    headers: {
      Authorization: "Bearer " + auth.edu_tech_user.token,
    },
  });
}
export function getByFilterConatctUs() {
  return axios.get(`${APP_SETTINGS.API_PATH.CONTACTUS.getByFilterConatctUs}`);
}